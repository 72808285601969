import { CSSProperties } from "react";
import { getTheme, mergeStyleSets } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  root: CSSProperties;
  iconeRemover: CSSProperties;
}

export const styles: IStyle = {
  root: {
    display: "flex",
    flex: 1,
    height:260
  },
  iconeRemover: {
    color: tema.semanticColors.errorText
  },
};

export const classNames = mergeStyleSets({
  tabela: {
    flex: 1,
  },
  celulaDireita: {
    textAlign: 'right',
  },
  celulaCentro: {
    textAlign: 'center',
    paddingRight: 12,
  },
  iconeArquivoCabecalho: {
    padding: 0,
    fontSize: '16px',
  },
  iconeArquivoCelula: {
    display: 'flex!important' as any,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5px!important',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
});