import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  versao?: string;
  existeLogo?: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IDefinir { type: "UNIDADE::DEFINIR" }

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = IDefinir;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  obter: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "UNIDADE::DEFINIR", ...({ versao: "3.0.0", existeLogo: false } as State) });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return {
      versao: undefined,
      existeLogo: undefined
    };
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {
    case "UNIDADE::DEFINIR":
      return { versao: action.versao || state?.versao || "", existeLogo: action.existeLogo || state?.existeLogo || false };
    default:
      return state;
  }
};
