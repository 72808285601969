import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  IDropdownOption,
  ITextField,
  SelectionMode,
  IColumn,
  Dropdown,
} from "@fluentui/react";
import { UseTranslationResponse, useTranslation } from "../../i18n";

import { getStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";
import {
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_Login,
  Objeto_EmpresaObjeto_MotivoTransferencia,
  Objeto_ServicoObjeto_SolicitacaoWeb,
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import { PesquisaModal } from "../../components/PesquisaModal";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";

const TransferenciaContrato: React.FC = () => {
  const styles = getStyles();

  const matriculaTFRef = useRef<ITextField>(null);

  const { t }: UseTranslationResponse = useTranslation();

  const dispatch = useDispatch<any>();
  const {
    autenticacao,
    empresa: {
      planos,
      contratoAtivo,
      motivosTransferencia,
    }
  } = useSelector((store: ApplicationState) => store);
  const usuario: Objeto_EmpresaObjeto_Login.Resposta | undefined = autenticacao.usuario;

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [contratoSelecionado, definirContratoSelecionado] = useState<string>();
  const [plano, definirPlano] = useState<string>();

  const [motivoModalAberto, definirMotivoModalAberto] = useState<boolean>();
  const [motivoSelecionado, definirMotivoSelecionado] = useState<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta | undefined>();

  const [descricao, definirDescricao] = useState<string | undefined>("");

  useEffect(() => {
    dispatch(EmpresaStore.actions.obterEmpresas());
    dispatch(EmpresaStore.actions.obterMotivosTransferencia());
  }, [dispatch]);

  const contratos = planos.reduce((acc, curr) => {
    if (!acc.find(e => e.key === curr.SCACONTR_CODIGO)) {
      acc.push({
        key: curr.SCACONTR_CODIGO as string,
        text: `${curr.SCACONTR_CODIGO} - ${curr.SCAEMPBA_NOME}`,
      });
    }

    return acc;
  }, [] as Array<IDropdownOption>);

  const planoDestinto = planos.filter(p => p.SCACONTR_CODIGO === contratoSelecionado).map(e => ({ key: e.SGEPLANO_DESCRICAO, text: e.SGEPLANO_DESCRICAO })) as Array<IDropdownOption>;

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("transferencia-contrato")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Stack>
          <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <TextField
              componentRef={matriculaTFRef}
              required
              readOnly
              autoFocus
              styles={{ root: { flex: 1, maxWidth: "20em" } }}
              label={t("matricula")} iconProps={{ iconName: 'Search' }}
              value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
              onClick={() => definirBeneficiarioModalAberto(true)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  definirBeneficiarioModalAberto(true);
                }
              }}
            />
            <TextField disabled={!beneficiarioSelecionado} readOnly styles={styles.textInput} label={t("nome-beneficiario")} value={beneficiarioSelecionado?.SGEPESS_NOME || ""} />
          </Stack>
        </Stack>

        <Stack>
          <Label style={styles.subTitle}>{t("plano-atual")}</Label>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <TextField
              disabled
              readOnly
              styles={styles.textInput}
              label={t("contrato")}
              value={planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.chain((e: any) => `${e.SCAEMPBA_CODIGO} - ${e.SCAEMPBA_NOME}`)} />
            <TextField
              disabled
              readOnly
              styles={{ root: { flex: 1, maxWidth: 420 } }}
              label={t("plano")} value={beneficiarioSelecionado ? planos.find((e) => e.SCACONTR_CODIGO === beneficiarioSelecionado.SCACONTR_CODIGO)?.SGEPLANO_DESCRICAO : ""} />
          </Stack>
        </Stack>

        <Stack>
          <Label style={styles.subTitle}>{t("novo-plano")}</Label>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <Dropdown
              required
              disabled={!beneficiarioSelecionado}
              styles={{ root : { flex: 1 } }}
              label={t("contrato")}
              options={contratos || []}
              selectedKey={contratoSelecionado}
              onChange={(evt, item) => definirContratoSelecionado(item?.key as string)}
            />
            <Dropdown
              required
              disabled={!beneficiarioSelecionado}
              styles={{ root: { flex: 1 } }}
              label={t("plano")}
              options={planoDestinto || []}
              selectedKey={plano}
              onChange={(evt, item) => definirPlano(item?.key as string)}
            />
          </Stack>
        </Stack>

        <TextField
          required
          readOnly
          disabled={!beneficiarioSelecionado}
          label={t("motivo-transferencia-plano")}
          iconProps={{ iconName: 'Search' }}
          value={motivoSelecionado ? `${ motivoSelecionado.SGEMOTIV_CODIGO } - ${ motivoSelecionado.SGEMOTIV_DESCRICAO }` : ""}
          onClick={() => definirMotivoModalAberto(true)}
          onKeyPress={e => {
            if (e.key === "Enter") {
              definirMotivoModalAberto(true);
            }
          }}
        />

        <TextField
          label={t("descricao")}
          multiline
          autoAdjustHeight
          value={descricao}
          onChange={(evt, texto) => definirDescricao(texto)}
        />

        <div style={{ flex: 1 }} />

        <Stack.Item align="end">
          <BotaoSolicitacaoWeb
            disabled={!(
              beneficiarioSelecionado
              && contratoSelecionado
              && plano
              && motivoSelecionado
            )}
            text={t("salvar")}
            iconProps={{ iconName: "Save" }}
            onClick={() => (
              dispatch(EmpresaStore.actions.solicitarTransferenciaContrato({
                TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao.TRANSFERENCIA_CONTRATO,
                TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
                CD_USUARIO_CRIACAO: usuario?.SCAEMPBA_NOME as string,
                ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
                ID_SGEUNID: usuario?.ID_SGEUNID as number,
                DS_OBSERVACAO: descricao as string,
                CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
                ID_SCAEMPBA: usuario?.ID_SCAEMPBA as number,
                NM_SEGURADO: beneficiarioSelecionado?.SGEPESS_NOME as string,
                SN_TITULAR: beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "S" : "N",
                TP_USUARIO: beneficiarioSelecionado?.SCADEPEN_QUALIFICACAO as string,
                NR_CPF: beneficiarioSelecionado?.SGEPESS_FCPF as string,
                ID_SCAEMPBA_TRANSFERENCIA: usuario?.ID_SCAEMPBA as number,
                ID_SGEPLANO: planos.filter(e => e.SCACONTR_CODIGO === contratoSelecionado)[0].ID_SGEPLANO as number,
                CD_MOTIVO_ALTERACAO_ANS: parseInt(motivoSelecionado?.SGEMOTIV_CODIGO || ""),
              }))
            )}
          />
        </Stack.Item>
      </Stack>

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario);
          definirBeneficiarioModalAberto(false);
        }}
      />

      <PesquisaModal<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta>
        titulo={t("listagem-motivos-transferencia-plano")}
        itens={motivosTransferencia}
        placeholderCampoPesquisa={t("pesquisar")}
        tituloBotaoSelecionar={t("ok")}
        isBlocking={false}
        isOpen={motivoModalAberto}
        onDismiss={(motivo) => {
          definirMotivoSelecionado(motivo);
          definirMotivoModalAberto(false);
        }}
        selectionMode={SelectionMode.single}
        columns={[
          {
            key: "codigo",
            name: t("codigo"),
            ariaLabel: t("codigo"),
            fieldName: "SGEMOTIV_CODIGO",
            minWidth: 110,
            maxWidth: 110,
          },
          {
            key: "empresa",
            name: t("motivo"),
            ariaLabel: t("motivo"),
            fieldName: "SGEMOTIV_DESCRICAO",
          },
        ] as Array<IColumn>}
      />
    </div>
  );
}

export default TransferenciaContrato;
