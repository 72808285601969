import { CSSProperties } from "react";
import { FontSizes } from '@uifabric/fluent-theme';
import { FontWeights, IDropdownStyles, mergeStyleSets, getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  title: CSSProperties;
  subTitle: CSSProperties;
  body: CSSProperties;
  dataLabel: CSSProperties;
  dropdown: Partial<IDropdownStyles>;
  celulaDireita: CSSProperties;
  celulaCento: CSSProperties;
  botaoTabela: CSSProperties;
  carregandoModalContainer: CSSProperties;
}

export const styles: IStyle = {
  title: {
    fontSize: "1.5em",
    fontWeight: FontWeights.regular as any
  },
  subTitle: {
    fontSize: FontSizes.size20,
    fontWeight: FontWeights.regular as any
  },
  body: {
    marginTop: "1em",
    flex: 1,
  },
  dataLabel: {
    fontWeight: FontWeights.regular as any
  },
  dropdown: {
    root: { position: "absolute", right: 0, left: 0 },
    dropdown: { minWidth: "12.5em" },
  },
  celulaDireita: {
    textAlign: "right",
  },
  celulaCento: {
    textAlign: "center",
  },
  botaoTabela: {
    color: tema.semanticColors.bodyText,
    height: "auto",
    margin: ".25em",
    display: "inherit",
  },
  carregandoModalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
};

export const classNames = mergeStyleSets({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "1.25em",
    selectors: {
      '& .ms-DetailsHeader-cellIsCheck, .ms-DetailsRow-cellCheck, .ms-Shimmer-shimmerWrapper > div > :first-child': {
        display: "none",
      },
    },
  },
  labelPlano: {
    flex: 1,
    minHeight: 61,
    selectors: {
      '& > label': {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
  }
});
