import React, { useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import { IContextualMenuProps, IconButton, CommandBarButton } from "office-ui-fabric-react";
import { getTheme, IContextualMenuItem } from "@fluentui/react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { i18n as II18n } from "i18next";

import { ApplicationState } from "../../store";
import * as Configuracao from '../../store/Configuracao';

import { styles } from "./styles";
import logo from "./logo_mv.png";

const renderizarMenuItem = ({ key, name, text, i18n }: IContextualMenuItem & II18n, dismissMenu: () => void): JSX.Element => (
  <CommandBarButton style={{ width: "100%", padding: 4 }} onClick={() => {
      i18n.changeLanguage(key);
      dismissMenu();
      Configuracao.actions.definirIdioma(key);
      localStorage.setItem("idioma", key);
    }}>
    {/* <Flag code={name} size={24} type="flat" /> */}
    <span style={styles.menuItemText}>{text}</span>
  </CommandBarButton>
);

const Rodape: React.FC = () => {
  const { unidade, configuracao } = useSelector(state => state as ApplicationState);
  const dispatch = useDispatch();

  const { t, i18n }: UseTranslationResponse = useTranslation();
  const tema = getTheme();

  const menuProps: IContextualMenuProps = {
    items: Object.keys(i18n.options.resources || []).map(key => ({
      key,
      i18n,
      text: i18n.options.resources?.[key].name as string,
      name: key.slice(-2).toUpperCase(),
      dispatch,
      onRender: renderizarMenuItem,
    })),
    useTargetAsMinWidth: true,
    styles: { list: { marginTop: 8, marginBottom: 8 } },
  };

  const idiomaBotao = useMemo(() => (
    <IconButton
      menuProps={menuProps}
      menuIconProps={{ iconName: "ChevronUpMed", style: { color: tema.semanticColors.bodyText } }}
      title={t("mudar-idioma")}
      ariaLabel={t("mudar-idioma")}>
      {/* <Flag code={i18n.language.slice(-2).toUpperCase()} size={24} type="flat" /> */}
    </IconButton>
  ), [configuracao?.idioma, menuProps, tema, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const logoContainer = useMemo(() => (
    <div>
      <img style={styles.mvLogo} src={logo} alt="GPSi" />
    </div>
  ), [styles]);

  return (
    <footer style={styles.footer}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {window.location.pathname.indexOf("/imprimir/") === -1 && idiomaBotao}
        {window.location.pathname.indexOf("/imprimir/") === -1 && (
          <IconButton
            iconProps={{ iconName: "Light", style: { color: tema.semanticColors.bodyText } }}
            title={t("alternar-modo-noturno")}
            onClick={() => dispatch(Configuracao.actions.alternarTema())} />
        )}
        <span style={styles.copyright}>
          &#x24B8; MV Sistemas {unidade?.versao && `- ${t("versao").toLocaleLowerCase()}: ${unidade.versao}`}
        </span>
      </div>
      {logoContainer}
    </footer>
  );
};

export default Rodape;
