import React, { ReactElement } from "react";

import { Route, Redirect, RouteProps } from "react-router";
import { IItem } from "../Passos/Passos";

export interface IRota extends RouteProps {
  autenticado?: boolean;
  login?: boolean;
  privado?: boolean;
}

const Rota: React.FC<IRota> & { Grupo?: React.FC<IRota> | any }  = ({ autenticado, component: Component, login, privado, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        (login && !privado && !autenticado) || (privado && !login && autenticado) || (!login && !privado) ? Component ? (
          <Component {...props} />
        ) : null : (
            <Redirect
              to={{
                pathname: (login && !privado && autenticado) || (privado && !login && autenticado) ? "/" : "/login",
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
}

export const Grupo: React.FC<IRota> = ({ autenticado, children, login, privado }) => (
  <>
    {React.Children.map(children as ReactElement<IRota>, (child: ReactElement<IRota>) =>
      React.cloneElement(child, {
        autenticado: child.props.autenticado !== undefined ? child.props.autenticado : autenticado,
        login: child.props.login !== undefined ? child.props.login : login,
        privado: child.props.privado !== undefined ? child.props.privado : privado
      })
    )}
  </>
);

Rota.Grupo = Grupo;

export default Rota;
