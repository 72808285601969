import { CSSProperties } from "react";
import { FontSizes } from '@uifabric/fluent-theme';
import { FontWeights, getTheme, ITextFieldProps, mergeStyleSets } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  root: CSSProperties;
  title: CSSProperties;
  subTitle: CSSProperties;
  body: CSSProperties;
  dataLabel: CSSProperties;
  textInput: ITextFieldProps["styles"];
  pivotItem: CSSProperties;
}

export const styles: IStyle = {
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "1.25em",
    flex: 1,
  },
  title: {
    fontSize: "1.5em",
    fontWeight: FontWeights.regular as any
  },
  subTitle: {
    fontSize: FontSizes.size20,
    fontWeight: FontWeights.regular as any
  },
  body: {
    marginTop: "1em",
    flex: 1,
  },
  dataLabel: {
    fontWeight: FontWeights.regular as any
  },
  textInput: {
    root: {
      flex: 1
    }
  },
  pivotItem: {
    margin: "1em"
  },
};

export interface IClassNames {
  celulaDireita: string;
  celulaCentro: string;
  botaoAdicionarDocumento: string;
}

export const classNames: IClassNames = mergeStyleSets({
  celulaDireita: {
    textAlign: 'right',
  },
  celulaCentro: {
    textAlign: 'center',
    paddingRight: 12,
  },
  botaoAdicionarDocumento: {
    marginRight: 8,
    selectors: {
      '& span': {
        fontWeight: 500,
        color: tema.semanticColors.link
      },
      '&:active span': {
        color: tema.semanticColors.linkHovered
      },
      '&:hover .ms-Button-textContainer': {
        textDecoration: "underline"
      },
    },
  }
});