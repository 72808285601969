import React, { useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  IColumn,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IconButton, ITextField
} from "@fluentui/react";
import { UseTranslationResponse, useTranslation, Key } from "../../i18n";

import { getStyles } from "./styles";
import { Tabela } from "../../components/Tabela";
import { styles } from "../DesligamentosAgendados/styles";
import { Objeto_EmpresaObjeto_Beneficiario } from "../../api";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";

const obterColunas = (t: (key: Key) => string): Array<IColumn> => {
  return ([
    {
      key: 'matricula-codigo-solicitacao',
      name: t("matricula-codigo-solicitacao"),
      ariaLabel: t("matricula-codigo-solicitacao"),
      fieldName: 'matricula-codigo-solicitacao',
      minWidth: 160,
      maxWidth: 160,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
    {
      key: 'nome-beneficiario',
      name: t("nome-beneficiario"),
      ariaLabel: t("nome-beneficiario"),
      fieldName: 'nome-beneficiario',
      minWidth: 120,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
    {
      key: 'tipo',
      name: t("tipo"),
      ariaLabel: t("tipo"),
      fieldName: 'tipo',
      minWidth: 120,
      maxWidth: 120,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
    {
      key: "impressao",
      iconName: "Print",
      name: "",
      fieldName: 'arquivo',
      minWidth: 64,
      maxWidth: 64,
      onRender: (item: any) => { // TODO: ADD Type
        return (
          <Stack horizontal style={{ alignContent: "center" }}>
            <IconButton
              styles={{ root: { height: "auto", margin: "0 auto" } }}
              iconProps={{ iconName: "Barcode", style: { ...styles.botaoTabela } }}
              title={t("imprimir-boleto")}
              onClick={() => {
                // TODO: Print
              }} />
          </Stack>
        );
      },
    },
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

const Emissao2ViaCarteirinha: React.FC = () => {
  const styles = getStyles();

  const { t }: UseTranslationResponse = useTranslation();

  const matriculaTFRef = useRef<ITextField>(null);

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("impressao-termos")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label>

        <Stack horizontal tokens={{ childrenGap: "1em" }}>
          <TextField
            componentRef={matriculaTFRef}
            required
            readOnly
            autoFocus
            styles={{ root: { flex: 1, maxWidth: "20em" } }}
            label={t("matricula")} iconProps={{ iconName: 'Search' }}
            value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            onClick={() => definirBeneficiarioModalAberto(true)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                definirBeneficiarioModalAberto(true);
              }
            }}/>
          <TextField
            disabled={!beneficiarioSelecionado}
            readOnly
            styles={styles.textInput}
            label={t("nome-beneficiario")}
            value={beneficiarioSelecionado?.SGEPESS_NOME}/>
        </Stack>

        <Label style={styles.info}>{t("selecione-tipo-termo-desejado")}</Label>

        <Label style={styles.subTitle}>{t("beneficiarios-desta-empresa")}</Label>

        <Tabela
          cabecalhoFixo
          items={[]}
          compact={false}
          columns={obterColunas(t)}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
      </Stack>

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario);
          definirBeneficiarioModalAberto(false);
        }}
      />
    </div>
  );
}

export default Emissao2ViaCarteirinha;
