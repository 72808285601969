import { CSSProperties } from "react";
import { FontWeights, getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  titulo: CSSProperties;
  tituloMargin: CSSProperties;
  container: CSSProperties;
}

export const styles: IStyle = {
  titulo: {
    fontWeight: "bold",
    fontSize: 14,
  },
  tituloMargin:{
    marginLeft: 100,
    fontWeight: "bold",
    fontSize: 14,
  },
  container: {
    maxWidth: 400,
    margin: "0 auto",
    border: "1px solid #ccc",
  }

};
