import { CSSProperties } from "react";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";
import { getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  footer: CSSProperties;
  copyright: CSSProperties;
  mvLogo: CSSProperties;
  menuItemText: CSSProperties;
}

export const styles: IStyle = {
  footer: {
    position: "relative",
    minHeight: "2.25em",
    display: "flex",
    flexDirection: "row",
    backgroundColor: tema.semanticColors.bodyBackground,
    borderTopColor: tema.semanticColors.disabledBorder,
    borderTopWidth: 1,
    maxWidth: "none",
    padding: ".75em",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: Depths.depth8,
    zIndex: 9999,
  },
  copyright: {
    marginLeft: ".5em",
    fontSize: ".75em",
    color: tema.semanticColors.bodyText,
  },
  mvLogo: {
    width: "4em",
    height: "1.25em",
    margin: "5 0",
  },
  menuItemText: {
    marginLeft: ".5em",
    flex: 1,
    textAlign: "left",
    color: tema.semanticColors.bodyText,
  },
};
