import { Action, Reducer } from "redux";
import { loadTheme } from "@fluentui/react";

import { AppThunkAction } from ".";
import temas from "../theme";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  idioma: string;
  tema: "dark" | "light";
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IDefinirIdioma { type: "CONFIGURACAO::DEFINIR_IDIOMA" }
export interface IDefinirTema { type: "CONFIGURACAO::DEFINIR_TEMA" }

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = IDefinirIdioma | IDefinirTema;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  definirIdioma: (idioma: string): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "CONFIGURACAO::DEFINIR_IDIOMA", ...{ idioma } });
  },
  alternarTema: (tema?: "dark" | "light"): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const t = tema ? tema : getState().configuracao?.tema === "light" ? "dark" : "light";

    loadTheme(temas[t]);
    localStorage.setItem("tema", t);
    dispatch({ type: "CONFIGURACAO::DEFINIR_TEMA", ...{ tema: t } });
  },
};

// ----------------
// DEFAULT STORE - For initial state.

const defaultStore: State = {
  idioma: localStorage.getItem("idioma") || "pt-BR",
  tema: localStorage.getItem("tema") as any || "light",
};

loadTheme(temas[defaultStore.tema]);

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return defaultStore;
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {
    case "CONFIGURACAO::DEFINIR_IDIOMA":
      return { ...state, idioma: action.idioma };
    case "CONFIGURACAO::DEFINIR_TEMA":
      return { ...state, tema: action.tema };
    default:
      return state;
  }
};
