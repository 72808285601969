import React, { useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  IColumn,
  SelectionMode,
  DetailsListLayoutMode,
  ITextField
} from "@fluentui/react";
import { UseTranslationResponse, useTranslation, TFunction } from "../../i18n";

import { getStyles } from "./styles";
import { Tabela } from "../../components/Tabela";
import { Objeto_EmpresaObjeto_Beneficiario } from "../../api";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";

const obterColunas = (t: TFunction): Array<IColumn> => {
  return [
    {
      key: 'digitais-cadastradas',
      name: t("digitais-cadastradas"),
      ariaLabel: t("digitais-cadastradas"),
      fieldName: 'digitais-cadastradas',
      minWidth: 140,
      maxWidth: 140,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
    {
      key: 'data-cadastro',
      name: t("data-cadastro"),
      ariaLabel: t("data-cadastro"),
      fieldName: 'data-cadastro',
      minWidth: 140,
      maxWidth: 140,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
    {
      key: 'api',
      name: t("api"),
      ariaLabel: t("api"),
      fieldName: 'api',
      minWidth: 260,
      onRender: () => { // TODO: ADD Type
        return null;
      },
    },
  ];
}

const Emissao2ViaCarteirinha: React.FC = () => {
  const styles = getStyles();

  const { t }: UseTranslationResponse = useTranslation();

  const matriculaTFRef = useRef<ITextField>(null);

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("cadastro-biometrico")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Stack>
          <Label style={styles.info}>{t("selecione-matricula-deseja-cadastrar")}</Label>
          <Label style={styles.info}>{t("apenas-dedos-indicadores")}</Label>
          <Label style={styles.info}>{t("caso-necessite-remover-cadastrar")}</Label>
        </Stack>

        <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label>

        <Stack horizontal tokens={{ childrenGap: "1em" }}>
          <TextField
            componentRef={matriculaTFRef}
            required
            readOnly
            autoFocus
            styles={{ root: { flex: 1, maxWidth: "20em" } }}
            label={t("matricula")} iconProps={{ iconName: 'Search' }}
            value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            onClick={() => definirBeneficiarioModalAberto(true)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                definirBeneficiarioModalAberto(true);
              }
            }}/>
          <TextField
            disabled={!beneficiarioSelecionado}
            readOnly
            styles={styles.textInput}
            label={t("nome-beneficiario")}
            value={beneficiarioSelecionado?.SGEPESS_NOME}/>
        </Stack>

        <Tabela
          cabecalhoFixo
          styles={{ root: { minHeight: 320 } }}
          items={[]}
          compact={false}
          columns={obterColunas(t)}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
      </Stack>

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario);
          definirBeneficiarioModalAberto(false);
        }}
      />
    </div>
  );
}

export default Emissao2ViaCarteirinha;
