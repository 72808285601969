import React, {useMemo, useEffect, useState, CSSProperties, useRef} from "react";
import {
  getTheme,
  IDropdownOption,
  Stack,
  Dropdown,
  Label,
  IColumn,
  Pivot,
  PivotItem,
  CommandButton,
  IconButton, SelectionMode, Checkbox, Modal, Spinner, SpinnerSize, Link,
} from "@fluentui/react";
import {SharedColors} from "@uifabric/fluent-theme";
import Axios, { CancelTokenSource } from "axios";

import { UseTranslationResponse, TFunction, useTranslation } from "../../i18n";
import { styles, IStyle, classNames } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_EmpresaObjeto_Boleto,
  Objeto_EmpresaObjeto_Fatura,
  Objeto_EmpresaObjeto_FaturaContrato, 
  Objeto_EmpresaObjeto_Login, 
  Objeto_Servico
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import {EmpresaAPI} from "../../store/Empresa";
import {aplicarTransparencia} from "../../utils";
import { Tabela } from "../../components/Tabela";
import { stringify } from "querystring";

const obterBoleto = (ID_SCOTITUL: number, CD_MULTI_EMPRESA: number, SCACONTR_CODIGO: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterBoleto/${ID_SCOTITUL}/${CD_MULTI_EMPRESA}/${SCACONTR_CODIGO}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterRelatorio = (ID_SCOTITUL: number, CD_MULTI_EMPRESA: number, SCACONTR_CODIGO: string, TIPO: Objeto_Servico.TipoRelatorio, QUEBRA: "S" | "N", CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterRelatorio/${ID_SCOTITUL}/${CD_MULTI_EMPRESA}/${SCACONTR_CODIGO}/${TIPO}/${QUEBRA}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

/* const obterRelatorio = (ID_SCOTITUL: number, CD_MULTI_EMPRESA: number, SCACONTR_CODIGO: string, TIPO: Objeto_Servico.TipoRelatorio, QUEBRA: "S" | "N", CANCEL?: CancelTokenSource) => Axios({
  //url: `/api/Servico/ObterRelatorio/${ID_SCOTITUL}/${CD_MULTI_EMPRESA}/${SCACONTR_CODIGO}/${TIPO}/${QUEBRA}`,
  baseURL: `https://online.unimedcl.com.br:8443/`,
  url: `/gpsireport/reportrunner?portal=true&mensContrato=1276662&cdMultiEmpresa=79&cdContrato=002539&tpBoleto=3&qpag=N&snQuebraCentroCusto=N`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
  timeout:0,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
}); */

const obterArquivo = (SWEDOCUM_DS_CAMINHO_ARQUIVO: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterArquivo/${encodeURI(SWEDOCUM_DS_CAMINHO_ARQUIVO)}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterColunasFatura = (t: TFunction, styles: IStyle, SCACONTR_CODIGO: string, definirDocumentoModalUrl: (url: string | undefined) => void, abreviadoNome: string | undefined,  CNPJ: string | undefined, cancelToken?: CancelTokenSource): Array<IColumn> => {
  return ([
    {
      key: "codigo",
      name: t("codigo"),
      minWidth: 60,
      maxWidth: 120,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.ID_SCOTITUL}</div>
        );
      },
    },
    {
      key: "numero",
      name: t("numero"),
      minWidth: 60,
      maxWidth: 120,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SCOTITUL_NOSSO_NUMERO}</div>
        );
      },
    },
    {
      key: "ano-mes",
      name: t("ano-mes"),
      minWidth: 60,
      maxWidth: 120,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.DT_COMPETENCIA?.toString().replace(/(\d{4})(\d{2})/, "$1/$2")}</div>
        );
      },
    },
    {
      key: "emissao",
      name: t("emissao"),
      minWidth: 90,
      maxWidth: 180,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SCOTITUL_DATA_EMISSAO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "data-vencimento",
      name: t("data-vencimento"),
      minWidth: 90,
      maxWidth: 180,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SCOTITUL_DATA_VCTO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "valor",
      name: t("valor"),
      minWidth: 70,
      maxWidth: 140,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <div style={styles.celulaDireita}>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.SCOTITUL_VALOR_DOCUMENTO || 0)}</div>
        );
      },
    },
    {
      key: "data-pagamento",
      name: t("data-pagamento"),
      minWidth: 90,
      maxWidth: 180,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return item?.SCOTITUL_DATA_BAIXA ? (
          <div style={styles.celulaCento}>{item.SCOTITUL_DATA_BAIXA?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        ) : null;
      },
    },
    {
      key: "atraso",
      name: t("atraso"),
      minWidth: 50,
      maxWidth: 100,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return item?.SCOTITUL_DATA_BAIXA ? (
          <div style={{ ...styles.celulaCento, ...( item.ATRASO ? { color: "red" } : null )}}>{item.ATRASO}</div>
        ) : null;
      },
    },
    {
      key: "valor-pago",
      name: t("valor-pago"),
      minWidth: 70,
      maxWidth: 140,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return item?.SCOTITUL_DATA_BAIXA ? (
          <div style={{ ...styles.celulaCento, ...((item.SCOTITUL_VALOR_PAGO || 0) < (item.SCOTITUL_VALOR_DOCUMENTO || 0) ? { color: "red" } : { color: "green" }) }}>
            {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.SCOTITUL_VALOR_PAGO || 0)}
          </div>
        ) : null;
      },
    },
    {
      key: "boleto",
      iconName: "Print",
      minWidth: 70,
      maxWidth: 140,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        // TDOD: && PORTAL_PERMITE_IMPRESSAO_BOLETO_EMPRESA
        const botaoBoletoHabilitado = item.TP_QUITACAO === "A" && !item.DT_CANCELAMENTO;

        return (
          <Stack horizontal style={{ alignContent: "center" }}>
            <IconButton
              disabled={!botaoBoletoHabilitado}
              styles={{ root: { height: "auto", margin: "0 auto", opacity: botaoBoletoHabilitado ? 1 : .2 } }}
              iconProps={{ iconName: "Barcode", style: { ...styles.botaoTabela } }}
              title={t("imprimir-boleto")}
              onClick={() => {
                if(abreviadoNome == 'UNIMED INCONFIDENTES' ){
                  window.open(`http://boleto.unimedinconfidentes.coop.br/boleto/Default.asp?codSacado=${item.JCNPJ}`)
                }
                else if(abreviadoNome === 'UNIMED PIRASSUNUNGA'){
                  window.open(`http://unipira.duckdns.org:8888/Go.asp?inlineDefaultRadios=CNPJ&codSacado=${CNPJ}&gerar=`)
                }
                else{
                  definirDocumentoModalUrl("carregando");
                  obterBoleto(item.ID_SCOTITUL as number, item.CD_MULTI_EMPRESA as number, SCACONTR_CODIGO, cancelToken).then(url => {
                  definirDocumentoModalUrl(url);
                  });
                }
              }} />
          </Stack>
        );
      },
    }
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

const obterColunasFaturaContrato = (t: TFunction, styles: IStyle, boletosFaturaAtiva: Array<Objeto_EmpresaObjeto_FaturaContrato.Resposta>): Array<IColumn> => {
  const grupos = boletosFaturaAtiva.reduce((a, { CARTAO_TITULAR_CODIGO }) => (a[CARTAO_TITULAR_CODIGO as string] = (a[CARTAO_TITULAR_CODIGO as string] || 0) + 1, a), Object.create(null));
  const cores = Object.values(SharedColors);

  return ([
    {
      key: "matricula",
      name: t("matricula"),
      maxWidth: 180,
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SCACARTE_CODIGO_CART}</div>
        );
      },
    },
    {
      key: "nome-beneficiario",
      name: t("nome-beneficiario"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div>{item.SGEPESS_NOME}</div>
        );
      },
    },
    {
      key: "data-adesao",
      name: t("data-adesao"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SCACONBE_INICIO_VIGENCIA?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "data-nascimento",
      name: t("data-nascimento"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.SGEPESS_FDATA_NASCIMENT?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "tipo-beneficiario",
      name: t("tipo-beneficiario"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        const cor = cores[Object.keys(grupos).indexOf(item.CARTAO_TITULAR_CODIGO as string) % 12];

        const dependenciaStyle: { [key: string]: CSSProperties } | undefined = cor ? {
          TITULAR: { backgroundColor: aplicarTransparencia(cor, .7), borderRadius: 3, color: "#fff", padding: 2 },
          DEPENDENTE: { border: `2px solid ${aplicarTransparencia(cor, .7)}`, borderRadius: 3, color: cor },
        }: undefined;

        return (
          <div style={{ ...styles.celulaCento, ...(dependenciaStyle ? dependenciaStyle[item.SN_TITULAR as string] : {}) }}>{item.SN_TITULAR}</div>
        );
      },
    },
    {
      key: "valor-total",
      name: t("valor-total"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div style={styles.celulaDireita}>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.SMNFATBM_VALOR || 0)}</div>
        );
      },
    },
    {
      key: "rateio-despesas",
      name: t("rateio-despesas"),
      onRender: (item: Objeto_EmpresaObjeto_FaturaContrato.Resposta) => {
        return (
          <div style={styles.celulaCento} />
        );
      },
    },
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

export enum PivotItens {
  DETALHAMENTO_FATURA = "DETALHAMENTO_FATURA",
  MENSAGEM_REAJUSTE = "MENSAGEM_REAJUSTE",
}

const InformacoesFinanceiras: React.FC = () => {
  const tema = getTheme();

  const dispatch = useDispatch();
  const {
    autenticacao: {
      usuario
    },
    empresa: {
      faturas,
      planos,
      contratoAtivo,
      nomeAbreviado,
      anexo,
      tipoArquivo
    }
  } = useSelector((store: ApplicationState) => store);

  const { t }: UseTranslationResponse = useTranslation();

  const [documentoModalUrl, definirDocumentoModalUrl] = useState<string>();
  const [cancelToken] = useState(Axios.CancelToken.source());

  const [planosDistinto, definirPlanosDistinto] = useState<any>();
  const [planoAtivo, definirPlanoAtivo] = useState<string>();
  const [faturaAtiva, definirFaturaAtiva] = useState<Objeto_EmpresaObjeto_Fatura.Resposta>();

  const [boletosFaturaAtiva, definirBoletosFaturaAtiva] = useState<Array<Objeto_EmpresaObjeto_FaturaContrato.Resposta>>([]);

  const [pivotKey, definirPivotKey] = useState<PivotItens>(PivotItens.DETALHAMENTO_FATURA);

  const [quebrarPorCentro, definirQuebrarPorCentro] = useState<boolean>();

  const CNPJ = faturas?.[0]?.JCNPJ;

  //console.log('faturas',faturas);

  const abreviadoNome = nomeAbreviado?.[0]?.SGEUNID_ABREVIADO;

  const mensagemReajuste = planos.filter(p => p.SCACONTR_CODIGO === contratoAtivo).map(e => ({ SCACOMREAJ_MENSAGEM: e.SCACOMREAJ_MENSAGEM }));

  const ID_ORIGEM = planos.filter(p => p.SCACONTR_CODIGO === contratoAtivo).map(e => ({ SCACONPL_ID_SCACONPL: e.SCACONPL_ID_SCACONPL }));

  //console.log('planos', planos);
  //console.log('planoAtivo', planoAtivo);
  //console.log('ID_ORIGEM', ID_ORIGEM?.[0]?.SCACONPL_ID_SCACONPL)
  //console.log('ANEXO', anexo?.[0]?.DESCRICAO +'-'+anexo?.[0]?.DS_CAMINHO_ARQUIVO);

  useEffect(() => {
    if((usuario as Objeto_EmpresaObjeto_Login.Resposta )?.Tipo === 'LOTACAO'){
      dispatch(EmpresaStore.actions.obterPlanosLotacao());
    }else{
      dispatch(EmpresaStore.actions.obterPlanos());
    }
    dispatch(EmpresaStore.actions.obterNomeAbreviado());
    dispatch(EmpresaStore.actions.obterTipoArquivo()) 
  }, [dispatch]);

  //console.log('tipoArquivo', tipoArquivo);

  useEffect(() =>{
    ID_ORIGEM[0] && dispatch(EmpresaStore.actions.obterAnexo(ID_ORIGEM?.[0]?.SCACONPL_ID_SCACONPL as string));
  }, [ contratoAtivo ]);

  useEffect(() => {
    if (contratoAtivo) {
      const contrato = planos.find(e => e.SCACONTR_CODIGO === contratoAtivo);
      contrato && dispatch(EmpresaStore.actions.obterFaturas(
        contrato.SCACONTR_CODIGO as string, 
        (usuario as Objeto_EmpresaObjeto_Login.Resposta )?.Tipo === 'LOTACAO'? (usuario as Objeto_EmpresaObjeto_Login.Resposta) ?.SCAEMPBA_CODIGO : undefined 
        ) );
    }
  }, [dispatch, planos,/* planos por que? */ contratoAtivo]);

  useEffect(() => {
    if (!faturaAtiva) return;

    EmpresaAPI.FaturaContrato({ ID_SCOTITUL: faturaAtiva.ID_SCOTITUL }).then(faturas => {
      definirBoletosFaturaAtiva(faturas.map(f => {
        f.SCACONBE_INICIO_VIGENCIA = f.SCACONBE_INICIO_VIGENCIA ? new Date(f.SCACONBE_INICIO_VIGENCIA) : undefined;
        f.SGEPESS_FDATA_NASCIMENT = f.SGEPESS_FDATA_NASCIMENT ? new Date(f.SGEPESS_FDATA_NASCIMENT) : undefined;

        return f;
      }));
    });
  }, [faturaAtiva]);

  useEffect(() => {
    const p = planos.filter(p => p.SCACONTR_CODIGO === contratoAtivo).map(e => ({ key: e.SGEPLANO_DESCRICAO, text: e.SGEPLANO_DESCRICAO })) as Array<IDropdownOption>;

    definirPlanosDistinto(p);

    if (p.length) {
      definirPlanoAtivo(p[0]?.key as string);
    }
  }, [planos, contratoAtivo]);

  useEffect(() => {
    !faturaAtiva && definirPivotKey(PivotItens.DETALHAMENTO_FATURA);
  }, [faturaAtiva]);

  const impressao = useMemo(() => (
    <CommandButton
      iconProps={{ iconName: 'Print' }}
      text={t("imprimir")}
      menuProps={{
        onRenderMenuList: (menuListProps, defaultRender) => (
          <div>
            <div style={{ padding: 8, maxWidth: 180, borderBottom: '1px solid #ccc' }}>
              <Checkbox
                checked={quebrarPorCentro}
                styles={{ checkbox: { top: "50%", transform: "translateY(-50%)" } }}
                label={t("quebrar-detalhamento-centro-custo")}
                onChange={(ev, isChecked) => definirQuebrarPorCentro(isChecked)} />
            </div>
            {defaultRender && defaultRender(menuListProps)}
          </div>
        ),
        shouldFocusOnMount: true,
        items: [
          {
            key: Objeto_Servico.TipoRelatorio.DETALHAMENTO.toString(),
            text: t("detalhamento"),
            iconProps: { iconName: 'CustomList' },
          },
          {
            key: Objeto_Servico.TipoRelatorio.CO_PARTICIPACOES.toString(),
            text: t("co-participacoes"),
            iconProps: { iconName: 'EngineeringGroup' },
          },
          {
            key: Objeto_Servico.TipoRelatorio.VIDAS_ATIVAS.toString(),
            text: t("vidas-ativas"),
            iconProps: { iconName: 'Group' },
          },
        ],
        onItemClick: (_, button) => {
          definirDocumentoModalUrl("carregando");
          const u = usuario as Objeto_EmpresaObjeto_Login.Resposta;

          obterRelatorio(faturaAtiva?.ID_SCOTITUL as number, faturaAtiva?.CD_MULTI_EMPRESA as number, contratoAtivo as string, parseInt(button?.key as string), quebrarPorCentro ? "S" : "N", cancelToken).then(url => {
            definirDocumentoModalUrl(url);
          });
        }
      }} />
  ), [faturaAtiva, quebrarPorCentro]);

  const tabelaBoletos = useMemo(() => (
    <Tabela<Objeto_EmpresaObjeto_Boleto.Resposta>
      cabecalhoFixo
      items={boletosFaturaAtiva}
      selectionMode={SelectionMode.none}
      enableShimmer={!boletosFaturaAtiva.length}
      columns={obterColunasFaturaContrato(t, styles, boletosFaturaAtiva)}
    />
  ), [boletosFaturaAtiva, quebrarPorCentro])

  const reajusteMensagem = useMemo(() =>{

    function handleClickOnLink(ev: React.MouseEvent<unknown>) {
      definirDocumentoModalUrl("carregando");

      obterArquivo(anexo?.[0]?.DS_CAMINHO_ARQUIVO as string, cancelToken).then(url => {
        definirDocumentoModalUrl(url);
      });
    }

    return(
      <>
    <Label style={styles.dataLabel}>{mensagemReajuste?.[0]?.SCACOMREAJ_MENSAGEM}</Label>
    <Label><Link onClick={handleClickOnLink}>{anexo?.[0]?.DESCRICAO}</Link></Label>
    </>
    );
  },[mensagemReajuste, anexo])

  return (
    <div className={classNames.root}>
      <Label style={styles.title}>{t("informacoes-financeiras")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        {useMemo(() => {
          const ativo = contratoAtivo ? planos.filter(p => p.SGEPLANO_DESCRICAO === planoAtivo)[0] : undefined;

          return (
          <Stack tokens={{ childrenGap: 12 }} horizontal>
            <div style={{ position: "relative", flex: 1, minWidth: 175 }}>
              <Dropdown
                label={t("plano")}
                options={planosDistinto}
                styles={styles.dropdown}
                selectedKey={planoAtivo}
                onChange={(_, item?: IDropdownOption) => definirPlanoAtivo(item?.key as string) }
              />
            </div>
            <Stack style={{ flex: 1 }} tokens={{ childrenGap: 12 }} horizontal>
              <Stack className={classNames.labelPlano}>
                <Label title={t("data-adesao")}>{t("data-adesao")}</Label>
                <Label style={styles.dataLabel} disabled>{ativo?.SCACONTR_DATA_INICIO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</Label>
              </Stack>
              <Stack className={classNames.labelPlano}>
                <Label title={t("tempo-plano")}>{t("tempo-plano")}</Label>
                <Label style={styles.dataLabel} disabled>{ativo?.TEMPO_DE_PLANO}</Label>
              </Stack>
              <Stack className={classNames.labelPlano}>
                <Label title={t("vencimento-contrato")}>{t("vencimento-contrato")}</Label>
                <Label style={styles.dataLabel} disabled>{ativo?.SCACONTR_VALIDADE?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</Label>
              </Stack>
              <Stack className={classNames.labelPlano}>
                <Label title={t("responsavel-financeiro")}>{t("responsavel-financeiro")}</Label>
                <Label title={ativo?.SCAEMPBA_NOME} style={styles.dataLabel} disabled>{ativo?.SCAEMPBA_NOME}</Label>
              </Stack>
            </Stack>
          </Stack>
          )}, [t, planos, contratoAtivo, planosDistinto, planoAtivo])}

        <Label style={styles.subTitle}>{t("relacao-boletos")}</Label>

        {useMemo(() => (
          <Tabela<Objeto_EmpresaObjeto_Fatura.Resposta>
            alternarComClick
            cabecalhoFixo
            items={faturas}
            enableShimmer={!faturas.length}
            styles={{ container: { minHeight: 154 } }}
            columns={obterColunasFatura(t, styles, (usuario as Objeto_EmpresaObjeto_Login.Resposta).SCACONTR_CODIGO as string, definirDocumentoModalUrl, abreviadoNome, CNPJ, cancelToken)}
            selectionMode={SelectionMode.single}
            selecao={(faturas) => {
              definirFaturaAtiva(faturas[faturas.length - 1]);
            }}
          />
        ), [t, faturas])}

        {faturaAtiva ? (
          <Stack style={{ flex: 5, display: "flex", flexDirection: "column", paddingTop: 16, backgroundColor: tema.semanticColors.bodyBackground }}>
            <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center", paddingRight: 18 }}>
              <Pivot
                selectedKey={pivotKey}
                onLinkClick={(item: any) => definirPivotKey(item.props.itemKey)}>
                <PivotItem itemKey={PivotItens.DETALHAMENTO_FATURA} headerText={t("detalhamento-faturas")} style={{ height: "100%" }} />
                <PivotItem itemKey={PivotItens.MENSAGEM_REAJUSTE} headerText={t("mensagem-reajuste")} />
              </Pivot>
              {pivotKey === PivotItens.DETALHAMENTO_FATURA && impressao}
            </Stack>
            {pivotKey === PivotItens.DETALHAMENTO_FATURA && tabelaBoletos}
            {pivotKey === PivotItens.MENSAGEM_REAJUSTE && reajusteMensagem}
          </Stack>
        ) : null}
      </Stack>

      <Modal
        isOpen={!!documentoModalUrl}
        onDismiss={() => {
          definirDocumentoModalUrl(undefined);
          documentoModalUrl === "carregando" && cancelToken.cancel();
        }}
        isBlocking={documentoModalUrl === "carregando" ? true: false}
        styles={{ scrollableContent: { overflow: "hidden" } }}>
        <div style={{ width: "90vw", height: "80vh" }}>
          {documentoModalUrl === "carregando" ? (
            <div style={styles.carregandoModalContainer}>
              <Spinner label={t("carregando-documento")} ariaLive="assertive" labelPosition="left" size={SpinnerSize.large} />
            </div>
          ) : (
            <iframe src={documentoModalUrl} width="100%" height="100%" style={{ overflow: "auto" }} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default InformacoesFinanceiras;
