import { CSSProperties } from "react";
import { FontSizes } from '@uifabric/fluent-theme';
import { FontWeights, ITextFieldProps } from "@fluentui/react";

export interface IStyle {
  root: CSSProperties;
  title: CSSProperties;
  subTitle: CSSProperties;
  body: CSSProperties;
  dataLabel: CSSProperties;
  textInput: ITextFieldProps["styles"];
  pivotItem: CSSProperties;
}

export const getStyles = (): IStyle => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "1.25em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: FontWeights.regular as any,
  },
  subTitle: {
    fontSize: "1.25em",
    fontWeight: FontWeights.regular as any,
  },
  body: {
    flex: 1,
    marginTop: "1em",
  },
  dataLabel: {
    fontWeight: FontWeights.regular as any,
  },
  textInput: {
    root: {
      flex: 1,
    }
  },
  pivotItem: {
    margin: "1em",
  },
});
