import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  API_Controllers, Objeto_ConexaoBanco, Objeto_ServicoObjeto_SolicitacaoWeb, Objeto_Servico_Banco,
  Objeto_Servico_Documentos,
  Objeto_Servico_DocumentosPerfil,
  Objeto_Servico_Municipios,
  Objeto_Servico_Pais,
  Objeto_Servico_Parentesco
} from "../api";
import { obterTipoUsuario, TipoUsuario } from "./Autenticacao";

export const ServicoAPI = new API_Controllers.Servico("");

(window as any).ServicoAPI = ServicoAPI;

export interface IBaseSolicitacaoWeb {
  TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao,
  TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus,
  CD_USUARIO_CRIACAO: string,
  ID_SCACONTR: number,
  ID_SGEUNID: number,
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  parentescos?: Array<Objeto_Servico_Parentesco.Resposta>;
  paises?: Array<Objeto_Servico_Pais.Resposta>;
  municipios?: Array<Objeto_Servico_Municipios.Resposta>;
  bancos?: Array<Objeto_Servico_Banco.Resposta>;
  documentos?: Array<Objeto_Servico_Documentos.Resposta | Objeto_Servico_DocumentosPerfil.Resposta>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ObterParentescosAction {
  type: "SERVICO::PARENTESCOS",
  parentescos?: Array<Objeto_Servico_Parentesco.Resposta>
}

export interface ObterPaisesAction {
  type: "SERVICO::PAISES",
  paises?: Array<Objeto_Servico_Pais.Resposta>
}

export interface ObterMunicipiosAction {
  type: "SERVICO::MUNICIPIOS",
  municipios?: Array<Objeto_Servico_Municipios.Resposta>;
}

export interface ObterBancosAction {
  type: "SERVICO::BANCOS",
  bancos?: Array<Objeto_Servico_Banco.Resposta>
}

export interface IObterDocumentos {
  type: "SERVICO::DOCUMENTOS",
  documentos?: Array<Objeto_Servico_Documentos.Resposta | Objeto_Servico_DocumentosPerfil.Resposta>;
}

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = ObterParentescosAction | ObterPaisesAction | ObterMunicipiosAction | ObterBancosAction | IObterDocumentos;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  obterParentescos: (): AppThunkAction<KnownAction> => (dispatch) => {
    return ServicoAPI.Parentesco().then(parentescos => {
      dispatch({ type: "SERVICO::PARENTESCOS", parentescos });
    });
  },
  obterPaises: (): AppThunkAction<KnownAction> => (dispatch) => {
    return ServicoAPI.Pais().then(paises => {
      dispatch({ type: "SERVICO::PAISES", paises });
    });
  },
  obterMunicipios: (): AppThunkAction<KnownAction> => (dispatch) => {
    return ServicoAPI.Municipio().then(municipios => {
      dispatch({ type: "SERVICO::MUNICIPIOS", municipios });

      return municipios;
    });
  },
  obterBancos: (): AppThunkAction<KnownAction> => (dispatch) => {
    return ServicoAPI.Banco().then(bancos => {
      dispatch({ type: "SERVICO::BANCOS", bancos });
    });
  },
  obterDependentes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const { autenticacao: { usuario } } = getState();

    if (obterTipoUsuario(usuario) === TipoUsuario.BENEFICIARIO) {
      ServicoAPI.ObterDocumentoDiverso().then((documentos) => {
        dispatch({ type: "SERVICO::DOCUMENTOS", documentos });
      });
    } else if (obterTipoUsuario(usuario) === TipoUsuario.EMPRESA) {
      ServicoAPI.ObterDocumentoDiversoPerfil().then((documentos) => {
        dispatch({ type: "SERVICO::DOCUMENTOS", documentos });
      });
    }
  },
  consultarCEP: (Cep: string): AppThunkAction<KnownAction> => () => {
    return ServicoAPI.ConsultarCep({ Cep });
  },
  obterConfiguracoes: (req: Objeto_ConexaoBanco.Requisicao): AppThunkAction<KnownAction> => () => (
    ServicoAPI.Configuracao(req)
  ),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return { };
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {
    case "SERVICO::PARENTESCOS":
      return { ...state, parentescos: action.parentescos };
    case "SERVICO::PAISES":
      return { ...state, paises: action.paises };
    case "SERVICO::MUNICIPIOS":
      return { ...state, municipios: action.municipios };
    case "SERVICO::BANCOS":
      return { ...state, bancos: action.bancos };
    case "SERVICO::DOCUMENTOS":
      return { ...state, documentos: action.documentos };
    default:
      return state;
  }
};
