import React, { useEffect, useState, useMemo } from "react";
import { Label, Stack, IDropdownOption, TextField, DefaultButton, ScrollablePane } from "@fluentui/react";
import { styles } from "./styles";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { ILabelStyles } from 'office-ui-fabric-react/lib/Label';
import { IStyleSet } from 'office-ui-fabric-react/lib/Styling';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Dropdown, IDropdown } from "office-ui-fabric-react/lib/Dropdown";
//import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as GuiaMedicoStore from "../../store/GuiaMedico";
import { 
  Objeto_GuiaMedicoObjeto_Uf,
  Objeto_GuiaMedicoObjeto_Cidade,
  Objeto_GuiaMedicoObjeto_Bairro, 
  Objeto_GuiaMedicoObjeto_Recurso,
  Objeto_GuiaMedicoObjeto_Especialidades,
  Objeto_GuiaMedicoObjeto_Prestadores
} 
from "../../api";

const GuiaMedico: React.FC = () => {
  const { t }: UseTranslationResponse = useTranslation();
  const procedimento = useState("");
  const dropdownRef = React.createRef<IDropdown>();
  const { guiaMedico: { obterUf, obterCidadesPorUf, obterBairroPorCidadePorUf, obterTipoPrestador, obterEspecialidades, obterPrestadores } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch<any>();
  const [ufSelecionado, setUfSelecionado] = useState<Objeto_GuiaMedicoObjeto_Uf.Resposta | undefined>();
  const [cidadeSelecionada, setCidadeSelecionada] = useState<Objeto_GuiaMedicoObjeto_Cidade.Resposta | undefined>();
  const [bairroSelecionado, setBairroSelecionado] = useState<Objeto_GuiaMedicoObjeto_Bairro.Resposta | undefined>();
  const [tpPrestadorSelecionado, setTpPrestadorSelecionado] = useState<Objeto_GuiaMedicoObjeto_Recurso.Resposta | undefined>();
  const [especialidadeSelecionado, setEspecialidadeSelecionado] = useState<Objeto_GuiaMedicoObjeto_Especialidades.Resposta | undefined>();
  const [contador, setContador] = useState(0);
  const [codigo, setCodigo] = useState(""); 
  const [nomePrestador, setNomePrestador] = useState("");

  useEffect(() => {
    dispatch(GuiaMedicoStore.actions.obterUf()).then((uf:any) => {
      dispatch(GuiaMedicoStore.actions.obterCidadesPorUf(ufSelecionado?.UF || "")).then((cidade:any) => {
        dispatch(GuiaMedicoStore.actions.obterBairroPorCidadePorUf(ufSelecionado?.UF || "" , cidadeSelecionada?.CIDADE || "") ).then((tpPrestador:any)=>{
          dispatch(GuiaMedicoStore.actions.obterTipoPrestador()).then((especialidade:any) =>{
            dispatch(GuiaMedicoStore.actions.obterEspecialidades(ufSelecionado?.UF || "", cidadeSelecionada?.CIDADE || "", bairroSelecionado?.SGEENDER_BAIRRO || "", tpPrestadorSelecionado?.SCAPRANS_TP_PREST || ""));
          })
        })
      })
    })

  }, [dispatch, ufSelecionado, cidadeSelecionada, bairroSelecionado, tpPrestadorSelecionado, especialidadeSelecionado ]);

  const obterPrestador = () => {
    dispatch(
      GuiaMedicoStore.actions.obterPrestadores(
        ufSelecionado?.UF || "",
        cidadeSelecionada?.CIDADE || "",
        bairroSelecionado?.SGEENDER_BAIRRO || "",
        tpPrestadorSelecionado?.SCAPRANS_TP_PREST || "",
        especialidadeSelecionado?.SMNESPEC_ID_SMNESPEC || "0",
        nomePrestador || ''
      ));
  };

  const ncliente = useMemo(() => {
    return (
      <div>
        <div>
          <Dropdown
            placeholder="Estado (UF)"
            componentRef={dropdownRef}
            options={obterUf?.map(e => ({ key: e.UF, text: `${e.UF}` })) as Array<IDropdownOption>}
            required={true}
            selectedKey={ufSelecionado?.UF}
            onChange={ (_, item) => {setUfSelecionado(obterUf?.find(e => e.UF === item?.key));} }
            styles={{ dropdown: { width: 250, margin:10 } }}
          />
        </div>
        <div>
          <Dropdown
            placeholder="Cidade"
            componentRef={dropdownRef}
            options={obterCidadesPorUf?.map(e => ({ key: e.CIDADE, text: `${e.CIDADE}` })) as Array<IDropdownOption>}
            required={true}
            selectedKey={cidadeSelecionada?.CIDADE}
            onChange={(_, item) => {setCidadeSelecionada(obterCidadesPorUf?.find(e => e.CIDADE === item?.key));}}
            styles={{ dropdown: { width: 250, margin:10 } }}
          />
        </div>
        <div>
          <Dropdown
            placeholder="Bairro"
            componentRef={dropdownRef}
            options={obterBairroPorCidadePorUf?.map(e => ({ key: e.SGEENDER_BAIRRO, text: `${e.SGEENDER_BAIRRO}` })) as Array<IDropdownOption>}
            required={true}
            selectedKey={bairroSelecionado?.SGEENDER_BAIRRO}
            onChange={(_, item) => {setBairroSelecionado(obterBairroPorCidadePorUf?.find(e => e.SGEENDER_BAIRRO === item?.key));}}
            styles={{ dropdown: { width: 250, margin:10 } }}
          />
        </div>
        <div>
          <Dropdown
            placeholder="Tipo de Recurso"
            componentRef={dropdownRef}
            options={obterTipoPrestador?.map(e => ({ key: e.SCAPRANS_TP_PREST, text: `${e.SCAPRANS_TP_PREST_DESCRICAO}` })) as Array<IDropdownOption>}
            required={true}
            selectedKey={tpPrestadorSelecionado?.SCAPRANS_TP_PREST}
            onChange={ (_, item) => {setTpPrestadorSelecionado(obterTipoPrestador?.find(e => e.SCAPRANS_TP_PREST === item?.key));}}
            styles={{ dropdown: { width: 250, margin:10 } }}
          />
        </div>

        <div>
          <Dropdown
            placeholder="Especialidades"
            componentRef={dropdownRef}
            options={obterEspecialidades?.map(e => ({ key: e.SMNESPEC_ID_SMNESPEC, text: `${e.SMNESPEC_DESCRICAO}` })) as Array<IDropdownOption>}
            required={true}
            selectedKey={especialidadeSelecionado?.SMNESPEC_ID_SMNESPEC}
            onChange={ (_, item) => {setEspecialidadeSelecionado(obterEspecialidades?.find(e => e.SMNESPEC_ID_SMNESPEC === item?.key)); }}
            styles={{ dropdown: { width: 250, margin:10 } }}
            ///multiSelect
          />
        </div>
        <div style={{margin: 10}}>
          <TextField 
          label="Nome do Prestador"  
          value={nomePrestador} 
            onChange={(_evt, texto) => {
              setNomePrestador(texto as string);
            }}/>
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent: "right", margin: 10}}>
          <DefaultButton
            //disabled
            text="Pesquisar" 
            onClick={obterPrestador}
            />
        </div>

      </div>
    );
  }, [procedimento]);

  const prestador = useMemo(() => {
    return (
      <div style={{overflow: "auto",height: 589}}>
        <div>
          <Label style={{textAlign:"center", fontWeight:"bold", fontSize:15}}>Total de prestadores encontrados({contador})</Label>
        </div>
        
        {obterPrestadores?.map((entry, index) => (

          setContador(index+1),

          <div style={{margin: 10, border:"2px solid red", padding: 10, borderRadius: 25}}>
            <div>
              <Label>{entry.CD_TISS} {entry.DS_COD_CONSELHO}/{entry.UF_CONSELHO}</Label>
            </div>
            <div>
              <Label style={{fontWeight: "lighter"}}>{entry.NM_PRESTADOR}</Label>
            </div>
            <div>
              <Label style={{fontWeight: "lighter"}}>Endereço: {entry.DS_BAIRRO}, {entry.NUMERO}, {entry.CEP}, {entry.DS_CIDADE}/{entry.UF_CONSELHO}</Label>
            </div>
            <div>              
              <table style={{width:"100%"}}>
                <tr>
                  <th style={{textAlign:"left"}}>ESPECIALIDADE</th>
                  <th style={{textAlign:"left"}}>AMB</th>
                  <th style={{textAlign:"left"}}>INT</th>
                  <th style={{textAlign:"left"}}>DC</th>
                  <th style={{textAlign:"left"}}>PA 12h</th>
                  <th style={{textAlign:"left"}}>PS 12h</th>
                  <th style={{textAlign:"left"}}>PA 24h</th>
                  <th style={{textAlign:"left"}}>PS 24h</th>
                </tr>
                <tr>
                  <td>{entry.ESPECIALIDADE}</td>
                  <td>Não</td>
                  <td>Não</td>
                  <td>Não</td>
                  <td>Não</td>
                  <td>Não</td>
                  <td>Não</td>
                  <td>Não</td>
                </tr>
              </table> 
              <div>
                <Label style={{fontWeight: "lighter"}}>TELEFONE COMERCIAL:{entry.CELULAR}</Label>
              </div>
            </div>
          </div>

        ))}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Label style={{fontSize:12, margin:10}}>AMB - Ambulatório</Label>
          <Label style={{fontSize:12, margin:10}}>INT - Internação</Label>
          <Label style={{fontSize:12, margin:10}}>DC - Dia Clínico</Label>
          <Label style={{fontSize:12, margin:10}}>AMB - Ambulatório</Label>
          <Label style={{fontSize:12, margin:10}}>PA 12h - Pronto Atendimento 12 horas</Label>
          <Label style={{fontSize:12, margin:10}}>PS 12h - Pronto Socorro 12 horas</Label>
        </div>
      </div>
    );

  }, [obterPrestadores, contador]);
  

  return (
    <div style={{display:"flex", flexDirection: "row"}}>
      <div style={styles.container}>
        <div>
          <Label style={{textAlign:"center", fontWeight:"bold", fontSize:15}}>Encontre seu médico</Label>
        </div>
        <div style={{display:"flex", flexDirection: "column"}}>
          <Stack style={{ paddingTop: "1em", backgroundColor: "#fff", flex: 1, height:300 }}>
            <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
              {ncliente}
            </Stack>
          </Stack>
        </div>
      </div>
      
      {prestador}      

    </div>
  );
};

export default GuiaMedico;
