import React, { useState, useEffect, useMemo } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { Dropdown, IDropdown } from "office-ui-fabric-react/lib/Dropdown";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import {
  Pivot,
  PivotItem,
  
 } from "office-ui-fabric-react";
import { TFunction } from "i18next";
import { Label, IDropdownOption, IColumn, Stack, ScrollablePane, DefaultButton} from "@fluentui/react";
import { styles } from "./styles";
import * as ServicoStore from "../../store/Servico";
import * as BeneficiarioStore from "../../store/Beneficiario";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Tabela } from "../../components/Tabela";
import {Prestador} from "./Prestador";
import { ProcedimentoModal } from "./ProcedimentoModal";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";
import {
  Objeto_BeneficiarioObjeto_Atendimento,
  Objeto_BeneficiarioObjeto_DadosCadastrais,
  Objeto_BeneficiarioObjeto_Especialidades,
  Objeto_Servico_Banco
} from "../../api";

enum PivotItens {
  PRESTADOR = "PRESTADOR",
  PROCEDIMENTOS = "PROCEDIMENTOS",
}

export interface IProcedimento {
  codigo: string;
  nome: string;
  descricao: string;
  quantidade: string;
  valorUnidade: string;
  valorTotal: string;
}

const obterColunasProcedimentos = (t: TFunction): IColumn[] => [
  {
    key: "codigo",
    name: t("codigo"),
    minWidth: 70,
    maxWidth: 70,
    onRender: (item: IProcedimento) => {
      return <Label style={{ marginLeft: 16 }}>{item.codigo}</Label>;
    },
  },
  {
    key: "nome",
    name: t("nome"),
    minWidth: 91,
    onRender: (item: IProcedimento) => {
      return <Label>{item.nome}</Label>;
    },
  },
  {
    key: "descricao",
    name: t("descrição-adicional"),
    minWidth: 91,
    onRender: (item: IProcedimento) => {
      return <Label>{item.descricao}</Label>;
    },
  },
  {
    key: "quantidade",
    name: t("quantidade"),
    minWidth: 91,
    onRender: (item: IProcedimento) => {
      return <Label>{item.quantidade}</Label>;
    },
  },
  {
    key: "valorUnidade",
    name: t("valor-un-cobrado"),
    minWidth: 91,
    onRender: (item: IProcedimento) => {
      return <Label>{item.valorUnidade}</Label>;
    },
  },
  {
    key: "valorTotal",
    name: t("valor-total-cobrado"),
    minWidth: 91,
    onRender: (item: IProcedimento) => {
      return <Label>{item.valorTotal}</Label>;
    },
  },
];

const Reembolso: React.FC = () => {
  const { t }: UseTranslationResponse = useTranslation();
  const dropdownRef = React.createRef<IDropdown>();
  const [pivotKey, setPivotKey] = useState<PivotItens>(PivotItens.PROCEDIMENTOS);

  const { beneficiario: { familia, atendimento, especialidades, procedimento }, servico: { bancos } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch();
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState<Objeto_BeneficiarioObjeto_Especialidades.Resposta | undefined>();
  const [bancoSelecionado, setBancoSelecionado] = useState<Objeto_Servico_Banco.Resposta | undefined>();
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState<Objeto_BeneficiarioObjeto_Atendimento.Resposta | undefined>();
  const [beneficiarioSelecionado, setBeneficiarioSelecionado] = useState<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta | undefined>();
  const [modal, setModal] = useState(false)
  const [dados, setDados] = useState<Array<IProcedimento>>([]);
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [notafiscal, setNotaFiscal] = useState("");
  const [protocolo, setProtocolo] = useState("");

  useEffect(() => {
    dispatch(ServicoStore.actions.obterBancos());
    dispatch(BeneficiarioStore.actions.obterFamilia());
    dispatch(BeneficiarioStore.actions.listaAtendimento());
    dispatch(BeneficiarioStore.actions.listaEspecialidades());
  }, [dispatch]);


  const procedimentos = useMemo(() => {
    return (
      <Tabela
        cabecalhoFixo
        items={dados || []}
        columns={obterColunasProcedimentos(t)}
      />
    );
  }, [procedimento,dados]);

  
  return (
    <div style={styles.container}>
      <div><Label style={styles.titulo}>Solicitar Reembolso</Label></div>
        <div style={{alignSelf: "left"}}>
          <Dropdown
            componentRef={dropdownRef}
            placeholder="Selecione um usuário:"
            options={familia?.map(e => ({ key: e.SCADEPEN_CODIGO, text: `${e.SCADEPEN_CODIGO} - ${e.SGEPESS_NOME}` })) as Array<IDropdownOption>}
            required={true}
            styles={{ dropdown: { width: 250 } }}
            selectedKey={beneficiarioSelecionado?.SCADEPEN_CODIGO}
            onChange={(_, item) => setBeneficiarioSelecionado(familia?.find(e => e.SCADEPEN_CODIGO === item?.key))}
            />
        </div>
        <div>
          <div style={{display:"flex", flexDirection: "row"}}>
            <div /* style={{marginTop:10}} */>
              <div>
                <Label>Dados bancários para pagamento:</Label>
              </div>
              <Dropdown
                componentRef={dropdownRef}
                placeholder="Banco:"
                options={bancos?.map(e => ({ key: e.ID_SGEBANCO, text: `${e.ID_SGEBANCO} - ${e.SGEBANCO_NOME}` })) as Array<IDropdownOption>}
                required={true}
                styles={{ dropdown: { width: 250 } }}
                selectedKey={bancoSelecionado?.ID_SGEBANCO}
                onChange={(_, item) => setBancoSelecionado(bancos?.find(e => e.SGEBANCO_NOME === item?.key))}
              />

            </div>
            <div style={{marginLeft:15, marginRight:15}}>
              <TextField label="Agência"
              value={agencia}
              onChange={(_evt, texto) => {
                setAgencia(texto as string);
              }}
              />
            </div>

            <div>
              <TextField label="Conta"
              value={conta}
              onChange={(_evt, texto) => {
                setConta(texto as string);
              }}/>
            </div>
            
          </div>
        </div>
        <div>
          <div style={{display:"flex",flexDirection: "row", justifyContent:"space-between"}}>

            <div>
              <Label>Tipo de Atendimento:</Label>
              <Dropdown
                componentRef={dropdownRef}
                placeholder="Tipo de Atendimento:"
                options={atendimento?.map(e => ({ key: e.SMNTPNOT_CODIGO, text: `${e.SMNTPNOT_CODIGO} - ${e.SMNTPNOT_DESCRICAO}` })) as Array<IDropdownOption>}
                required={true}
                selectedKey={atendimentoSelecionado?.SMNTPNOT_CODIGO}
                onChange={(_, item) => setAtendimentoSelecionado(atendimento?.find(e => e.SMNTPNOT_CODIGO === item?.key))}
                styles={{ dropdown: { width: 250 } }}
              />
            </div>
            
            <div style={{marginLeft:15, marginRight:15}}>
              <Label>Especialidade:</Label>
              <Dropdown
                componentRef={dropdownRef}
                placeholder="Especialidade:"
                options={especialidades?.map(e => ({ key: e.SMNESPEC_ID_SMNESPEC, text: `${e.SMNESPEC_ID_SMNESPEC} - ${e.SMNESPEC_DESCRICAO}` })) as Array<IDropdownOption>}
                required={true}
                selectedKey={especialidadeSelecionada?.SMNESPEC_ID_SMNESPEC}
                onChange={(_, item) => setEspecialidadeSelecionada(especialidades?.find(e => e.SMNESPEC_DESCRICAO === item?.key))}
                styles={{ dropdown: { width: 250 } }}
              />
            </div>

            <div>
              <TextField label="Nº Recibo/Nota Fiscal" 
              value={notafiscal}
              onChange={(_evt, texto) => {
                setNotaFiscal(texto as string);
              }}/>
            </div>
            <div style={{marginLeft:15}}>
              <TextField label="Nº Protocolo Call Center" 
              value={protocolo}
              onChange={(_evt, texto) => {
                setProtocolo(texto as string);
              }}
              />
            </div>

          </div>
        </div>
        <Stack style={{ paddingTop: "1em", backgroundColor: "#fff", flex: 1, height:300 }}>
          <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Pivot
              selectedKey={pivotKey}
              onLinkClick={(item: any): void => setPivotKey(item.props.itemKey)}>
              <PivotItem headerText={t("prestador")} itemKey={PivotItens.PRESTADOR} />
              <PivotItem headerText={t("procedimentos")} itemKey={PivotItens.PROCEDIMENTOS} />
            </Pivot>
          </Stack>
          {(pivotKey === PivotItens.PROCEDIMENTOS) && procedimentos}
          {(pivotKey === PivotItens.PRESTADOR) && (
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
              <ScrollablePane>
                <Prestador />
              </ScrollablePane>
            </div>             
            )}
        </Stack>
        <DefaultButton
          text="Adicionar" 
          onClick={() => setModal(true)}
          />
          <ProcedimentoModal
            onDismiss={() => setModal(false)} 
            isOpen={modal}
            titulo='Procedimento'
            selecao={(p)=> {console.log(p);setDados([...dados,p])}}>
          </ProcedimentoModal >
    </div>
  );
};

export default Reembolso;
