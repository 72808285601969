import React, { useEffect, useState } from "react";
import Axios from 'axios';

import "./utils";
import { Login } from "./pages/Login";
import { Lotes } from "./pages/Lotes";
import { InformacoesFinanceiras } from "./pages/InformacoesFinanceiras";
import { InclusaoBeneficiario } from "./pages/InclusaoBeneficiario";
import { DesligamentoBeneficiario } from "./pages/DesligamentoBeneficiario";
import { AlteracaoBeneficiario } from "./pages/AlteracaoBeneficiario";
import { TransferenciaContrato } from "./pages/TransferenciaContrato";
import { Emissao2ViaCarteirinha } from "./pages/Emissao2ViaCarteirinha";
import { DesligamentosAgendados } from "./pages/DesligamentosAgendados";
import { ManutencaoSolicitacoes } from "./pages/ManutencaoSolicitacoes";
import { CadastroBiometrico } from "./pages/CadastroBiometrico";
import { ImpressaoTermos } from "./pages/ImpressaoTermos";
import { Impressao } from "./pages/Impressao";
import { Documentos } from "./pages/Documentos";
import { AlterarDadosCadastrais } from "./pages/AlterarDadosCadastrais";
import { Reembolso } from "./pages/Reembolso";
import { Layout } from "./components/Layout";
import { InformacoesGerais } from "./pages/InformacoesGerais";
import { Configuracao } from "./pages/Configuracao";
import { GuiaMedico } from "./pages/GuiaMedico";
import { CancelamentoPlano } from "./pages/CancelamentoPlano";
import Rota from "./components/Rota/Rota";
import i18next from "./i18n";

import { actions, obterTipoUsuario, TipoUsuario } from "./store/Autenticacao";

import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "./store";
import { Objeto_EmpresaObjeto_Login, Objeto_PrestadorObjeto_Login } from "./api";
import * as EmpresaStore from "./store/Empresa";
import { Redirect } from "react-router";

Axios.interceptors.request.use((config) => {
  if (localStorage.getItem("Token")) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
  }

  return config;
}, (error: any) => {
  return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});

const Home: React.FC = () => {
  const { autenticacao: { usuario } } = useSelector((store: ApplicationState) => store);

  let pathname = "/publico";
  switch (obterTipoUsuario(usuario)) {
    case TipoUsuario.BENEFICIARIO:
      pathname = "/informacoes-gerais";
      break;
    case TipoUsuario.PRESTADOR:
      pathname = "/lotes";
      break;
    case TipoUsuario.EMPRESA:
      pathname ="/informacoes-financeiras";
      break;
    case TipoUsuario.LOTACAO:
      pathname ="/informacoes-financeiras";
      break;  
  }

  return (
    <Redirect
      to={{ pathname }}
    />
  );
}

const App: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { autenticacao: { usuario }, /* empresa: { contratosLotacao } */ } = useSelector((store: ApplicationState) => store);
  const [idiomaCarregado, setIdiomaCarregado] = useState<boolean>(false);
  const tipoUsuario = window.location.pathname.indexOf("/imprimir/") === -1 ? obterTipoUsuario(usuario) : null;

  useEffect(() => {
    if ((usuario as Objeto_PrestadorObjeto_Login.Resposta)?.ID_SCAPREST) {
      //
    } else if(tipoUsuario === TipoUsuario.LOTACAO) {
      dispatch(EmpresaStore.actions.obterBeneficiariosLotacao());
      dispatch(EmpresaStore.actions.obterContratosLotacao());
      //dispatch(EmpresaStore.actions.obterPlanosLotacao());
    } else if ((usuario as Objeto_EmpresaObjeto_Login.Resposta)?.ID_SCAEMPBA) {
      dispatch(EmpresaStore.actions.obterBeneficiarios());
      dispatch(EmpresaStore.actions.obterPlanos());
    }
  }, [dispatch, usuario, /* contratosLotacao */]);

  useEffect(() => {
    const requirements = [
      i18next.init()
    ];

    if (localStorage.getItem("Token")) {
      requirements.push(new Promise((resolve, reject) => {
        dispatch(actions.recuperar()).then(resolve).catch(reject);
      }));
    }

    Promise.all(requirements).then(() => setIdiomaCarregado(true));
  }, [dispatch]);

  return idiomaCarregado ? (
    <Layout>
      <Rota.Grupo autenticado={!!usuario}>
        <Rota exact path='/' component={Home} privado />
        <Rota exact path='/informacoes-gerais' component={InformacoesGerais} privado />
        <Rota exact path='/lotes' component={Lotes} privado />
        <Rota exact path='/informacoes-financeiras' component={InformacoesFinanceiras} privado />
        <Rota exact path='/inclusao-beneficiario' component={InclusaoBeneficiario} privado />
        <Rota exact path='/desligamento-beneficiario' component={DesligamentoBeneficiario} privado />
        <Rota exact path='/alteracao-beneficiario' component={InclusaoBeneficiario} privado />
        <Rota exact path='/transferencia-contrato' component={TransferenciaContrato} privado />
        <Rota exact path='/emissao-2via-carteirinha' component={Emissao2ViaCarteirinha} privado />
        <Rota exact path='/desligamentos-agendados' component={DesligamentosAgendados} privado />
        <Rota exact path='/manutencao-solicitacoes' component={ManutencaoSolicitacoes} privado />
        <Rota exact path='/cadastro-biometrico' component={CadastroBiometrico} privado />
        <Rota exact path='/impressao-termos' component={ImpressaoTermos} privado />
        <Rota exact path='/documentos' component={Documentos} privado />
        <Rota exact path='/alterar-dados-cadastrais' component={AlterarDadosCadastrais} privado />
        <Rota exact path='/solicitar-reembolso' component={Reembolso} privado />
        <Rota exact path='/imprimir/:tipo' component={Impressao} privado />
        <Rota exact path='/login' component={Login} login />
        <Rota exact path='/guia-medico' component={GuiaMedico} />
        <Rota exact path='/config' component={Configuracao} />
        <Rota exact path='/cancelamento-plano' component={CancelamentoPlano} privado />
      </Rota.Grupo>
    </Layout>
  ) : null;
}

export default App;
