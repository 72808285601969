import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  getTheme,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SearchBox,
  Stack,
  PivotLinkSize,
  TextField,
  Modal
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";

import { TFunction, useTranslation, UseTranslationResponse } from "../../i18n";
import { Objeto_PrestadorObjeto_Lote } from "../../api";
import * as PrestadorStore from "../../store/Prestador";
import { styles } from "./styles";
import { DataSeletor } from "../../components/DataSeletor";
import { ApplicationState } from "../../store";
import { aplicarTransparencia } from "../../utils";
import { Tabela } from "../../components/Tabela";
import { ModalDetalhes } from "./ModalDetalhes";
import { DialogCancelamento } from "./DialogoCancelamento";
import { fdatasync } from "fs";

const tema = getTheme();

enum Direcao {
  ASC = 1,
  DESC = -1,
}

export enum Situacao {
  IMPORTADO = "I",
  PROCESSANDO = "P",
}

export enum Estado {
  ANALISE = "A",
  CANCELADO = "C",
  TRANSPORTADO = "T",
}

export enum Retorno {
  CRITICADO = "C",
  ERRO = "E",
  TRANSPORTADO = "T",
}

export const situacoes: {[key: string]: { texto: string, cor: string }} = {
  A: {
    texto: "Em análise",
    cor: tema.semanticColors.warningText,
  },
  C: {
    texto: "Cancelado",
    cor: tema.semanticColors.errorText,
  },
  I: {
    texto: "Importado",
    cor: tema.semanticColors.successText,
  },
  P: {
    texto: "Em processamento",
    cor: tema.semanticColors.warningText,
  },
  T: {
    texto: "Transportado",
    cor: tema.semanticColors.successText,
  },
};

export const retornos: {[key: string]: { texto: string, cor: string }} = {
  C: {
    texto: "Criticado",
    cor: tema?.semanticColors.warningText,
  },
  E: {
    texto: "Erro",
    cor: tema?.semanticColors.errorText,
  },
  T: {
    texto: "Transportado",
    cor: tema?.semanticColors.successText,
  },
};

enum AcaoLinha {
  CANCELAR,
  DETALHES_XML,
  SOLICITAR_STATUS
}

interface ILinhaAcoes {
  dados: Objeto_PrestadorObjeto_Lote.Resposta
  tipo: AcaoLinha;
}

interface IOrdenacao {
  campo: string;
  direcao: Direcao;
}

interface IDetalhesXML {
  chave: string;
  valor: string;
}

const obterColunasLoteGuia = (t: TFunction, filtros: any, acaoLinha: any): IColumn[] => [
  {
    key: "id",
    name: "ID",
    fieldName: "id",
    minWidth: 80,
    maxWidth: 80,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.ID_SMNWSLOT}</div>;
    },
  },
  {
    key: "protocolo",
    name: "Protocolo",
    fieldName: "protocolo",
    minWidth: 130,
    maxWidth: 130,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_PROTOCOLO}</div>;
    },
  },
  {
    key: "lote",
    name: "Nº Lote Prestador",
    fieldName: "lote",
    minWidth: 120,
    maxWidth: 120,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_LOTE}</div>;
    },
  },
  {
    key: "data",
    name: "Data de Geração",
    fieldName: "data",
    minWidth: 110,
    maxWidth: 110,
    data: "number",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_DT_GERACAO_ARQ?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
    },
  },
  {
    key: "situacao",
    name: "Situação",
    fieldName: "situacao",
    minWidth: 140,
    maxWidth: 140,
    data: "string",
    isFiltered: filtros.indexOf("situacao") > -1,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      if (!item.SITUACAO) return null;

      const situacao = item.SITUACAO === Situacao.IMPORTADO ? item.SMNWSLOT_STATUS === Estado.TRANSPORTADO ? Situacao.IMPORTADO : item.SMNWSLOT_STATUS : "P";
      const { cor, texto } = situacoes[situacao as string];

      return (
        <div style={{ ...styles.textoTabela, ...styles.textoBalao, backgroundColor: aplicarTransparencia(cor, .1), color: cor }}>{texto}</div>
      );
    },
  },
  {
    key: "retorno",
    name: "Retorno/Observação",
    fieldName: "retorno",
    minWidth: 160,
    maxWidth: 160,
    data: "string",
    isFiltered: filtros.indexOf("retorno") > -1,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      if (!item.SMNWSLOT_CRITICA_WS) return null;

      const { cor, texto } = retornos[item.SMNWSLOT_CRITICA_WS];

      return (
        <div style={{ ...styles.textoTabela, ...styles.textoBalao, backgroundColor: aplicarTransparencia(cor, .1), color: cor }}>{texto}</div>
      );
    },
  },
  {
    key: "acoes",
    name: "Ações",
    isIconOnly: true,
    minWidth: 5,
    maxWidth: 10,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      const bloquearCancelamento = (item.SMNWSLOT_STATUS === Estado.CANCELADO || item.SMNWSLOT_STATUS === Estado.TRANSPORTADO);

      return (
        <Stack horizontal>
          <IconButton
            styles={{ root: { height: "auto" } }}
            iconProps={{ iconName: "TextDocument", style: { ...styles.botaoTabela } }}
            title={t("detalhes-lote")}
            onClick={() => acaoLinha({ dados: item, tipo: AcaoLinha.DETALHES_XML }) } />
          <div style={{ width: 12 }} />
          <IconButton
            disabled={bloquearCancelamento}
            styles={{ root: { height: "auto", opacity: bloquearCancelamento ? .2 : 1 } }}
            iconProps={{ iconName: "RemoveFromShoppingList", style: { ...styles.botaoTabela, height: "auto", color: tema.semanticColors.errorText } }}
            title={t("cancelar-lote")}
            onClick={() => acaoLinha({ dados: item, tipo: AcaoLinha.CANCELAR }) } />
        </Stack>
      )
    },
  },
].map((c: IColumn, i: number) => {
  /* const sorteado = ordenacao && ordenacao.campo === c.fieldName;
  c.isSorted = sorteado;
  c.isSortedDescending = sorteado && ordenacao.direcao === Direcao.DESC; */

  c.styles = { ...c.styles, cellTitle: { justifyContent: "center" } };

  return c;
});

const obterColunasRecursoGlosa = (t: TFunction, filtros: any, acaoLinha: any): IColumn[] => [
    {
    key: "id",
    name: "ID",
    fieldName: "id",
    minWidth: 80,
    maxWidth: 80,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.ID_SMNWSLOT}</div>;
    },
  },
  {
    key: "protocolo",
    name: "Protocolo",
    fieldName: "protocolo",
    minWidth: 130,
    maxWidth: 130,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_PROTOCOLO}</div>;
    },
  },
  {
    key: "lote",
    name: "Nº Lote Prestador",
    fieldName: "lote",
    minWidth: 120,
    maxWidth: 120,
    data: "string",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_LOTE}</div>;
    },
  },
  {
    key: "data",
    name: "Data de Geração",
    fieldName: "data",
    minWidth: 110,
    maxWidth: 110,
    data: "number",
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      return <div style={styles.celulaCento}>{item.SMNWSLOT_DT_GERACAO_ARQ?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
    },
  },
  {
    key: "situacao",
    name: "Situação",
    fieldName: "situacao",
    minWidth: 140,
    maxWidth: 140,
    data: "string",
    isFiltered: filtros.indexOf("situacao") > -1,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      if (!item.SITUACAO) return null;

      const situacao = item.SITUACAO === Situacao.IMPORTADO ? item.SMNWSLOT_STATUS === Estado.TRANSPORTADO ? Situacao.IMPORTADO : item.SMNWSLOT_STATUS : "P";
      const { cor, texto } = situacoes[situacao as string];

      return (
        <div style={{ ...styles.textoTabela, ...styles.textoBalao, backgroundColor: aplicarTransparencia(cor, .1), color: cor }}>{texto}</div>
      );
    },
  },
  {
    key: "retorno",
    name: "Retorno/Observação",
    fieldName: "retorno",
    minWidth: 160,
    maxWidth: 160,
    data: "string",
    isFiltered: filtros.indexOf("retorno") > -1,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      if (!item.SMNWSLOT_CRITICA_WS) return null;

      const { cor, texto } = retornos[item.SMNWSLOT_CRITICA_WS];

      return (
        <div style={{ ...styles.textoTabela, ...styles.textoBalao, backgroundColor: aplicarTransparencia(cor, .1), color: cor }}>{texto}</div>
      );
    },
  },
  {
    key: "acoes",
    name: "Ações",
    isIconOnly: true,
    minWidth: 5,
    maxWidth: 15,
    onRender: (item: Objeto_PrestadorObjeto_Lote.Resposta) => {
      const bloquearCancelamento = (item.SMNWSLOT_STATUS === Estado.CANCELADO || item.SMNWSLOT_STATUS === Estado.TRANSPORTADO);

      return (
        <Stack horizontal>
          <IconButton
            styles={{ root: { height: "auto" } }}
            iconProps={{ iconName: "TextDocument", style: { ...styles.botaoTabela } }}
            title={t("detalhes-lote")}
            onClick={() => acaoLinha({ dados: item, tipo: AcaoLinha.DETALHES_XML }) } />
          <div style={{ width: 12 }} />
          <IconButton
            disabled={!bloquearCancelamento}
            styles={{ root: { height: "auto", opacity: !bloquearCancelamento ? .2 : 1 } }}
            iconProps={{ iconName: "Refresh", style: { ...styles.botaoTabela, height: "auto" } }}
            title={t("nova-solicitacao-status")}
            onClick={() => acaoLinha({ dados: item, tipo: AcaoLinha.SOLICITAR_STATUS }) } />
          <div style={{ width: 12 }} />
          <IconButton
            disabled={bloquearCancelamento}
            styles={{ root: { height: "auto", opacity: bloquearCancelamento ? .2 : 1 } }}
            iconProps={{ iconName: "RemoveFromShoppingList", style: { ...styles.botaoTabela, height: "auto", color: tema.semanticColors.errorText } }}
            title={t("cancelar-lote")}
            onClick={() => acaoLinha({ dados: item, tipo: AcaoLinha.CANCELAR }) } />
        </Stack>
      )
    },
  },
].map((c: IColumn, i: number) => {
  /* const sorteado = ordenacao && ordenacao.campo === c.fieldName;
  c.isSorted = sorteado;
  c.isSortedDescending = sorteado && ordenacao.direcao === Direcao.DESC; */

  c.styles = { ...c.styles, cellTitle: { justifyContent: "center" } };

  return c;
});

interface IAcoes<S = undefined> {
  valor: S | undefined;
  definirValor: React.Dispatch<React.SetStateAction<S | undefined>>;
}

type IFiltroAcoes = {[key in "situacao" | "retorno"]: IAcoes<string>}

const filtroProps = (t: TFunction, dados?: any[], acoes?: any): IContextualMenuProps => ({
  items: [
    {
      key: "situacao",
      text: t("situacao"),
      iconProps: {
        iconName: acoes.situacao.valor ? "FilterSolid" : undefined,
      },
      subMenuProps: dados ? {
        items: dados.reduce((acc, curr) => {
          const situacao = curr.SITUACAO === Situacao.IMPORTADO ? curr.SMNWSLOT_STATUS === Estado.TRANSPORTADO ? Situacao.IMPORTADO : curr.SMNWSLOT_STATUS : "P";

          if (acc.indexOf(situacao) < 0) acc.push(situacao);
          return acc;
        }, []).map((e: string) => ({
          key: e,
          text: situacoes[e]?.texto,
          title: situacoes[e]?.texto,
          iconProps: {
            iconName: acoes.situacao.valor === e ? "CheckMark" : null,
          },
          onClick: (ev: any, item: IContextualMenuItem) => acoes.situacao.definirValor(acoes.situacao.valor === e ? undefined : item.key),
        })),
      } : undefined,
    },
    {
      key: "retorno-observacao",
      text: t("retorno-observacao"),
      iconProps: {
        iconName: acoes.retorno.valor ? "FilterSolid" : undefined,
      },
      subMenuProps: dados ? {
        items: dados.reduce((acc, curr) => { if (curr.SMNWSLOT_CRITICA_WS && acc.indexOf(curr.SMNWSLOT_CRITICA_WS) < 0) acc.push(curr.SMNWSLOT_CRITICA_WS); return acc; }, []).map((e: string) => ({
          key: e,
          text: retornos[e]?.texto,
          title: retornos[e]?.texto,
          iconProps: {
            iconName: acoes.retorno.valor === e ? "CheckMark" : null,
          },
          onClick: (ev: any, item: IContextualMenuItem) => acoes.retorno.definirValor(acoes.retorno.valor ? undefined : item.key),
        })),
      } : undefined,
    },
    {
      key: "divider",
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: "remover-filtros",
      text: "Remover todos filtros",
      disabled: Object.keys(acoes).filter(chave => acoes[chave as string].valor).length === 0,
      iconProps: {
        iconName: "ClearFilter",
      },
      onClick: () => Object.keys(acoes).forEach(chave => acoes[chave].definirValor(null))
    },
  ],
  directionalHint: DirectionalHint.bottomRightEdge,
});

interface IPivotItems {
  [state: string]: {
    colunas: IColumn[],
      dados?: Array<Objeto_PrestadorObjeto_Lote.Resposta>,
      titulo: string
  };
}

const Lotes: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { prestador: { loteGuias, recursoGlosa } } = useSelector((store: ApplicationState) => store);

  const { t }: UseTranslationResponse = useTranslation();
  
  const arquivoInput = useRef<HTMLInputElement>(null);

  const [pivotKey, definirPivotKey] = useState(Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS);
  const [dataInicial, definirDataInicial] = useState<Date>(new Date().chain(data => data.setMonth(data.getMonth() - 1).chain(() => data)));
  const [dataLimite, definirDataLimite] = useState<Date>(new Date());
  const [carregando, definirCarregando] = useState<boolean>(false);
  const [pesquisaFocused, definirPesquisaFocused] = useState<boolean>(false);
  const [pesquisa, definirPesquisa] = useState<string>("");
  const [situacao, definirSituacao] = useState<string>();
  const [retorno, definirRetorno] = useState<string>();
  const [acaoLinha, definirAcaoLinha] = useState<ILinhaAcoes>();
  const [arquivoTexto, definirArquivoTexto] = useState("");
  const [detalhesXML, definirDetalhesXML] = useState<IDetalhesXML[]>([]);

  const [exibindoUpload, definirExibindoUpload] = useState(false);
  const [arquivo, definirArquivo] = useState<File>();
  const [upando, definirUpando] = useState<boolean>(false);
  const [uploadErro, definirUploadErro] = useState<string>();

  const filtros = [situacao ? "situacao" : undefined, retorno ? "retorno" : undefined];
  
  const pivotItems: IPivotItems = {
    [Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS]: { colunas: obterColunasLoteGuia(t, filtros, definirAcaoLinha), dados: loteGuias, titulo: t("lote-guias") },
    // TODO: Temp data
    [Objeto_PrestadorObjeto_Lote.TipoLote.RECURSO_GLOSA]: { colunas: obterColunasRecursoGlosa(t, filtros, definirAcaoLinha), dados: loteGuias, titulo: t("recurso-glosa") },
  };

  const acoes: IFiltroAcoes = {
    situacao: { valor: situacao, definirValor: definirSituacao },
    retorno: { valor: retorno, definirValor: definirRetorno },
  };

  const enviarLote = useCallback(() => {
    definirUpando(true);

    dispatch(PrestadorStore.actions.enviarLoteGuias(arquivo as File))
      .then(() => definirExibindoUpload(false))
      .catch((erro: string) => definirUploadErro(erro))
      .finally(() => definirUpando(false));
  }, [arquivo, dispatch]);

  useEffect(() => {
    definirCarregando(true);
    dispatch(PrestadorStore.actions.obterLoteGuias()).then((data: any) => {
      definirDataInicial(data[0]?.SMNWSLOT_DT_GERACAO_ARQ || new Date());
    }).finally(() => definirCarregando(false));
  }, [dispatch]);

  /* const organizarDados = useCallback((dados: Array<Objeto_PrestadorObjeto_Lote.Resposta>) => {
    if (pesquisa) {
       _dados = dados.filter((i) => (
        i.ID_SMNWSLOT?.toString().indexOf(pesquisa) || 0 >= 0 || i.SMNWSLOT_PROTOCOLO?.indexOf(pesquisa) || 0 >= 0 || i.SMNWSLOT_LOTE?.toString().indexOf(pesquisa) || 0 >= 0
      ));
      dados = dados.filter(e => (
        // @ts-ignore
        e.SMNWSLOT_LOTE?.toString().indexOf(pesquisa) >= 0)
      );
      console.log(dados)
    }

    if (situacao) {
      dados = dados.filter((dado) => {
        return (dado.SITUACAO === Situacao.IMPORTADO ? dado.SMNWSLOT_STATUS === Estado.TRANSPORTADO ? Situacao.IMPORTADO : dado.SMNWSLOT_STATUS : "P") === situacao;
      });
    }

    if (retorno) {
      dados = dados.filter((dado) => dado.SMNWSLOT_CRITICA_WS === retorno);
    }

    const { campo, direcao } = ordenacao || { campo: "data", direcao: Direcao.ASC };
    // @ts-ignore
    dados = dados.sort((a, b) => ((direcao === Direcao.ASC ? a[campo] < b[campo] : a[campo] > b[campo]) ? 1 : -1));

    return dados;
  }, [ordenacao, pesquisa, retorno, situacao]); */

  const { dados, titulo, colunas } = pivotItems[pivotKey];

  return (
    <div style={styles.root}>
      {useMemo(() => carregando ? (
        <ProgressIndicator styles={{ root: { position: "absolute", top: 0, width: "100%"}, itemProgress: { padding: 0 } }} />
      ) : null, [carregando])}

      <Stack horizontal style={{ margin: "0 1em", justifyContent: "space-between", alignItems: "center" }}>
        <Pivot selectedKey={pivotKey.toString()} onLinkClick={(item: any): void => definirPivotKey(item.props.itemKey)} linkSize={PivotLinkSize.large}>
          <PivotItem headerText={t("lote-guias")} itemKey={Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS.toString()} />
          <PivotItem headerText={t("recurso-glosa")} itemKey={Objeto_PrestadorObjeto_Lote.TipoLote.RECURSO_GLOSA.toString()} />
        </Pivot>
        <Stack style={{ flexDirection: "row", alignItems: "center" }}>
          <Label style={{ whiteSpace: "nowrap" }}>{t("periodo-envio")}</Label>
          <DataSeletor
            value={dataInicial}
            onSelectDate={data => definirDataInicial(data as Date)}
            maxDate={dataLimite}
            placeholder={t("data-inicial")}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <Label>{t("ate")}</Label>
          <DataSeletor
            value={dataLimite}
            onSelectDate={data => definirDataLimite(data as Date)}
            minDate={dataInicial}
            maxDate={new Date()}
            placeholder={t("data-final")}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <DefaultButton text={t("buscar")} disabled={carregando} onClick={() => {
            definirCarregando(true);
            dispatch(PrestadorStore.actions.obterLoteGuias(dataInicial, dataLimite)).finally(() => definirCarregando(false));
          }} />
          <IconButton
            disabled={!dados?.length}
            style={{ ...(!dados?.length ? { opacity: .5 } : {}) }}
            title={t("filtro")}
            iconProps={{ iconName: (situacao || retorno) ? "FilterSolid" : "Filter", style: { height: "auto"} }}
            menuProps={filtroProps(t, pivotItems[pivotKey].dados, acoes)} styles={{ root: { marginLeft: 8 } }} />
          <SearchBox
            disabled={!dados?.length}
            value={pesquisa}
            placeholder={t("pesquisar")}
            onFocus={() => definirPesquisaFocused(true)}
            onBlur={() => definirPesquisaFocused(false)}
            onChange={(evt, texto) => definirPesquisa(texto || "")}
            styles={{
              root: {
                ...(!dados?.length ? { opacity: .5 } : {}),
                transition: "width 0.167s ease 0s",
                marginLeft: ".5em",
                marginRight: "1em",
                ...(pesquisaFocused || pesquisa !== "" ? { width: 200 } : { borderColor: "transparent !important", width: 38 }),
              },
              iconContainer: pesquisaFocused || pesquisa !== "" ? null : { cursor: "pointer" },
            }}
          />
        </Stack>
      </Stack>

      {useMemo(() => (
        <Tabela<Objeto_PrestadorObjeto_Lote.Resposta>
          cabecalhoFixo
          items={dados || []}
          filtro={e => {
            /* if (!((situacao && e.SITUACAO === Situacao.IMPORTADO ? e.SMNWSLOT_STATUS === Estado.TRANSPORTADO ? Situacao.IMPORTADO : e.SMNWSLOT_STATUS : "P") === situacao)) {
              return false;
            }
            if (!(retorno && e.SMNWSLOT_CRITICA_WS === retorno)) {
              return false;
            } */

            return Object.values(e).find(e => e.toString().toUpperCase().indexOf((pesquisa || "").toUpperCase()) === 0)
          }}
          columns={colunas}
        />
      ), [dados, colunas, pesquisa])}

      <Stack horizontal style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", margin: ".5em 1.5em" }}>
        <div style={{ color: tema.semanticColors.bodyText, fontSize: ".875em", height: 32, lineHeight: "32px" }}>{`${t("exibindo")} ${pivotItems[pivotKey].dados?.length.toLocaleString("pt-BR") || 0} ${t("itens").toLowerCase()}`}</div>
        {pivotKey == Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS && (
          <PrimaryButton style={{ whiteSpace: "nowrap" }} text={t("enviar-novo-lote")} disabled={false} onClick={() => definirExibindoUpload(true)} />
        )}
      </Stack>

      {useMemo(() => (
        <ModalDetalhes
          dados={acaoLinha?.tipo === AcaoLinha.DETALHES_XML ? acaoLinha.dados : undefined}
          titulo={`DETALHES DO ${titulo.toUpperCase()} SELECIONADO`}
          onDismiss={() => definirAcaoLinha(undefined)} />
      ), [acaoLinha, titulo])}

      {useMemo(() => (
        <DialogCancelamento
          isOpen={acaoLinha?.tipo === AcaoLinha.CANCELAR}
          onDismissed={(resultado) => {
            !resultado ? definirAcaoLinha(undefined) : dispatch(PrestadorStore.actions.cancelarLoteGuias(acaoLinha?.dados.SMNWSLOT_PROTOCOLO as string)).then(() => {
              definirAcaoLinha(undefined);
            });
          }} />
      ), [acaoLinha, dispatch])}
      
      {useMemo(() => {
        return (
          <Modal
            isOpen={exibindoUpload}
            onDismissed={() => { definirArquivo(undefined); definirUploadErro(""); definirArquivoTexto(""); definirDetalhesXML([]); }}
            isBlocking={true}>
            <div style={{ margin: "1.5em", justifyContent: "center" }}>
              <ProgressIndicator styles={{ root: { position: "absolute", top: 0, left: 0, width: "100%", display: upando ? "block" : "none"}, itemProgress: { padding: 0 } }} />
              <h2 style={{ margin: "1.75em 0 1em" }}>{`${t("enviar-novo-lote-de")} ${pivotItems[pivotKey].titulo}`}</h2>
              <div>
                <TextField
                  value={arquivoTexto}
                  errorMessage={uploadErro}
                  label="Arquivo"
                  placeholder="Selecione o arquivo"
                  iconProps={{ iconName: "OpenFile" }}
                  onClick={() => (arquivoInput as any).current.click()}
                  readOnly />
                <input ref={arquivoInput} type="file" accept=".xml" style={{ display: "none" }} onChange={(e) => {
                  if (e.target.files?.length) {
                    definirArquivo(e.target.files[0]);
                    const { name } = e.target.files[0];

                    definirArquivoTexto(name);
                    definirUploadErro(undefined);

                    const reader = new FileReader();
                    reader.onload = (event) => {
                      const xml =  event.target?.result?.toString().replace(/[\r\n]+[\x20\t]*/ig, "") as string;
                      const xmlDoc = new DOMParser().parseFromString(xml, "text/xml");
                      
                      const obterDoXML = (elemento: string) => {
                        return xmlDoc.getElementsByTagName(elemento)[0].textContent as string;
                      };

                      const obterDoFilhoXML = (elemento: string) => {
                        return xmlDoc.getElementsByTagName(elemento)[0].lastChild?.textContent as string;
                      };

                      const obterDoElementoXML = (elemento: string) => {
                        return xmlDoc.getElementsByTagName(elemento)[0].lastElementChild?.textContent as string;
                      };

                      const valorTotal = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                        Array.from(xmlDoc.getElementsByTagName("ans:valorTotalGeral")).reduce((acc, curr) => acc + parseFloat(curr.textContent || "0"), 0)
                      );

                      definirDetalhesXML([
                        { chave: t("versao"), valor: obterDoFilhoXML("ans:Padrao")},
                        { chave: t("tipo-transacao"), valor: obterDoFilhoXML("ans:tipoTransacao") },
                        { chave: t("hash"), valor: obterDoFilhoXML("ans:hash") },
                        { chave: t("prestador"), valor: obterDoXML("ans:nomeContratado") || obterDoFilhoXML("ans:identificacaoPrestador") },
                        { chave: t("operadora"), valor: obterDoElementoXML("ans:destino") },
                        { chave: t("n-lote"), valor: obterDoFilhoXML("ans:numeroLote") },
                        { chave: t("tipo-guia"), valor: xmlDoc.getElementsByTagName("ans:guiasTISS")[0].lastElementChild?.tagName?.replace("ans:guia", "").replace("-", "/") as string },
                        { chave: t("quantidade-guias"), valor: xmlDoc.getElementsByTagName("ans:guiasTISS")[0].childElementCount.toString() },
                        { chave: t("valor-total-informado"), valor: valorTotal },
                      ]);
                    };

                    reader.readAsText(e.target.files[0]);
                  }
                }} />
                <Stack style={{ margin: "1em 0" }} tokens={{ childrenGap: 8 }}>
                  {detalhesXML.map(({ chave, valor }) => (
                    <Stack key={chave.toLowerCase()} horizontal styles={{ root: { display: "flex", justifyContent: "space-between" } }}>
                      <Label style={{ paddingTop: 0, paddingBottom: 0, marginRight: ".75em" }}>{chave}</Label>
                      <Label disabled style={{ paddingTop: 0, paddingBottom: 0, fontWeight: 400 }}>{valor}</Label>
                    </Stack>
                  ))}
                </Stack>
              </div>
              <div style={{ marginTop: "1.5em", textAlign: "end" }}>
                <DefaultButton text={t("cancelar")} onClick={() => definirExibindoUpload(false)} style={{ marginRight: ".5em" }} disabled={upando} />
                <PrimaryButton text={t("enviar")} onClick={enviarLote} disabled={!arquivo || upando} />
              </div>
            </div>
          </Modal>
        )
      }, [exibindoUpload, upando, t, pivotItems, pivotKey, arquivoTexto, uploadErro, detalhesXML, enviarLote, arquivo])}
    </div>
  );
};

export default Lotes;
