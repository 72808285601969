/* eslint-disable */

import Axios from 'axios';
import { AxiosResponse } from 'axios';
export namespace Objeto {
	export interface ObterParametroResposta {
		Chave?: string;
		Permitido?: boolean;
	}

	export interface Unidade {
		ExisteLogo?: boolean;
		Versao?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto {
	export interface ReembolsoObjeto {
		ABA_IR?: string;
		MENU_ALTERACAO_DADOS_CADASTRO?: string;
		MENU_CALL_CENTER?: string;
		MENU_CONSULTAR_SOLICITACOES?: string;
		MENU_DESLIGAMENTO?: string;
		MENU_DOCUMENTOS?: string;
		MENU_REEMBOLSO?: string;
		MENU_SOLICITACAO_REEMBOLSO?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Atendimento {
	export interface Resposta {
		SMNTPNOT_CODIGO?: string;
		SMNTPNOT_DESCRICAO?: string;
		TP_GUIA?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Boleto {
	export interface Requisicao {
		NR_CPF_CGC?: string;
		P_CD_MATRICULA?: string;
		QTD_MESES?: number;
	}

	export interface Resposta {
		ATRASO?: number;
		CD_CONTRATO?: number;
		CD_NOSSO_NUMERO?: string;
		CD_PROCESS_CB?: string;
		CDMULTIEMPRESA?: number;
		DT_CANCELAMENTO?: Date;
		DT_COMPETENCIA?: string;
		DT_EMISSAO?: Date;
		DT_RECEBIMENTO?: Date;
		DT_VENCIMENTO?: Date;
		FCPF?: string;
		ID_SCOTITUL?: number;
		NR_DIA_VENCIMENTO?: number;
		SN_BOLETO?: string;
		TP_QUITACAO?: string;
		VALOR?: number;
		VALOR_PAGO?: number;
	}

}

export namespace Objeto_BeneficiarioObjeto_Carencia {
	export interface Requisicao {
		SCACARTE_CODIGO__CART?: string;
	}

	export interface Resposta {
		DT_VENCIMENTO?: Date;
		SCACARTE_CODIGO_CART?: string;
		SCAITCOB_CODIGO?: string;
		SCAITCOB_DESCRICAO?: string;
		SGEPESS_NOME?: string;
		TP_CARENCIA?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Carteirinha {
	export interface Requisicao {
		SCACARTE_CODIGO?: string;
	}

	export interface Resposta {
		abrangencia?: string;
		ABREVIADO_OPERADORA?: string;
		acomodacao?: string;
		CNS?: string;
		coberura_ptemp?: string;
		codigo?: string;
		CODIGO?: string;
		contratante?: string;
		DADOS_OPERADORA?: string;
		data_val?: string;
		DESCRICAO?: string;
		DT_CONTRATACAO?: string;
		fdata_nasciment?: string;
		local_aten?: string;
		NATUREZA_JURIDICA?: string;
		nome?: string;
		NOME_OPERADORA?: string;
		NR_REG_MS?: string;
		nr_via?: string;
		plano_reg?: string;
		rede?: string;
		REG_ANS?: string;
		segme_plano?: string;
		tipo_contr_plano?: string;
		vigencia_plano?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_DadosCadastrais {
	export interface Requisicao {
		SCACARTE_CODIGO?: string;
	}

	export interface Resposta {
		CD_MULTI_EMPRESA?: number;
		CD_ZONA?: string;
		ID_SCACONTR?: number;
		ID_SGEPAIS?: number;
		SCABENEF_CNS?: string;
		SCABENEF_CPF?: string;
		SCABENEF_ESTADO_CIVIL?: string;
		SCABENEF_RG?: string;
		SCACARTE_CODIGO?: string;
		SCACARTE_CODIGO_CART?: string;
		SCADEPEN_CODIGO?: string;
		SCADEPEN_DESCRICAO?: string;
		SGEENDER_BAIRRO?: string;
		SGEENDER_CELULAR?: string;
		SGEENDER_CEP?: string;
		SGEENDER_COMP_ENDERECO?: string;
		SGEENDER_EMAIL?: string;
		SGEENDER_ENDERECO?: string;
		SGEENDER_FONE?: string;
		SGEENDER_FONE2?: string;
		SGEENDER_NRO_IMOVEL?: string;
		SGEMUNIC_NOME?: string;
		SGEPESS_FDATA_NASCIMENT?: Date;
		SGEPESS_FNOME_MATERNO?: string;
		SGEPESS_FORGAO_RG?: string;
		SGEPESS_FSEXO?: string;
		SGEPESS_NM_PATERNO?: string;
		SGEPESS_NOME?: string;
		SGEUNIFE_COD_UNIDFED?: string;
		TP_SITUACAO?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Dependente {
	export interface Requisicao {
		SCACARTE_CODIGO_CART?: string;
	}

	export interface Resposta {
		ATIVO?: boolean;
		CARTEDEP_CODIGO_CART?: string;
		PESSDEP_FCPF?: string;
		PESSDEP_NOME?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Especialidades {
	export interface Resposta {
		SMNESPEC_DESCRICAO?: string;
		SMNESPEC_ID_SMNESPEC?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Login {
	export interface AlterarSenha extends Objeto_BeneficiarioObjeto_Login.Requisicao {
	}

	export interface Requisicao {
		HASH?: string;
		SCACARTE_CODIGO_CART?: string;
		SGEPESS_FCPF?: string;
	}

	export interface Resposta {
		CARTETIT_CODIGO?: string;
		ID_SCACONBE?: number;
		ID_SGEUNID?: number;
		SCACARTE_CODIGO_CART?: string;
		SGEPESS_FCPF?: string;
		SGEPESS_NOME?: string;
		SGEUNID_NOME?: string;
		Token?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_PrestadoresDesligados {
	export interface Resposta {
		CD_PRESTADOR?: number;
		CNPJ_PRESTADOR?: string;
		DT_DESLIGAMENTO?: string;
		NM_PRESTADOR?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Procedimento {
	export interface Requisicao {
		SGESERVI_CODIGO_SERVI?: string;
	}

	export interface Resposta {
		ID_SGESERVI?: number;
		SGESERVI_DESCRICAO?: string;
	}

}

export namespace Objeto_BeneficiarioObjeto_Utilizacao {
	export interface Requisicao {
		COMPETENCIA?: number;
		ID_SCACONBE?: number;
		TP_DESPESAS?: number;
	}

	export interface Resposta {
		CD_MULTI_EMPRESA?: number;
		NM_PRESTADOR?: string;
		REFER?: number;
		SGESERVI_DESCRICAO?: string;
		SMNINNOT_TP_NOTA?: number;
		SMNPMIT?: number;
		SMNPMIT_VLR_COBR?: number;
		SMNPMIT_VLR_COPAR?: number;
		SMNPMIT_VLR_TXADM?: number;
		SMNPRODM_CO_CODIGO_CARTAO?: string;
		SMNPRODM_CO_DATA_ATEND?: Date;
		SMNPRODM_CO_NOME_BENEF?: string;
		SMNPRODM_SITUACAO?: string;
	}

}

export namespace Objeto_ConexaoBanco {
	export enum BD { NotSet, Sql, Oracle }

	export interface Requisicao {
		Senha?: string;
		Slave?: Objeto_ConexaoBanco.Slave;
		Usuario?: string;
	}

	export interface Slave {
		Banco?: string;
		BaseDados?: Objeto_ConexaoBanco.BD;
		Online?: string;
		Porta?: string;
		Senha?: string;
		Servico?: string;
		Servidor?: string;
		Usuario?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Anexo {
	export interface Requisicao {
		SWEDOCUM_ID_ORIGEM?: string;
	}

	export interface Resposta {
		DESCRICAO?: string;
		DS_CAMINHO_ARQUIVO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Beneficiario {
	export interface Requisicao {
		SCAEMPBA_CODIGO?: string;
	}

	export interface Resposta {
		ID_SCABENEF?: number;
		ID_SCACONBE?: number;
		ID_SCACONTR?: number;
		SCACARTE_CODIGO_CART?: string;
		SCACONBE_INICIO_VIGENCIA?: Date;
		SCACONTR_CODIGO?: string;
		SCADEPEN_DESCRICAO?: string;
		SCADEPEN_QUALIFICACAO?: string;
		SGEFAMIL_CODIGO?: string;
		SGEPESS_CPF_RESPONSAVEL?: string;
		SGEPESS_FCPF?: string;
		SGEPESS_NOME?: string;
		TP_SITUACAO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_BeneficiarioCadastro {
	export interface Requisicao {
		ID_SCABENEF?: number;
		ID_SCAEMPBA?: number;
	}

	export interface Resposta {
		ID_SGEPAIS?: number;
		SCABENCC_ID_SGEBANCO?: number;
		SCABENCC_NOME_AGENCIA?: string;
		SCABENCC_NUM_AGENCIA?: string;
		SCABENCC_NUM_CONTA?: string;
		SCABENEF_CNS?: string;
		SCABENEF_CPF?: string;
		SCABENEF_DATA_INCLUSAO?: Date;
		SCABENEF_DATA_NASC?: Date;
		SCABENEF_DECLARACAO_NASC?: string;
		SCABENEF_ESTADO_CIVIL?: string;
		SCABENEF_PIS?: number;
		SCABENEF_RG?: string;
		SCACONBE_INICIO_VIGENCIA?: Date;
		SCADEPEN_TIPO?: string;
		SEXO?: string;
		SGEENDER_BAIRRO?: string;
		SGEENDER_CELULAR?: string;
		SGEENDER_CEP?: string;
		SGEENDER_COMP_ENDERECO?: string;
		SGEENDER_EMAIL?: string;
		SGEENDER_ENDERECO?: string;
		SGEENDER_FONE?: string;
		SGEENDER_NRO_IMOVEL?: string;
		SGEMUNIC_NOME?: string;
		SGEPESS_FNOME_MATERNO?: string;
		SGEPESS_FORGAO_RG?: string;
		SGEPESS_NM_PATERNO?: string;
		SGEPESS_NOME?: string;
		SGEUNIFE_COD_UNIDFED?: string;
		ZONA?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Boleto {
	export interface Requisicao {
		DATA_INICIAL?: Date;
		DATA_LIMITE?: Date;
		SCOTITUL_CONTRATO?: string;
	}

	export interface Resposta {
		ATRASO?: number;
		CD_LCTO_MENSALIDADE?: string;
		CD_MULTI_EMPRESA?: number;
		DS_LCTO_MENSALIDADE?: string;
		ID_SCOTITUL?: number;
		IDADE?: number;
		ORDENACAO?: string;
		SCABENEF_DATA_INCLUSAO?: Date;
		SCACARTE_CODIGO_CART?: string;
		SCOTITUL_COMPETENCIA?: string;
		SCOTITUL_DATA_BAIXA?: Date;
		SCOTITUL_DATA_EMISSAO?: Date;
		SCOTITUL_DATA_VCTO?: Date;
		SCOTITUL_NOSSO_NUMERO?: string;
		SCOTITUL_VALOR_DOCUMENTO?: number;
		SCOTITUL_VALOR_PAGO?: number;
		SGEPESS_FDATA_NASCIMENT?: Date;
		SGEPESS_NOME?: string;
		SGEPLANO_DESCRICAO?: string;
		SN_CO_PARTICIPACAO?: string;
		SN_TITULAR?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Carteirinha {
	export interface Requisicao {
		SCACARTE_CODIGO_CART?: string;
	}

	export interface Resposta {
		SCACARTE_CODIGO_CART?: string;
		SCACARTE_DT_GERACAO?: Date;
		SCACARTE_VALIDADE_FINAL?: Date;
		SCALOARD_ARQUIVO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_CategoriaDependente {
	export interface Resposta {
		DESCRICAO?: string;
		ID_SCADEPEN?: number;
	}

}

export namespace Objeto_EmpresaObjeto_DesligamentoAgendado {
	export interface Requisicao {
		DATA_INICIAL?: Date;
		DATA_LIMITE?: Date;
		ID_SCAEMPBA?: number;
	}

	export interface Resposta {
		SCACARTE_CODIGO_CART?: string;
		SCACONBE_DATA_ULT_ALT?: Date;
		SCACONBE_FINAL_VIGENCIA?: Date;
		SGEPESS_NOME?: string;
		TP_SITUACAO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Empresa {
	export interface Resposta {
		SCAEMPBA_CODIGO?: string;
		SCAEMPBA_NOME?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Fatura {
	export interface Requisicao {
		SCACONTR_CODIGO?: string;
		SCALOTEM_CODIGO?: string;
	}

	export interface Resposta {
		ATRASO?: number;
		CD_MULTI_EMPRESA?: number;
		CD_PROCESS_CB?: number;
		DT_CANCELAMENTO?: Date;
		DT_COMPETENCIA?: string;
		ID_SCOTITUL?: number;
		JCNPJ?: string;
		SCOTITUL_DATA_BAIXA?: Date;
		SCOTITUL_DATA_EMISSAO?: Date;
		SCOTITUL_DATA_VCTO?: Date;
		SCOTITUL_NOSSO_NUMERO?: string;
		SCOTITUL_VALOR_DOCUMENTO?: number;
		SCOTITUL_VALOR_PAGO?: number;
		SN_BOLETO?: string;
		TP_QUITACAO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_FaturaContrato {
	export interface Requisicao {
		ID_SCOTITUL?: number;
	}

	export interface Resposta {
		CARTAO_TITULAR_CODIGO?: string;
		CD_MULTI_EMPRESA?: number;
		ID_SCOTITUL?: number;
		IDADE?: number;
		SCACARTE_CODIGO_CART?: string;
		SCACONBE_INICIO_VIGENCIA?: Date;
		SCACONTR_CODIGO?: string;
		SCADEPEN_QUALIFICACAO?: number;
		SCOTITUL_COMPETENCIA?: string;
		SCOTITUL_NOSSO_NUMERO?: string;
		SGEPESS_FDATA_NASCIMENT?: Date;
		SGEPESS_NOME?: string;
		SGEPLANO_DESCRICAO?: string;
		SMNFATBM_VALOR?: number;
		SN_TITULAR?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Login {
	export interface AlterarSenha extends Objeto_EmpresaObjeto_Login.Requisicao {
	}

	export interface Requisicao {
		HASH?: string;
		SCAEMPBA_CODIGO?: string;
		SGEPESS_JCNPJ?: string;
	}

	export interface Resposta {
		ID_SCAEMPBA?: number;
		ID_SGEUNID?: number;
		SCACONTR_CODIGO?: string;
		SCACONTR_ID_SCACONTR?: number;
		SCAEMPBA_CODIGO?: string;
		SCAEMPBA_DATA_INCLUSAO?: Date;
		SCAEMPBA_NOME?: string;
		SCAGRUPO_CODIGO?: string;
		SGEPESS_JCONTATO?: string;
		SGEPESS_LIBERA_BIOMETRIA?: string;
		Tipo?: string;
		Token?: string;
	}

}

export namespace Objeto_EmpresaObjeto_MensagemReajuste {
	export interface Requisicao {
		ID_SCACONPL?: string;
	}

	export interface Resposta {
		MENSAGEM?: string;
	}

}

export namespace Objeto_EmpresaObjeto_MotivoDesligamento {
	export interface Resposta {
		ID_SGEMOTIV?: number;
		SGEMOTIV_DESCRICAO?: string;
		SGEMOTIV_TP_CLASSIFICACAO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_MotivoTransferencia {
	export interface Resposta {
		SGEMOTIV_CODIGO?: string;
		SGEMOTIV_DESCRICAO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_NomeAbreviado {
	export interface Resposta {
		SGEUNID_ABREVIADO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_Plano {
	export interface Requisicao {
		SCAEMPBA_CODIGO?: string;
	}

	export interface Resposta {
		ID_SGEPLANO?: number;
		SCACOMREAJ_MENSAGEM?: string;
		SCACONPL_ID_SCACONPL?: string;
		SCACONTR_CODIGO?: string;
		SCACONTR_DATA_INICIO?: Date;
		SCACONTR_ID_SCACONTR?: number;
		SCACONTR_VALIDADE?: Date;
		SCAEMPBA_CODIGO?: string;
		SCAEMPBA_NOME?: string;
		SGEPLANO_CODIGO?: string;
		SGEPLANO_DESCRICAO?: string;
		TEMPO_DE_PLANO?: string;
	}

}

export namespace Objeto_EmpresaObjeto_TipoArquivo {
	export interface Resposta {
		CD_TIPO_ARQUIVO?: string;
		DS_TIPO_ARQUIVO?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Bairro {
	export interface Requisicao {
		SCAPRANS_SG_UF_CONS?: string;
		SGEMUNIC_NOME?: string;
	}

	export interface Resposta {
		SGEENDER_BAIRRO?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Cidade {
	export interface Requisicao {
		SCAPRANS_SG_UF_CONS?: string;
	}

	export interface Resposta {
		CIDADE?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Especialidades {
	export interface Requisicao {
		SCAPRANS_SG_UF_CONS?: string;
		SCAPRANS_TP_PREST?: string;
		SGEENDER_BAIRRO?: string;
		SGEMUNIC_NOME?: string;
	}

	export interface Resposta {
		SMNESPEC_DESCRICAO?: string;
		SMNESPEC_ID_SMNESPEC?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Prestadores {
	export interface Requisicao {
		SCAPRANS_SG_UF_CONS?: string;
		SCAPRANS_TP_PREST?: string;
		SCAPREST_NOME?: string;
		SGEENDER_BAIRRO?: string;
		SGEMUNIC_NOME?: string;
		SMNESPEC_ID_SMNESPEC?: string;
	}

	export interface Resposta {
		CD_MULTI_EMPRESA?: string;
		CD_PRESTADOR?: string;
		CD_TISS?: string;
		CD_UF?: string;
		CELULAR?: string;
		CEP?: string;
		DS_BAIRRO?: string;
		DS_CIDADE?: string;
		DS_COD_CONSELHO?: string;
		ESPECIALIDADE?: string;
		F_J_PRESTADOR?: string;
		NM_FANTASIA?: string;
		NM_PRESTADOR?: string;
		NR_CPF_CGC?: string;
		NUMERO?: string;
		TP_PRESTADOR?: string;
		TP_TIP_PRESTADOR?: string;
		UF_CONSELHO?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Recurso {
	export interface Resposta {
		SCAPRANS_TP_PREST?: string;
		SCAPRANS_TP_PREST_DESCRICAO?: string;
	}

}

export namespace Objeto_GuiaMedicoObjeto_Uf {
	export interface Resposta {
		UF?: string;
	}

}

export namespace Objeto_PrestadorObjeto {
	export interface Prestador {
		SGEPESS_CODIGO?: string;
	}

}

export namespace Objeto_PrestadorObjeto_Login {
	export interface Requisicao {
		HASH?: string;
		SCAPREST_CODIGO?: string;
		SGEUSUAR_NET_LOGIN?: string;
	}

	export interface Resposta {
		ID_SCAPREST?: number;
		SCAPREST_CODIGO?: string;
		SGEUSUAR_NET_ATIVO?: string;
		SGEUSUAR_NET_NOME?: string;
		Token?: string;
	}

}

export namespace Objeto_PrestadorObjeto_Login_Prestador {
	export interface Requisicao {
		SGEUSUAR_NET_LOGIN?: string;
	}

	export interface Resposta {
		SGEUSPRE_CODIGO?: string;
		SGEUSPRE_NOME?: string;
		SGEUSPRE_PRESTADOR_PRI?: boolean;
		SGEUSUAR_NET_ATIVO?: boolean;
	}

}

export namespace Objeto_PrestadorObjeto_Lote {
	export interface Cancelamento {
		SMNWSLOT_PROTOCOLO?: string;
		TIPO?: Objeto_PrestadorObjeto_Lote.TipoLote;
	}

	export interface Envio {
		SMNWSLOT_PROTOCOLO?: string;
	}

	export interface Requisicao {
		DATA_INICIAL?: Date;
		DATA_LIMITE?: Date;
		SMNWSLOT_LOTE_ORIG?: number;
		TIPO?: Objeto_PrestadorObjeto_Lote.TipoLote;
	}

	export interface Resposta {
		ID_SMNWSLOT?: number;
		QUANTIDADE_GUIAS?: number;
		SITUACAO?: string;
		SMNWSLOT_CRITICA_WS?: string;
		SMNWSLOT_DT_GERACAO_ARQ?: Date;
		SMNWSLOT_LOTE?: number;
		SMNWSLOT_PROTOCOLO?: string;
		SMNWSLOT_STATUS?: string;
		VALOR_TOTAL?: number;
	}

	export enum TipoLote { LOTE_GUIAS, RECURSO_GLOSA }

}

export namespace Objeto_Servico {
	export enum TipoRelatorio { DETALHAMENTO = 2, CO_PARTICIPACOES = 3, VIDAS_ATIVAS = 4 }

}

export namespace Objeto_Servico_Banco {
	export interface Resposta {
		ID_SGEBANCO?: number;
		SGEBANCO_NOME?: string;
	}

}

export namespace Objeto_Servico_Documentos {
	export interface Requisicao {
		SWEDOCUM_TIPO_PERFIL?: string;
		VW_ID_CODIGO?: string;
	}

	export interface Resposta {
		SWEDOCUM_DESCRICAO?: string;
		SWEDOCUM_DS_CAMINHO_ARQUIVO?: string;
	}

}

export namespace Objeto_Servico_DocumentosPerfil {
	export interface Requisicao {
		ID_SGEUNID?: number;
		SCACONTR_CODIGO?: string;
		SWEDOCUM_TIPO_PERFIL?: string;
	}

	export interface Resposta {
		SWEDOCUM_DESCRICAO?: string;
		SWEDOCUM_DS_CAMINHO_ARQUIVO?: string;
	}

}

export namespace Objeto_Servico_Endereco {
	export interface Requisicao {
		Cep?: string;
	}

	export interface Resposta {
		Bairro?: string;
		Cep?: string;
		Cidade?: string;
		Complemento?: string;
		Logradouro?: string;
		UF?: string;
	}

}

export namespace Objeto_Servico_Municipios {
	export interface Resposta {
		ID_SGEMUNIC?: number;
		ID_SGEUNIFE?: number;
		SGEMUNIC_NOME?: string;
		SGEUNIFE_COD_UNIDFED?: string;
		SGEUNIFE_NOME?: string;
	}

}

export namespace Objeto_Servico_Pais {
	export interface Resposta {
		ID_SGEPAIS?: number;
		SGEPAIS_DESCRICAO?: string;
	}

}

export namespace Objeto_Servico_Parentesco {
	export interface Resposta {
		DS_PARENTESCO?: string;
		SCADEPEN_TIPO?: string;
	}

}

export namespace Objeto_Servico_TesteConfiguracao {
	export interface Resposta {
		BANCO?: boolean;
		ONLINE?: boolean;
		SERVICO?: boolean;
		SPs?: Array<Objeto_Servico_TesteConfiguracao.SP>;
	}

	export interface SP {
		SYS_PROCEDURES_NAME?: string;
	}

}

export namespace Objeto_ServicoObjeto_SolicitacaoWeb {
	export interface Requisicao {
		CD_BANCO?: number;
		CD_CATEGORIA_BENEFICIARIO?: number;
		CD_MATRICULA?: string;
		CD_MATRICULA_TEM?: string;
		CD_MOTIVO_ALTERACAO_ANS?: number;
		CD_NOVO_RESPONSAVEL_FINANCEIRO?: number;
		CD_ORGAO?: number;
		CD_PAIS_EMISSOR_CARTEIRA?: string;
		CD_PIS_PASEP?: number;
		CD_SOLICITACAO?: number;
		CD_SOLICITACAO_TITULAR?: number;
		CD_USUARIO_ALTERACAO?: string;
		CD_USUARIO_CRIACAO?: string;
		CD_ZONA?: number;
		CD_ZONA_COM?: number;
		DS_BAIRRO?: string;
		DS_BAIRRO_COM?: string;
		DS_COMPLEMENTO?: string;
		DS_COMPLEMENTO_COM?: string;
		DS_EMAIL?: string;
		DS_ENDERECO?: string;
		DS_ENDERECO_COM?: string;
		DS_OBSERVACAO?: string;
		DS_ORGAO_EXPEDITOR?: string;
		DT_ADESAO?: Date;
		DT_ADMISSAO?: Date;
		DT_DESLIGAMENTO?: Date;
		DT_FALECIMENTO?: Date;
		DT_NASCIMENTO?: Date;
		ID_SCACONTR?: number;
		ID_SCADEPEN?: string;
		ID_SCAEMPBA?: number;
		ID_SCAEMPBA_TRANSFERENCIA?: number;
		ID_SGEMOTIV?: number;
		ID_SGEPLANO?: number;
		ID_SGEUNID?: number;
		NM_AGENCIA?: string;
		NM_CIDADE?: string;
		NM_CIDADE_COM?: string;
		NM_CONJUGE?: string;
		NM_MAE?: string;
		NM_PAI?: string;
		NM_SEGURADO?: string;
		NM_UF?: string;
		NM_UF_COM?: string;
		NR_AGENCIA?: string;
		NR_CELULAR?: string;
		NR_CEP?: string;
		NR_CEP_COM?: string;
		NR_CNS?: string;
		NR_CONTA?: string;
		NR_CPF?: string;
		NR_DN?: string;
		NR_ENDERECO?: number;
		NR_ENDERECO_COM?: number;
		NR_RG?: string;
		NR_TELEFONE?: string;
		NR_TELEFONE_COM?: string;
		SN_CANCELA_MENSALIDADES?: string;
		SN_DESLIGA_DEPENDENTE?: string;
		SN_EXCLUI_DEPENDENTE?: string;
		SN_TITULAR?: string;
		TP_ESTADO_CIVIL?: string;
		TP_SEXO?: string;
		TP_SOLICITACAO?: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao;
		TP_STATUS?: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus;
		TP_STATUS_ANTERIOR?: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus;
		TP_USUARIO?: string;
	}

	export interface Resposta {
		CD_SOLICITACAO?: number;
	}

	export enum TipoSolicitacao { CADASTRO_TITULAR = 1, CADASTRO_DEPENDENTE = 2, DESLIGAMENTO_TITULAR = 3, DESLIGAMENTO_DEPENDENTE = 4, TRANSFERENCIA_CONTRATO = 5, SEGUNDA_VIA_CARTEIRINHA = 6, ALTERACAO_EMPRESA = 7, ALTERACAO_BENEFICIARIO = 9 }

	export enum TipoStatus { ABERTA = 1, ATENDIDA = 2, CANCELADA = 3, DIVERGENCIA = 4, EM_ANALISE = 5, EM_EDICAO = 6 }

}

export namespace Objeto_Solicitacao {
	export interface Requisicao {
		DATA_INICIAL?: Date;
		DATA_LIMITE?: Date;
	}

	export interface Resposta {
		ABERTA?: number;
		ATENDIDA?: number;
		CANCELADA?: number;
		CD_TP_SOLICITACAO?: number;
		DIVERGENCIA?: number;
		EM_ANALISE?: number;
		EM_EDICAO?: number;
		SW?: number;
		TIPO_SOLICITACAO?: string;
	}

}

export namespace API_Controllers {
	export class Beneficiario {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * POST api/Beneficiario/Atendimento
		 */
		Atendimento(): Promise<Array<Objeto_BeneficiarioObjeto_Atendimento.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Atendimento.Resposta>>(this.baseUri + 'api/Beneficiario/Atendimento', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Boleto
		 */
		Boleto(): Promise<Array<Objeto_BeneficiarioObjeto_Boleto.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Boleto.Resposta>>(this.baseUri + 'api/Beneficiario/Boleto', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Especialidades
		 */
		ListaEspecialidades(): Promise<Array<Objeto_BeneficiarioObjeto_Especialidades.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Especialidades.Resposta>>(this.baseUri + 'api/Beneficiario/Especialidades', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/PrestadoresDesligados
		 */
		ListaPrestadoresDesligados(req: any): Promise<Array<Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta>>(this.baseUri + 'api/Beneficiario/PrestadoresDesligados', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Carencia
		 */
		ObterCarencias(): Promise<Array<Objeto_BeneficiarioObjeto_Carencia.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Carencia.Resposta>>(this.baseUri + 'api/Beneficiario/Carencia', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Carteirinha
		 */
		ObterCarteirinha(): Promise<Objeto_BeneficiarioObjeto_Carteirinha.Resposta> {
			return Axios.post<Objeto_BeneficiarioObjeto_Carteirinha.Resposta>(this.baseUri + 'api/Beneficiario/Carteirinha', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Dependente
		 */
		ObterDependentes(): Promise<Array<Objeto_BeneficiarioObjeto_Dependente.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Dependente.Resposta>>(this.baseUri + 'api/Beneficiario/Dependente', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Familia
		 */
		ObterFamilia(): Promise<Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>>(this.baseUri + 'api/Beneficiario/Familia', null).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Procedimento
		 */
		ObterProcedimento(req: Objeto_BeneficiarioObjeto_Procedimento.Requisicao): Promise<Objeto_BeneficiarioObjeto_Procedimento.Resposta> {
			return Axios.post<Objeto_BeneficiarioObjeto_Procedimento.Resposta>(this.baseUri + 'api/Beneficiario/Procedimento', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Utilizacao
		 */
		ObterUtilizacoes(req: Objeto_BeneficiarioObjeto_Utilizacao.Requisicao): Promise<Array<Objeto_BeneficiarioObjeto_Utilizacao.Resposta>> {
			return Axios.post<Array<Objeto_BeneficiarioObjeto_Utilizacao.Resposta>>(this.baseUri + 'api/Beneficiario/Utilizacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/Solicitacao
		 */
		Solicitacao(req: Objeto_Solicitacao.Requisicao): Promise<Array<Objeto_Solicitacao.Resposta>> {
			return Axios.post<Array<Objeto_Solicitacao.Resposta>>(this.baseUri + 'api/Beneficiario/Solicitacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Beneficiario/SolicitacaoWeb
		 */
		SolicitacaoWeb(req: Objeto_ServicoObjeto_SolicitacaoWeb.Requisicao): Promise<Array<Objeto_Solicitacao.Resposta>> {
			return Axios.post<Array<Objeto_Solicitacao.Resposta>>(this.baseUri + 'api/Beneficiario/SolicitacaoWeb', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}
	}

	export class Empresa {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * POST api/Empresa/Anexo
		 */
		Anexo(req: Objeto_EmpresaObjeto_Anexo.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Anexo.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Anexo.Resposta>>(this.baseUri + 'api/Empresa/Anexo', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Beneficiario
		 */
		Beneficiario(): Promise<Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>>(this.baseUri + 'api/Empresa/Beneficiario', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/BeneficiarioCadastro
		 */
		BeneficiarioCadastro(req: Objeto_EmpresaObjeto_BeneficiarioCadastro.Requisicao): Promise<Objeto_EmpresaObjeto_BeneficiarioCadastro.Resposta> {
			return Axios.post<Objeto_EmpresaObjeto_BeneficiarioCadastro.Resposta>(this.baseUri + 'api/Empresa/BeneficiarioCadastro', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/BeneficiarioLotacao
		 */
		BeneficiarioLotacao(): Promise<Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>>(this.baseUri + 'api/Empresa/BeneficiarioLotacao', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Boleto
		 */
		Boleto(req: Objeto_EmpresaObjeto_Boleto.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Boleto.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Boleto.Resposta>>(this.baseUri + 'api/Empresa/Boleto', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Carteirinha
		 */
		Carteirinha(req: Objeto_EmpresaObjeto_Carteirinha.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Carteirinha.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Carteirinha.Resposta>>(this.baseUri + 'api/Empresa/Carteirinha', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/CategoriaDependente
		 */
		CategoriaDependente(): Promise<Array<Objeto_EmpresaObjeto_CategoriaDependente.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_CategoriaDependente.Resposta>>(this.baseUri + 'api/Empresa/CategoriaDependente', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/ContratosLotacao
		 */
		ContratosLotacao(req: Objeto_EmpresaObjeto_Plano.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Plano.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Plano.Resposta>>(this.baseUri + 'api/Empresa/ContratosLotacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/DesligamentoAgendado
		 */
		DesligamentoAgendado(req: Objeto_EmpresaObjeto_DesligamentoAgendado.Requisicao): Promise<Array<Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta>>(this.baseUri + 'api/Empresa/DesligamentoAgendado', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Empresa
		 */
		Empresa(): Promise<Array<Objeto_EmpresaObjeto_Empresa.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Empresa.Resposta>>(this.baseUri + 'api/Empresa/Empresa', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Fatura
		 */
		Fatura(req: Objeto_EmpresaObjeto_Fatura.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Fatura.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Fatura.Resposta>>(this.baseUri + 'api/Empresa/Fatura', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/FaturaContrato
		 */
		FaturaContrato(req: Objeto_EmpresaObjeto_FaturaContrato.Requisicao): Promise<Array<Objeto_EmpresaObjeto_FaturaContrato.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_FaturaContrato.Resposta>>(this.baseUri + 'api/Empresa/FaturaContrato', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/FaturaLotacao
		 */
		FaturaLotacao(req: Objeto_EmpresaObjeto_Fatura.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Fatura.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Fatura.Resposta>>(this.baseUri + 'api/Empresa/FaturaLotacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/MotivoDesligamento
		 */
		MotivoDesligamento(): Promise<Array<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta>>(this.baseUri + 'api/Empresa/MotivoDesligamento', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/MotivoTransferencia
		 */
		MotivoTransferencia(): Promise<Array<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta>>(this.baseUri + 'api/Empresa/MotivoTransferencia', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/NomeAbreviado
		 */
		NomeAbreviado(): Promise<Array<Objeto_EmpresaObjeto_NomeAbreviado.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_NomeAbreviado.Resposta>>(this.baseUri + 'api/Empresa/NomeAbreviado', null).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Plano
		 */
		Plano(req: Objeto_EmpresaObjeto_Plano.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Plano.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Plano.Resposta>>(this.baseUri + 'api/Empresa/Plano', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/PlanoLotacao
		 */
		PlanoLotacao(req: Objeto_EmpresaObjeto_Plano.Requisicao): Promise<Array<Objeto_EmpresaObjeto_Plano.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_Plano.Resposta>>(this.baseUri + 'api/Empresa/PlanoLotacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/Solicitacao
		 */
		Solicitacao(req: Objeto_Solicitacao.Requisicao): Promise<Array<Objeto_Solicitacao.Resposta>> {
			return Axios.post<Array<Objeto_Solicitacao.Resposta>>(this.baseUri + 'api/Empresa/Solicitacao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/SolicitacaoWeb
		 */
		SolicitacaoWeb(req: Objeto_ServicoObjeto_SolicitacaoWeb.Requisicao): Promise<Array<Objeto_Solicitacao.Resposta>> {
			return Axios.post<Array<Objeto_Solicitacao.Resposta>>(this.baseUri + 'api/Empresa/SolicitacaoWeb', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Empresa/TipoArquivo
		 */
		TipoArquivo(): Promise<Array<Objeto_EmpresaObjeto_TipoArquivo.Resposta>> {
			return Axios.post<Array<Objeto_EmpresaObjeto_TipoArquivo.Resposta>>(this.baseUri + 'api/Empresa/TipoArquivo', null).then(d => d.data);
		}
	}

	export class GuiaMedico {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * POST api/GuiaMedico/Bairro
		 */
		ObterBairroPorCidadePorUf(req: Objeto_GuiaMedicoObjeto_Bairro.Requisicao): Promise<Array<Objeto_GuiaMedicoObjeto_Bairro.Resposta>> {
			return Axios.post<Array<Objeto_GuiaMedicoObjeto_Bairro.Resposta>>(this.baseUri + 'api/GuiaMedico/Bairro', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/GuiaMedico/Cidade
		 */
		ObterCidadesPorUf(req: Objeto_GuiaMedicoObjeto_Cidade.Requisicao): Promise<Array<Objeto_GuiaMedicoObjeto_Cidade.Resposta>> {
			return Axios.post<Array<Objeto_GuiaMedicoObjeto_Cidade.Resposta>>(this.baseUri + 'api/GuiaMedico/Cidade', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/GuiaMedico/Especialidades
		 */
		ObterEspecialidades(req: Objeto_GuiaMedicoObjeto_Especialidades.Requisicao): Promise<Array<Objeto_GuiaMedicoObjeto_Especialidades.Resposta>> {
			return Axios.post<Array<Objeto_GuiaMedicoObjeto_Especialidades.Resposta>>(this.baseUri + 'api/GuiaMedico/Especialidades', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/GuiaMedico/Prestadores
		 */
		ObterPrestadores(req: Objeto_GuiaMedicoObjeto_Prestadores.Requisicao): Promise<Array<Objeto_GuiaMedicoObjeto_Prestadores.Resposta>> {
			return Axios.post<Array<Objeto_GuiaMedicoObjeto_Prestadores.Resposta>>(this.baseUri + 'api/GuiaMedico/Prestadores', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/GuiaMedico/TpPrest
		 */
		ObterTpPrest(): Promise<Objeto_GuiaMedicoObjeto_Recurso.Resposta> {
			return Axios.post<Objeto_GuiaMedicoObjeto_Recurso.Resposta>(this.baseUri + 'api/GuiaMedico/TpPrest', null).then(d => d.data);
		}

		/**
		 * POST api/GuiaMedico/Uf
		 */
		ObterUf(): Promise<Objeto_GuiaMedicoObjeto_Uf.Resposta> {
			return Axios.post<Objeto_GuiaMedicoObjeto_Uf.Resposta>(this.baseUri + 'api/GuiaMedico/Uf', null).then(d => d.data);
		}
	}

	export class Login {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * POST api/Login/Beneficiario/Senha
		 */
		AlterarSenhaBeneficiario(req: Objeto_BeneficiarioObjeto_Login.AlterarSenha): Promise<Objeto_BeneficiarioObjeto_Login.AlterarSenha> {
			return Axios.post<Objeto_BeneficiarioObjeto_Login.AlterarSenha>(this.baseUri + 'api/Login/Beneficiario/Senha', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Empresa/Senha
		 */
		AlterarSenhaEmpresa(req: Objeto_EmpresaObjeto_Login.AlterarSenha): Promise<Objeto_EmpresaObjeto_Login.AlterarSenha> {
			return Axios.post<Objeto_EmpresaObjeto_Login.AlterarSenha>(this.baseUri + 'api/Login/Empresa/Senha', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Beneficiario
		 */
		LoginBeneficiario(autenticacao: Objeto_BeneficiarioObjeto_Login.Requisicao): Promise<Objeto_BeneficiarioObjeto_Login.Resposta> {
			return Axios.post<Objeto_BeneficiarioObjeto_Login.Resposta>(this.baseUri + 'api/Login/Beneficiario', JSON.stringify(autenticacao), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Empresa
		 */
		LoginEmpresa(autenticacao: Objeto_EmpresaObjeto_Login.Requisicao): Promise<Objeto_EmpresaObjeto_Login.Resposta> {
			return Axios.post<Objeto_EmpresaObjeto_Login.Resposta>(this.baseUri + 'api/Login/Empresa', JSON.stringify(autenticacao), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Prestador
		 */
		LoginPrestador(autenticacao: Objeto_PrestadorObjeto_Login.Requisicao): Promise<Objeto_EmpresaObjeto_Login.Resposta> {
			return Axios.post<Objeto_EmpresaObjeto_Login.Resposta>(this.baseUri + 'api/Login/Prestador', JSON.stringify(autenticacao), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Prestadores
		 */
		Prestadores(req: Objeto_PrestadorObjeto_Login_Prestador.Requisicao): Promise<Array<Objeto_PrestadorObjeto_Login_Prestador.Resposta>> {
			return Axios.post<Array<Objeto_PrestadorObjeto_Login_Prestador.Resposta>>(this.baseUri + 'api/Login/Prestadores', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Login/Token
		 */
		Token(): Promise<Objeto_EmpresaObjeto_Login.Resposta> {
			return Axios.post<Objeto_EmpresaObjeto_Login.Resposta>(this.baseUri + 'api/Login/Token', null).then(d => d.data);
		}
	}

	export class Prestador {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * DELETE api/Prestador/Lote
		 */
		CancelarLote(req: Objeto_PrestadorObjeto_Lote.Cancelamento): Promise<AxiosResponse> {
			return Axios.delete(this.baseUri + 'api/Prestador/Lote');
		}

		/**
		 * PUT api/Prestador/Lote
		 */
		EnvioLote(): Promise<Objeto_PrestadorObjeto_Lote.Resposta> {
			return Axios.put<Objeto_PrestadorObjeto_Lote.Resposta>(this.baseUri + 'api/Prestador/Lote', null).then(d => d.data);
		}

		/**
		 * POST api/Prestador/Lote
		 */
		Lote(req: Objeto_PrestadorObjeto_Lote.Requisicao): Promise<Array<Objeto_PrestadorObjeto_Lote.Resposta>> {
			return Axios.post<Array<Objeto_PrestadorObjeto_Lote.Resposta>>(this.baseUri + 'api/Prestador/Lote', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}
	}

	export class Servico {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * POST api/Servico/Banco
		 */
		Banco(): Promise<Array<Objeto_Servico_Banco.Resposta>> {
			return Axios.post<Array<Objeto_Servico_Banco.Resposta>>(this.baseUri + 'api/Servico/Banco', null).then(d => d.data);
		}

		/**
		 * POST api/Servico/Configuracao
		 */
		Configuracao(req: Objeto_ConexaoBanco.Requisicao): Promise<Objeto_ConexaoBanco.Slave> {
			return Axios.post<Objeto_ConexaoBanco.Slave>(this.baseUri + 'api/Servico/Configuracao', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Servico/ConsultarCEP
		 */
		ConsultarCep(req: Objeto_Servico_Endereco.Requisicao): Promise<Objeto_Servico_Endereco.Resposta> {
			return Axios.post<Objeto_Servico_Endereco.Resposta>(this.baseUri + 'api/Servico/ConsultarCEP', JSON.stringify(req), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(d => d.data);
		}

		/**
		 * POST api/Servico/Municipio
		 */
		Municipio(): Promise<Array<Objeto_Servico_Municipios.Resposta>> {
			return Axios.post<Array<Objeto_Servico_Municipios.Resposta>>(this.baseUri + 'api/Servico/Municipio', null).then(d => d.data);
		}

		/**
		 * POST api/Servico/Documentos
		 */
		ObterDocumentoDiverso(): Promise<Array<Objeto_Servico_Documentos.Resposta>> {
			return Axios.post<Array<Objeto_Servico_Documentos.Resposta>>(this.baseUri + 'api/Servico/Documentos', null).then(d => d.data);
		}

		/**
		 * POST api/Servico/DocumentosPerfil
		 */
		ObterDocumentoDiversoPerfil(): Promise<Array<Objeto_Servico_DocumentosPerfil.Resposta>> {
			return Axios.post<Array<Objeto_Servico_DocumentosPerfil.Resposta>>(this.baseUri + 'api/Servico/DocumentosPerfil', null).then(d => d.data);
		}

		/**
		 * POST api/Servico/Pais
		 */
		Pais(): Promise<Array<Objeto_Servico_Pais.Resposta>> {
			return Axios.post<Array<Objeto_Servico_Pais.Resposta>>(this.baseUri + 'api/Servico/Pais', null).then(d => d.data);
		}

		/**
		 * POST api/Servico/Parentesco
		 */
		Parentesco(): Promise<Array<Objeto_Servico_Parentesco.Resposta>> {
			return Axios.post<Array<Objeto_Servico_Parentesco.Resposta>>(this.baseUri + 'api/Servico/Parentesco', null).then(d => d.data);
		}

		/**
		 * GET api/Servico/Configuracao
		 */
		TesteConfiguracao(): Promise<Objeto_Servico_TesteConfiguracao.Resposta> {
			return Axios.get<Objeto_Servico_TesteConfiguracao.Resposta>(this.baseUri + 'api/Servico/Configuracao').then(d => d.data);
		}
	}

	export class Unidade {
		constructor(private baseUri: string = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/') {
		}

		/**
		 * GET api/Unidade/Logo
		 */
		BannerImage(): Promise<AxiosResponse> {
			return Axios.get(this.baseUri + 'api/Unidade/Logo');
		}

		/**
		 * GET api/Unidade
		 */
		Unidade(): Promise<Objeto.Unidade> {
			return Axios.get<Objeto.Unidade>(this.baseUri + 'api/Unidade').then(d => d.data);
		}
	}

}

