import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IDetailsList,
  IShimmeredDetailsListProps,
  IStyleFunctionOrObject,
  ScrollablePane,
  ScrollbarVisibility,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TooltipHost,
  IShimmeredDetailsListStyleProps,
  IShimmeredDetailsListStyles,
  IRenderFunction,
  IDetailsHeaderProps,
  Selection,
  ScrollToMode,
} from "@fluentui/react";
import { classNames } from "./styles";

/* export type IFiltro<T> = {
  [P in keyof T]?: T[P];
}; */

export type Filtro<T> = (value: T, index: number, array: Array<T>) => void;

type TabelaStyle = IStyleFunctionOrObject<IShimmeredDetailsListStyleProps, IShimmeredDetailsListStyles> & { container?: React.CSSProperties };

export interface ITabela<T = any> extends IShimmeredDetailsListProps {
  titulo?: string;
  carregamentoAnimacao?: boolean;
  cabecalhoFixo?: boolean;
  selecao?: (selecao: Array<T>) => void;
  styles?: TabelaStyle;
  virtualizar?: boolean;
  filtro?: Filtro<T>;
  // limparSelecaoClickFora?: boolean;
  alternarComClick?: boolean;
}

export const Tabela = <T,>(p: React.PropsWithChildren<ITabela<T>>) => {
  const { titulo, carregamentoAnimacao, cabecalhoFixo, selecao, styles, virtualizar, filtro, items, alternarComClick, ...props} = p;
  const [itensFiltrados, definirItensFiltrados] = useState(items);

  const containerRef = useRef<HTMLDivElement>(null);
  const listRef: React.RefObject<IDetailsList> = React.useRef(null);

  const { container: containerStyle, ..._styles } = styles || {};

  const [selection] = useState(new Selection({
    onSelectionChanged: () => {
      const primeiraLinhaSelecionada = selection.getSelectedIndices()[0];

      selecao?.call(null, selection.getSelection() as Array<T>);

      /* if (limparSelecaoClickFora && primeiraLinhaSelecionada) {
        const alturaLinha = (listRef.current as any)?._root.current.querySelector(".ms-List-cell:first-child").offsetHeight;

        setTimeout(() => {
          listRef.current?.scrollToIndex(primeiraLinhaSelecionada, idx => alturaLinha, ScrollToMode.top);
        }, 100);
      } */
    },
  }));

  /* useEffect(() => {
    const limparSelecao = (e: MouseEvent) => {
      !containerRef.current?.contains(e.target as Node) && section.setAllSelected(false);
    }

    if (limparSelecaoClickFora) {
      document.addEventListener("mousedown", limparSelecao);
    }

    return () => {
      document.removeEventListener("mousedown", limparSelecao);
    };
  }, [listRef, limparSelecaoClickFora]); */

  useEffect(() => {
    // TODO: Optimizar

    if (!items.length || !filtro) {
      return definirItensFiltrados(items);
    }

    definirItensFiltrados(items.filter(filtro));
  }, [items, filtro]);

  useEffect(() => {
    if (!virtualizar) props.onShouldVirtualize = () => false
  }, [virtualizar]);

  const detailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => props ? (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip: tooltipHostProps => <TooltipHost {...tooltipHostProps} />,
      })}
    </Sticky>
  ) : null;

  const _tabela = useMemo(() => (
    <ShimmeredDetailsList
      onRenderDetailsHeader={cabecalhoFixo ? detailsHeader : undefined}
      className={classNames.tabela}
      styles={styles}
      onRenderRow={(props, defaultRender): JSX.Element => {
        return (
          <div {...(alternarComClick ? { "data-selection-toggle": true } : null)}>
            {defaultRender && defaultRender(props)}
          </div>
        );
      }}
      {...props}
      componentRef={listRef}
      items={itensFiltrados || []}
      selection={selection}
    />
  ), [cabecalhoFixo, props, _styles]);

  return cabecalhoFixo ? (
    <div ref={containerRef} style={containerStyle} className={classNames.fixedWrapper}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always}>
        {_tabela}
      </ScrollablePane>
    </div>
  ) : _tabela;
}

export default Tabela;
