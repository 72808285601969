import { getTheme, mergeStyleSets } from "@fluentui/react";

const tema = getTheme();

export interface IClassNames {
  tabela: string;
}

export const classNames = mergeStyleSets({
  tabela: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  fixedWrapper: {
    position: "relative",
    flex: 1,
    backgroundColor: tema.semanticColors.bodyBackground,
  },
});
