import { CSSProperties } from "react";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";
import { getTheme, IDropdownStyles, mergeStyleSets } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  cabecalho: CSSProperties;
  logo: CSSProperties;
  autenticacaoCard: CSSProperties;
  autenticacaoUsuario: CSSProperties;
  logoutButton: CSSProperties;
  dropdown: Partial<IDropdownStyles>;
}

export const styles: IStyle = {
  cabecalho: {
    position: "relative",
    display: "flex",
    padding: "0 12px",
    backgroundColor: tema.semanticColors.bodyBackground,
    height: 68,
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: Depths.depth8,
    zIndex: 9999,
  },
  logo: {
    maxHeight: 64,
    marginTop: 6,
    marginBottom: 6,
  },
  autenticacaoCard: {
    display: "flex",
    alignItems: "center",
  },
  autenticacaoUsuario: {
    fontWeight: "bold",
    color: tema.semanticColors.bodyText,
  },
  logoutButton: {
    color: tema.semanticColors.bodyText,
  },
  dropdown: {
    title: {
      display: "flex",
      height: 54,
      alignItems: "center",
      border: "none",
    },
    caretDownWrapper: {
      top: "50%",
      transform: "translateY(-50%)",
    }
  },
};

export interface IClassNames {
  autenticacaoWrapper: string;
  empresas: string;
}

export const classNames: IClassNames = mergeStyleSets({
  empresas: {
    selectors: {
      "& *": {
        cursor: "pointer",
      },
      '& .ms-Dropdown::after': {
        borderColor: "transparent !important",
      },
      "&:hover, & .ms-Dropdown.is-open": {
        boxShadow: "0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)"
      },
      "& .ms-Button-flexContainer": {
        width: "100%!important",
      }
    }
  },
  autenticacaoWrapper: {
    marginRight: 8,
    textAlign: "right",
    selectors: {
      '& label': {
        lineHeight: "1.45em",
        padding: 0,
      },
    },
  }
});
