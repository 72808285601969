import React, { useState } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { UseTranslationResponse, useTranslation } from "react-i18next";

interface IDialogCancelamento {
  isOpen: boolean;
  onDismissed: (resultado: boolean) => void;
}

export const DialogCancelamento = ({ isOpen, onDismissed }: IDialogCancelamento) => {
  const { t }: UseTranslationResponse = useTranslation();
  const [cancelandoLote, setCancelandoLote] = useState<boolean>(false);

  return(
    <Dialog
      hidden={!isOpen}
      onDismissed={() => onDismissed(false)}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("cancelamento-lote-guia"),
        subText: t("deseja-realmente-realizar-cancelamento-guia"),
      }}
      modalProps={{
        isBlocking: false,
        isDarkOverlay: true,
        styles: { main: { maxWidth: 450 } },
      }} >
    <DialogFooter>
      <DefaultButton disabled={cancelandoLote} onClick={() => onDismissed(false)} text={t("nao")} />
      <PrimaryButton disabled={cancelandoLote} onClick={() => {
          setCancelandoLote(true);
          onDismissed(true);
        }} text={t("sim")} />
    </DialogFooter>
  </Dialog>
  );
};

