import * as Autenticacao from './Autenticacao';
import * as Configuracao from './Configuracao';
import * as Unidade from './Unidade';
import * as Beneficiario from './Beneficiario';
import * as Prestador from './Prestador';
import * as Empresa from './Empresa';
import * as Servico from './Servico';
import * as GuiaMedico from './GuiaMedico';

// The top-level state object
export interface ApplicationState {
    autenticacao: Autenticacao.State | (undefined & { fromT?: any });
    configuracao: Configuracao.State | (undefined & { fromT?: any });
    unidade: Unidade.State | (undefined & { fromT?: any });
    beneficiario: Beneficiario.State | (undefined & { fromT?: any });
    prestador: Prestador.State | (undefined & { fromT?: any });
    empresa: Empresa.State | (undefined & { fromT?: any });
    servico: Servico.State | (undefined & { fromT?: any });
    guiaMedico: GuiaMedico.State | (undefined & { fromT?: any });
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    autenticacao: Autenticacao.reducer,
    configuracao: Configuracao.reducer,
    unidade: Unidade.reducer,
    beneficiario: Beneficiario.reducer,
    prestador: Prestador.reducer,
    empresa: Empresa.reducer,
    servico: Servico.reducer,
    guiaMedico: GuiaMedico.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
