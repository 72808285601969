import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect, useMemo } from "react";
import { DefaultButton, PrimaryButton, FocusZone } from "office-ui-fabric-react";
import { ProgressIndicator, Link } from "@fluentui/react";
import { UseTranslationResponse, useTranslation } from "../../i18n";

export interface IPassos {
  retrocederBotaoTexto: string | string[];
  avancarBotaoTexto: string | string[];
  passoInicial?: number;
  passos: string[] | number[];
  retroceder: () => any;
}

// childs as cards
const Passos: React.FC<IPassos> & { Item?: React.FC<IItem> | any } = forwardRef(({ retrocederBotaoTexto, avancarBotaoTexto, passoInicial, children, passos, retroceder }, ref) => {
  const proximoBotao = useRef(null);
  const anteriorBotao = useRef(null);
  const [passo, setPasso] = useState<number>(passoInicial || 0);
  const [validando, setValidando] = useState<boolean>(false);

  retrocederBotaoTexto = !Array.isArray(retrocederBotaoTexto) ? [retrocederBotaoTexto] : retrocederBotaoTexto;
  avancarBotaoTexto = !Array.isArray(avancarBotaoTexto) ? [avancarBotaoTexto] : avancarBotaoTexto;

  children = React.Children.count(children) === 1 ? [children] : children;

  const primeiroPasso = passos[0] === passo;
  const ultimoPasso = passos[passos.length - 1] === passos[passo];

  // @ts-ignore
  const tela = children[passos[passo]];

  const rbTexto: string = retrocederBotaoTexto.length !== React.Children.count(children) ? primeiroPasso ? retrocederBotaoTexto[0] : retrocederBotaoTexto.slice(-1)[0] : retrocederBotaoTexto[passo];
  const abTexto: string = avancarBotaoTexto.length !== React.Children.count(children) ? ultimoPasso ? avancarBotaoTexto.slice(-1)[0] : avancarBotaoTexto[0] : avancarBotaoTexto[passo];
  const { t }: UseTranslationResponse = useTranslation();
  const [redefinirSenha, setRedefinirSenha] = useState<boolean>(false); 
  useImperativeHandle(ref, () => ({
    proximo() {
      // @ts-ignore: Object is possibly 'null'.
      const pbProps = proximoBotao?.current.props;

      // tslint:disable-next-line: no-unused-expression
      !pbProps?.disabled && pbProps.onClick();
    },
  }), [proximoBotao]);

  useEffect(() => {
    const pbProps = (proximoBotao?.current as any)?.props;
    const abProps = (anteriorBotao?.current as any)?.props;

    const keyDown = (evt: any) => {
      if (evt.key === "Enter") {
        // tslint:disable-next-line: no-unused-expression
        !pbProps?.disabled && pbProps.onClick();
      } else if (evt.ctrlKey && evt.key === "ArrowRight") {
        // tslint:disable-next-line: no-unused-expression
        !pbProps?.disabled && pbProps.onClick();
      } else if (evt.ctrlKey && evt.key === "ArrowLeft") {
        // tslint:disable-next-line: no-unused-expression
        abProps.onClick();
      } else if (evt.key === "ArrowUp" || evt.key === "ArrowDown") {
        const focusable: HTMLElement[] = Array.from(document.querySelectorAll("button, input, select, textarea"));
        // tslint:disable-next-line: no-unused-expression
        focusable.indexOf(document.activeElement as HTMLElement) === -1 && focusable[0].focus();
      } else {
        return;
      }

      evt.stopPropagation();
      evt.preventDefault();
    };

    window.addEventListener("keydown", keyDown);

    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  });

  return (
    <FocusZone>
      {useMemo(() => validando ? (
        <ProgressIndicator styles={{ root: { position: "absolute", top: 0, width: "100%"}, itemProgress: { padding: 0 } }} />
      ) : null, [validando])}
      {tela}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "1em", marginRight: 44, marginLeft: 44 }}>
        <DefaultButton
          componentRef={anteriorBotao}
          text={rbTexto}
          disabled={validando}
          onClick={() => retroceder && primeiroPasso ? retroceder() : setPasso(passo - 1)} />
        <PrimaryButton
          componentRef={proximoBotao}
          style={{ marginLeft: 8 }}
          text={abTexto}
          disabled={tela?.props?.invalido || validando}
          onClick={() => {
            //console.log(redefinirSenha);
            //debugger;
            setValidando(true);
            if (redefinirSenha == false){

              ((tela.props.validar ? tela.props.validar() : Promise.resolve(true)) as Promise<boolean>).then((sucesso) => {
                // tslint:disable-next-line: no-unused-expression
                sucesso && setPasso(passo + 1);
              }).finally(() => {
                setValidando(false);
              });

            }
            else{
              //redefinir senha
              ((tela.props.validar ? tela.props.validar() : Promise.resolve(true)) as Promise<boolean>).then((sucesso) => {
                // tslint:disable-next-line: no-unused-expression
                sucesso && setPasso(passo + 0);;
              }).finally(() => {
                setValidando(false);
              });

            }
            
          }} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "1em", marginRight: 44, marginLeft: 44 }}>
        {tela?.props?.exibirRedefinicaoSenha && (
          <Link onClick={()=>{setPasso(passos.length - 1); setRedefinirSenha(true)}} style={{fontSize: 13}} >{t("esqueceu-sua-senha")}</Link>
        )}
      </div>
      
    </FocusZone>
  );
});

export interface IItem {
  invalido?: boolean;
  validar?: () => Promise<boolean>;
  exibirRedefinicaoSenha?: boolean;
}

export const Item: React.FC<IItem> = ({ children }) => (
  <>{children}</>
);

Passos.Item = Item;

export default Passos;
