import React, { useMemo } from "react";
import { Card } from "@uifabric/react-cards";
import { Label, IconButton, Dropdown, IDropdownOption, Stack, CommandBarButton, IContextualMenuItem } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { styles, classNames } from "./styles";
//import logo from "./Gpsi-Vista9.png";
//import logo from "./unimed-registro.png";
//import logo from "./unimed-sta-rita.png";
import logo from "./unimed-inconfidentes.png";
//import logo from "./unimed-ibitinga.png";
//import logo from "./unimed-mococa.png";
//import logo from "./unimed-pirassununga.jpg";
import { ApplicationState } from "../../store";
import { obterTipoUsuario, TipoUsuario, actions as AutenticacaoActions } from "../../store/Autenticacao";
import { useTranslation, UseTranslationResponse } from "../../i18n";
import * as EmpresaStore from "../../store/Empresa";

import { Objeto_BeneficiarioObjeto_Login, Objeto_PrestadorObjeto_Login } from "../../api";

const Cabecalho: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { autenticacao: { usuario }, empresa: { planos, contratosLotacao, contratoAtivo } } = useSelector((store: ApplicationState) => store);

  const history = useHistory();

  const { t }: UseTranslationResponse = useTranslation();

  const logoutBotao = useMemo(() => usuario ? (
    <IconButton style={{ marginLeft: ".5em", height: 54, }} ariaLabel={t("sair")} iconProps={{ iconName: "SignOut", style: styles.logoutButton }} title={t("sair")} onClick={() => {
      dispatch(AutenticacaoActions.logout());
      history.push("/login");
    } } />
  ) : null, [dispatch, history, t, usuario]);

  return (
    <header style={styles.cabecalho}>
      <img style={styles.logo} src={logo} alt="GPSi" />
      {useMemo(() =>  {
        const tipoUsuario = window.location.pathname.indexOf("/imprimir/") === -1 ? obterTipoUsuario(usuario) : null;

        if (tipoUsuario === TipoUsuario.BENEFICIARIO) {
          const u = usuario as Objeto_BeneficiarioObjeto_Login.Resposta;
          return (
            <Card.Item styles={{ root: styles.autenticacaoCard as any }}>
              <div className={classNames.autenticacaoWrapper}>
                <Label>{u.SGEPESS_NOME}</Label>
                <Label style={{ fontWeight: 400 }}>{u.SCACARTE_CODIGO_CART?.replace(/^(\d{3})(\d{4})(\d{6})(\d{2})(\d{1}).*/, "$1.$2.$3.$4-$5")}</Label>
              </div>
              {logoutBotao}
            </Card.Item>
          );
        } else if (tipoUsuario === TipoUsuario.PRESTADOR) {
          const u = usuario as Objeto_PrestadorObjeto_Login.Resposta;
          return (
            <Card.Item styles={{ root: styles.autenticacaoCard as any }}>
              <div className={classNames.autenticacaoWrapper}>
                <Label>{u.SGEUSUAR_NET_NOME}</Label>
                <Label style={{ fontWeight: 400 }}>{u.SCAPREST_CODIGO}</Label>
              </div>
              {logoutBotao}
            </Card.Item>
          );
        } else if (tipoUsuario === TipoUsuario.LOTACAO) {
          
          const contratos = contratosLotacao.reduce((acc, curr) => {
            if (!acc.find(e => e.key === curr.SCACONTR_CODIGO)) {
              acc.push({
                key: curr.SCACONTR_CODIGO as string,
                text: curr.SCAEMPBA_NOME,
                secondaryText: curr.SCACONTR_CODIGO,
                onClick: () => dispatch(EmpresaStore.actions.definirContratoAtivo(curr.SCACONTR_CODIGO as string))
              });
            }

            return acc;
          }, [] as Array<IContextualMenuItem>);

          return (
            <Card.Item styles={{ root: styles.autenticacaoCard as any }}>
              <CommandBarButton
                style={{ height: 54 }}
                onRenderText={() => {
                  const item = contratos.find(e => e.key === contratoAtivo);

                  return (
                    <div key={`${item?.key}-${(Math.random() * (1))}`} style={{ paddingLeft: ".5em", cursor: "pointer" }} className={classNames.autenticacaoWrapper}>
                      <Label>{item?.text}</Label>
                      <Label style={{ fontWeight: 400 }}>{item?.key}</Label>
                    </div>
                  )
                }}
                menuProps={{
                  items: contratos,
                  useTargetWidth: true,
                }}
              />
              {logoutBotao}
            </Card.Item>
          );
        } else if (tipoUsuario === TipoUsuario.EMPRESA) {
          const contratos = planos.reduce((acc, curr) => {
            if (!acc.find(e => e.key === curr.SCACONTR_CODIGO)) {
              acc.push({
                key: curr.SCACONTR_CODIGO as string,
                text: curr.SCAEMPBA_NOME,
                secondaryText: curr.SCACONTR_CODIGO,
                onClick: () => dispatch(EmpresaStore.actions.definirContratoAtivo(curr.SCACONTR_CODIGO as string))
              });
            }

            return acc;
          }, [] as Array<IContextualMenuItem>);

          return (
            <Card.Item styles={{ root: styles.autenticacaoCard as any }}>
              <CommandBarButton
                style={{ height: 54 }}
                onRenderText={() => {
                  const item = contratos.find(e => e.key === contratoAtivo);

                  return (
                    <div key={`${item?.key}-${(Math.random() * (1))}`} style={{ paddingLeft: ".5em", cursor: "pointer" }} className={classNames.autenticacaoWrapper}>
                      <Label>{item?.text}</Label>
                      <Label style={{ fontWeight: 400 }}>{item?.key}</Label>
                    </div>
                  )
                }}
                menuProps={{
                  items: contratos,
                  useTargetWidth: true,
                }}
              />
              {logoutBotao}
            </Card.Item>
          );
        }

        return  null;
      }, [logoutBotao, usuario, planos, contratoAtivo, contratosLotacao])}
    </header>
  );
};

export default Cabecalho;
