import React from "react";
import { Stack, Label } from "office-ui-fabric-react";
import { useLocation, useParams } from "react-router";

const Impressao: React.FC = () => {
  const { tipo } = useParams() as any;
  const { search } = useLocation();

  if (tipo === "comprovante_envio_xml") {
    const dadosPreparados = JSON.parse(atob(search.substr(1)));

    return (
      <div style={{ flex: 1, padding: "1em", justifyContent: "center", backgroundColor: "#FFF" }}>
        <div>
          <Stack
            style={{ marginTop: 8, marginBottom: 16 }}
            tokens={{ childrenGap: 8 }} >
            {dadosPreparados.map(([titulo, valor]: any, i: number) => (
              <Stack key={`Impressao-${i}`} horizontal>
                <Label style={{ paddingTop: 0, paddingBottom: 0, marginRight: 8 }} >{titulo}:</Label>
                <Label disabled style={{ paddingTop: 0, paddingBottom: 0, fontWeight: 400 }} >{valor}</Label>
              </Stack>
            ))}
          </Stack>
        </div>
      </div>
    );
  }

  return null;
};

export default Impressao;
