import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { API_Controllers, Objeto, Objeto_PrestadorObjeto_Lote } from "../api";
import Axios from "axios";

export const PrestadorAPI = new API_Controllers.Prestador("");

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  loteGuias?: Array<Objeto_PrestadorObjeto_Lote.Resposta>;
  recursoGlosa?: Array<Objeto_PrestadorObjeto_Lote.Resposta>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ObterLoteGuiasAction {
  type: "PRESTADOR::LOTE_GUIAS",
  loteGuias?: Array<Objeto_PrestadorObjeto_Lote.Resposta>;
}

export interface ObterRecursoGlosaAction {
  type: "PRESTADOR::RECURSO_GLOSA",
  recursoGlosa?: Array<Objeto_PrestadorObjeto_Lote.Resposta>;
}

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = ObterLoteGuiasAction | ObterRecursoGlosaAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  obterLoteGuias: (DATA_INICIAL?: Date, DATA_LIMITE?: Date): AppThunkAction<KnownAction> => (dispatch) => (
    PrestadorAPI.Lote({ TIPO: Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS, DATA_INICIAL, DATA_LIMITE }).then((loteGuias) => {
      loteGuias = loteGuias.map(b => {
        b.SMNWSLOT_DT_GERACAO_ARQ = b.SMNWSLOT_DT_GERACAO_ARQ ? new Date(b.SMNWSLOT_DT_GERACAO_ARQ) : undefined;

        return b;
      })

      dispatch({ type: "PRESTADOR::LOTE_GUIAS", loteGuias });

      return loteGuias;
    })
  ),
  obterRecursoGlosa: (DATA_INICIAL: Date, DATA_LIMITE: Date): AppThunkAction<KnownAction> => (dispatch) => (
    PrestadorAPI.Lote({ TIPO: Objeto_PrestadorObjeto_Lote.TipoLote.RECURSO_GLOSA, DATA_INICIAL, DATA_LIMITE }).then((reursoGlosa) => {
      reursoGlosa = reursoGlosa.map(b => {
        b.SMNWSLOT_DT_GERACAO_ARQ = b.SMNWSLOT_DT_GERACAO_ARQ ? new Date(b.SMNWSLOT_DT_GERACAO_ARQ) : undefined;

        return b;
      })

      dispatch({ type: "PRESTADOR::RECURSO_GLOSA", recursoGlosa: reursoGlosa });
    })
  ),
  cancelarLoteGuias: (SMNWSLOT_PROTOCOLO: string): AppThunkAction<KnownAction> => (dispatch, getState) => (
    // TODO: ERROR
    PrestadorAPI.CancelarLote({ TIPO: Objeto_PrestadorObjeto_Lote.TipoLote.LOTE_GUIAS, SMNWSLOT_PROTOCOLO }).then((resosta) => {
      const { prestador: { loteGuias } } = getState();

      if (resosta.status === 200) {
        const indice = loteGuias?.findIndex(loteGuias?.find((e) => e.SMNWSLOT_PROTOCOLO === SMNWSLOT_PROTOCOLO) as any);
        if (loteGuias && indice) {
          loteGuias[indice].SMNWSLOT_STATUS = "C";

          dispatch({ type: "PRESTADOR::LOTE_GUIAS", loteGuias })
        }
      } else {
        return Promise.reject("Erro ao cancelar guia");
      }
    })
  ),
  cancelarRecursoGlosa: (SMNWSLOT_PROTOCOLO: string): AppThunkAction<KnownAction> => (dispatch, getState) => (
    PrestadorAPI.CancelarLote({ TIPO: Objeto_PrestadorObjeto_Lote.TipoLote.RECURSO_GLOSA, SMNWSLOT_PROTOCOLO }).then(() => {
      const { prestador: { recursoGlosa } } = getState();
      dispatch({ type: "PRESTADOR::RECURSO_GLOSA", recursoGlosa: recursoGlosa?.filter((e) => e.SMNWSLOT_PROTOCOLO !== SMNWSLOT_PROTOCOLO) });
    })
  ),
  enviarLoteGuias: (LOTE_GUIAS: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const data = new FormData();
    data.append("lote", LOTE_GUIAS, LOTE_GUIAS.name);

    /*return new Promise((res, rej) => {
      Axios({
        method: "PUT",
        url: "api/Prestador/Lote",
        headers: {
          "Content-Type": "multipart/form-data;charset=ISO-8859-1",
        },
        data,
      }).then((resposta) => {
        debugger;
        if (resposta.status === 200) {
          const { prestador: { loteGuias } } = getState();
          const lote: Objeto_PrestadorObjeto_Lote.Resposta = resposta.data;
  
          lote.SMNWSLOT_DT_GERACAO_ARQ = lote.SMNWSLOT_DT_GERACAO_ARQ ? new Date(lote.SMNWSLOT_DT_GERACAO_ARQ) : undefined;
  
          dispatch({ type: "PRESTADOR::LOTE_GUIAS", loteGuias: [...(loteGuias || []), lote] });
        } else {
          const erro = resposta.data.indexOf("(") !== -1 ? resposta.data.match(/\((.*)\)/)[1]?.replace(/(^.)(.+)/, (m: any, f: any, r: any) => `${f}${r.toLowerCase()}`) : data;
  
          return Promise.reject(erro);
        }
      }).catch(erro => {
        debugger;
      });
      erro.response.data
    })*/

    return Axios({
      method: "PUT",
      url: "api/Prestador/Lote",
      headers: {
        "Content-Type": "multipart/form-data;charset=ISO-8859-1",
      },
      data,
    }).then((resposta) => {
      debugger;
      if (resposta.status === 200) {
        const { prestador: { loteGuias } } = getState();
        const lote: Objeto_PrestadorObjeto_Lote.Resposta = resposta.data;

        lote.SMNWSLOT_DT_GERACAO_ARQ = lote.SMNWSLOT_DT_GERACAO_ARQ ? new Date(lote.SMNWSLOT_DT_GERACAO_ARQ) : undefined;

        dispatch({ type: "PRESTADOR::LOTE_GUIAS", loteGuias: [...(loteGuias || []), lote] });
      } else {
        const erro = resposta.data.indexOf("(") !== -1 ? resposta.data.match(/\((.*)\)/)[1]?.replace(/(^.)(.+)/, (m: any, f: any, r: any) => `${f}${r.toLowerCase()}`) : data;

        return Promise.reject(erro);
      }
    }).catch(erro => {
      return Promise.reject(erro.response.data);
    });
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return {
      loteGuias: undefined,
      recursoGlosa: undefined,
    };
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {
    case "PRESTADOR::LOTE_GUIAS":
      return { ...state, loteGuias: action.loteGuias };
    case "PRESTADOR::RECURSO_GLOSA":
      return { ...state, recursoGlosa: action.recursoGlosa };
    default:
      return state;
  }
};
