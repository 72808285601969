import { CSSProperties } from "react";
import { FontWeights, getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  root: CSSProperties;
  title: CSSProperties;
  body: CSSProperties;
  celulaDireita: CSSProperties;
  celulaCento: CSSProperties;
  botaoTabela: CSSProperties;
}

export const styles: IStyle = {
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "1.25em",
  },
  title: {
    fontSize: "1.5em",
    fontWeight: FontWeights.regular as any
  },
  body: {
    display: "flex",
    flex: 1,
    marginTop: "1em",
  },
  celulaDireita: {
    textAlign: "right",
  },
  celulaCento: {
    textAlign: "center",
  },
  botaoTabela: {
    color: tema.semanticColors.bodyText,
    height: "auto",
    margin: ".25em",
    display: "inherit",
  },
};
