import React, { useState, useEffect } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { Dropdown, IDropdown } from "office-ui-fabric-react/lib/Dropdown";
import { TextField, MaskedTextField } from "office-ui-fabric-react/lib/TextField";
import { TFunction } from "i18next";
import { IDatePickerStrings, DatePicker, Checkbox } from "office-ui-fabric-react";

const styles = {
  container: {
    alignSelf: "center",
    alignItems: "justify",
  },
  titulo: {
    fontSize: 24,
    fontWeight: "500",
    marginTop: 12,
    marginBottom: 8,
    textAlign: "center",
  },

};

const usuarios = [
  {key:"01", text:"FULANO"},
];

const pessoa = [
  {key:"01", text:"FISICA"},
  {key:"02", text:"JURÍDICA"},
  {key:"03", text:"FILANTRÓPICA"}
];

const enderecoFixo = [
  {key:"01", text:"RUA"}
];

const uf = [
  {key:"01", text:"PE"},
]


const DayPickerStrings = (t: TFunction): IDatePickerStrings => ({
  months: [t("janeiro"), t("fevereiro"), t("marco"), t("abril"), t("maio"), t("junho"), t("julho"), t("agosto"), t("setembro"), t("outubro"), t("novembro"), t("dezembro")],

  shortMonths: [t("jan"), t("fev"), t("mar"), t("abr"), t("mai"), t("jun"), t("jul"), t("ago"), t("set"), t("out"), t("nov"), t("dez")],

  days: [t("domingo"), t("segunda"), t("terca"), t("quarta"), t("quinta"), t("sexta"), t("sabado")],

  shortDays: t("dias-semana-abreviados").split(""),

  goToToday: t("ir-para-dia-atual"),
  prevMonthAriaLabel: t("ir-para-mes-anterior") + ":",
  nextMonthAriaLabel: t("ir-para-proximo-mes") + ":",
  prevYearAriaLabel: t("ir-para-ano-anterior") + ":",
  nextYearAriaLabel: t("ir-para-proximo-ano") + ":",
  closeButtonAriaLabel: t("fechar-selecao-data") + ":",
});

const Prestador : React.FC = () => {
  const { t }: UseTranslationResponse = useTranslation();
  const dropdownRef = React.createRef<IDropdown>();
  const [dataInicial, setDataInicial] = useState<Date>(new Date());
  const [dataLimite, setDataLimite] = useState<Date>(new Date());
  const [isChecked, setIsChecked] = useState(false);

  const [nome, setNome] = useState("");
  const [conselho, setConselho] = useState("");
  const [codigoConselho, setCodigoConselho] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [enderecoUtilizado, setEnderecoUtilizado] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");

  function _onChange() {
    isChecked === false ? setIsChecked(true) : setIsChecked(false);
  }

  useEffect(() => {
    console.log('isChecked',isChecked);
  });

  return (
    <div style={styles.container}>
      <div>
        <Dropdown
          componentRef={dropdownRef}
          placeholder="Selecione um usuário:"
          options={usuarios}
          required={false}
          styles={{ dropdown: { width: 250 } }}/>
      </div>
      <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
        
        <div style={{marginTop:28}}>
          <Dropdown
            componentRef={dropdownRef}
            placeholder="Tipo de Pessoa:"
            options={pessoa}
            required={true}
            styles={{ dropdown: { width: 250 } }} 
            />
        </div>

        <div style={{marginLeft:15, marginRight:15}}>
          <TextField label="Nome do Prestador" required
            value={nome} 
            onChange={(_evt, texto) => {
              setNome(texto as string);
            }}
          />
        </div>
        <TextField label="Conselho" 
          value={conselho}
          onChange={(_evt, texto) => {
            setConselho(texto as string);
          }}
        />
      </div>
      <div style={{display:"flex",flexDirection:"row"}}>
        <TextField label="Código do conselho" 
          value={codigoConselho}
          onChange={(_evt, texto) => {
            setCodigoConselho(texto as string);
          }}
        />
        
        <div style={{marginLeft:15, marginRight:15}}>
          <MaskedTextField label="CPF do prestador" mask="999.9999.999-99" required style={{width:98}}
           value={cpf}
           onChange={(_evt, texto) => {
             setCpf(texto as string);
           }}/>
        </div>
        
        <div>
          <DatePicker
            value={dataInicial}
            //onSelectDate={setDataInicial}
            maxDate={dataLimite}
            isRequired
            strings={DayPickerStrings(t)}
            placeholder={t("data-inicial")}
            //formatDate={(data: Date) => data.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}
            style={{ maxWidth: 112, marginTop: 28 }}
            />
        </div>
        
      </div>

      <div style={{marginTop:15}}>
        <div style={{marginBottom:10}}>
          <label style={{fontWeight:500}}>Endereço (Local em que o serviço foi realizado):</label>
        </div>
        <div>
          <div>
            <Dropdown
              componentRef={dropdownRef}
              placeholder="Endereços utilizados:"
              options={enderecoFixo}
              required={false}
              styles={{ dropdown: { width: 250 } }} 
              />
          </div>
          <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
            
            <div>
              <MaskedTextField label="CEP" mask="99999-999" style={{width:69}}
              value={cep}
              onChange={(_evt, texto) => {
                setCep(texto as string);
              }}/>
            </div>

            <div style={{marginLeft:10,marginRight:10}}>
              <TextField label="Endereço"
              value={endereco}
              onChange={(_evt, texto) => {
                setEndereco(texto as string);
              }}/>
            </div>

            <div style={{marginRight:10}}>
              <TextField label="Número" disabled={isChecked === true ? true : false} defaultValue={isChecked === true ? "S/N" : " "} style={{width:69}}
              value={numero}
              onChange={(_evt, texto) => {
                setNumero(texto as string);
              }}/>
            </div>
            
            <div style={{marginTop:35}}>
              <Checkbox label="Caso sem número, selecione" onChange={_onChange} style={{marginTop:5}}/>
            </div>
            
          </div>

          <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
            <div>
              <TextField label="Complemento" style={{width:200}}
              value={complemento}
              onChange={(_evt, texto) => {
                setComplemento(texto as string);
              }}/>
            </div>
            <div style={{}}>
              <TextField label="Bairro" style={{width:100}}
              value={bairro}
              onChange={(_evt, texto) => {
                setBairro(texto as string);
              }}/>
            </div>
            <div>
              <TextField label="Cidade" style={{width:100}}
              value={cidade}
              onChange={(_evt, texto) => {
                setCidade(texto as string);
              }}/>
            </div>
            <div style={{marginTop:28}}>
              <Dropdown
                componentRef={dropdownRef}
                placeholder="UF:"
                options={uf}
                required={false}
                styles={{ dropdown: { width: 100 } }} />
            </div>  
          </div>
        </div>
      </div>   

    </div>
  );
};

export default Prestador;
