import React, { useState, useMemo, useEffect } from "react";
import {
  Stack,
  Label,
  Pivot,
  PivotItem,
  IColumn,
  Dropdown,
  DefaultButton,
  DirectionalHint,
  Modal, Spinner, SpinnerSize, SelectionMode, ScrollablePane, IDropdownOption, IconButton
} from "@fluentui/react";
import { useId } from '@uifabric/react-hooks';

import { UseTranslationResponse, useTranslation, TFunction } from "../../i18n";
import { styles, classNames } from "./styles";
import { Tabela } from "../../components/Tabela";
import {
  Objeto_BeneficiarioObjeto_Carencia,
  Objeto_BeneficiarioObjeto_Dependente,
  Objeto_BeneficiarioObjeto_PrestadoresDesligados,
  Objeto_BeneficiarioObjeto_Utilizacao,
  Objeto_BeneficiarioObjeto_Boleto,
  Objeto_Servico,
  Objeto_BeneficiarioObjeto_Login
} from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as BeneficiarioStore from "../../store/Beneficiario";
import { DataSeletor } from "../../components/DataSeletor";
import { PesquisaModal } from "../../components/PesquisaModal";
import Axios, { CancelTokenSource } from "axios";
import { Carteirinha } from "../../components/Carteirinha";
import Cadastro from "./Cadastro";
import * as EmpresaStore from "../../store/Empresa";

import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { useBoolean } from '@uifabric/react-hooks';

const obterBoleto = (ID_SCOTITUL: number, CD_MULTI_EMPRESA: number, SCACONTR_CODIGO: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterBoleto/${ID_SCOTITUL}/${CD_MULTI_EMPRESA}/${SCACONTR_CODIGO}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterRelatorio = (ID_SCOTITUL: number, CD_MULTI_EMPRESA: number, SCACONTR_CODIGO: string, TIPO: Objeto_Servico.TipoRelatorio, QUEBRA: "S" | "N", SCOTITUL_NOSSO_NUMERO: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterRelatorio/${ID_SCOTITUL}/${CD_MULTI_EMPRESA}/${SCACONTR_CODIGO}/${TIPO}/${QUEBRA}/${SCOTITUL_NOSSO_NUMERO}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
  timeout: 120000,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterIR = (SCACONTR_CODIGO: string, SCACARTE_CODIGO_CART: string, CD_MULTI_EMPRESA: string, ANO: number, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterIR/${SCACONTR_CODIGO}/${SCACARTE_CODIGO_CART}/${CD_MULTI_EMPRESA}/${ANO}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterInformacoesUtilizacao = (SCACARTE_CODIGO_CART: string, DT_COMPETENCIA: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterInformacoesUtilizacao/${SCACARTE_CODIGO_CART}/${DT_COMPETENCIA}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const obterCarencia = (SCACARTE_CODIGO_CART: string, DT_COMPETENCIA: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterCarencias/${SCACARTE_CODIGO_CART}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token,
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

enum PivotItens {
  DEPENDENTES = "DEPENDENTES",
  INFORMACOES_UTILIZACAO = "INFORMACOES_UTILIZACAO",
  MEU_CADASTRO = "MEU_CADASTRO",
  REDEASSISTENCIAL = "REDEASSISTENCIAL",
  CARENCIA = "CARENCIA",
  RENDIMENTOS = "RENDIMENTOS",
  BOLETOS = "BOLETOS"
}

const obterColunasDependentes = (t: TFunction): IColumn[] => [
  {
    key: "codigo",
    name: t("codigo"),
    minWidth: 120,
    maxWidth: 140,
    // isFiltered: true,
    onRender: (item: Objeto_BeneficiarioObjeto_Dependente.Resposta) => {
      return <div style={{ marginLeft: 16 }}>{item.CARTEDEP_CODIGO_CART}</div>;
    },
  },
  {
    key: "nome",
    name: t("nome"),
    minWidth: 120,
    onRender: (item: Objeto_BeneficiarioObjeto_Dependente.Resposta) => {
      return <div>{item.PESSDEP_NOME}</div>;
    },
  },
  {
    key: "situacao",
    name: t("situacao"),
    minWidth: 60,
    maxWidth: 80,
    onRender: (item: Objeto_BeneficiarioObjeto_Dependente.Resposta) => {
      return <div>{item.ATIVO ? "ATIVO" : "INATIVO"}</div>;
    },
  },
];

const obterColunasInformacaoUtilizacao = (t: TFunction): IColumn[] => {
  return ([
    {
      key: "data",
      name: t("data"),
      minWidth: 110,
      maxWidth: 110,
      onRender: (item: Objeto_BeneficiarioObjeto_Utilizacao.Resposta) => {
        return <div style={{ marginLeft: 16 }}>{item.SMNPRODM_CO_DATA_ATEND?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
      },
    },
    {
      key: "procedimento-realizado",
      name: t("procedimento-realizado"),
      minWidth: 120,
      onRender: (item: Objeto_BeneficiarioObjeto_Utilizacao.Resposta) => {
        return <div title={item.SGESERVI_DESCRICAO}>{item.SGESERVI_DESCRICAO}</div>;
      },
    },
    {
      key: "prestador",
      name: t("prestador-credenciado"),
      minWidth: 120,
      maxWidth: 240,
      onRender: (item: Objeto_BeneficiarioObjeto_Utilizacao.Resposta) => {
        return <div title={item.NM_PRESTADOR}>{item.NM_PRESTADOR}</div>;
      },
    },
    {
      key: "valor",
      name: t("valor"),
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: Objeto_BeneficiarioObjeto_Utilizacao.Resposta) => {
        //return <div style={styles.celulaDireita}>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.SMNPMIT_VLR_COBR || item.SMNPMIT_VLR_COPAR || 0)}</div>;
        return <div style={styles.celulaDireita}>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.SMNPRODM_SITUACAO === "RB" || item.SMNPRODM_SITUACAO ==="PB" || item.SMNPRODM_SITUACAO ==="BV" ?  (item?.SMNPMIT_VLR_COBR ||0) + (item?.SMNPMIT_VLR_TXADM ||0) : item.SMNPMIT_VLR_COPAR || 0)}</div>;
      },
    },
    {
      key: "beneficiario",
      name: t("beneficiario"),
      minWidth: 120,
      maxWidth: 240,
      onRender: (item: Objeto_BeneficiarioObjeto_Utilizacao.Resposta) => {
        return <div title={item.SMNPRODM_CO_NOME_BENEF}>{item.SMNPRODM_CO_NOME_BENEF}</div>;
      },
    },
  ] as Array<IColumn>);
}

const obterColunasCarencia = (t: TFunction): IColumn[] => [
  {
    key: "matricula",
    name: t("matricula"),
    minWidth: 70,
    maxWidth: 70,
    // isFiltered: true,
    onRender: (item: Objeto_BeneficiarioObjeto_Carencia.Resposta) => {
      return <div style={{ marginLeft: 16 }}>{item.SCAITCOB_CODIGO}</div>;
    },
  },
  {
    key: "nome",
    name: t("nome-segurado"),
    minWidth: 150,
    maxWidth: 200,
    onRender: (item: Objeto_BeneficiarioObjeto_Carencia.Resposta) => {
      return <div title={item.SGEPESS_NOME}>{item.SGEPESS_NOME}</div>;
    },
  },
  {
    key: "procedimento",
    name: t("procedimento"),
    minWidth: 120,
    onRender: (item: Objeto_BeneficiarioObjeto_Carencia.Resposta) => {
      return <div title={item.SCAITCOB_DESCRICAO}>{item.SCAITCOB_DESCRICAO}</div>;
    },
  },
  {
    key: "vencimento",
    name: t("vencimento"),
    minWidth: 90,
    maxWidth: 90,
    onRender: (item: Objeto_BeneficiarioObjeto_Carencia.Resposta) => {
      return <div>{item.DT_VENCIMENTO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
    },
  },
  {
    key: "tipo",
    name: t("tipo"),
    minWidth: 90,
    maxWidth: 90,
    onRender: (item: Objeto_BeneficiarioObjeto_Carencia.Resposta) => {
      return <div>{item.TP_CARENCIA}</div>;
    },
  },
];

const obterColunasRedeAssistencial = (t: TFunction): IColumn[] => [
  {
    key: "CNPJ_PRESTADOR",
    name: t("cpf-cnpj"),
    minWidth: 120,
    maxWidth: 180,
    // isFiltered: true,
    onRender: (item: Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta) => {
      return <div style={{ marginLeft: 16 }}>{item.CNPJ_PRESTADOR}</div>;
    },
  },
  {
    key: "NM_PRESTADOR",
    name: t("nome-prestador"),
    minWidth: 150,
    onRender: (item: Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta) => {
      return <div>{item.NM_PRESTADOR}</div>;
    },
  },
  {
    key: "DT_DESLIGAMENTO",
    name: t("data-desligamento"),
    minWidth: 140,
    maxWidth: 180,
    onRender: (item: Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta) => {
      return <div>{item.DT_DESLIGAMENTO}</div>;
    },
  },
];

const obterColunasBoletosFaturas = (t: TFunction, definirDocumentoModalUrl: (url: string | undefined) => void, operadora: string | undefined,  CPF_CNPJ: string | undefined, cancelToken?: CancelTokenSource): IColumn[] => {
  return ([
    {
      key: "numero",
      name: t("numero"),
      minWidth: 60,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.CD_NOSSO_NUMERO}</div>
        );
      },
    },
    {
      key: "emissao",
      name: t("emissao"),
      minWidth: 90,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.DT_EMISSAO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "data-vencimento",
      name: t("data-vencimento"),
      minWidth: 90,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return (
          <div style={styles.celulaCento}>{item.DT_VENCIMENTO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        );
      },
    },
    {
      key: "valor",
      name: t("valor"),
      minWidth: 70,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return (
          <div style={styles.celulaDireita}>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.VALOR || 0)}</div>
        );
      },
    },
    {
      key: "data-pagamento",
      name: t("data-pagamento"),
      minWidth: 90,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return item?.DT_RECEBIMENTO || true ? (
          <div style={styles.celulaCento}>{item.DT_RECEBIMENTO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
        ) : null;
      },
    },
    {
      key: "atraso",
      name: t("atraso"),
      minWidth: 50,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return item?.DT_RECEBIMENTO || true ? (
          <div style={{ ...styles.celulaCento, ...( item.ATRASO ? { color: "red" } : null )}}>{item.ATRASO}</div>
        ) : null;
      },
    },
    {
      key: "valor-pago",
      name: t("valor-pago"),
      minWidth: 70,
      maxWidth: 200,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        return item?.DT_RECEBIMENTO || true ? (
          <div style={{ ...styles.celulaCento, ...((item.VALOR_PAGO || 0) < (item.VALOR || 0) ? { color: "red" } : { color: "green" }) }}>
            {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.VALOR_PAGO || 0)}
          </div>
        ) : null;
      },
    },
    {
      key: "boleto",
      name: "",
      iconName: "Print",
      minWidth: 70,
      maxWidth: 140,
      // isFiltered: true,
      onRender: (item: Objeto_BeneficiarioObjeto_Boleto.Resposta) => {
        // TDOD: && PORTAL_PERMITE_IMPRESSAO_BOLETO_EMPRESA
        //const botaoBoletoHabilitado = item.TP_QUITACAO === "A" && !item.DT_CANCELAMENTO;
        const botaoBoletoHabilitado = item.TP_QUITACAO !== "P";

        return (
          <Stack horizontal style={{ alignContent: "center" }}>
            <IconButton
              disabled={!botaoBoletoHabilitado}
              styles={{ root: { height: "auto", margin: "0 auto", opacity: botaoBoletoHabilitado ? 1 : .2 } }}
              iconProps={{ iconName: "Barcode", style: { ...styles.botaoTabela } }}
              title={t("imprimir-boleto")}
              onClick={() => {
                if(operadora === 'UNIMED INCONFIDENTES' ){
                  window.open(`http://boleto.unimedinconfidentes.coop.br/boleto/Default.asp?codSacado=${CPF_CNPJ}`)
                }
                else if(operadora === 'UNIMED PIRASSUNUNGA'){
                  window.open(`http://unipira.duckdns.org:8888/Go.asp?codSacado=${CPF_CNPJ}&gerar=`)
                }
                else{
                  definirDocumentoModalUrl("carregando");
                  obterBoleto(item.ID_SCOTITUL as number, item.CDMULTIEMPRESA as number, item.CD_CONTRATO?.toString() || "", cancelToken).then(url => {
                    definirDocumentoModalUrl(url);
                  });
                }
              }} />
          </Stack>
        );
      },
    },
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

const InformacoesGerais: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { autenticacao: { usuario }, beneficiario: { dependentes, carencias, utilizacoes, boletos, prestadoresDesligados, carteirinha } } = useSelector((store: ApplicationState) => store);
  const usr = usuario as Objeto_BeneficiarioObjeto_Login.Resposta;

  const { t }: UseTranslationResponse = useTranslation();

  const [pivotKey, setPivotKey] = useState<PivotItens>(PivotItens.DEPENDENTES);
  const [prestaoresDesligadosModalAberto, definirPrestaoresDesligadosModalAberto] = useState(false);

  const [documentoModalUrl, definirDocumentoModalUrl] = useState<string>();
  const [cancelToken] = useState(Axios.CancelToken.source());

  const [faturaAtiva, definirFaturaAtiva] = useState<Objeto_BeneficiarioObjeto_Boleto.Resposta>();

  const [tipoDespesa, definirtipoDespesa] = useState(0);

  const [competencia, definirCompetencia] = useState<Date>();

  const tipoDespesaDropdown = useId('tipoDespesaDropdown');

  const { empresa: { nomeAbreviado } } = useSelector((store: ApplicationState) => store);

  const [teachingBubbleVisible, toggleTeachingBubbleVisible ] = useState(false);

  useEffect(() => {
    dispatch(BeneficiarioStore.actions.obterBoletos());
    dispatch(BeneficiarioStore.actions.obterDependentes());
    dispatch(BeneficiarioStore.actions.obterCarencias());
    dispatch(BeneficiarioStore.actions.obterUtilizacoes());
    dispatch(BeneficiarioStore.actions.listaPrestadoresDesligados(30));
    dispatch(BeneficiarioStore.actions.obterCarteirinha());
    dispatch(EmpresaStore.actions.obterNomeAbreviado()); 
  }, [dispatch]);

  const dependente = useMemo(() => {
    return (
      <Tabela
        cabecalhoFixo
        items={dependentes || []}
        columns={obterColunasDependentes(t)}
      />
    );
  }, [t, dependentes]);

  
  const operadora = nomeAbreviado?.[0]?.SGEUNID_ABREVIADO;
  const CPF_CNPJ = boletos?.[0]?.FCPF;

  const DATA_COMPETENCIA = competencia?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$2-$1") || undefined
  
  const itens = tipoDespesa !== 0 && tipoDespesa !== 6  ? utilizacoes?.filter(e => e.SMNINNOT_TP_NOTA === tipoDespesa) : utilizacoes
  const informacaoUtilizacao = useMemo(() => {
    const copiaItens = competencia != undefined ? itens?.filter(e =>  e.REFER === Number(competencia?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$1$2"))) : itens
    
    return (
      <>
        <Tabela
          cabecalhoFixo
          items={copiaItens || []}
          columns={obterColunasInformacaoUtilizacao(t)}
        />
        <Stack horizontal style={{ padding: "1em", justifyContent: "space-between" }}>
          <div style={{ alignSelf: "center" }}>Valor Total: {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(copiaItens?.reduce((acc, curr) => acc + (curr?.SMNPRODM_SITUACAO === "RB" || curr?.SMNPRODM_SITUACAO === "PB" || curr?.SMNPRODM_SITUACAO === "BV" ? (curr?.SMNPMIT_VLR_COBR || 0) + (curr?.SMNPMIT_VLR_TXADM || 0) :  curr?.SMNPMIT_VLR_COPAR || 0), 0) || 0)}</div>

          <Stack horizontal style={{ marginRight: "1em" }} tokens={{ childrenGap: "1em" }}>
            <Label htmlFor={tipoDespesaDropdown}>{t("tipo-despesas")}</Label>
            <Dropdown
              id={tipoDespesaDropdown}
              style={{ minWidth: 104 }}
              selectedKey={tipoDespesa}
              onChange={(event, item): void => {
                definirtipoDespesa((item?.key || 0) as number);
              }}
              options={[
                { key: 0, text: t("todos") },
                { key: 1, text: t("consulta") },
                { key: 2, text: t("exames") },
                { key: 5, text: t("internacao") },
                { key: 6, text: t("outros") },
              ]}
              
              /* options={[
                { key: 0, text: t("todos") },
                { key: 2, text: t("consulta") },
                { key: 5, text: t("exames") },
                { key: 7, text: t("internacao") },
                { key: 6, text: t("outros") },
              ]} */

            />
            <Label style={{ marginRight: ".5em" }} htmlFor={tipoDespesaDropdown}>{t("competencia")}</Label>
            <DataSeletor
              mensal
              maxDate={new Date()}
              value={competencia}
              onSelectDate={data => definirCompetencia(data || undefined)}
              showClearButton
              className={classNames.competenciaDataSeletor} />
              
            <DefaultButton
              //disabled
              iconProps={{ iconName: 'Print' }}
              text={t("imprimir")}
              onClick={() => {
                definirDocumentoModalUrl("carregando");
                obterInformacoesUtilizacao(usr?.SCACARTE_CODIGO_CART as string, DATA_COMPETENCIA as string).then(url => {
                  definirDocumentoModalUrl(url);
                });                
              }} />
          </Stack>
        </Stack>
      </>
    );
  }, [competencia, tipoDespesa, itens]);

  const carencia = useMemo(() => {
    return (
      <>
        <Tabela
          cabecalhoFixo
          items={carencias || []}
          columns={obterColunasCarencia(t)}
        />
        <Stack horizontal style={{ padding: "1em", justifyContent: "flex-end" }}>
          <DefaultButton
            //disabled
            iconProps={{ iconName: 'Print' }}
            text={t("imprimir")} 
            onClick={() => {
              definirDocumentoModalUrl("carregando");
              obterCarencia(usr?.SCACARTE_CODIGO_CART as string, '').then(url => {
                definirDocumentoModalUrl(url);
              });                
            }}
            />
        </Stack>
      </>
    );
  }, [t, carencias]);

  const funcao = () => {
    dispatch(BeneficiarioStore.actions.listaPrestadoresDesligados(-180))
    definirPrestaoresDesligadosModalAberto(true);
  };

  const redeAssistencial = useMemo(() => {
    return (
      <>
        <Tabela<Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta>
          cabecalhoFixo
          items={prestadoresDesligados || []}
          columns={obterColunasRedeAssistencial(t)}
        />
        <Stack horizontal style={{ padding: "1em", justifyContent: "flex-end" }}>
          <DefaultButton
            iconProps={{ iconName: 'UserRemove' }}
            text={t("prestadores-deligados")}
            onClick={funcao} />

          <DefaultButton
            style={{ marginLeft: "1em" }}
            iconProps={{ iconName: 'UserSync' }}
            text={t("prestadores-substituidos")} />
        </Stack>
      </>
    );
  }, [prestadoresDesligados,t]);

  const boletosFaturas = useMemo(() => {
    return (
      <Tabela<Objeto_BeneficiarioObjeto_Boleto.Resposta>
        cabecalhoFixo
        items={boletos || []}
        columns={obterColunasBoletosFaturas(t, definirDocumentoModalUrl, operadora, CPF_CNPJ, cancelToken)}
        alternarComClick
        selectionMode={SelectionMode.single}
        selecao={(faturas) => {
          definirFaturaAtiva(faturas.length ? faturas[0] : undefined);
        }}
      />
    );
  }, [boletos, cancelToken, t]);

  let d = new Date();
  let ano = d.getFullYear()-1; //para emissão de relatório do IR.

  return (
    <div className={classNames.root}>
      <div style={styles.title}>{t("informacoes-gerais")}</div>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Stack horizontal style={{ flex: 1.25 }} tokens={{ childrenGap: "1em" }}>
          <Stack style={{ paddingTop: "1em", backgroundColor: "#fff", flex: 1 }}>
            <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
              <Pivot
                selectedKey={pivotKey}
                onLinkClick={(item: any): void => setPivotKey(item.props.itemKey)}>
                <PivotItem headerText={t("dependentes")} itemKey={PivotItens.DEPENDENTES} />
                <PivotItem headerText={t("informacoes-utilizacao")} itemKey={PivotItens.INFORMACOES_UTILIZACAO} />
                <PivotItem headerText={t("carencia")} itemKey={PivotItens.CARENCIA} />
                <PivotItem headerText={t("meu-cadastro")} itemKey={PivotItens.MEU_CADASTRO} />
                <PivotItem headerText={t("rede-assistencial")} itemKey={PivotItens.REDEASSISTENCIAL} />
              </Pivot>
            </Stack>
              {(pivotKey === PivotItens.DEPENDENTES) && dependente}
              {(pivotKey === PivotItens.INFORMACOES_UTILIZACAO) && informacaoUtilizacao}
              {(pivotKey === PivotItens.CARENCIA) && carencia}
              {(pivotKey === PivotItens.MEU_CADASTRO) && (
                <div style={{ position: "relative", width: "100%", height: "100%" }}>
                  <ScrollablePane>
                    <Cadastro />
                  </ScrollablePane>
                </div>
              )}
              {(pivotKey === PivotItens.REDEASSISTENCIAL) && redeAssistencial}
          </Stack>
          <div style={{ width: 348 }}>
            <Carteirinha styles={{ root: { marginTop: -36, marginLeft: -58, transform: "scale(.75)" } }} />
          </div>
        </Stack>
        <Stack style={{ flex: 1 }}>
          <Stack horizontal style={{ marginTop: ".25em", marginBottom: "1em" }}>
            <div style={styles.subTitle}>{t("boletos-faturas")}</div>

            <div style={{ flex: 1 }} />

            <DefaultButton
              disabled={!faturaAtiva}
              iconProps={{ iconName: 'Print' }}
              text={t("imprimir-co-participacoes")}
              onClick={() => {
                definirDocumentoModalUrl("carregando");

                //debugger;

                obterRelatorio(faturaAtiva?.ID_SCOTITUL as number, faturaAtiva?.CDMULTIEMPRESA as number, faturaAtiva?.CD_CONTRATO?.toString() as string, 3, "N", usr?.SCACARTE_CODIGO_CART as string, cancelToken).then(url => {
                  definirDocumentoModalUrl(url);
                });
              }}/>
            <DefaultButton
              id="targetButton"
              style={{ marginLeft: "1em" }}
              iconProps={{ iconName: 'Print' }}
              text={t("imprimir-informacoes-imposto-renda")}
              menuProps={{
                directionalHint: DirectionalHint.bottomRightEdge,
                items: [
                  {
                    key: 'ano-base',
                    text: t("ano-base"),
                    onRender: () => (<div style={{ minHeight: 36, lineHeight: "36px", padding: "0 8px", color: "rgb(0, 120, 212)" }}>Ano Base</div>),
                    disabled: true,
                  },
                  {
                    key: ano.toString(),
                    text: ano.toString(),
                    onClick: () => {

                      if (boletos?.[0]?.CD_CONTRATO?.toString() === undefined || carteirinha?.NATUREZA_JURIDICA === '4' && operadora === 'UNIMED INCONFIDENTES')
                      {
                        
                        toggleTeachingBubbleVisible(true);
                        
                      }
                      else
                      {
                        definirDocumentoModalUrl("carregando");

                        obterIR(boletos?.[0]?.CD_CONTRATO?.toString() || "", usr?.SCACARTE_CODIGO_CART as string, boletos?.[0]?.CDMULTIEMPRESA?.toString() || "" , ano, cancelToken).then(url => {
                          definirDocumentoModalUrl(url);
                        });
                      }
                    }
                  },
                  {
                    key: (ano-1).toString(),
                    text: (ano-1).toString(),
                    onClick: () => {

                      if (boletos?.[0]?.CD_CONTRATO?.toString() === undefined || carteirinha?.NATUREZA_JURIDICA === '4' && operadora === 'UNIMED INCONFIDENTES')
                      {
                        
                        toggleTeachingBubbleVisible(true);
                        
                      }
                      else 
                      {
                        definirDocumentoModalUrl("carregando");

                        obterIR(boletos?.[0]?.CD_CONTRATO?.toString() || "", usr?.SCACARTE_CODIGO_CART as string, boletos?.[0]?.CDMULTIEMPRESA?.toString() || "" , ano-1, cancelToken).then(url => {
                          definirDocumentoModalUrl(url);
                        });
                      }
                    }
                  }
                ],
              }} />
              
              {teachingBubbleVisible && (
                <TeachingBubble
                  target="#targetButton"
                  isWide={true}
                  hasCloseButton={true}
                  hasSmallHeadline={false}
                  onDismiss={()=> {toggleTeachingBubbleVisible(false)}}
                  headline="Prezado(a) beneficiário(a),"
                  closeButtonAriaLabel="Fechar"
                >
                  As informações de Informe de Rendimentos dos contratos empresariais devem ser solicitadas a empresa contratante do plano. 
                  Atenciosamente, {operadora}.
                </TeachingBubble>
              )}
          </Stack>
          {boletosFaturas}
        </Stack>
      </Stack>

      <PesquisaModal
        itens={prestadoresDesligados || []}
        titulo={"Prestadores Desligados a 180 dias"}
        columns={obterColunasRedeAssistencial(t)}
        placeholderCampoPesquisa={t("pesquisar")}
        tituloBotaoSelecionar={t("ok")}
        isBlocking={false}
        isOpen={prestaoresDesligadosModalAberto}
        onDismiss={() => {
          definirPrestaoresDesligadosModalAberto(false);
          dispatch(BeneficiarioStore.actions.listaPrestadoresDesligados(30));
        }} />

      <Modal
        isOpen={!!documentoModalUrl}
        onDismiss={() => {
          definirDocumentoModalUrl(undefined);
          documentoModalUrl === "carregando" && cancelToken.cancel();
        }}
        isBlocking={false}
        styles={{ scrollableContent: { overflow: "hidden" } }}>
        <div style={{ width: "90vw", height: "80vh" }}>
          {documentoModalUrl === "carregando" ? (
            <div style={styles.carregandoModalContainer}>
              <Spinner label={t("carregando-documento")} ariaLive="assertive" labelPosition="left" size={SpinnerSize.large} />
            </div>
          ) : (
            <iframe title="doc" src={documentoModalUrl} width="100%" height="100%" style={{ overflow: "auto" }} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default InformacoesGerais;
