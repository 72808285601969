import React from "react";
import { useSelector } from "react-redux";
import { useTranslation, UseTranslationResponse } from "../../i18n";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

import { ApplicationState } from "../../store";
import { Cabecalho } from "../Cabecalho";
import { Rodape } from "../Rodape";
import { Menu } from "../Menu";
import { IMenuItem } from "../Menu/Menu";

import { styles } from "./styles";
import { obterTipoUsuario, TipoUsuario } from "../../store/Autenticacao";

const Layout: React.FC = ({ children }) => {
  const { t }: UseTranslationResponse = useTranslation();
  
  const { autenticacao } = useSelector(state => state as ApplicationState);

  const tipoUsuario = obterTipoUsuario(autenticacao.usuario);

  const menu = tipoUsuario === TipoUsuario.BENEFICIARIO ? [
    { icone: "Info", id: "informacoes-gerais" },
    // { icone: "Money", id: "solicitar-reembolso" },
    { icone: "EditContact", id: "alterar-dados-cadastrais" },
    { icone: "TaskManager", id: "manutencao-solicitacoes" },
    { icone: "DocumentSet", id: "documentos" },
    { icone: "UserRemove", id: "cancelamento-plano" },
  ] : tipoUsuario === TipoUsuario.EMPRESA || tipoUsuario === TipoUsuario.LOTACAO ? [
    { icone: "Money", id: "informacoes-financeiras" },
    { icone: "AddFriend", id: "inclusao-beneficiario" },
    { icone: "EditContact", id: "alteracao-beneficiario" },
    { icone: "UserRemove", id: "desligamento-beneficiario" },
    // { icone: "UserFollowed", id: "reativacao-beneficiario" },
    { icone: "Transition", id: "transferencia-contrato" },
    { icone: "PaymentCard", id: "emissao-2via-carteirinha" },
    { icone: "TimelineDelivery", id: "desligamentos-agendados" },
    { icone: "TaskManager", id: "manutencao-solicitacoes" },
    { icone: "Print", id: "impressao-termos" },
    { icone: "Fingerprint", id: "cadastro-biometrico" },
    { icone: "DocumentSet", id: "documentos" },
    // { icone: "BulkUpload", id: "importacao-arquivo" },
  ] : [];

  const menuItens = (autenticacao?.menu || menu)?.map(({ icone, id }) => ({ icone, titulo: t(id), href: id }) as IMenuItem) || [];

  const pathname = useLocation().pathname;

  return (
    <div style={styles.layout}>
      <Helmet>
        <title>{`GPSi Portal - ${pathname !== "/" ? t(pathname.replace(/(\W)/, "")) : "Home"}`}</title>
      </Helmet>

      <Cabecalho />
      <div style={styles.body}>
        {window.location.pathname.indexOf("/imprimir/") === -1 && menuItens && !!menuItens.length && <Menu itens={menuItens} reduzido />}
        {children}
      </div>
      <Rodape />
    </div>
  )
}

export default Layout;
