import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  API_Controllers,
  Objeto_BeneficiarioObjeto_Atendimento,
  Objeto_BeneficiarioObjeto_Boleto,
  Objeto_BeneficiarioObjeto_Carencia,
  Objeto_BeneficiarioObjeto_Carteirinha,
  Objeto_BeneficiarioObjeto_DadosCadastrais,
  Objeto_BeneficiarioObjeto_Dependente,
  Objeto_BeneficiarioObjeto_Especialidades,
  Objeto_BeneficiarioObjeto_PrestadoresDesligados,
  Objeto_BeneficiarioObjeto_Procedimento,
  Objeto_BeneficiarioObjeto_Utilizacao,
  Objeto_Solicitacao
} from "../api";
import { IBaseSolicitacaoWeb } from "./Servico";

export const BeneficiarioAPI = new API_Controllers.Beneficiario("");

(window as any).BeneficiarioAPI = BeneficiarioAPI;

type SN = 'S' | 'N';

export interface IAlteracaoBeneficiario extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  NM_SEGURADO: string,
  CD_MATRICULA: string,
  NM_MAE: string,
  NR_CPF: string, // Numeros apenas // Validar CPF
  DT_NASCIMENTO: Date,
  DS_EMAIL: string,
  NR_CEP: string,
  DS_ENDERECO: string,
  NR_ENDERECO: number,
  DS_COMPLEMENTO: string,
  DS_BAIRRO: string,
  CD_ZONA: number | null,
  NM_CIDADE: string,
  NM_UF: string,
  NR_CELULAR: string,
  NR_TELEFONE: string,
  CD_USUARIO_ALTERACAO: string,
}

interface IDesligamentoBeneficiario extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  CD_MATRICULA: string,
  ID_SGEMOTIV: number,
  DT_DESLIGAMENTO: Date,
  NM_SEGURADO: string,
  SN_TITULAR: SN,
  TP_USUARIO: string,
  SN_EXCLUI_DEPENDENTE: SN,
  NR_CPF: string,
  DT_FALECIMENTO: Date | null,
  CD_SOLICITACAO_TITULAR: number | null,
  // TODO: DOCS
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  dependentes?: Array<Objeto_BeneficiarioObjeto_Dependente.Resposta>;
  carencias?: Array<Objeto_BeneficiarioObjeto_Carencia.Resposta>;
  utilizacoes?: Array<Objeto_BeneficiarioObjeto_Utilizacao.Resposta>;
  solicitacoes?: Array<Objeto_Solicitacao.Resposta>;
  familia?: Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>;
  carteirinha?: Objeto_BeneficiarioObjeto_Carteirinha.Resposta;
  especialidades?: Array<Objeto_BeneficiarioObjeto_Especialidades.Resposta>;
  prestadoresDesligados?: Array<Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta>;
  boletos?: Array<Objeto_BeneficiarioObjeto_Boleto.Resposta>;
  atendimento?: Array<Objeto_BeneficiarioObjeto_Atendimento.Resposta>;
  procedimento?: Objeto_BeneficiarioObjeto_Procedimento.Resposta;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IObterDependentes {
  type: "BENEFICIARIO::DEPENDENTES",
  dependentes?: Array<Objeto_BeneficiarioObjeto_Dependente.Resposta>;
}

export interface IObterCarencias {
  type: "BENEFICIARIO::CARENCIAS",
  carencias?: Array<Objeto_BeneficiarioObjeto_Carencia.Resposta>;
}

export interface IObterUtilizacoes {
  type: "BENEFICIARIO::UTILIZACAO",
  utilizacoes?: Array<Objeto_BeneficiarioObjeto_Utilizacao.Resposta>;
}

export interface IObterSolicitacoes {
  type: "BENEFICIARIO::SOLICITACOES",
  solicitacoes?: Array<Objeto_Solicitacao.Resposta>;
}

export interface IObterFamilia {
  type: "BENEFICIARIO::FAMILIA",
  familia?: Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>;
}

export interface IObterCarteirinha {
  type: "BENEFICIARIO::CARTEIRINHA",
  carteirinha: Objeto_BeneficiarioObjeto_Carteirinha.Resposta;
}

export interface ITipoBoleto {
  type: "BENEFICIARIO::BOLETO",
  boletos?: Array<Objeto_BeneficiarioObjeto_Boleto.Resposta>;
}

export interface ITipoAtendimento {
  type: "BENEFICIARIO::ATENDIMENTO",
  atendimento?: Array<Objeto_BeneficiarioObjeto_Atendimento.Resposta>;
}

export interface IListaEspecialidades {
  type: "BENEFICIARIO::ESPECIALIDADES",
  especialidades?: Array<Objeto_BeneficiarioObjeto_Especialidades.Resposta>;
}

export interface IListaPrestadoresDesligados {
  type: "BENEFICIARIO::PRESTADORESDESLIGADOS",
  prestadoresDesligados?: Array<Objeto_BeneficiarioObjeto_PrestadoresDesligados.Resposta>;
}

export interface IObtemProcedimento {
  type: "BENEFICIARIO::PROCEDIMENTO",
  procedimento?: Objeto_BeneficiarioObjeto_Procedimento.Resposta;
}

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction =
  IObterDependentes
  | IObterCarencias
  | IObterUtilizacoes
  | IObterSolicitacoes
  | IObterFamilia
  | IObterCarteirinha
  | ITipoBoleto
  | ITipoAtendimento
  | IListaEspecialidades
  | IObtemProcedimento
  | IListaPrestadoresDesligados;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  obterDependentes: (): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.ObterDependentes().then((dependentes) => {
      dispatch({ type: "BENEFICIARIO::DEPENDENTES", dependentes });

      return dependentes;
    })
  ),
  obterCarencias: (): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.ObterCarencias().then((carencias) => {
      carencias = carencias.map(p => {
        p.DT_VENCIMENTO = p.DT_VENCIMENTO ? new Date(p.DT_VENCIMENTO) : undefined;

        return p;
      })

      dispatch({ type: "BENEFICIARIO::CARENCIAS", carencias });

      return carencias;
    })
  ),
  obterUtilizacoes: (TP_DESPESAS?: number, COMPETENCIA?: number): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.ObterUtilizacoes({ TP_DESPESAS, COMPETENCIA }).then((utilizacoes) => {
      utilizacoes = utilizacoes.map(p => {
        p.SMNPRODM_CO_DATA_ATEND = p.SMNPRODM_CO_DATA_ATEND ? new Date(p.SMNPRODM_CO_DATA_ATEND) : undefined;

        return p;
      })

      dispatch({ type: "BENEFICIARIO::UTILIZACAO", utilizacoes });

      return utilizacoes;
    })
  ),
  obterSolicitacoes: (DATA_INICIAL?: Date, DATA_LIMITE?: Date): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.Solicitacao({ DATA_INICIAL, DATA_LIMITE }).then((solicitacoes) => {
      dispatch({ type: "BENEFICIARIO::SOLICITACOES", solicitacoes });

      return solicitacoes;
    })
  ),
  obterFamilia: (): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.ObterFamilia().then((familia) => {
      dispatch({ type: "BENEFICIARIO::FAMILIA", familia });

      return familia;
    })
  ),
  obterCarteirinha: (): AppThunkAction<KnownAction> => (dispatch) => (
    BeneficiarioAPI.ObterCarteirinha().then((carteirinha) => {
      dispatch({ type: "BENEFICIARIO::CARTEIRINHA", carteirinha });

      return carteirinha;
    })
  ),
  obterBoletos: (): AppThunkAction<KnownAction> => (dispatch) => {
    BeneficiarioAPI.Boleto().then((boletos) => {
      boletos = boletos.map(p => {
        p.DT_EMISSAO = p.DT_EMISSAO ? new Date(p.DT_EMISSAO) : undefined;
        p.DT_VENCIMENTO = p.DT_VENCIMENTO ? new Date(p.DT_VENCIMENTO) : undefined;
        p.DT_RECEBIMENTO = p.DT_RECEBIMENTO ? new Date(p.DT_RECEBIMENTO) : undefined;
        p.DT_CANCELAMENTO = p.DT_CANCELAMENTO ? new Date(p.DT_CANCELAMENTO) : undefined;

        return p;
      })

      dispatch({ type: "BENEFICIARIO::BOLETO", boletos });
    });
  },
  listaAtendimento: (): AppThunkAction<KnownAction> => (dispatch) => {
    BeneficiarioAPI.Atendimento().then((atendimento) => {
      dispatch({ type: "BENEFICIARIO::ATENDIMENTO", atendimento });
    });
  },
  listaEspecialidades: (): AppThunkAction<KnownAction> => (dispatch) => {
    BeneficiarioAPI.ListaEspecialidades().then((especialidades) => {
      dispatch({ type: "BENEFICIARIO::ESPECIALIDADES", especialidades });
    });
  },
  listaPrestadoresDesligados: (NR_DIAS: number): AppThunkAction<KnownAction> => (dispatch) => {
    BeneficiarioAPI.ListaPrestadoresDesligados({NR_DIAS }).then((prestadoresDesligados) => {
      dispatch({ type: "BENEFICIARIO::PRESTADORESDESLIGADOS", prestadoresDesligados });
    });
  },
  obterProcedimento: (SGESERVI_CODIGO_SERVI: string): AppThunkAction<KnownAction> => (dispatch) => {
    BeneficiarioAPI.ObterProcedimento({SGESERVI_CODIGO_SERVI }).then((procedimento) => {
      dispatch({ type: "BENEFICIARIO::PROCEDIMENTO", procedimento });
    });
  },
  solicitarAlteracaoBeneficiario: (dados: IAlteracaoBeneficiario): AppThunkAction<KnownAction> => () => (
    BeneficiarioAPI.SolicitacaoWeb(dados as any)
  ),
  solicitarDesligamentoBeneficiario: (dados: IDesligamentoBeneficiario): AppThunkAction<KnownAction> => () => (
    BeneficiarioAPI.SolicitacaoWeb(dados as any)
  ),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return { };
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {
    case "BENEFICIARIO::DEPENDENTES":
      return { ...state, dependentes: action.dependentes };
    case "BENEFICIARIO::CARENCIAS":
      return { ...state, carencias: action.carencias };
    case "BENEFICIARIO::UTILIZACAO":
      return { ...state, utilizacoes: action.utilizacoes };
    case "BENEFICIARIO::SOLICITACOES":
      return { ...state, solicitacoes: action.solicitacoes };
    case "BENEFICIARIO::FAMILIA":
      return { ...state, familia: action.familia };
    case "BENEFICIARIO::CARTEIRINHA":
      return { ...state, carteirinha: action.carteirinha };
    case "BENEFICIARIO::BOLETO":
      return { ...state, boletos: action.boletos };
    case "BENEFICIARIO::ATENDIMENTO":
      return { ...state, atendimento: action.atendimento };
    case "BENEFICIARIO::ESPECIALIDADES":
      return { ...state, especialidades: action.especialidades };
    case "BENEFICIARIO::PRESTADORESDESLIGADOS":
      return { ...state, prestadoresDesligados: action.prestadoresDesligados };
    case "BENEFICIARIO::PROCEDIMENTO":
      return { ...state, procedimento: action.procedimento };
    default:
      return state;
  }
};
