import { CSSProperties } from "react";
import { getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  layout: CSSProperties;
  body: CSSProperties;
}

export const styles: IStyle = {
  layout: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    backgroundColor: tema.semanticColors.bodyBackgroundChecked,
    overflow: "hidden",
  },
  body: {
    position: "relative",
    display: "flex",
    flex: 1,
    //maxHeight: "calc(100% - 68px - 56px)",
  },
};
