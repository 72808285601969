import React, { useState, useCallback, useEffect } from "react";
import {
  Stack,
  Label,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  PrimaryButton,
} from "@fluentui/react";
import { UseTranslationResponse, TFunction, useTranslation } from "../../i18n";

import { styles } from "./styles";
import { DataSeletor } from "../../components/DataSeletor";
import { Tabela } from "../../components/Tabela";
import {
  Objeto_EmpresaObjeto_DesligamentoAgendado,
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";

const obterColunas = (t: TFunction): Array<IColumn> => {
  return ([
    {
      key: 'matricula',
      name: t("matricula"),
      minWidth: 120,
      maxWidth: 140,
      onRender: (item: Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta) => {
        return <div style={styles.celulaCento}>{item.SCACARTE_CODIGO_CART}</div>;
      },
    },
    {
      key: 'nome',
      name: t("nome"),
      fieldName: 'NM_SEGURADO',
      minWidth: 120,
      onRender: (item: Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta) => {
        return <div>{item.SGEPESS_NOME}</div>;
      },
    },
    {
      key: 'agendada-em',
      name: t("agendada-em"),
      minWidth: 120,
      maxWidth: 240,
      onRender: (item: Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta) => {
        return <div style={styles.celulaCento}>{item.SCACONBE_DATA_ULT_ALT?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
      },
    },
    {
      key: 'agendada-para',
      name: t("agendada-para"),
      minWidth: 120,
      maxWidth: 240,
      onRender: (item: Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta) => {
        return <div style={styles.celulaCento}>{item.SCACONBE_FINAL_VIGENCIA?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
      },
    },
    {
      key: 'situacao',
      name: t("situacao"),
      minWidth: 128,
      maxWidth: 240,
      onRender: (item: Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta) => {
        return <div style={styles.celulaCento}>{item.TP_SITUACAO}</div>;
      },
    },
    /* {
      key: "impressao",
      iconName: "Print",
      name: "",
      fieldName: 'arquivo',
      minWidth: 64,
      maxWidth: 64,
      onRender: (item: Objeto_EmpresaObjeto_Fatura.Resposta) => {
        return (
          <Stack horizontal style={{ alignContent: "center" }}>
            <IconButton
              styles={{ root: { height: "auto", margin: "0 auto" } }}
              iconProps={{ iconName: "Barcode", style: { ...styles.botaoTabela } }}
              title={t("imprimir-boleto")}
              onClick={() => {
                // TODO: Print
              }} />
          </Stack>
        );
      },
    }, */
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

const DesligamentosAgendados: React.FC = () => {
  const { autenticacao: { usuario }, empresa: { desligamentosAgendados } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch<any>()

  const { t }: UseTranslationResponse = useTranslation();

  const [carregando, setCarregando] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState<Date | undefined>(new Date().chain(data => data.setMonth(data.getMonth() - 1).chain(() => data)));
  const [dataLimite, setDataLimite] = useState<Date | undefined>(new Date());

  const obterDados = useCallback(() => {
    setCarregando(true);

    dispatch(EmpresaStore.actions.obterDesligamentosAgendados(dataInicial, dataLimite)).finally(() => setCarregando(false));
  }, [dispatch, usuario, dataInicial, dataLimite]);

  useEffect(() => {
    obterDados();
  }, []);

  return (
    <div style={styles.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Label style={styles.title}>{t("desligamentos-agendados")}</Label>
        <Stack horizontal style={{ alignItems: "center" }}>
          <Label>{t("periodo-agendamento")}</Label>
          <DataSeletor
            value={dataInicial}
            onSelectDate={data => setDataInicial(data || undefined)}
            placeholder={t("data-inicial")}
            formatDate={(data?: Date) => data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1") || ""}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <Label>{t("ate")}</Label>
          <DataSeletor
            value={dataLimite}
            onSelectDate={data => setDataLimite(data || undefined)}
            minDate={dataInicial}
            placeholder={t("data-final")}
            formatDate={(data?: Date) => data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1") || ""}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <PrimaryButton text={t("buscar")} disabled={carregando} onClick={obterDados} />
        </Stack>
      </div>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Tabela
          cabecalhoFixo
          styles={{ root: { height: "100%", minHeight: 320 } }}
          items={desligamentosAgendados || []}
          compact={false}
          columns={obterColunas(t)}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
      </Stack>
    </div>
  );
}

export default DesligamentosAgendados;
