import React, { useEffect, useState } from "react";
import { Label } from "@fluentui/react";
import { styles, classNames } from "./styles";
import * as BeneficiarioStore from "../../store/Beneficiario";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";

const Cadastro: React.FC = () => {
  
  const { beneficiario: { carteirinha } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BeneficiarioStore.actions.obterCarteirinha());
  }, [dispatch]);

  return (
    <div style={{...styles.body, margin: "1em" }}>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Matrícula:</span> {carteirinha?.codigo}</Label>
        <Label><span style={styles.titulo}>Nome Beneficiário:</span> {carteirinha?.nome}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Data de Nascimento:</span> {carteirinha?.fdata_nasciment}</Label>
        <Label><span style={styles.titulo}>Cartão Nacional Saúde:</span> {carteirinha?.CNS}</Label>
      </div>

      <hr style={styles.linha} />

      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Nº Contrato:</span> {carteirinha?.CODIGO}</Label>
        <Label><span style={styles.titulo}>Cobertura Parcial Temporária:</span> {carteirinha?.coberura_ptemp}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Nº Reg.Plano:</span> {carteirinha?.NR_REG_MS}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Acomodação:</span> {carteirinha?.acomodacao}</Label>
        <Label><span style={styles.titulo}>Segmentação Assistencial:</span> {carteirinha?.segme_plano}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Data Contratação:</span> {carteirinha?.DT_CONTRATACAO}</Label>
        <Label><span style={styles.titulo}>Tipo de contratação:</span> {carteirinha?.tipo_contr_plano}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Data Início Cobertura:</span> {carteirinha?.vigencia_plano}</Label>
        <Label><span style={styles.titulo}>Regulamentação Plano:</span> {carteirinha?.plano_reg}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Abrangência Geográfica:</span> {carteirinha?.abrangencia}</Label>
      </div>

      <hr style={styles.linha} />

      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Razão Social:</span> {carteirinha?.contratante}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Nome Fantasia Contratante:</span> {carteirinha?.contratante}</Label>
      </div>

      <hr style={styles.linha} />

      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Registro na ANS:</span> {carteirinha?.REG_ANS}</Label>
        <Label><span style={styles.titulo}>Contato:</span> {carteirinha?.DADOS_OPERADORA}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Razão Social:</span> {carteirinha?.NOME_OPERADORA}</Label>
      </div>
      <div className={classNames.wrapper}>
        <Label><span style={styles.titulo}>Nome Fantasia:</span> {carteirinha?.ABREVIADO_OPERADORA}</Label>
      </div>

      <hr style={styles.linha} />
      
      <div>
        <Label style={styles.titulo}>Prazo Máximo de Carências: </Label>
        <div style={{ lineHeight: "1em" }}>
          <Label>24 horas para os casos de urgência/emergência;</Label>
          <Label>30 dias para os consultas e exames simples;</Label>
          <Label>180 dias para demais procedimentos;</Label>
          <Label>300 dias para parto.</Label>
        </div>
      </div>

      <hr style={styles.linha} />

      <div>
        <Label style={styles.titulo}>DISQUE ANS 0800 701 9656 - http://www.ans.gov.br</Label>
        <Label style={styles.titulo}>NÚCLEOS FISCALIZADORES - http://www.ans.gov.br/aans/nossos-enderecos</Label>
        <Label style={styles.titulo}>ACESSO A RN Nº 389 ANEXO - http://www.ans.gov.br/images/stories/Legislacao/rn/RN_389anexo.pdf</Label>
      </div>

    </div>
  );
};

export default Cadastro;
