import React, { useEffect, useRef, useState } from "react";
import { Stack, Label, SelectionMode, DetailsListLayoutMode, IColumn, IconButton, Modal, Spinner, SpinnerSize } from "@fluentui/react";
import Axios, { CancelTokenSource } from 'axios';

import * as ServicoStore from "../../store/Servico";
import { styles } from "./styles";
import { UseTranslationResponse, TFunction, useTranslation } from "../../i18n";
import { Tabela } from "../../components/Tabela";
import { Objeto, Objeto_Servico_Documentos, Objeto_Servico_DocumentosPerfil } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";

interface IAcoes {
  documento: Objeto_Servico_Documentos.Resposta | Objeto_Servico_DocumentosPerfil.Resposta;
  definirDocumentoModalUrl: (url: string | undefined) => void;
  cancelToken?: CancelTokenSource,
}

const obterArquivo = (SWEDOCUM_DS_CAMINHO_ARQUIVO: string, CANCEL?: CancelTokenSource) => Axios({
  url: `/api/Servico/ObterArquivo/${encodeURI(SWEDOCUM_DS_CAMINHO_ARQUIVO)}`,
  method: 'GET',
  responseType: 'blob',
  cancelToken: CANCEL?.token
}).then((response) => {
  return window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
});

const Acoes: React.FC<IAcoes> = ({ documento, definirDocumentoModalUrl, cancelToken }) => {
  const { t }: UseTranslationResponse = useTranslation();
  const link = useRef<HTMLAnchorElement>(null);
  const [processando, definirProcessando] = useState<boolean>(false);

  return (
    <Stack horizontal style={{ alignContent: "center" }}>
      <a ref={link} style={{ display: "none" }} type="hidden" />
      <IconButton
        styles={{ root: { height: "auto", margin: "0 auto" } }}
        iconProps={{ iconName: "EntryView", style: { ...styles.botaoTabela } }}
        title={t("visualizar-documento")}
        onClick={() => {
          definirDocumentoModalUrl("carregando");

          obterArquivo(documento.SWEDOCUM_DS_CAMINHO_ARQUIVO as string, cancelToken).then(url => {
            definirDocumentoModalUrl(url);

            /*link?.setAttribute("href", url);
            link?.setAttribute("target", "_blank");

            link?.click();*/
          });
        }} />
      <IconButton
        styles={{ root: { height: "auto", margin: "0 auto", cursor: processando ? "progress" : "pointer" } }}
        iconProps={{ iconName: "DownloadDocument", style: { ...styles.botaoTabela } }}
        title={t("download-documento")}
        onClick={() => {
          definirProcessando(true);
          obterArquivo(documento.SWEDOCUM_DS_CAMINHO_ARQUIVO as string).then(url => {
            link.current?.setAttribute("download", (documento.SWEDOCUM_DS_CAMINHO_ARQUIVO as string).split("\\").pop() as string);
            link.current?.setAttribute("href", url);

            link.current?.click();
            definirProcessando(false);
          });
        }} />
    </Stack>
  );
}

const obterColunas = (t: TFunction, definirDocumentoModalUrl: (url: string | undefined) => void, cancelToken?: CancelTokenSource): Array<IColumn> => {
  return [
    {
      key: 'arquivo',
      name: t("arquivo"),
      ariaLabel: t("arquivo"),
      fieldName: 'SWEDOCUM_DESCRICAO',
      minWidth: 320,
    },
    {
      key: 'acoes',
      name: "",
      minWidth: 96,
      maxWidth: 96,
      onRender: (documento: Objeto_Servico_Documentos.Resposta | Objeto_Servico_DocumentosPerfil.Resposta) => (
        <Acoes {...{documento, definirDocumentoModalUrl, cancelToken}} />
      ),
    },
  ];
}

const Documentos: React.FC = () => {
  const { t }: UseTranslationResponse = useTranslation();

  const [documentoModalUrl, definirDocumentoModalUrl] = useState<string>();
  const [cancelToken] = useState(Axios.CancelToken.source());

  const dispatch = useDispatch();
  const { servico: { documentos } } = useSelector((store: ApplicationState) => store);

  useEffect(() => {
    dispatch(ServicoStore.actions.obterDependentes());
  }, [dispatch]);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("documentos-disponiveis-v-d")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Tabela
          cabecalhoFixo
          styles={{ root: { height: "100%", minHeight: 320 } }}
          items={documentos || []}
          compact={false}
          columns={obterColunas(t, definirDocumentoModalUrl, cancelToken)}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
      </Stack>

      <Modal
        isOpen={!!documentoModalUrl}
        onDismiss={() => {
          definirDocumentoModalUrl(undefined);
          documentoModalUrl === "carregando" && cancelToken.cancel();
        }}
        isBlocking={false}
        styles={{ scrollableContent: { overflow: "hidden" } }}>
        <div style={{ width: "90vw", height: "80vh" }}>
          {documentoModalUrl === "carregando" ? (
            <div style={styles.carregandoModalContainer}>
              <Spinner label={t("carregando-documento")} ariaLive="assertive" labelPosition="left" size={SpinnerSize.large} />
            </div>
          ) : (
            <iframe src={documentoModalUrl} width="100%" height="100%" style={{ overflow: "auto" }} />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Documentos;
