import { CSSProperties } from "react";

import { aplicarTransparencia } from "../../utils";
import { getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  menuContainer: CSSProperties;
  menuContainerReduzido: CSSProperties;
  menu: CSSProperties;
  menuReduzido: CSSProperties;
  menuIcone: CSSProperties;
  menuItem: CSSProperties;
  menuItemHover: CSSProperties;
  menuItemActive: CSSProperties;
  menuItemActiveSmall: CSSProperties;
  menuItemIcone: CSSProperties;
}

export const styles: IStyle = {
  menuContainer: {
    width: "max-content",
    maxWidth: "20em",
    transition: "max-width 0.167s cubic-bezier(.5, 0, .5, 1) 0s",
    zIndex: 999,
  },
  menuContainerReduzido: {
    maxWidth: "3em"
  },
  menu: {
    display: "flex",
    width: "max-content",
    height: "100%",
    maxWidth: "20em",
    boxSizing: "border-box",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: aplicarTransparencia(tema.semanticColors.bodyBackground, .6),
    backdropFilter: "blur(60px) saturate(125%)",
    transition: "max-width 0.167s cubic-bezier(.5, 0, .5, 1) 0s",
    willChange: "max-width",
  },
  menuReduzido: {
    maxWidth: "3em",
  },
  menuIcone: {
    width: "fit-content",
    marginLeft: ".25em",
    padding: "1em",
    color: tema.semanticColors.bodyText,
    cursor: "pointer",
    marginTop: ".5em",
  },
  menuItem: {
    display: "flex",
    width: "20em",
    height: "3em",
    padding: "0 1.5em 0 1em",
    whiteSpace: "nowrap",
    textDecoration: "none",
    color: tema.semanticColors.bodyText,
    alignItems: "center",
    borderLeft: ".25em solid transparent",
  },
  menuItemHover: {
    backgroundColor: tema.semanticColors.bodyBackground,
    borderImage: "unset",
  },
  menuItemActive: {
    borderLeft: `.25em solid ${tema.semanticColors.link}`,
  },
  menuItemActiveSmall: {
    borderLeft: `.25em solid ${tema.semanticColors.link}`,
    borderImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, ${tema.semanticColors.link} 25%, ${tema.semanticColors.link} 75%, rgba(0, 0, 0, 0) 75%)`,
    borderImageSlice: 1,
  },
  menuItemIcone: {
    marginRight: 16,
    color: tema.semanticColors.bodyText,
  },
};
