import { Action, Reducer } from 'redux';
import CryptoJS from "crypto-js";
import Axios, { AxiosError } from "axios";

import {
  API_Controllers, Objeto_BeneficiarioObjeto, Objeto_BeneficiarioObjeto_Login, Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_Login,
  Objeto_PrestadorObjeto_Login,
  Objeto_PrestadorObjeto_Login_Prestador
} from '../api';
import { AppThunkAction } from '.';

export const LoginAPI = new API_Controllers.Login("");

const byteArray = (arr: number[]): CryptoJS.LibWordArray => {
  const word: any = [];
  // tslint:disable-next-line: typedef // TSLint Bug
  for (let i = 0; i < arr.length; i += 4) {
    word.push(
      (arr[i] << 24) |
      (arr[i + 1] << 16) |
      (arr[i + 2] << 8) |
      (arr[i + 3] << 0)
    );
  }
  return CryptoJS.lib.WordArray.create(word);
};

const gerarHash = (senha: string): string => {
  const key_str: CryptoJS.LibWordArray = byteArray([42, 16, 93, 156, 78, 4, 218, 32, 15, 167, 44, 80, 26, 250, 155, 112, 2, 94, 11, 204, 119, 35, 184, 197]);
  const iv_str: CryptoJS.LibWordArray = byteArray([55, 103, 246, 79, 36, 99, 167, 3, 42, 5, 62, 83, 184, 7, 209, 13, 145, 23, 200, 58, 173, 10, 121, 222]);
  const key: any = CryptoJS.enc.Hex.parse(key_str.toString());
  const iv: any = CryptoJS.enc.Hex.parse(iv_str.toString());
  const encrypted: CryptoJS.WordArray = CryptoJS.TripleDES.encrypt(senha, key, { iv });
  const hash: CryptoJS.WordArray = CryptoJS.SHA256(encrypted.toString());
  return hash.toString().toUpperCase();
};

type Usuario = Objeto_EmpresaObjeto_Beneficiario.Resposta | Objeto_PrestadorObjeto_Login.Resposta | Objeto_EmpresaObjeto_Login.Resposta | (undefined & { fromT?: any });

interface IMenu {
  icone: string;
  id: string;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  usuario?: Usuario;
  prestadores?: Array<Objeto_PrestadorObjeto_Login_Prestador.Resposta>;
  menu?: IMenu[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginAction {
  type: 'AUTENTICACAO::LOGIN';
  usuario?: Objeto_EmpresaObjeto_Login.Resposta | Objeto_PrestadorObjeto_Login.Resposta;
}

export interface LogoutAction {
  type: 'AUTENTICACAO::LOGOUT';
}

export interface PrestadoresAction {
  type: 'AUTENTICACAO::PRESTADORES';
  prestadores?: Array<Objeto_PrestadorObjeto_Login_Prestador.Resposta>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginAction | LogoutAction | PrestadoresAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  loginBeneficiario: (SCACARTE_CODIGO_CART: string, SENHA: string): AppThunkAction<KnownAction> => (dispatch) => (
    LoginAPI.LoginBeneficiario({ SCACARTE_CODIGO_CART: SCACARTE_CODIGO_CART.replace(/\D|^0+/g, ""), HASH: gerarHash(SENHA) }).then((usuario) => {
      dispatch({ type: "AUTENTICACAO::LOGIN", usuario });

      localStorage.setItem("Token", usuario?.Token as string);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${usuario?.Token}`;

      return usuario;
    })
  ),
  loginPrestador: (SGEUSUAR_NET_LOGIN: string, SCAPREST_CODIGO: string, SENHA: string): AppThunkAction<KnownAction> => (dispatch) => (
    LoginAPI.LoginPrestador({ SGEUSUAR_NET_LOGIN, SCAPREST_CODIGO, HASH: gerarHash(SENHA) }).then((usuario) => {
      dispatch({ type: "AUTENTICACAO::LOGIN", usuario });

      localStorage.setItem("Token", usuario?.Token as string);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${usuario?.Token}`;

      return usuario;
    })
  ),
  loginEmpresa: (SCAEMPBA_CODIGO: string, SENHA: string): AppThunkAction<KnownAction> => (dispatch): Promise<void | Objeto_EmpresaObjeto_Login.Resposta> => (
    LoginAPI.LoginEmpresa({ SCAEMPBA_CODIGO, HASH: gerarHash(SENHA) }).then((usuario) => {
      dispatch({ type: "AUTENTICACAO::LOGIN", usuario });

      localStorage.setItem("Token", usuario?.Token as string);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${usuario?.Token}`;

      return usuario;
    })
  ),
  redefinirSenhaEmpresa: (SGEPESS_JCNPJ:string, SCAEMPBA_CODIGO: string, SENHA: string): AppThunkAction<KnownAction> => (dispatch): Promise<void | Objeto_EmpresaObjeto_Login.AlterarSenha> => (
    LoginAPI.AlterarSenhaEmpresa({ SGEPESS_JCNPJ, SCAEMPBA_CODIGO, HASH: gerarHash(SENHA) }).then(() => {
      console.log('ALTERADO COM SUCESSO!')
      
      LoginAPI.LoginEmpresa({ SCAEMPBA_CODIGO, HASH: gerarHash(SENHA) }).then((usuario) => {
        dispatch({ type: "AUTENTICACAO::LOGIN", usuario });
  
        localStorage.setItem("Token", usuario?.Token as string);
        Axios.defaults.headers.common['Authorization'] = `Bearer ${usuario?.Token}`;
  
        return usuario;
      })
    }).catch(()=>{
      console.log('DADO INCORRETO!')
    })
  ),
  redefinirSenhaBeneficiario: (SGEPESS_FCPF:string, SCACARTE_CODIGO_CART: string, SENHA: string): AppThunkAction<KnownAction> => (dispatch): Promise<void | Objeto_BeneficiarioObjeto_Login.AlterarSenha> => (
    LoginAPI.AlterarSenhaBeneficiario({ SGEPESS_FCPF, SCACARTE_CODIGO_CART: SCACARTE_CODIGO_CART.replace(/\D|^0+/g, ""), HASH: gerarHash(SENHA) }).then(() => {
      console.log('ALTERADO COM SUCESSO!')
      
      LoginAPI.LoginBeneficiario({ SCACARTE_CODIGO_CART: SCACARTE_CODIGO_CART.replace(/\D|^0+/g, ""), HASH: gerarHash(SENHA) }).then((usuario) => {
        dispatch({ type: "AUTENTICACAO::LOGIN", usuario });
  
        localStorage.setItem("Token", usuario?.Token as string);
        Axios.defaults.headers.common['Authorization'] = `Bearer ${usuario?.Token}`;
  
        return usuario;
      })
    }).catch(()=>{
      console.log('DADO INCORRETO!')
    })
  ),
  logout: (): AppThunkAction<KnownAction> => (dispatch) => {
    localStorage.removeItem("Token");

    dispatch({ type: "AUTENTICACAO::LOGOUT" });
  },
  recuperar: (): AppThunkAction<KnownAction> => (dispatch) => (
    LoginAPI.Token().then((usuario) => {
      dispatch({ type: "AUTENTICACAO::LOGIN", usuario: { ...usuario, Token: localStorage.getItem("Token") as string } });
    }).catch((erro: AxiosError) => {
      if (erro.response?.status === 401) {
        localStorage.removeItem("Token");
      }
    })
  ),
  obterPrestadores: (SGEUSUAR_NET_LOGIN: string): AppThunkAction<KnownAction> => (dispatch) => (
    LoginAPI.Prestadores({ SGEUSUAR_NET_LOGIN }).then((prestadores) => {
      dispatch({ type: "AUTENTICACAO::PRESTADORES", prestadores });
      return prestadores;
    }).catch((erro: AxiosError) => {
      dispatch({ type: "AUTENTICACAO::PRESTADORES", prestadores: undefined });
      return undefined;
    })
  ),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return {
      /*menu: [
        { icone: "Money", id: "informacoes-financeiras" },
        { icone: "AddFriend", id: "inclusao-beneficiario" },
        { icone: "UserRemove", id: "desligamento-beneficiario" },
        // { icone: "UserFollowed", id: "reativacao-beneficiario" },
        { icone: "EditContact", id: "alteracao-beneficiario" },
        { icone: "Transition", id: "transferencia-contrato" },
        { icone: "PaymentCard", id: "emissao-2via-carteirinha" },
        { icone: "TimelineDelivery", id: "desligamentos-agendados" },
        { icone: "TaskManager", id: "manutencao-solicitacoes" },
        { icone: "Print", id: "impressao-termos" },
        { icone: "Fingerprint", id: "cadastro-biometrico" },
        { icone: "DocumentSet", id: "documentos" },
        // { icone: "BulkUpload", id: "importacao-arquivo" },
      ]*/
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'AUTENTICACAO::LOGIN':
      return { ...state, usuario: action.usuario };
    case 'AUTENTICACAO::LOGOUT':
      return { ...state, usuario: undefined };
    case 'AUTENTICACAO::PRESTADORES':
      return { ...state, prestadores: action.prestadores };
    default:
      return state;
  }
};

// ----------------
// Helper

export enum TipoUsuario {
  BENEFICIARIO,
  PRESTADOR,
  EMPRESA,
  LOTACAO
}

export const obterTipoUsuario = (usuario?: Usuario): TipoUsuario | undefined => {
  if ((usuario as Objeto_BeneficiarioObjeto_Login.Resposta)?.CARTETIT_CODIGO) {
    return TipoUsuario.BENEFICIARIO;
  } else if ((usuario as Objeto_PrestadorObjeto_Login.Resposta)?.ID_SCAPREST) {
    return TipoUsuario.PRESTADOR;
  } else if((usuario as Objeto_EmpresaObjeto_Login.Resposta )?.Tipo === 'LOTACAO'){
    return TipoUsuario.LOTACAO;
  } else if ((usuario as Objeto_EmpresaObjeto_Login.Resposta)?.ID_SCAEMPBA) {
    return  TipoUsuario.EMPRESA;
  }

  return undefined;
}
