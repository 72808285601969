import React, { useEffect, useMemo, useState } from "react";
import { IColumn, SelectionMode } from "@fluentui/react";
import { classNames } from "../../pages/DesligamentoBeneficiario/styles";
import { Objeto_EmpresaObjeto_Beneficiario, Objeto_Servico_Banco } from "../../api";
import { PesquisaModal } from "../PesquisaModal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useTranslation, UseTranslationResponse } from "../../i18n";
import * as EmpresaStore from "../../store/Empresa";

export interface IBeneficiarioPesquisaModal {
  isOpen: boolean | undefined;
  onDismiss: (beneficiario: Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined) => void;
  titular?: boolean;
  ativo?: boolean;
  filtro?: (value: Objeto_EmpresaObjeto_Beneficiario.Resposta, index: number, array: Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>) => void
}

const BeneficiarioPesquisaModal: React.FC<IBeneficiarioPesquisaModal> = ({ isOpen, onDismiss, titular, ativo, filtro }) => {
  const dispatch = useDispatch();
  const { empresa: { beneficiarios, contratoAtivo } } = useSelector((store: ApplicationState) => store);

  const { t }: UseTranslationResponse = useTranslation();
  
  useEffect(() => {
    dispatch(EmpresaStore.actions.obterBeneficiarios());
  }, []);

  const itens = filtro ? beneficiarios.filter(filtro) : titular ? beneficiarios.filter(e => e.SCADEPEN_DESCRICAO === "TITULAR" && e.TP_SITUACAO === "ATIVO" && e.SCACONTR_CODIGO === contratoAtivo) : beneficiarios.filter(e => e.TP_SITUACAO === "ATIVO" && e.SCACONTR_CODIGO === contratoAtivo);

  return (
    <PesquisaModal<Objeto_EmpresaObjeto_Beneficiario.Resposta>
      itens={itens}
      titulo={titular ? t("lista-titulares-ativos") : t("lista-beneficiarios-ativos")}
      placeholderCampoPesquisa={t("pesquisar")}
      tituloBotaoSelecionar={t("ok")}
      isBlocking={false}
      isOpen={isOpen}
      onDismiss={onDismiss}
      selectionMode={SelectionMode.single}
      columns={[
        {
          key: "matricula",
          name: t("matricula"),
          ariaLabel: t("matricula"),
          fieldName: "SCACARTE_CODIGO_CART",
          minWidth: 110,
          maxWidth: 110,
        },
        {
          key: "beneficiario",
          name: t("beneficiario"),
          ariaLabel: t("beneficiario"),
          fieldName: "SGEPESS_NOME",
          minWidth: 120,
          maxWidth: 900,
        },
        {
          key: "situacao",
          name: t("situacao"),
          ariaLabel: t("situacao"),
          fieldName: "TP_SITUACAO",
          minWidth: 60,
          maxWidth: 60,
          className: classNames.celulaCentro
        },
        {
          key: "contrato",
          name: t("contrato"),
          ariaLabel: t("contrato"),
          fieldName: "SCACONTR_CODIGO",
          minWidth: 60,
          maxWidth: 60,
          className: classNames.celulaCentro
        },
        {
          key: "data-adesao",
          name: t("data-adesao"),
          ariaLabel: t("data-adesao"),
          minWidth: 90,
          maxWidth: 90,
          className: classNames.celulaCentro,
          onRender: (item: Objeto_EmpresaObjeto_Beneficiario.Resposta) => {
            return (
              <div>{item.SCACONBE_INICIO_VIGENCIA?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>
            );
          },
        },
      ] as Array<IColumn>}
    />
  );
}

export default BeneficiarioPesquisaModal;