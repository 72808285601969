import i18n, { TFunction as TFunctionBase } from "i18next";
import { initReactI18next, UseTranslationResponse as UseTranslationResponseBase, useTranslation as useTranslationBase } from "react-i18next";

import en_US from "./translations/en-US.json";
import es from "./translations/es.json";
import pt_BR from "./translations/pt-BR.json";
import { Key as KeyBase } from "./translations";

export const resources = {
  "en-US": {
    translation: en_US,
    name: "English",
  },
  "es": {
    translation: es,
    name: "Spanish",
  },
  "pt-BR": {
    translation: pt_BR,
    name: "Português",
  },
};

const init = (): Promise<TFunctionBase> => (
  i18n.use(initReactI18next).init(
    {
      resources,

      lng: "pt-BR",
      fallbackLng: "pt-BR",

      interpolation: {
        escapeValue: false,
      },

      initImmediate: false,
    },
    (err: any): any => {
      if (err) {
        console.error("Error loading translation files", err);
      }
    }
  )
);

export const useTranslation = useTranslationBase;

export type TFunction = (key: KeyBase | string) => string;

export interface UseTranslationResponse extends UseTranslationResponseBase {
  t: TFunction;
}

export type Key = KeyBase;

export default { init };
