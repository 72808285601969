import { CSSProperties } from "react";
import { FontWeights, getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  container: CSSProperties;
  titulo: CSSProperties;
}

export const styles: IStyle = {
  container: {
    alignSelf: "center",
    alignItems: "justify",
  },
  titulo: {
    fontSize: 24,
    fontWeight: FontWeights.regular as any,
    marginTop: 12,
    marginBottom: 8,
    textAlign: "center",
  }
};
