import React, { useEffect, useRef, useState } from "react";
import {
  DetailsListLayoutMode,
  IColumn,
  ITextField,
  Label,
  SelectionMode,
  Stack,
  TextField
} from "@fluentui/react";
import { Key, useTranslation, UseTranslationResponse } from "../../i18n";

import { getStyles } from "./styles";
import { Tabela } from "../../components/Tabela";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_Carteirinha, Objeto_EmpresaObjeto_Login,
  Objeto_ServicoObjeto_SolicitacaoWeb
} from "../../api";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";
import * as EmpresaStore from "../../store/Empresa";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";

const obterColunas = (t: (key: Key) => string): Array<IColumn> => {
  return [
    {
      key: 'numero-carteira',
      name: t("numero-carteira"),
      fieldName: 'numero-carteira',
      minWidth: 140,
      maxWidth: 140,
      onRender: (item: Objeto_EmpresaObjeto_Carteirinha.Resposta) => {
        return <div>{item.SCACARTE_CODIGO_CART}</div>;
      },
    },
    {
      key: 'descricao-lote',
      name: t("descricao-lote"),
      fieldName: 'descricao-lote',
      minWidth: 240,
      onRender: (item: Objeto_EmpresaObjeto_Carteirinha.Resposta) => {
        return <div>{item.SCALOARD_ARQUIVO}</div>;
      },
    },
    {
      key: 'emissao',
      name: t("emissao"),
      fieldName: 'emissao',
      minWidth: 128,
      maxWidth: 128,
      onRender: (item: Objeto_EmpresaObjeto_Carteirinha.Resposta) => {
        return <div>{item.SCACARTE_DT_GERACAO?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
      },
    },
    {
      key: 'validade',
      name: t("validade"),
      fieldName: 'validade',
      minWidth: 128,
      maxWidth: 128,
      onRender: (item: Objeto_EmpresaObjeto_Carteirinha.Resposta) => {
        return <div>{item.SCACARTE_VALIDADE_FINAL?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1")}</div>;
      },
    },
  ];
}

const Emissao2ViaCarteirinha: React.FC = () => {
  const styles = getStyles();

  const matriculaTFRef = useRef<ITextField>(null);

  const { t }: UseTranslationResponse = useTranslation();

  const dispatch = useDispatch<any>();
  const {
    autenticacao,
  } = useSelector((store: ApplicationState) => store);
  const usuario: Objeto_EmpresaObjeto_Login.Resposta | undefined = autenticacao.usuario;

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [carteirinhas, definirCarteirinhas] = useState<Array<Objeto_EmpresaObjeto_Carteirinha.Resposta>>();

  const [descricao, definirDescricao] = useState<string | undefined>("");

  useEffect(() => {
    beneficiarioSelecionado?.SCACARTE_CODIGO_CART && dispatch(EmpresaStore.actions.obterCarteirinhas(beneficiarioSelecionado?.SCACARTE_CODIGO_CART)).then((carteirinhas: any) => definirCarteirinhas(carteirinhas));
  }, [dispatch, beneficiarioSelecionado]);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("emissao-2via-carteirinha")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Stack>
          <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <TextField
              componentRef={matriculaTFRef}
              required
              readOnly
              autoFocus
              styles={{ root: { flex: 1, maxWidth: "20em" } }}
              label={t("matricula")} iconProps={{ iconName: 'Search' }}
              value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
              onClick={() => definirBeneficiarioModalAberto(true)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  definirBeneficiarioModalAberto(true);
                }
              }}/>
            <TextField
              disabled={!beneficiarioSelecionado}
              readOnly
              styles={styles.textInput}
              label={t("nome-beneficiario")}
              value={beneficiarioSelecionado?.SGEPESS_NOME || ""} />
          </Stack>
        </Stack>

        <Tabela
          cabecalhoFixo
          items={carteirinhas || []}
          compact={false}
          columns={obterColunas(t)}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
        
        <TextField
          label={t("descricao")}
          multiline
          autoAdjustHeight
          value={descricao}
          onChange={(evt, texto) => definirDescricao(texto)}
        />

        <Stack.Item align="end">
          <BotaoSolicitacaoWeb
            disabled={!beneficiarioSelecionado}
            text={t("solicitar")}
            iconProps={{ iconName: "Send" }}
            onClick={() => (
              dispatch(EmpresaStore.actions.solicitar2ViaCarteirinha({
                TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao.SEGUNDA_VIA_CARTEIRINHA,
                TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
                CD_USUARIO_CRIACAO: usuario?.SCAEMPBA_NOME as string,
                ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
                ID_SGEUNID: usuario?.ID_SGEUNID as number,
                DS_OBSERVACAO: descricao as string,
                CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
                ID_SCAEMPBA: usuario?.ID_SCAEMPBA as number,
                NM_SEGURADO: beneficiarioSelecionado?.SGEPESS_NOME as string,
                SN_TITULAR: beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "S" : "N",
                TP_USUARIO: beneficiarioSelecionado?.SCADEPEN_QUALIFICACAO as string,
                NR_CPF: beneficiarioSelecionado?.SGEPESS_FCPF as string,
              }))
            )}
          />
        </Stack.Item>
      </Stack>

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario);
          definirBeneficiarioModalAberto(false);
        }}
      />
    </div>
  );
}

export default Emissao2ViaCarteirinha;
