import React, { ReactElement, useRef, useState, useEffect } from "react";
import {
  DatePicker,
  IDatePickerStrings,
  IDatePickerProps,
  TextField,
  Callout,
  FocusTrapZone,
  Calendar,
  ITextField,
  DirectionalHint,
  DayOfWeek,
  IconButton
} from "@fluentui/react";

import { TFunction, useTranslation, UseTranslationResponse } from "../../i18n";
import ReactDOM from "react-dom";

const DayPickerStrings = (t: TFunction): IDatePickerStrings => ({
  months: [t("janeiro"), t("fevereiro"), t("marco"), t("abril"), t("maio"), t("junho"), t("julho"), t("agosto"), t("setembro"), t("outubro"), t("novembro"), t("dezembro")],

  shortMonths: [t("jan"), t("fev"), t("mar"), t("abr"), t("mai"), t("jun"), t("jul"), t("ago"), t("set"), t("out"), t("nov"), t("dez")],

  days: [t("domingo"), t("segunda"), t("terca"), t("quarta"), t("quinta"), t("sexta"), t("sabado")],

  shortDays: t("dias-semana-abreviados").split(""),

  goToToday: t("ir-para-dia-atual"),
  prevMonthAriaLabel: `${t("ir-para-mes-anterior")}:`,
  nextMonthAriaLabel: `${t("ir-para-proximo-mes")}:`,
  prevYearAriaLabel: `${t("ir-para-ano-anterior")}:`,
  nextYearAriaLabel: `${t("ir-para-proximo-ano")}:`,
  closeButtonAriaLabel: `${t("fechar-selecao-data")}:`,
});

export interface IDataSeletor extends IDatePickerProps {
  mensal?: boolean;
  showClearButton?: boolean;
}

const clearButtonConatiner = document.createElement("div");
clearButtonConatiner.setAttribute("class", "clearButtonConatiner");

const DataSeletor: React.FC<IDataSeletor> = (props) => {
  const { t }: UseTranslationResponse = useTranslation();

  const calendarTFRef = useRef<ITextField>(null);

  const [data, definirData] = useState(props.value);
  const [exibindoCalendario, definirExibindoCalendario] = useState(false);

  const { showClearButton } = props;

  const clearButton = (
    <IconButton
      iconProps={{ iconName: 'EraseTool' }}
      title="Limpar data"
      ariaLabel="Limpar data"
      disabled={!data}
      onClick={() => {
        definirExibindoCalendario(false);
        definirData(undefined);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onSelectDate?.call(null, undefined);
      }}
    />
  );

  useEffect(() => {
    if (showClearButton) {
      if (exibindoCalendario) {
        setTimeout(() => {
          const el = document.querySelector(".ms-DatePicker-picker--focused .ms-DatePicker-monthPicker");
          
          if (el) {
            el.appendChild(clearButtonConatiner);

            ReactDOM.render(clearButton, document.querySelector(".clearButtonConatiner") as Element);
          }
        });
      } else {
        //
      }
    }
  }, [showClearButton, exibindoCalendario, clearButton]);

  return props.mensal ? (
    <div>
      <TextField
        componentRef={calendarTFRef}
        readOnly
        label={props.label}
        className={props.className}
        iconProps={{ iconName: 'Calendar' }}
        style={{ ...props.style, cursor: "pointer" }}
        onClick={() => definirExibindoCalendario(true)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            definirExibindoCalendario(true);
          }
        }}
        value={data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$2/$1")} />
      {exibindoCalendario && (
        <Callout
          isBeakVisible={false}
          className="ms-DatePicker-callout"
          gapSpace={1}
          doNotLayer={false}
          target={(calendarTFRef.current as any)?._textElement.current}
          directionalHint={DirectionalHint.bottomLeftEdge}
          onDismiss={() => definirExibindoCalendario(false)}
          setInitialFocus
        >
          <FocusTrapZone firstFocusableSelector="ms-DatePicker-day--today" isClickableOutsideFocusTrap>
            <Calendar
              onSelectDate={(date: Date): void => {
                definirExibindoCalendario(false);
                definirData(date);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                props.onSelectDate?.call(null, date);
              }}
              maxDate={props.maxDate}
              onDismiss={() => definirExibindoCalendario(false)}
              isMonthPickerVisible
              value={data!}
              firstDayOfWeek={DayOfWeek.Sunday}
              strings={DayPickerStrings(t)}
              isDayPickerVisible={false}
              highlightCurrentMonth={true}
              highlightSelectedMonth={true}
              showMonthPickerAsOverlay={false}
              showGoToToday={true}
            />
          </FocusTrapZone>
        </Callout>
      )}
    </div>
  ) : (
    <DatePicker
      {...props}
      strings={DayPickerStrings(t)}
      formatDate={(data?: Date) => data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1") as string}
    />
  );
}

export default DataSeletor;
