import { CSSProperties } from "react";

export interface IStyle {
  login: CSSProperties;
  loginLogo: any;
  titulo: CSSProperties;
  botaoPerfil: CSSProperties;
  botaoPerfilHover: CSSProperties;
  botaoPerfilActive: CSSProperties;
  icone: CSSProperties;
  iconePerfilSelecionado: CSSProperties;
}

export const styles: IStyle = {
  login: {
    position: "relative",
    width: "100%",
    maxWidth: 440,
    margin: "auto",
    paddingTop: 44,
    paddingBottom: 44,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  loginLogo: {
    maxHeight: 48,
    marginLeft: 44,
  },
  titulo: {
    color: "#1b1b1b",
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 44,
  },
  botaoPerfil: {
    minHeight: 64,
    paddingLeft: 44,
    width: "100%",
  },
  botaoPerfilHover: {
    color: "#1b1b1b",
    backgroundColor: "#e6e6e6",
  },
  botaoPerfilActive: {
    backgroundColor: "#b2b2b2",
  },
  icone: {
    color: "#1b1b1b",
    padding: 5,
    borderRadius: "50%",
    fontSize: 22,
    boxSizing: "border-box",
    width: 48,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e6e6e6",
  },
  iconePerfilSelecionado: {
    color: "#1b1b1b",
    fontSize: 17,
    marginRight: 8,
  },
};
