import {
  ActionButton, Dropdown, FocusZone, FocusZoneDirection, FontIcon, IIconProps, Image, Link, Stack, TextField, DefaultButton
} from "@fluentui/react";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, ICardTokens } from "@uifabric/react-cards";

import * as AutenticacaoStore from "../../store/Autenticacao";

import { Passos } from "../../components/Passos";
import { styles } from "./styles";
import { useTranslation, UseTranslationResponse } from "../../i18n";
import { ApplicationState } from "../../store";
import { Objeto_PrestadorObjeto_Login_Prestador } from "../../api";

const cardTokens: ICardTokens = { childrenMargin: 12 };

enum Telas {
  USUARIO,
  PRESTADOR,
  SENHA,
  REDEFINICAO_SENHA
}

type TipoPerfil = "beneficiario" | "prestador" | "empresa";

interface IPerfil {
  tipo: TipoPerfil;
  icone: IIconProps;
  telas: Telas[];
} 

const EventedActionButton = ({hoverStyle, activeStyle, ...props}: any) => {
  const [hover, setHover] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  // tslint:disable-next-line: no-object-literal-type-assertion
  props.style = {...props.style, ...(hover ? hoverStyle : {}), ...(active ? activeStyle : {})} as CSSProperties;

  return (
    <ActionButton
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}/>
  );
};

const Login: React.FC = () => {
  const { unidade, autenticacao: { prestadores, usuario } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch<any>();

  const { t }: UseTranslationResponse = useTranslation();

  const [perfil, setPerfil] = useState<IPerfil>();
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [prestador, setPrestador] = useState<Objeto_PrestadorObjeto_Login_Prestador.Resposta | undefined>();
  const [nomeErro, setNomeErro] = useState<string>();
  const [senhaErro, setSenhaErro] = useState<string>();
  const [prestadorErro, setPrestadorErro] = useState<string>();
  const [cpfcnpj, setCpfcnpj] = useState("");
  const [codigo, setCodigo] = useState(""); 
  const [confirmaSenha, setConfirmaSenha] = useState("");

  const perfils: IPerfil[] = [{
    tipo: "beneficiario",
    icone: { iconName: "ReminderPerson", style: styles.icone },
    telas: [Telas.USUARIO, Telas.SENHA, Telas.REDEFINICAO_SENHA],
  }, {
    tipo: "prestador",
    icone: { iconName: "AccountManagement", style: styles.icone },
    telas: [Telas.USUARIO, Telas.PRESTADOR, Telas.SENHA],
  }, {
    tipo: "empresa",
    icone: { iconName: "CityNext2", style: styles.icone },
    telas: [Telas.USUARIO, Telas.SENHA, Telas.REDEFINICAO_SENHA],
  }];

  const listarPrestadores = () => dispatch(AutenticacaoStore.actions.obterPrestadores(nome)).then((p: Array<any>) => {
    if (!p.length) {
      setNomeErro(t("nao-existem-prestadores-relacionados"));
    }
    return !!p.length;
  });

  const fazerLogin = () => {
    let requisicao = Promise.resolve();

    if (perfil?.tipo === "beneficiario") {
      requisicao = dispatch(AutenticacaoStore.actions.loginBeneficiario(nome, senha));
    } else if (perfil?.tipo === "prestador") {
      requisicao = dispatch(AutenticacaoStore.actions.loginPrestador(nome, prestador?.SGEUSPRE_CODIGO as string, senha));
    } else if (perfil?.tipo === "empresa") {
      requisicao = dispatch(AutenticacaoStore.actions.loginEmpresa(nome, senha));
    }

    return requisicao.then((usuario) => {
      return true;
    }).catch(() => {
      setSenhaErro("Usuario ou senha inválida");
      return false;
    });
  };

  const logo = useMemo(() => (
    <Card.Item fill>
    <Image src={`${(window as any)}/api/unidade/logo`} style={styles.loginLogo} />
  </Card.Item>
  ), []);

  const validaSenha = () => {

    let letrasMaiusculas = /[A-Z]/;
    let letrasMinusculas = /[a-z]/;
    let numeros = /[0-9]/;
    let caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    let auxMaiuscula = 0;
    let auxMinuscula = 0;
    let auxNumero = 0;
    let auxEspecial = 0;

    if (senha?.length >= 8 ){

      for(var i=0; i<senha.length; i++){
        if(letrasMaiusculas.test(senha[i]))
        auxMaiuscula++;
        else if(letrasMinusculas.test(senha[i]))
        auxMinuscula++;
        else if(numeros.test(senha[i]))
        auxNumero++;
        else if(caracteresEspeciais.test(senha[i]))
        auxEspecial++;
      }
      
      if (auxMaiuscula > 0){
        if (auxMinuscula > 0){
          if (auxNumero > 0){
            if (auxEspecial) {
              return false;
            }
          }
        }
      }
      return true;
    }
    else{
      return true;
    }
  }

  const validaSenha2 = () => {
    //essa função é responsável por habilitar o botão OK. 
    if( validaSenha() == false && senha == confirmaSenha){
      return false;
    }
    else{
      return true;
    }
  }

  const redefinirSenha = () => {

    let requisicao = Promise.resolve();

    if (perfil?.tipo === "empresa"){
      dispatch(AutenticacaoStore.actions.redefinirSenhaEmpresa(cpfcnpj, nome, senha));
    }
    else{
      dispatch(AutenticacaoStore.actions.redefinirSenhaBeneficiario(cpfcnpj, nome, senha));
    }
    
    
    return requisicao.then((usuario) => {
      return true;
    }).catch(() => {
      setSenhaErro("Dados incorretos!");
      return false;
    });
  };

  return (
    <Card aria-label="Login" tokens={cardTokens} style={{...styles.login, ...{ display: !usuario ? "block" : "none"}}}>
      {unidade && unidade.existeLogo && logo}
      {!perfil ? (
        <div>
          <Card.Item fill styles={{ root: { marginBottom: 16 } }}>
            <label style={styles.titulo}>{t("selecione-seu-perfil")}</label>
          </Card.Item>
          <Card.Item fill>
            <Stack styles={{ inner: { height: 64 } }}>
              <FocusZone
                direction={FocusZoneDirection.vertical}>
                {perfils.map(p => (
                  <EventedActionButton
                    key={p.tipo}
                    iconProps={p.icone}
                    style={styles.botaoPerfil}
                    hoverStyle={styles.botaoPerfilHover}
                    activeStyle={styles.botaoPerfilActive}
                    onClick={() => setPerfil(p)}>
                    {t(p.tipo)}
                  </EventedActionButton>
                ))}
              </FocusZone>
            </Stack>
          </Card.Item>
        </div>
      ) : (
        <>
          <div style={{ flexDirection: "row", alignItems: "center", marginLeft: 44, marginRight: 44 }}>
            <FontIcon style={styles.iconePerfilSelecionado} iconName={perfil?.icone.iconName} />
            <label>{t(perfil?.tipo as string)}</label>
          </div>
          <Passos
            passos={perfil?.telas || []}
            retrocederBotaoTexto={[t("voltar")]}
            avancarBotaoTexto={[t("avancar"), t("entrar"), "OK"]}
            retroceder={() => {
              setPerfil(undefined);
              setNome("");
              setPrestador(undefined);
              setSenha("");
            }}>

            <Passos.Item key={Telas.USUARIO} invalido={nome?.length < 3} validar={perfil?.tipo === "prestador" ? listarPrestadores : null}>
              <div>
                <Card.Item fill styles={{ root: { marginBottom: 16 } }}>
                  <label style={styles.titulo}>{"Insira seu usuário"}</label>
                </Card.Item>
                <Card.Item fill styles={{ root: { marginBottom: 16, paddingLeft: 44, paddingRight: 44 } }}>
                  <Stack styles={{ inner: { height: 64 } }} tokens={{ childrenGap: 32 }}>
                    <TextField
                      underlined
                      placeholder={t("login")}
                      errorMessage={nomeErro}
                      value={nome}
                      onLoad={console.log}
                      onChange={(_evt, texto) => {
                        setNome(texto as string);
                        setNomeErro(undefined);
                      }} />
                  </Stack>
                </Card.Item>
              </div>
            </Passos.Item>

            <Passos.Item key={Telas.PRESTADOR} invalido={!prestador}>
              <div>
                <Card.Item fill styles={{ root: { marginBottom: 16 } }}>
                  <label style={styles.titulo}>{"Selecione o prestador"}</label>
                </Card.Item>
                <Card.Item fill styles={{ root: { marginBottom: 16, paddingLeft: 44, paddingRight: 44 } }}>
                  <Stack styles={{ inner: { height: 64 } }} tokens={{ childrenGap: 32 }}>
                    <Dropdown
                      placeholder={t("selecione-um-prestador")}
                      options={prestadores?.map(e => ({ key: e.SGEUSPRE_CODIGO as string, text: e.SGEUSPRE_NOME as string })) || []}
                      errorMessage={prestadorErro}
                      selectedKey={prestador?.SGEUSPRE_CODIGO}
                      onChange={(evt, item) => {
                        setPrestador(prestadores?.find(e => e.SGEUSPRE_CODIGO === item?.key));
                        setPrestadorErro(undefined);
                      }} />
                  </Stack>
                </Card.Item>
              </div>
            </Passos.Item>
            <Passos.Item key={Telas.SENHA} invalido={senha?.length < 3} validar={fazerLogin} exibirRedefinicaoSenha>
              <div>
                <Card.Item fill styles={{ root: { marginBottom: 16 } }}>
                  <label style={styles.titulo}>{"Insira sua senha"}</label>
                </Card.Item>
                <Card.Item fill styles={{ root: { marginBottom: 16, paddingLeft: 44, paddingRight: 44 } }}>
                  <Stack styles={{ inner: { height: 64 } }} tokens={{ childrenGap: "1em" }}>
                    <TextField
                      underlined
                      placeholder={t("senha")}
                      errorMessage={senhaErro}
                      type="password"
                      value={senha}
                      onChange={(evt, texto) => {
                        setSenha(texto as string);
                        setSenhaErro(undefined);
                      }} />
                    {/* {perfil?.tipo !== "prestador" && (
                      <Link style={{fontSize: 13}} href="#">{t("esqueceu-sua-senha")}</Link>
                    )} */}
                  </Stack>
                </Card.Item>
              </div>
            </Passos.Item>
            <Passos.Item key={Telas.REDEFINICAO_SENHA} invalido={validaSenha2()} validar={redefinirSenha} >
              <div>
                <Card.Item fill styles={{ root: { marginBottom: 16 } }}>
                  <label style={styles.titulo}>{"Redefinir senha"}</label>
                </Card.Item>
                <Card.Item fill styles={{ root: { marginBottom: 16, paddingLeft: 44, paddingRight: 44 } }}>
                  <Stack styles={{ inner: { height: 64 } }} tokens={{ childrenGap: "1em" }}>

                    <TextField
                      underlined
                      placeholder= {perfil?.tipo == 'beneficiario'? 'CPF' : 'CNPJ'}
                      //errorMessage={senhaErro}
                      type="text"
                      value={cpfcnpj}
                      onChange={(evt, texto) => {
                        setCpfcnpj(texto as string);
                        //setSenhaErro(undefined);
                      }} />

                    <TextField
                      underlined
                      placeholder= {perfil?.tipo == 'beneficiario'? 'Matrícula' : 'Código'}
                      //errorMessage={nomeErro}
                      type="text"
                      value={nome}
                      onChange={(evt, texto) => {
                        setNome(texto as string);
                        //setNomeErro(undefined);
                      }} />

                    <TextField
                      underlined
                      placeholder={t("senha")}
                      errorMessage={senhaErro}
                      type="password"
                      value={senha}
                      
                      onKeyUp={()=>{
                        if(validaSenha() == true){
                          console.log('senha',validaSenha())
                          setSenhaErro('A SENHA TEM QUE CONTER PELO MENOS 8 CARACTERES, SENDO UMA LETRA MAIÚSCULA, UMA MINÚSCULA, UM NUMERAL E UM SIMBOLO(@, #, %, &, *)');
                        }
                        else{
                          setSenhaErro(undefined);
                        }
                      }}
                      onChange={(evt, texto) => {
                        setSenha(texto as string);
                        //setSenhaErro(undefined);
                        /* if(validaSenha() == true){
                          console.log('senha',validaSenha())
                          setSenhaErro('A SENHA TEM QUE CONTER PELO MENOS 8 CARACTERES, SENDO UMA LETRA MAIÚSCULA, UMA MINÚSCULA, UM NUMERAL E UM SIMBOLO(@, #, %, &, *)');
                        }
                        else{
                          setSenhaErro(undefined);
                        } */
                        
                      }} />
                      
                      <TextField
                      underlined
                      placeholder='Confirme sua senha'
                      //errorMessage={senhaErro}
                      type="password"
                      value={confirmaSenha}
                      onChange={(evt, texto) => {
                        setConfirmaSenha(texto as string);
                        //setSenhaErro(undefined);
                      }} />
                      {/* <DefaultButton
                      text='texto'
                      onClick={() => {
                        dispatch(AutenticacaoStore.actions.redefinirSenhaEmpresa(cpfcnpj, codigo, senha));
                      }} /> */}
                  </Stack>
                </Card.Item>
              </div>
            </Passos.Item>
          </Passos>
        </>
      )}
    </Card>
  );
};

export default Login;
