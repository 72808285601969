import React, { useRef, useState, useEffect } from "react";
import {
  Stack,
  Label,
  TextField,
  ITextField,
  Checkbox,
  Dropdown
} from "@fluentui/react";
import { useId } from '@uifabric/react-hooks';
import { useTranslation, UseTranslationResponse } from "../../i18n";

import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_BeneficiarioObjeto_DadosCadastrais,
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_MotivoDesligamento,
  Objeto_ServicoObjeto_SolicitacaoWeb
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import { DataSeletor } from "../../components/DataSeletor";
import * as BeneficiarioStore from "../../store/Beneficiario";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";

const CancelamentoPlano: React.FC = () => {
  const dispatch = useDispatch<any>();
  const {
    autenticacao,
    beneficiario: {
      familia,
    },
    empresa: {
      motivosDesligamento
    }
  } = useSelector((store: ApplicationState) => store);
  const usuario = autenticacao.usuario as Objeto_EmpresaObjeto_Beneficiario.Resposta;

  const titularFieldId = useId('titularFI');
  const cancelarDependentesFieldId = useId('cancelarDependentesFI');

  const matriculaTFRef = useRef<ITextField>(null);

  const { t }: UseTranslationResponse = useTranslation();

  const [motivoSelecionado, definirMotivoSelecionado] = useState<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta | undefined>();
  const [data, definirData] = useState<Date | undefined>(new Date());

  const [cancelarDependentes, definirCancelarDependetes] = useState<boolean | undefined>(false);

  const [descricao, definirDescricao] = useState<string | undefined>();

  const [beneficiarios, definirBeneficiarios] = useState<Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta | undefined>();
  const [dataFalecimento, definirDataFalecimento] = useState<Date | undefined>(new Date());
  const falecimento = (motivoSelecionado?.SGEMOTIV_TP_CLASSIFICACAO === "O") || (motivoSelecionado?.SGEMOTIV_DESCRICAO?.indexOf("ÓBITO") === 0);
  const [novoResponsavelFinanciero, definirNovoResponsavelFinanciero] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  useEffect(() => {
    dispatch(BeneficiarioStore.actions.obterFamilia()).then((familia: Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>) => {
      const usuarioAutenticado = familia.find(e => e.SCACARTE_CODIGO_CART === usuario.SCACARTE_CODIGO_CART) as Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta;

      definirBeneficiarios([...(usuarioAutenticado?.SCADEPEN_DESCRICAO === "TITULAR" ? familia : [usuarioAutenticado])]);
      definirBeneficiarioSelecionado(usuarioAutenticado);
      dispatch(EmpresaStore.actions.obterMotivosDesligamento());
    });
  }, []);

  useEffect(() => {
    definirCancelarDependetes(true);

  }, [beneficiarioSelecionado]);

  useEffect(() => {
    const CD_SOLICITACAO_TITULAR = cancelarDependentes == true ? usuario?.ID_SCABENEF as number : novoResponsavelFinanciero?.ID_SCABENEF as number;
    console.log('cancelarDependentes', cancelarDependentes);
    console.log('CD_SOLICITACAO_TITULAR',CD_SOLICITACAO_TITULAR);
    console.log('usuario', usuario);
    console.log('novoResponsavelFinanceiro', novoResponsavelFinanciero);

  }, [cancelarDependentes]);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("cancelamento-plano")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label>
        <Stack style={{ marginTop: "1em" }}>
          <Dropdown
            style={{ flex: 1 }}
            selectedKey={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            onChange={(_, item) => definirBeneficiarioSelecionado(familia?.find(e => e.SCACARTE_CODIGO_CART === item?.key as string))}
            label={t("selecione-um-beneficiario")}
            options={beneficiarios?.filter(e => e.TP_SITUACAO === "ATIVO").map(e => ({ key: e.SCACARTE_CODIGO_CART as string, text: e.SGEPESS_NOME as string })) || []}
          />
        </Stack>

        <Stack horizontal tokens={{ childrenGap: "1em" }}>
          <TextField
            componentRef={matriculaTFRef}
            required
            readOnly
            autoFocus
            styles={{ root: { flex: 1, maxWidth: "20em" } }}
            label={t("matricula")}
            value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            />
          <TextField
            disabled={!beneficiarioSelecionado}
            readOnly
            styles={styles.textInput}
            label={t("nome-beneficiario")}
            value={beneficiarioSelecionado?.SGEPESS_NOME}/>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <Label disabled={!beneficiarioSelecionado} htmlFor={titularFieldId}>{t("titular")}</Label>
            <Checkbox
              disabled={!beneficiarioSelecionado}
              id={titularFieldId}
              styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center" } }}
              checked={beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR"}/>
          </span>
        </Stack>

        <Stack style={{ paddingTop: "1em", backgroundColor: "#fff", flex: 1 }}>
          <Stack style={{ padding: '0 1em 1em 1em', flex: 1 }}>
            <Stack tokens={{ childrenGap: "1em" }}>
              <Stack horizontal tokens={{ childrenGap: "1em" }}>
                <Dropdown
                  style={{ flex: 1 }}
                  selectedKey={motivoSelecionado?.ID_SGEMOTIV}
                  onChange={(_, item) => definirMotivoSelecionado(motivosDesligamento?.find(e => e.ID_SGEMOTIV === item?.key as number))}
                  label={t("motivo-desligamento")}
                  options={motivosDesligamento?.map(e => ({ key: e.ID_SGEMOTIV as number, text: e.SGEMOTIV_DESCRICAO as string })) || []}
                />
                <DataSeletor
                  isRequired
                  disabled={!beneficiarioSelecionado}
                  value={data}
                  onSelectDate={data => definirData(data || undefined)}
                  label={t("data")}
                />
                {falecimento && (
                  <DataSeletor
                    isRequired
                    value={dataFalecimento}
                    onSelectDate={data => definirDataFalecimento(data || undefined)}
                    maxDate={new Date()}
                    label={t("data-falecimento")}
                  />
                )}
                <span style={{ display: "flex", flexDirection: "column" }}>
              <Label
                htmlFor={cancelarDependentesFieldId}
                disabled={beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}>
                {t("cancelar-dependentes")}
              </Label>
              <Checkbox
                disabled={beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}
                id={cancelarDependentesFieldId}
                styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center" } }}
                checked={cancelarDependentes}
                onChange={(evt, checked) => definirCancelarDependetes(checked)} />
              </span>
              </Stack>
              <Dropdown
                required
                disabled={cancelarDependentes || beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}
                style={{ flex: 1 }}
                selectedKey={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
                onChange={(_, item) => definirNovoResponsavelFinanciero(familia?.find(e => e.SCACARTE_CODIGO_CART === item?.key as string))}
                label={t("novo-responsavel-financeiro")}
                options={beneficiarios?.filter(e => e.TP_SITUACAO === "ATIVO").map(e => ({ key: e.SCACARTE_CODIGO_CART as string, text: e.SGEPESS_NOME as string })) || []}
              />
              <TextField
                label={t("descricao")}
                multiline
                autoAdjustHeight
                value={descricao}
                onChange={(evt, texto) => definirDescricao(texto)}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack.Item align="end">
          <BotaoSolicitacaoWeb
            disabled={
              !beneficiarioSelecionado ||
              !motivoSelecionado ||
              !data
            }
            text={t("salvar")}
            iconProps={{ iconName: "Save" }}
            onClick={() => (
              dispatch(BeneficiarioStore.actions.solicitarDesligamentoBeneficiario({
                TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao[beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "DESLIGAMENTO_TITULAR" : "DESLIGAMENTO_DEPENDENTE"],
                TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
                CD_USUARIO_CRIACAO: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
                ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
                ID_SGEUNID: beneficiarioSelecionado?.CD_MULTI_EMPRESA as number,

                DS_OBSERVACAO: descricao as string,
                CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
                NM_SEGURADO: beneficiarioSelecionado?.SGEPESS_NOME as string,
                SN_TITULAR: beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "S" : "N",
                TP_USUARIO: usuario?.SCADEPEN_QUALIFICACAO as string,
                NR_CPF: usuario?.SGEPESS_FCPF as string,
                DT_DESLIGAMENTO: data as Date,
                DT_FALECIMENTO: falecimento ? dataFalecimento as Date : null,
                ID_SGEMOTIV: motivoSelecionado?.ID_SGEMOTIV as number,
                SN_EXCLUI_DEPENDENTE: cancelarDependentes == true ? "S" : "N",
                CD_SOLICITACAO_TITULAR: cancelarDependentes == true ? usuario?.ID_SCABENEF as number : novoResponsavelFinanciero?.ID_SCABENEF as number,
              }))
            )}
          />
        </Stack.Item>
      </Stack>
      
    </div>
  );
}

export default CancelamentoPlano;
