import React, { useRef, useState, useMemo, useEffect } from "react";
import {
  Stack,
  Label,
  Pivot,
  PivotItem,
  TextField,
  ITextField,
  Checkbox,
  IColumn, SelectionMode, ActionButton
} from "@fluentui/react";
import { useId } from '@uifabric/react-hooks';
import { useTranslation, UseTranslationResponse } from "../../i18n";

import { styles, classNames } from "./styles";
import { PesquisaModal } from "../../components/PesquisaModal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_EmpresaObjeto_Beneficiario, Objeto_EmpresaObjeto_Login,
  Objeto_EmpresaObjeto_MotivoDesligamento,
  Objeto_ServicoObjeto_SolicitacaoWeb
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import { DataSeletor } from "../../components/DataSeletor";
import { Documentos } from "../../components/Documentos";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";

export enum PivotItens {
  DADOS_DESLIGAMENTO = "DADOS_DESLIGAMENTO",
  DOCUMENTOS = "DOCUMENTOS",
}

const DesligamentoBeneficiario: React.FC = () => {
  const dispatch = useDispatch<any>();
  const {
    autenticacao,
    empresa: {
      motivosDesligamento
    }
  } = useSelector((store: ApplicationState) => store);
  const usuario: Objeto_EmpresaObjeto_Login.Resposta | undefined = autenticacao.usuario;

  const titularFieldId = useId('titularFI');
  const cancelarDependentesFieldId = useId('cancelarDependentesFI');

  const arquivoInput = useRef<HTMLInputElement>(null);
  const matriculaTFRef = useRef<ITextField>(null);

  const { t }: UseTranslationResponse = useTranslation();

  const [arquivos, definirArquivos] = useState<Array<File>>([]);

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [novoResponsavelFinancieroModalAberto, definirNovoResponsavelFinancieroModalAberto] = useState<boolean>();
  const [novoResponsavelFinanciero, definirNovoResponsavelFinanciero] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [motivoModalAberto, definirMotivoModalAberto] = useState<boolean>();
  const [motivoSelecionado, definirMotivoSelecionado] = useState<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta | undefined>();
  const [data, definirData] = useState<Date | undefined>(new Date());
  const [dataFalecimento, definirDataFalecimento] = useState<Date | undefined>(new Date());

  const [cancelarDependentes, definirCancelarDependetes] = useState<boolean | undefined>(false);

  const [pivotKey, setPivotKey] = useState<PivotItens>(PivotItens.DADOS_DESLIGAMENTO);

  const [descricao, definirDescricao] = useState<string | undefined>();

  const { empresa: { contratoAtivo } } = useSelector((store: ApplicationState) => store);

  useEffect(() => {
    dispatch(EmpresaStore.actions.obterMotivosDesligamento());
  }, [dispatch]);

  useEffect(() => {
    definirCancelarDependetes(true);
  }, [beneficiarioSelecionado])

  const falecimento = (motivoSelecionado?.SGEMOTIV_TP_CLASSIFICACAO === "O") || (motivoSelecionado?.SGEMOTIV_DESCRICAO?.indexOf("ÓBITO") === 0);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("desligamento-beneficiario")}</Label>

      <input ref={arquivoInput} type="file" accept=".jpeg,.jpg,.jpg2,.png,.png2,.gif,.pdf,.doc,.docx,.docx2" style={{ display: "none" }} onChange={(e) => {
        if (e.target.files?.length && e.target.accept.indexOf(e.target.files[0].name.split(".").pop() as string) !== -1) {
          definirArquivos([...arquivos, e.target.files[0]]);
        }
      }} />

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        {/* <Label style={styles.subTitle}>{t("dados-beneficiario")}</Label> */}

        <Stack horizontal tokens={{ childrenGap: "1em" }}>
          <TextField
            componentRef={matriculaTFRef}
            required
            readOnly
            autoFocus
            styles={{ root: { flex: 1, maxWidth: "20em" } }}
            label={t("matricula")} iconProps={{ iconName: 'Search' }}
            value={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            onClick={() => definirBeneficiarioModalAberto(true)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                definirBeneficiarioModalAberto(true);
              }
            }}/>
          <TextField
            disabled={!beneficiarioSelecionado}
            readOnly
            styles={styles.textInput}
            label={t("nome-beneficiario")}
            value={beneficiarioSelecionado?.SGEPESS_NOME}/>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <Label disabled={!beneficiarioSelecionado} htmlFor={titularFieldId}>{t("titular")}</Label>
            <Checkbox
              disabled={!beneficiarioSelecionado}
              id={titularFieldId}
              styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center" } }}
              checked={beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR"}/>
          </span>
        </Stack>

        <Stack style={{ paddingTop: "1em", backgroundColor: "#fff", flex: 1 }}>
          <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Pivot
              selectedKey={pivotKey}
              onLinkClick={(item: any): void => setPivotKey(item.props.itemKey)}>
              <PivotItem itemKey={PivotItens.DADOS_DESLIGAMENTO} headerText={t("dados-desligamento")} style={styles.pivotItem} />
              {/* <PivotItem itemKey={PivotItens.DOCUMENTOS} headerText={t("documentos")} style={styles.pivotItem} /> */}
            </Pivot>
            {pivotKey === PivotItens.DOCUMENTOS && (
              <ActionButton disabled={!beneficiarioSelecionado} iconProps={{ iconName: "Add" }} className={classNames.botaoAdicionarDocumento} onClick={() => arquivoInput.current?.click()} >
                {t("adicionar")}
              </ActionButton>
            )}
          </Stack>
          <Stack style={{ padding: '0 1em 1em 1em', flex: 1 }}>
            {useMemo(() => {
              if (pivotKey === PivotItens.DADOS_DESLIGAMENTO) {
                return (
                  <Stack tokens={{ childrenGap: "1em" }}>
                    <Stack horizontal tokens={{ childrenGap: "1em" }}>
                      <TextField
                        required
                        readOnly
                        disabled={!beneficiarioSelecionado}
                        styles={styles.textInput}
                        label={t("motivo-desligamento")}
                        iconProps={{ iconName: 'Search' }}
                        value={motivoSelecionado ? `${motivoSelecionado?.ID_SGEMOTIV} - ${motivoSelecionado?.SGEMOTIV_DESCRICAO}` : ''}
                        onClick={() => definirMotivoModalAberto(true)}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            definirMotivoModalAberto(true);
                          }
                        }}/>
                      <DataSeletor
                        isRequired
                        disabled={!beneficiarioSelecionado}
                        value={data}
                        onSelectDate={data => definirData(data || undefined)}
                        label={t("data")}
                      />
                      {falecimento && (
                        <DataSeletor
                          isRequired
                          value={dataFalecimento}
                          onSelectDate={data => definirDataFalecimento(data || undefined)}
                          maxDate={new Date()}
                          label={t("data-falecimento")}
                        />
                      )}
                      <span style={{ display: "flex", flexDirection: "column" }}>
                    <Label
                      htmlFor={cancelarDependentesFieldId}
                      disabled={beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}>
                      {t("cancelar-dependentes")}
                    </Label>
                    <Checkbox
                      disabled={beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}
                      id={cancelarDependentesFieldId}
                      styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center" } }}
                      checked={cancelarDependentes}
                      onChange={(evt, checked) => definirCancelarDependetes(checked)} />
                    </span>
                    </Stack>
                    <TextField
                      required
                      disabled={cancelarDependentes || beneficiarioSelecionado?.SCADEPEN_DESCRICAO !== "TITULAR"}
                      styles={styles.textInput}
                      label={t("novo-responsavel-financeiro")}
                      iconProps={{ iconName: 'Search' }}
                      value={novoResponsavelFinanciero?.SGEPESS_NOME}
                      onClick={() => definirNovoResponsavelFinancieroModalAberto(true)}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          definirNovoResponsavelFinancieroModalAberto(true);
                        }
                      }}
                    />
                    <TextField
                      label={t("descricao")}
                      multiline
                      autoAdjustHeight
                      value={descricao}
                      onChange={(evt, texto) => definirDescricao(texto)}
                    />
                  </Stack>
                );
              } else if (pivotKey === PivotItens.DOCUMENTOS) {
                return (
                  <Documentos arquivos={arquivos} removerArquivo={arquivo => {
                    definirArquivos(arquivos.filter(e => e.name !== arquivo.name && e.lastModified !== arquivo.lastModified && e.size !== arquivo.size));
                  }} />
                );
              }
            }, [pivotKey, t, motivoSelecionado, cancelarDependentesFieldId, cancelarDependentes, arquivos, beneficiarioSelecionado, dataFalecimento, novoResponsavelFinanciero])}
          </Stack>
        </Stack>

        <Stack.Item align="end">
          <BotaoSolicitacaoWeb
            disabled={
              !beneficiarioSelecionado ||
              !motivoSelecionado ||
              !data ||
              (falecimento && !dataFalecimento)
            }
            text={t("salvar")}
            iconProps={{ iconName: "Save" }}
            onClick={() => (
              dispatch(EmpresaStore.actions.solicitarDesligamentoBeneficiario({
                TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao[beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "DESLIGAMENTO_TITULAR" : "DESLIGAMENTO_DEPENDENTE"],
                TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
                CD_USUARIO_CRIACAO: usuario?.SCAEMPBA_NOME as string,
                ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
                ID_SGEUNID: usuario?.ID_SGEUNID as number,
                DS_OBSERVACAO: descricao as string,
                CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
                ID_SCAEMPBA: usuario?.ID_SCAEMPBA as number,
                NM_SEGURADO: beneficiarioSelecionado?.SGEPESS_NOME as string,
                SN_TITULAR: beneficiarioSelecionado?.SCADEPEN_DESCRICAO === "TITULAR" ? "S" : "N",
                TP_USUARIO: beneficiarioSelecionado?.SCADEPEN_QUALIFICACAO as string,
                NR_CPF: beneficiarioSelecionado?.SGEPESS_FCPF as string,
                DT_DESLIGAMENTO: data as Date,
                DT_FALECIMENTO: falecimento ? dataFalecimento as Date : null,
                ID_SGEMOTIV: motivoSelecionado?.ID_SGEMOTIV as number,
                SN_EXCLUI_DEPENDENTE: cancelarDependentes ? "S" : "N",
                CD_SOLICITACAO_TITULAR: cancelarDependentes ? null : novoResponsavelFinanciero?.ID_SCABENEF as number,
              }))
            )}
          />
        </Stack.Item>
      </Stack>

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario);
          definirBeneficiarioModalAberto(false);
        }}
      />

      <BeneficiarioPesquisaModal
        isOpen={novoResponsavelFinancieroModalAberto}
        onDismiss={(novoResponsavelFinanceiro) => {
          definirNovoResponsavelFinanciero(novoResponsavelFinanceiro);
          definirNovoResponsavelFinancieroModalAberto(false);
        }}
        filtro={(e) => e.ID_SCACONBE !== beneficiarioSelecionado?.ID_SCACONBE && e.SCADEPEN_DESCRICAO === "TITULAR" && e.TP_SITUACAO === "ATIVO" && e.SCACONTR_CODIGO === contratoAtivo}
      />

      <PesquisaModal
        itens={motivosDesligamento}
        titulo={t("lista-motivos-desligamento")}
        placeholderCampoPesquisa={t("pesquisar")}
        tituloBotaoSelecionar={t("ok")}
        isBlocking={false}
        isOpen={motivoModalAberto}
        onDismiss={(motivo) => {
          definirMotivoSelecionado(motivo);
          definirMotivoModalAberto(false);
        }}
        selectionMode={SelectionMode.single}
        columns={[
          {
            key: "codigo",
            name: t("codigo"),
            ariaLabel: t("codigo"),
            fieldName: "ID_SGEMOTIV", // TODO: SP Type
            minWidth: 110,
            maxWidth: 110,
          },
          {
            key: "motivo-desligamento",
            name: t("motivo-desligamento"),
            ariaLabel: t("motivo-desligamento"),
            fieldName: "SGEMOTIV_DESCRICAO", // TODO: SP Type
          },
        ] as Array<IColumn>}
      />
    </div>
  );
}

export default DesligamentoBeneficiario;
