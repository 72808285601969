//@ts-nocheck

import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { 
  API_Controllers, 
  Objeto_GuiaMedicoObjeto_Uf,
  Objeto_GuiaMedicoObjeto_Cidade,
  Objeto_GuiaMedicoObjeto_Bairro,
  Objeto_GuiaMedicoObjeto_Recurso,
  Objeto_GuiaMedicoObjeto_Especialidades,
  Objeto_GuiaMedicoObjeto_Prestadores
 } from "../api";

export const GuiaMedicoAPI = new API_Controllers.GuiaMedico();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  obterUf?: Array<Objeto_GuiaMedicoObjeto_Uf.Resposta>;
  obterCidadesPorUf?:Array<Objeto_GuiaMedicoObjeto_Cidade.Resposta>;
  obterBairroPorCidadePorUf?:Array<Objeto_GuiaMedicoObjeto_Bairro.Resposta>;
  obterTipoPrestador?:Array<Objeto_GuiaMedicoObjeto_Recurso.Resposta>;
  obterEspecialidades?:Array<Objeto_GuiaMedicoObjeto_Especialidades.Resposta>;
  obterPrestadores?:Array<Objeto_GuiaMedicoObjeto_Prestadores.Resposta>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IObterUf {
  type: "GUIAMEDICO::OBTER_UF",
  obterUf?: Array<Objeto_GuiaMedicoObjeto_Uf.Resposta>;
}

export interface IObterCidadesPorUf {
  type: "GUIAMEDICO::OBTER_CIDADE_UF",
  obterCidadesPorUf?: Array<Objeto_GuiaMedicoObjeto_Cidade.Resposta>;
}

export interface IObterCidadesPorUf {
  type: "GUIAMEDICO::OBTER_BAIRRO_CIDADE_UF",
  obterBairroPorCidadePorUf?: Array<Objeto_GuiaMedicoObjeto_Bairro.Resposta>;
}

export interface IObterTipoPrestador {
  type: "GUIAMEDICO::OBTER_TIPO_PRESTADOR",
  obterTipoPrestador?: Array<Objeto_GuiaMedicoObjeto_Recurso.Resposta>;
}

export interface IObterEspecialidades {
  type: "GUIAMEDICO::OBTER_ESPECIALIDADES",
  obterEspecialidades?: Array<Objeto_GuiaMedicoObjeto_Especialidades.Resposta>;
}

export interface IObterPrestadores {
  type: "GUIAMEDICO::OBTER_PRESTADORES",
  obterPrestadores?: Array<Objeto_GuiaMedicoObjeto_Prestadores.Resposta>;
}

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).

export type KnownAction = IObterUf | IObterCidadesPorUf | IObterBairroCidadeUf | IObterTipoPrestador | IObterEspecialidades | IObterPrestadores;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {

  obterUf: (): AppThunkAction<KnownAction> => (dispatch) => {
    return GuiaMedicoAPI.ObterUf().then((obterUf) => {
      dispatch({ type: "GUIAMEDICO::OBTER_UF", obterUf});
      return obterUf;
    });
  },

  obterCidadesPorUf: (SCAPRANS_SG_UF_CONS: string): AppThunkAction<KnownAction> => (dispatch) => {
    return GuiaMedicoAPI.ObterCidadesPorUf({SCAPRANS_SG_UF_CONS }).then((obterCidadesPorUf) => {
      dispatch({ type: "GUIAMEDICO::OBTER_CIDADE_UF", obterCidadesPorUf });
      return obterCidadesPorUf;
    });
  },

  obterBairroPorCidadePorUf: (SCAPRANS_SG_UF_CONS: string, SGEMUNIC_NOME:string): AppThunkAction<KnownAction> => (dispatch) => {
    return GuiaMedicoAPI.ObterBairroPorCidadePorUf({SCAPRANS_SG_UF_CONS, SGEMUNIC_NOME }).then((obterBairroPorCidadePorUf) => {
      dispatch({ type: "GUIAMEDICO::OBTER_BAIRRO_CIDADE_UF", obterBairroPorCidadePorUf });
      return obterBairroPorCidadePorUf;
    });
  },

  obterTipoPrestador: (): AppThunkAction<KnownAction> => (dispatch) => {
    return GuiaMedicoAPI.ObterTpPrest().then((obterTipoPrestador) => {
      dispatch({ type: "GUIAMEDICO::OBTER_TIPO_PRESTADOR", obterTipoPrestador});
      return obterTipoPrestador;
    });
  },

  obterEspecialidades: (SCAPRANS_SG_UF_CONS: string, SGEMUNIC_NOME:string, SGEENDER_BAIRRO?:string, SCAPRANS_TP_PREST?:string ): AppThunkAction<KnownAction> => (dispatch) => {
    return GuiaMedicoAPI.ObterEspecialidades({SCAPRANS_SG_UF_CONS, SGEMUNIC_NOME, SGEENDER_BAIRRO, SCAPRANS_TP_PREST }).then((obterEspecialidades) => {
      dispatch({ type: "GUIAMEDICO::OBTER_ESPECIALIDADES", obterEspecialidades });
      return obterEspecialidades;
    });
  },

  obterPrestadores: (SCAPRANS_SG_UF_CONS: string, SGEMUNIC_NOME:string, SGEENDER_BAIRRO?:string, SCAPRANS_TP_PREST:string, SMNESPEC_ID_SMNESPEC?:string, SCAPREST_NOME?:string ): AppThunkAction<KnownAction> => (dispatch) => {
   return GuiaMedicoAPI.ObterPrestadores({SCAPRANS_SG_UF_CONS, SGEMUNIC_NOME, SGEENDER_BAIRRO, SCAPRANS_TP_PREST, SMNESPEC_ID_SMNESPEC, SCAPREST_NOME }).then((obterPrestadores) => {
      dispatch({ type: "GUIAMEDICO::OBTER_PRESTADORES", obterPrestadores });
      //return obterPrestadores;
    });
  },
  
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return {
      obterUf: [],
      obterCidadesPorUf: [],
    };
  }

  const action = incomingAction as KnownAction & State;
  switch (action.type) {

    case "GUIAMEDICO::OBTER_UF":
      return { ...state, obterUf: action.obterUf };

    case "GUIAMEDICO::OBTER_CIDADE_UF":
      return { ...state, obterCidadesPorUf: action.obterCidadesPorUf }; 

    case "GUIAMEDICO::OBTER_BAIRRO_CIDADE_UF":
      return { ...state, obterBairroPorCidadePorUf: action.obterBairroPorCidadePorUf };  
      
    case "GUIAMEDICO::OBTER_TIPO_PRESTADOR":
      return { ...state, obterTipoPrestador: action.obterTipoPrestador };

    case "GUIAMEDICO::OBTER_ESPECIALIDADES":
      return { ...state, obterEspecialidades: action.obterEspecialidades };  

    case "GUIAMEDICO::OBTER_PRESTADORES":
      return { ...state, obterPrestadores: action.obterPrestadores };

    default:
      return state;
  }
};
