import { CSSProperties } from "react";
import { FontSizes } from '@uifabric/fluent-theme';
import { FontWeights, mergeStyleSets, getTheme } from "@fluentui/react";

const tema = getTheme();

export interface IStyle {
  title: CSSProperties;
}

export const styles: IStyle = {
  title: {
    fontSize: "1.5em",
    fontWeight: FontWeights.regular as any
  },
};

export interface IClassNames {
  container: string;
  header: string;
  body: string;
  input: string;
}

export const classNames: IClassNames = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: 740,
    maxWidth: "100%",
    height: 520,
    maxHeight: "100%",
    selectors: {
      '.ms-Modal-scrollableContent': {
        display: "flex",
        flexDirection: "column",
      },
    }
  },
  header: [
    // tslint:disable-next-line:deprecation
    tema.fonts.xLargePlus,
    {
      borderTop: `4px solid ${tema.palette.themePrimary}`,
      color: tema.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '32px 16px 16px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  input: {
    margin: "0 16px 16px"
  }
});
