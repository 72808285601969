import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  PrimaryButton,
  IIconProps,
  DialogFooter,
  DialogType,
  DefaultButton
} from "@fluentui/react";

import { useTranslation, UseTranslationResponse } from "../../i18n";
import { Objeto_ServicoObjeto_SolicitacaoWeb } from "../../api";
import { useHistory } from "react-router";

export interface IBotaoSolicitacao {
  disabled?: boolean,
  text?: string,
  iconProps?: IIconProps,
  onClick: () => Promise<Objeto_ServicoObjeto_SolicitacaoWeb.Resposta>;
}

const BotaoSolicitacaoWeb: React.FC<IBotaoSolicitacao> = (props) => {
  const { t }: UseTranslationResponse = useTranslation();
  let history = useHistory();

  const [dialogoAberto, definirDialogoAberto] = useState(false);
  const [dialogoTexto, definirDialogoTexto] = useState(t("enviando-solicitacao"));
  const [botaoFecharDesabilitado, definirBotaoFecharDesabilitado] = useState(true);
  const [botaoSolicitacaoDesabilitado, definirBotaoSolicitacaoDesabilitado] = useState(true);

  const enviar = () => {
    definirBotaoSolicitacaoDesabilitado(true);
    definirBotaoFecharDesabilitado(true);
    definirDialogoTexto(t("enviando-solicitacao"));
    definirDialogoAberto(true);
    props.onClick().then((res: Objeto_ServicoObjeto_SolicitacaoWeb.Resposta) => {
      if (res.CD_SOLICITACAO) {
        definirBotaoSolicitacaoDesabilitado(false);
        definirDialogoTexto(t("solicitacao-enviada-sucesso"));
      } else {
        definirDialogoTexto(t("erro-enviar-solicitacao"));
      }
    }).catch(() => {
      definirDialogoTexto(t("erro-enviar-solicitacao"));
    }).finally(() => {
      definirBotaoFecharDesabilitado(false);
    })
  }

  return (
    <>
      <PrimaryButton
        disabled={props.disabled}
        text={props.text}
        iconProps={props.iconProps}
        onClick={enviar}
      />

      <Dialog
        hidden={!dialogoAberto}
        onDismiss={() => definirDialogoAberto(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("solicitacao-web"),
          subText: dialogoTexto,
        }}
      >
        <DialogFooter>
          <DefaultButton disabled={botaoSolicitacaoDesabilitado} onClick={() => {
            definirDialogoAberto(false);
            history.push("/manutencao-solicitacoes");
          }} text={t("tela-solicitacoes")} />
          <PrimaryButton disabled={botaoFecharDesabilitado} onClick={() => {
            definirDialogoAberto(false);
          }} text={t("ok")} />
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default BotaoSolicitacaoWeb;
