import React, { useEffect, useMemo, useState } from "react";
import { Modal, Stack, Label, IconButton, ProgressIndicator } from "@fluentui/react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../../store";
import { Objeto_PrestadorObjeto_Login, Objeto_PrestadorObjeto_Lote } from "../../api";
import { ProcessarBase64 } from "../../workers";
import { useTranslation, UseTranslationResponse } from "../../i18n";

type Dados = Objeto_PrestadorObjeto_Lote.Resposta & { PRESTAODR: string};

interface IModalDetalhes {
  dados?: Objeto_PrestadorObjeto_Lote.Resposta;
  onDismiss: () => void;
  titulo: string;
}

export const ModalDetalhes: React.FC<IModalDetalhes> = ({ dados, onDismiss, titulo }) => {
  const { autenticacao } = useSelector((store: ApplicationState) => store);
  const usuario = autenticacao.usuario as Objeto_PrestadorObjeto_Login.Resposta;
  const [impressaoDisponivel, definirImpressaoDisponivel] = useState<boolean>(false);

  const { t }: UseTranslationResponse = useTranslation();
  
  const print = () => {
    window.frames["iframe" as any].focus();
    window.frames["iframe" as any].print();
  };

  const filtro: {[key in keyof Dados]: string} = {
    PRESTAODR: "Prestador",
    SMNWSLOT_DT_GERACAO_ARQ: "Data de Envio",
    SMNWSLOT_PROTOCOLO: "Número de Protocolo Portal",
    SMNWSLOT_LOTE: "Número de Lote do Prestador",
    QUANTIDADE_GUIAS: "Quantidade Guias",
    ID_SMNWSLOT: "Código na Operadora",
    VALOR_TOTAL: "Valor total",
  }

  const d: {[key in keyof Dados]: any} = {
    ...dados,
    SMNWSLOT_DT_GERACAO_ARQ: dados?.SMNWSLOT_DT_GERACAO_ARQ?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1"),
    VALOR_TOTAL: parseFloat(dados?.VALOR_TOTAL?.toString() || "0").toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    }),
    PRESTAODR: `${usuario?.SCAPREST_CODIGO}   ${usuario?.SGEUSUAR_NET_NOME}`,
  };
  const dadosPreparados = Object.entries(filtro).map(([chave, titulo]) => [titulo, (d as any)[chave]]);

  const progress = useMemo(() => !impressaoDisponivel ? (
    <ProgressIndicator styles={{ root: { position: "absolute", top: 0, left: 0, width: "100%"}, itemProgress: { padding: 0 } }} />
  ) : null, [impressaoDisponivel]);

  useEffect(() => {
    new Promise<string>(res => res(btoa(JSON.stringify(dadosPreparados)))).then();
  }, [dadosPreparados]);

  const iframe = useMemo(() => (
    <iframe src={`/imprimir/comprovante_envio_xml/?${btoa(JSON.stringify(dadosPreparados))}`} name="iframe" style={{display: "none"}} onLoad={() => definirImpressaoDisponivel(true)} />
  ), [dadosPreparados]);

  const tabela = useMemo(() => (
    <Stack style={{ marginTop: "1em" }} tokens={{ childrenGap: 8 }}>
      {dadosPreparados.map(([titulo, valor], i) => (
        <Stack key={`ModalDetalhes-${i.toString()}`} horizontal styles={{ root: { display: "flex", justifyContent: "space-between" } }}>
          <Label style={{ paddingTop: 0, paddingBottom: 0, marginRight: ".5em" }}>{titulo}:</Label>
          <Label disabled style={{ paddingTop: 0, paddingBottom: 0, fontWeight: 400 }}>{valor}</Label>
        </Stack>
      ))}
    </Stack>
  ), [dadosPreparados]);

  return dados ? (
    <Modal
      isOpen={!!dados}
      onDismiss={() => {
        onDismiss();
        definirImpressaoDisponivel(false);
      }}
      isBlocking={false}>
      <div style={{ margin: "1.5em", justifyContent: "center" }}>
        {progress}
        <div>
          {iframe}
          <h2 style={{ margin: "1.75em 0 1em" }}>{titulo}</h2>
          {tabela}
        </div>
        <div style={{ marginTop: "1em", flexDirection: "row", textAlign: "end" }}>
          <IconButton ariaLabel={t("imprimir")} disabled={!impressaoDisponivel} iconProps={{ iconName: "Print"}} onClick={print}/>
        </div>
      </div>
    </Modal >
  ) : null;
};
