import React, { useState, useMemo, useEffect } from "react";
import { Icon } from "@fluentui/react";
import { NavLink } from "react-router-dom";

import { IStyle, styles } from "./styles";

export interface IMenuItem {
  titulo: string;
  icone: string;
  href: string;
}

const MenuItem: React.FC<IMenuItem & { styles: IStyle }> = ({ href, titulo, icone, styles }) => {
  const [ hover, setHover ] = useState<boolean>(false);

  return (
    <NavLink
      style={{ ...styles.menuItem, ...(hover ? styles.menuItemHover : null) }}
      to={href}
      activeStyle={{ ...(hover ? styles.menuItemActive : styles.menuItemActiveSmall) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon style={styles.menuItemIcone} iconName={icone} />
      <span>{titulo}</span>
    </NavLink>
  );
}

interface IMenu {
  itens?: Array<IMenuItem>,
  reduzido?: boolean;
}

const Menu: React.FC<IMenu> = (props) => {
  const [ reduzido, setReduzido ] = useState<boolean>(props.chain(({ reduzido }: IMenu) => {
    const r = localStorage.getItem("menu-reduzido")?.chain(e => JSON.parse(e));
    return r !== undefined ? r : reduzido;
  }));
  const [ hover, setHover ] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("menu-reduzido", reduzido.toString());
  }, [reduzido]);

  return (
    <div className="menu-principal" style={{ ...styles.menuContainer, ...( reduzido ? styles.menuContainerReduzido : null) }}>
      <aside
        className="menu"
        style={{ ...styles.menu, ...( reduzido && !hover ? styles.menuReduzido : null) }}
        onMouseEnter={() => reduzido && setHover(true)}
        onMouseLeave={() => reduzido && setHover(false)}
      >
        {
          useMemo(() => (
            <Icon id="CollapseMenu" style={styles.menuIcone} iconName="CollapseMenu" onClick={() => setReduzido(!reduzido)} />
          ), [reduzido])
        }
        {
          useMemo(() => (
            <>
              {
                props.itens?.map((i, index) => (
                  <MenuItem key={`menu-${index}`} styles={styles} {...i}  />
                ))
              }
            </>
          ), [props.itens])
        }
      </aside>
    </div>
  )
}

export default Menu;
