/* eslint no-extend-native: ["error", { "exceptions": ["Object"] }] */
export { KeyboardCodes } from "./KeyboardCodes";
export { MergeSort } from "./MargeSort";

Object.defineProperty(Object.prototype, "chain", {
  value(f?: (previous: any) => any) {
    return f ? f(this) : this;
  },
});

export const parseMotionDurations = (duration: string): number => parseInt(
  duration.replace(/([\d.]+)(\D+)/, (s: string, g1: number, g2: string): any => g1 * ({ ms: 1, s: 1000, m: 60000 } as any)[g2])
);

export const aplicarTransparencia = (color: string, opacidade: number) => {
  const [r, g, b]: any = (color[0] === "#") ?
    (color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b) as any).substring(1).match(/.{2}/g).map((x: string) => parseInt(x, 16)) :
    color.match(/(\d+)/g)?.map((e) => parseInt(e, 2));

  return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
}

export const validarCPF = (cpf: string | number | undefined): boolean | undefined => {
  const _cpf = cpf?.toString().replace(/\D/g, "");

  if (_cpf == null) {
    return undefined;
  }
  if (_cpf.length !== 11) {
    return undefined;
  }
  if ((_cpf === '00000000000') || (_cpf === '11111111111') || (_cpf === '22222222222') || (_cpf === '33333333333') || (_cpf === '44444444444') || (_cpf === '55555555555') || (_cpf === '66666666666') || (_cpf === '77777777777') || (_cpf === '88888888888') || (_cpf === '99999999999')) {
    return undefined;
  }
  let numero: number = 0;
  let caracter: string = '';
  let numeros: string = '0123456789';
  let j: number = 10;
  let somatorio: number = 0;
  let resto: number = 0;
  let digito1: number = 0;
  let digito2: number = 0;
  let _cpfAux: string = '';
  _cpfAux = _cpf.substring(0, 9);
  for (let i: number = 0; i < 9; i++) {
    caracter = _cpfAux.charAt(i);
    if (numeros.search(caracter) === -1) {
      return undefined;
    }
    numero = Number(caracter);
    somatorio = somatorio + (numero * j);
    j--;
  }
  resto = somatorio % 11;
  digito1 = 11 - resto;
  if (digito1 > 9) {
    digito1 = 0;
  }
  j = 11;
  somatorio = 0;
  _cpfAux = _cpfAux + digito1;
  for (let i: number = 0; i < 10; i++) {
    caracter = _cpfAux.charAt(i);
    numero = Number(caracter);
    somatorio = somatorio + (numero * j);
    j--;
  }
  resto = somatorio % 11;
  digito2 = 11 - resto;
  if (digito2 > 9) {
    digito2 = 0;
  }
  _cpfAux = _cpfAux + digito2;
  return _cpf === _cpfAux;
}
