import React, { useState, useEffect } from "react";
import { DetailsList, Icon, IDatePickerProps, Label, PrimaryButton, SelectionMode, Stack, DefaultButton, IconButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { FileIconType, getFileTypeIconProps } from '@uifabric/file-type-icons';

import { useTranslation, UseTranslationResponse } from "../../i18n";
import { classNames, styles } from "./styles";
import { Tabela } from "../Tabela";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Objeto_EmpresaObjeto_TipoArquivo } from "../../api";
import * as EmpresaStore from "../../store/Empresa";

export interface IDataSeletor extends IDatePickerProps {
  arquivos?: Array<File>;
  removerArquivo?: (arquivo: File) => void;
}

const Documentos: React.FC<IDataSeletor> = (props) => {
  const { t }: UseTranslationResponse = useTranslation();
  const dropdownStyles = { dropdown: { width: 150 } };
  const {
    empresa: {
      tipoArquivo
    }
  } = useSelector((store: ApplicationState) => store);
  const [tipoDocumento, definirTipoDocumento] = useState<Objeto_EmpresaObjeto_TipoArquivo.Resposta | any>();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(EmpresaStore.actions.obterTipoArquivo()) 
  }, [dispatch]);

  console.log('tipoDocumento', tipoDocumento);

  return (
    <div style={styles.root}>
      <Stack tokens={{ childrenGap: "1em" }} style={{ flex: 1 }}>
        <Tabela
          cabecalhoFixo
          className={classNames.tabela}
          items={props.arquivos || []}
          selectionMode={SelectionMode.none}
          columns={[
            {
              key: 'tipo',
              name: t("tipo-arquivo"),
              className: classNames.iconeArquivoCelula,
              iconClassName: classNames.iconeArquivoCabecalho,
              ariaLabel: t("tipo-arquivo"),
              iconName: 'Page',
              isIconOnly: true,
              fieldName: 'tipo',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: File) => {
                return <Icon {...getFileTypeIconProps({ extension: item.name.split(".").pop(), size: 16, imageFileType: 'svg' }) } />;
              },
            },
            {
              key: "arquivo",
              name: t("nome-arquivo"),
              ariaLabel: t("nome-arquivo"),
              fieldName: "name",
              minWidth: 200,
            },
            {
              key: 'tipoArquivo',
              name: t("tipo"),
              fieldName: "tipoArquivo",
              className: classNames.iconeArquivoCelula,
              iconClassName: classNames.iconeArquivoCabecalho,
              ariaLabel: t("tipo-arquivo"),
              minWidth: 150,
              onRender: (item: File) => {
                return <Dropdown
                placeholder="Selecione"             
                styles={dropdownStyles}
                options={tipoArquivo?.map(e => ({ key: e.CD_TIPO_ARQUIVO as string, text: e.DS_TIPO_ARQUIVO as string })) || []}
                selectedKey={tipoDocumento?.CD_TIPO_ARQUIVO}
                onChange={(_, item?: IDropdownOption) => definirTipoDocumento(item?.key as string) }
                required
              />;
              },
            },
            {
              key: 'remover',
              name: t("remover"),
              className: classNames.iconeArquivoCelula,
              iconClassName: classNames.iconeArquivoCabecalho,
              ariaLabel: t("remover-arquivo"),
              minWidth: 92,
              maxWidth: 92,
              onRender: (item: File) => {
                return <IconButton
                  ariaLabel={t('remover-arquivo')}
                  color={"red"}
                  iconProps={{ iconName: 'ChromeClose', style: styles.iconeRemover }}
                  onClick={() => {
                    props.removerArquivo?.call(null, item);
                  }} />;
              },
            },
          ]}
        />
        <Stack>
          <Label>{t("atencao")}!</Label>
          <Label style={{ fontWeight: "normal" }}>{t("nome-arquivo-validacao")}</Label>
          <Label style={{ fontWeight: "normal" }}>{t("tamanho-arquivo-validacao")}</Label>
        </Stack>
      </Stack>
    </div>
  );
}

export default Documentos;
