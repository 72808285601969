import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  PrimaryButton,
  ComboBox,
  Dropdown,
  IDropdownOption,
  MaskedTextField, Checkbox, Pivot, PivotItem, ActionButton, TooltipHost, FontIcon, Spinner
} from "@fluentui/react";
// @ts-ignore
import { getAllStates, getStateCities } from "easy-location-br";
import { UseTranslationResponse, useTranslation } from "../../i18n";

import { getStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_BeneficiarioObjeto_DadosCadastrais,
  Objeto_BeneficiarioObjeto_Dependente,
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_ServicoObjeto_SolicitacaoWeb,
  Objeto_Servico_Endereco,
  Objeto_Servico_Municipios
} from "../../api";
import * as BeneficiarioStore from "../../store/Beneficiario";
import * as ServicoStore from "../../store/Servico";
import { useId } from "@uifabric/react-hooks";
import { classNames } from "../DesligamentoBeneficiario/styles";
import { DataSeletor } from "../../components/DataSeletor";
import { Documentos } from "../../components/Documentos";
import { validarCPF } from "../../utils";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";
import * as EmpresaStore from "../../store/Empresa";

export enum PivotItens {
  IDENTIFICACAO = "IDENTIFICACAO",
  ENDERECO = "ENDERECO",
  OBSERVACAO = "OBSERVACAO",
}

interface IIdentificacao {
  nome?: string | undefined,
  mae?: string | undefined,
  estadoCivil?: string | undefined,
  conjugue?: string | undefined,
  email?: string | undefined,
  identidade?: string | undefined,
  paisEmissor?: string | undefined,
  orgaoEmissor?: string | undefined,
  cpf?: string | undefined,
}

interface IEndereco {
  cep?: string | undefined,
  logradouro?: string | undefined,
  numero?: number | undefined,
  sn?: boolean | undefined,
  complemento?: string | undefined,
  bairro?: string | undefined,
  zona?: string | undefined,
  cidade?: number | undefined,
  uf?: number | undefined,
  telefone?: string | undefined,
  celular?: string | undefined,
}

type UF = Omit<Objeto_Servico_Municipios.Resposta, "SGEMUNIC_ID_SGEMUNIC" | "SGEMUNIC_NOME">;

const AlterarDadosCadastrais: React.FC = () => {
  const dispatch = useDispatch<any>();
  const {
    autenticacao,
    beneficiario: {
      familia,
    },
    servico: {
      paises,
      municipios,
    }
  } = useSelector((store: ApplicationState) => store);
  const usuario = autenticacao.usuario as Objeto_EmpresaObjeto_Beneficiario.Resposta;

  const styles = getStyles();

  const tooltipId = useId('tooltip');
  const semNumeroFieldId = useId('semNumeroFieldId');

  const [ufs, definirUFs] = useState<Array<UF>>([]);

  const [pivotKey, setPivotKey] = useState<PivotItens>(PivotItens.IDENTIFICACAO);

  const [beneficiarios, definirBeneficiarios] = useState<Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta | undefined>();

  const [sn, definirSn] = useState(false);

  const [identificacao, definirIdentificacao] = useState<IIdentificacao>({});
  const [endereco, definirEndereco] = useState<IEndereco>();
  const [observacao, definirObservacao] = useState<string>();

  const { t }: UseTranslationResponse = useTranslation();

  useEffect(() => {
    dispatch(BeneficiarioStore.actions.obterFamilia()).then((familia: Array<Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta>) => {
      const usuarioAutenticado = familia.find(e => e.SCACARTE_CODIGO_CART === usuario.SCACARTE_CODIGO_CART) as Objeto_BeneficiarioObjeto_DadosCadastrais.Resposta;

      definirBeneficiarios([...(usuarioAutenticado?.SCADEPEN_DESCRICAO === "TITULAR" ? familia : [usuarioAutenticado])]);
      definirBeneficiarioSelecionado(usuarioAutenticado);
    });
    dispatch(ServicoStore.actions.obterPaises());
    dispatch(ServicoStore.actions.obterMunicipios()).then((m: Array<Objeto_Servico_Municipios.Resposta>) => {
      definirUFs(m?.reduce((acc: Array<UF>, { ID_SGEUNIFE, SGEUNIFE_COD_UNIDFED, SGEUNIFE_NOME }) => {
        return acc.some((i: Objeto_Servico_Municipios.Resposta) => i.ID_SGEUNIFE === ID_SGEUNIFE) ? acc : acc.concat({ ID_SGEUNIFE, SGEUNIFE_COD_UNIDFED, SGEUNIFE_NOME })
      }, []) || []);
    });
  }, []);

  useEffect(() => {
    if (beneficiarioSelecionado && paises?.length && municipios?.length && ufs.length) {
      definirIdentificacao({
        identidade: beneficiarioSelecionado.SCABENEF_RG,
        cpf: beneficiarioSelecionado.SCABENEF_CPF,
        nome: beneficiarioSelecionado.SGEPESS_NOME,
        mae: beneficiarioSelecionado.SGEPESS_FNOME_MATERNO,
        estadoCivil: beneficiarioSelecionado.SCABENEF_ESTADO_CIVIL,
        orgaoEmissor: beneficiarioSelecionado.SGEPESS_FORGAO_RG,
        email: beneficiarioSelecionado.SGEENDER_EMAIL,
        paisEmissor: beneficiarioSelecionado.ID_SGEPAIS?.toString(),
        conjugue: ""
      });
      definirEndereco({
        cidade: municipios?.find(m => m.SGEMUNIC_NOME === beneficiarioSelecionado.SGEMUNIC_NOME)?.ID_SGEMUNIC,
        celular: beneficiarioSelecionado.SGEENDER_CELULAR?.replace(/^/, "000")?.substr(-11) || "",
        telefone: beneficiarioSelecionado.SGEENDER_FONE?.replace(/^/, "000")?.substr(-10) || "",
        zona: beneficiarioSelecionado.CD_ZONA,
        bairro: beneficiarioSelecionado.SGEENDER_BAIRRO,
        complemento: beneficiarioSelecionado.SGEENDER_COMP_ENDERECO,
        numero: parseInt(beneficiarioSelecionado.SGEENDER_NRO_IMOVEL || ""),
        logradouro: beneficiarioSelecionado.SGEENDER_ENDERECO,
        cep: beneficiarioSelecionado.SGEENDER_CEP,
        sn: !beneficiarioSelecionado.SGEENDER_NRO_IMOVEL,
        uf: ufs.find(uf => uf.SGEUNIFE_COD_UNIDFED === beneficiarioSelecionado.SGEUNIFE_COD_UNIDFED)?.ID_SGEUNIFE,
      });
    }
  }, [beneficiarioSelecionado, paises, municipios, ufs]);

  const validacao = {
    identificacao_nome: identificacao?.nome
    ,identificacao_mae: identificacao?.mae
    ,identificacao_cpf: validarCPF(identificacao?.cpf)
    ,endereco_cep: endereco?.cep
    ,endereco_logradouro: endereco?.logradouro
    ,n: (endereco?.numero || endereco?.sn)
    ,endereco_bairro: endereco?.bairro
    ,endereco_uf: endereco?.uf
    ,endereco_cidade: endereco?.cidade
  };

  const validacaoErros = {
    identificacao_nome: t("nome-segurado")
    ,identificacao_mae: "Nome da mãe" // t("mae")
    ,identificacao_cpf: t("cpf")
    ,endereco_cep: t("cep")
    ,endereco_logradouro: t("endereco")
    ,n: "Número da residência"
    ,endereco_bairro: t("bairro")
    ,endereco_uf: t("uf")
    ,endereco_cidade: t("cidade")
  };

  const validacoesErrosArray = Object.entries(validacao).filter(([chave, valor]) => valor === undefined || valor === "").map(([chave]) => (validacaoErros as any)[chave]);

  const botaoSolicitacaoWeb = useMemo(() => (
    <BotaoSolicitacaoWeb
      aria-describedby={tooltipId}
      disabled={!!validacoesErrosArray.length}
      text={t("salvar")}
      iconProps={{ iconName: "Save" }}
      onClick={() => (
        dispatch(BeneficiarioStore.actions.solicitarAlteracaoBeneficiario({
          TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao["ALTERACAO_BENEFICIARIO"],
          TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
          CD_USUARIO_CRIACAO: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
          ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
          ID_SGEUNID: beneficiarioSelecionado?.CD_MULTI_EMPRESA as number,

          DS_OBSERVACAO: observacao as string,
          NM_SEGURADO: identificacao.nome as string,
          CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
          NM_MAE: identificacao.mae as string,
          NR_CPF: identificacao?.cpf as string, // Numeros apenas // Validar CPF
          DT_NASCIMENTO: beneficiarioSelecionado?.SGEPESS_FDATA_NASCIMENT as Date,
          DS_EMAIL: identificacao.email as string,
          NR_CEP: endereco?.cep as string,
          DS_ENDERECO: endereco?.logradouro as string,
          NR_ENDERECO: endereco?.numero as number,
          DS_COMPLEMENTO: endereco?.complemento as string,
          DS_BAIRRO: endereco?.bairro as string,
          CD_ZONA: null,
          NM_CIDADE: municipios?.find(e => e.ID_SGEMUNIC === endereco?.cidade)?.SGEMUNIC_NOME as string,
          NM_UF: ufs.find(e => e.ID_SGEUNIFE === endereco?.uf)?.SGEUNIFE_COD_UNIDFED as string || "",
          NR_CELULAR: endereco?.celular as string,
          NR_TELEFONE: endereco?.telefone as string,
          CD_USUARIO_ALTERACAO: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
        }))
      )}
    />
  ), [beneficiarioSelecionado, dispatch, endereco, identificacao, municipios, observacao, t, tooltipId, ufs, validacoesErrosArray.length]);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("alterar-dados-cadastrais")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Stack style={{ marginTop: "1em" }}>
          <Dropdown
            style={{ flex: 1 }}
            selectedKey={beneficiarioSelecionado?.SCACARTE_CODIGO_CART}
            onChange={(_, item) => definirBeneficiarioSelecionado(familia?.find(e => e.SCACARTE_CODIGO_CART === item?.key as string))}
            label={t("selecione-um-beneficiario")}
            options={beneficiarios?.filter(e => e.TP_SITUACAO === "ATIVO").map(e => ({ key: e.SCACARTE_CODIGO_CART as string, text: e.SGEPESS_NOME as string })) || []}
          />
        </Stack>

        {(!beneficiarioSelecionado || !paises || !municipios || !ufs) ? (
          <div style={{ margin: "2em" }}>
            <Spinner label={t("carregando-dados-cadastro")} ariaLive="assertive" labelPosition="left" />
          </div>
        ) : (
          <Stack style={{ paddingTop: "1em", paddingBottom: "2em", backgroundColor: "#fff", flex: 1 }}>
            <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
              <Pivot
                selectedKey={pivotKey}
                onLinkClick={(item: any): void => setPivotKey(item.props.itemKey)}>
                <PivotItem itemKey={PivotItens.IDENTIFICACAO} headerText={t("identificacao")} style={styles.pivotItem} />
                <PivotItem itemKey={PivotItens.ENDERECO} headerText={t("endereco")} style={styles.pivotItem} />
                <PivotItem itemKey={PivotItens.OBSERVACAO} headerText={t("observacao")} style={styles.pivotItem} />
              </Pivot>
            </Stack>

            <Stack style={{ padding: '0 1em 1em 1em', flex: 1 }}>
              {pivotKey === PivotItens.IDENTIFICACAO && (
                <Stack tokens={{ childrenGap: ".5em" }}>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("nome-segurado")}
                      value={identificacao?.nome}
                      onChange={(evt, nome) => definirIdentificacao({ ...identificacao, nome })}
                    />
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("mae")}
                      value={identificacao?.mae}
                      onChange={(evt, mae) => definirIdentificacao({ ...identificacao, mae })}
                    />
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <MaskedTextField
                      required
                      label={t("cpf")}
                      mask="999.999.999-99"
                      value={identificacao?.cpf}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, cpf: texto })}
                    />
                    <TextField
                      styles={styles.textInput}
                      label={t("e-mail")}
                      value={identificacao?.email}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, email: texto })}
                    />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.ENDERECO && (
                <Stack tokens={{ childrenGap: "1em" }}>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <MaskedTextField
                      required
                      value={endereco?.cep}
                      onChange={(_, cep) => {
                        const _cep = cep?.replace(/\D/g, "");

                        (_cep?.length === 8 ? dispatch(ServicoStore.actions.consultarCEP(_cep)).then((e: Objeto_Servico_Endereco.Resposta) => {
                          let _endereco = endereco || {};

                          _endereco.cidade = municipios?.find(m => m.SGEMUNIC_NOME === e.Cidade?.toUpperCase())?.ID_SGEMUNIC;
                          _endereco.bairro = e.Bairro;
                          _endereco.complemento = e.Complemento;
                          _endereco.logradouro = e.Logradouro;
                          _endereco.uf = ufs.find(uf => uf.SGEUNIFE_COD_UNIDFED === e.UF?.toUpperCase())?.ID_SGEUNIFE;

                          return _endereco;
                        }) : Promise.resolve(endereco)).then((e: IEndereco) => definirEndereco({ ...e, cep}));
                      }}
                      styles={styles.textInput}
                      label={t("cep")}
                      mask="99999-999" />
                    <TextField
                      required
                      value={endereco?.logradouro}
                      onChange={(_, logradouro) => definirEndereco({ ...endereco, logradouro })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("endereco")} />
                    <TextField
                      required={!endereco?.sn}
                      disabled={endereco?.sn}
                      value={endereco?.numero?.toString()}
                      onChange={(_, numero) => definirEndereco({ ...endereco, numero: parseInt(numero || "")})}
                      styles={styles.textInput}
                      label={t("numero")} />
                    <span>
                    <Label htmlFor={semNumeroFieldId}>{t("sem-numero")}</Label>
                    <Checkbox
                      id={semNumeroFieldId}
                      checked={endereco?.sn} onChange={(_, checked) => definirEndereco({ ...endereco, sn: checked })}
                      styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 6 } }}/>
                    </span>
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      value={endereco?.complemento}
                      onChange={(_, complemento) => definirEndereco({ ...endereco, complemento })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("complemento")} />
                    <TextField
                      value={endereco?.bairro}
                      onChange={(_, bairro) => definirEndereco({ ...endereco, bairro })}
                      required
                      styles={{ ...styles.textInput, root: { flex: 2 } }}
                      label={t("bairro")} />
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      readOnly
                      value={endereco?.zona}
                      onChange={(_, zona) => definirEndereco({ ...endereco, zona })}
                      styles={styles.textInput}
                      label={t("zona")}
                      iconProps={{ iconName: 'Search' }} />
                    <ComboBox
                      autoComplete="on"
                      required
                      styles={{ root: { width: 64 } }}
                      selectedKey={endereco?.uf}
                      onChange={(_, item) => definirEndereco({ ...endereco, uf: item?.key as number})}
                      label={t("uf")}
                      options={ufs.map((e) => ({ key: e.ID_SGEUNIFE as number, text: e.SGEUNIFE_COD_UNIDFED as string }))}
                    />
                    <ComboBox
                      autoComplete="on"
                      required
                      disabled={!endereco?.uf}
                      selectedKey={endereco?.uf ? endereco?.cidade : undefined}
                      onChange={(_, item) => definirEndereco({ ...endereco, cidade: item?.key as number })}
                      label={t("cidade")}
                      options={(municipios || []).filter(e => e.ID_SGEUNIFE === endereco?.uf).map((e) => ({ key: e.ID_SGEMUNIC as number, text: e.SGEMUNIC_NOME as string }))}
                    />
                    <MaskedTextField
                      value={endereco?.telefone}
                      onChange={(_, telefone) => definirEndereco({ ...endereco, telefone })}
                      label={t("telefone")}
                      mask="(99) 9999-9999" />
                    <MaskedTextField
                      value={endereco?.celular}
                      onChange={(_, celular) => definirEndereco({ ...endereco, celular })}
                      label={t("celular")}
                      mask="(99) 9.9999-9999" />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.OBSERVACAO && (
                <TextField
                  label="Descrever"
                  multiline
                  rows={3}
                  value={observacao}
                  onChange={(_, texto) => definirObservacao(texto)}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>

      <div style={{ flex: 1 }} />

      <Stack.Item align="end">
        {!!validacoesErrosArray.length ? (
          <TooltipHost
            id={tooltipId}
            calloutProps={{
              gapSpace: 0,
            }}
            tooltipProps={{
              onRenderContent: () => (
                <ul style={{ margin: 10, padding: 0 }}>
                  <li style={{ display: "flex", color: "#e81123" }}>
                    <FontIcon iconName="Error" style={{
                      marginRight: 8,
                    }} />
                    Os seguintes campos são inválidos:
                  </li>
                  {Object.entries(validacao).filter(([chave, valor]) => valor === undefined || valor === "").map(([chave]) => (validacaoErros as any)[chave]).map(e => {
                    return <li key={e}>{e}</li>
                  })}
                </ul>
              )
            }}
          >
            {botaoSolicitacaoWeb}
          </TooltipHost>
        ) : (
          botaoSolicitacaoWeb
        )}
      </Stack.Item>
    </div>
  );
}

export default AlterarDadosCadastrais;
