import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  MaskedTextField,
  ComboBox,
  Pivot,
  PivotItem,
  Dropdown,
  IDropdownOption, ITextField, ActionButton, Checkbox, IColumn, SelectionMode, Spinner, TooltipHost, FontIcon
} from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";

import { UseTranslationResponse, useTranslation } from "../../i18n";
import * as ServicoStore from "../../store/Servico";
import { getStyles } from "./styles";
import { DataSeletor } from "../../components/DataSeletor";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import {
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_BeneficiarioCadastro,
  Objeto_EmpresaObjeto_Login,
  Objeto_ServicoObjeto_SolicitacaoWeb,
  Objeto_Servico_Banco,
  Objeto_Servico_Endereco, Objeto_Servico_Municipios,
} from "../../api";
import * as EmpresaStore from "../../store/Empresa";
import { BeneficiarioPesquisaModal } from "../../components/BeneficiarioPesquisaModal";
import { Documentos } from "../../components/Documentos";
import { classNames } from "../DesligamentoBeneficiario/styles";
import { PesquisaModal } from "../../components/PesquisaModal";
import { validarCPF } from "../../utils";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";
import { backgroundColor } from "html2canvas/dist/types/css/property-descriptors/background-color";

export enum PivotItens {
  IDENTIFICACAO = "IDENTIFICACAO",
  DADOS_BANCARIOS = "DADOS_BANCARIOS",
  ENDERECO = "ENDERECO",
  ENDERECO_COMERCIAL = "ENDERECO_COMERCIAL",
  DOCUMENTOS = "DOCUMENTOS",
  OBSERVACAO = "OBSERVACAO",
}

interface IDadosGerais {
  nome?: string | undefined,
  tipo?: string | undefined,
  titular?: number | undefined,
  adesao?: Date | undefined,
  admissao?: Date | undefined,
  empresa?: string | undefined,
  plano?: string | undefined,
  categoria?: number | undefined,
}

interface IIdentificacao {
  pai?: string | undefined,
  mae?: string | undefined,
  sexo?: string | undefined,
  estadoCivil?: string | undefined,
  parentesco?: string | undefined,
  nascidoVivo?: string | undefined,
  nascimento?: Date | undefined,
  conjugue?: string | undefined,
  pis_pasep?: string | undefined,
  cpf?: string | undefined,
  email?: string | undefined,
  identidade?: string | undefined,
  paisEmissor?: string | undefined,
  orgaoEmissor?: string | undefined,
  cns?: string | undefined,
}

interface IDadosBancarios {
  banco?: number | undefined,
  agencia_n?: string | undefined,
  agencia?: string | undefined,
  conta?: string | undefined,
}

interface IEndereco {
  cep?: string | undefined,
  logradouro?: string | undefined,
  numero?: number | undefined,
  sn?: boolean | undefined,
  complemento?: string | undefined,
  bairro?: string | undefined,
  zona?: string | undefined,
  cidade?: number | undefined,
  uf?: number | undefined,
  telefone?: string | undefined,
  celular?: string | undefined,
}

enum TipoTela {
  CADASTRO = 0,
  ALTERACAO = 1,
}

type UF = Omit<Objeto_Servico_Municipios.Resposta, "SGEMUNIC_ID_SGEMUNIC" | "SGEMUNIC_NOME">;

const InclusaoBeneficiario: React.FC = () => {
  const styles = getStyles();

  const { t }: UseTranslationResponse = useTranslation();

  const arquivoInput = useRef<HTMLInputElement>(null);
  const matriculaTFRef = useRef<ITextField>(null);

  const tooltipId = useId('tooltip');
  const semNumeroFieldId = useId('semNumeroFieldId');

  const [pivotKey, setPivotKey] = useState<PivotItens>(PivotItens.IDENTIFICACAO);

  const [ufs, definirUFs] = useState<Array<UF>>([]);

  const [planosDistinto, definirPlanosDistinto] = useState<any>();
  const [planoAtivo, definirPlanoAtivo] = useState<string>();

  const [titularModalAberto, definirTitularModalAberto] = useState<boolean>();
  const [titularSelecionado, definirTitularSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [beneficiarioModalAberto, definirBeneficiarioModalAberto] = useState<boolean>();
  const [beneficiarioSelecionado, definirBeneficiarioSelecionado] = useState<Objeto_EmpresaObjeto_Beneficiario.Resposta | undefined>();

  const [dadosGerais, definirDadosGerais] = useState<IDadosGerais>({
    adesao: new Date(),
    admissao: new Date(),
    tipo: "T",
  });
  const [identificacao, definirIdentificacao] = useState<IIdentificacao>({});
  const [dadosBancarios, definirDadosBancarios] = useState<IDadosBancarios>({});
  const [endereco, definirEndereco] = useState<IEndereco>();
  const [enderecoComercial, definirEnderecoComercial] = useState<IEndereco>();
  const [documentos, definirDocumentos] = useState<Array<File>>([]);
  const [observacao, definirObservacao] = useState<string>();

  const [bancoModalAberto, definirBancoModalAberto] = useState<boolean>();
  const [bancoSelecionado, definirBancoSelecionado] = useState<Objeto_Servico_Banco.Resposta | undefined>();

  const [dadosCadastrais, definirDadosCadastrais] = useState<Objeto_EmpresaObjeto_BeneficiarioCadastro.Resposta>();

  // eslint-disable-next-line no-restricted-globals
  const [tipoTela] = useState<TipoTela>(location.pathname.indexOf("/inclusao-beneficiario") === 0 ? 0 : 1);

  const dispatch = useDispatch<any>();
  const {
    autenticacao,
    empresa: {
      planos,
      beneficiarios,
      contratoAtivo,
      categoriaDependente
    },
    servico: {
      parentescos,
      paises,
      municipios,
      bancos,
    }
  } = useSelector((store: ApplicationState) => store);
  const usuario: Objeto_EmpresaObjeto_Login.Resposta | undefined = autenticacao.usuario;

  useEffect(() => {
    dispatch(ServicoStore.actions.obterParentescos());
    dispatch(ServicoStore.actions.obterPaises());
    dispatch(ServicoStore.actions.obterMunicipios()).then((m: Array<Objeto_Servico_Municipios.Resposta>) => {
      definirUFs(m?.reduce((acc: Array<UF>, { ID_SGEUNIFE, SGEUNIFE_COD_UNIDFED, SGEUNIFE_NOME }) => {
        return acc.some((i: Objeto_Servico_Municipios.Resposta) => i.ID_SGEUNIFE === ID_SGEUNIFE) ? acc : acc.concat({ ID_SGEUNIFE, SGEUNIFE_COD_UNIDFED, SGEUNIFE_NOME })
      }, []) || []);
    });
    dispatch(ServicoStore.actions.obterBancos());
    dispatch(EmpresaStore.actions.obterCategoriaDependente());
  }, []);

  useEffect(() => {
    const p = planos.filter(p => p.SCACONTR_CODIGO === contratoAtivo).map(e => ({ key: e.SGEPLANO_DESCRICAO, text: e.SGEPLANO_DESCRICAO })) as Array<IDropdownOption>;

    definirPlanosDistinto(p);

    if (p.length) {
      definirPlanoAtivo(p[0]?.key as string);
      definirDadosGerais({
        ...dadosGerais,
        plano: planos[0]?.SGEPLANO_CODIGO as string,
        empresa: planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.SCAEMPBA_CODIGO
      });
    }
  }, [planos, contratoAtivo]);

  useEffect(() => {
    definirDadosGerais({ ...dadosGerais, titular: titularSelecionado?.ID_SCACONBE });
  }, [titularSelecionado]);

  useEffect(() => {
    dadosGerais.tipo === "T" && definirTitularSelecionado(undefined);
  }, [dadosGerais.tipo]);

  useEffect(() => {
    beneficiarioSelecionado
    && beneficiarioSelecionado?.ID_SCABENEF
    && dispatch(EmpresaStore.actions.obterDadosCadastrais(beneficiarioSelecionado?.ID_SCABENEF)).then((dados: Objeto_EmpresaObjeto_BeneficiarioCadastro.Resposta) => {
      definirDadosCadastrais(dados);
      definirDadosGerais({
        ...dadosGerais,
        adesao: beneficiarioSelecionado.SCACONBE_INICIO_VIGENCIA,
        admissao: dados.SCACONBE_INICIO_VIGENCIA,
        nome: dados.SGEPESS_NOME,
        tipo: beneficiarioSelecionado.SCADEPEN_QUALIFICACAO,
        titular: beneficiarios.find(e => e.SGEFAMIL_CODIGO === "00010150" && e.SCADEPEN_QUALIFICACAO === "T")?.ID_SCABENEF,
      });
      
      definirTitularSelecionado(beneficiarios.find(e => e.SGEFAMIL_CODIGO === beneficiarioSelecionado.SGEFAMIL_CODIGO && e.SCADEPEN_QUALIFICACAO === "T"));
      definirIdentificacao({
        pai: dados.SGEPESS_NM_PATERNO,
        mae: dados.SGEPESS_FNOME_MATERNO,
        sexo: dados.SEXO,
        estadoCivil: dados.SCABENEF_ESTADO_CIVIL,
        parentesco: dados.SCADEPEN_TIPO?.toString(),
        nascidoVivo: dados.SCABENEF_DECLARACAO_NASC,
        nascimento: dados.SCABENEF_DATA_NASC,
        //conjugue: dados.CO
        pis_pasep: (dados.SCABENEF_PIS || "").toString(),
        cpf: dados.SCABENEF_CPF,
        email: dados.SGEENDER_EMAIL,
        identidade: dados.SCABENEF_RG,
        paisEmissor: dados.ID_SGEPAIS?.toString(),
        orgaoEmissor: dados.SGEPESS_FORGAO_RG,
        cns: dados.SCABENEF_CNS,
      });
      definirDadosBancarios({
        banco: dados.SCABENCC_ID_SGEBANCO,
        agencia_n: dados.SCABENCC_NUM_AGENCIA,
        agencia: dados.SCABENCC_NOME_AGENCIA,
        conta: dados.SCABENCC_NUM_CONTA,
      });
      definirEndereco({
        cep: dados.SGEENDER_CEP,
        logradouro: dados.SGEENDER_ENDERECO,
        numero: parseInt(dados.SGEENDER_NRO_IMOVEL || ""),
        complemento: dados.SGEENDER_COMP_ENDERECO,
        bairro: dados.SGEENDER_BAIRRO,
        zona: dados.ZONA,
        uf: ufs.find(uf => uf.SGEUNIFE_COD_UNIDFED === dados.SGEUNIFE_COD_UNIDFED)?.ID_SGEUNIFE,
        cidade: municipios?.find(m => m.SGEMUNIC_NOME === dados.SGEMUNIC_NOME)?.ID_SGEMUNIC,
        telefone: dados.SGEENDER_FONE?.replace(/^/, "000")?.substr(-10) || "",
        celular: dados.SGEENDER_CELULAR?.replace(/^/, "000")?.substr(-11) || "",
      })
    })
  }, [beneficiarioSelecionado]);

  const validacao = {
    dadosGerais_nome: dadosGerais?.nome
    ,dadosGerais_adesao: dadosGerais?.adesao
    ,dadosGerais_admissao: dadosGerais?.admissao
    //,dadosGerais_empresa: dadosGerais?.empresa
    //,dadosGerais_plano: dadosGerais?.plano
    ,t: (dadosGerais?.tipo === "T" || dadosGerais?.titular)
    ,identificacao_mae: identificacao?.mae
    ,identificacao_sexo: identificacao?.sexo
    ,identificacao_estadoCivil: identificacao?.estadoCivil
    ,identificacao_parentesco: identificacao?.parentesco
    ,identificacao_nascimento: identificacao?.nascimento
    ,identificacao_cpf: validarCPF(identificacao?.cpf)
    ,identificacao_identidade: identificacao?.identidade
    ,identificacao_paisEmissor: identificacao?.paisEmissor
    ,identificacao_cns: identificacao?.cns
    ,endereco_cep: endereco?.cep
    ,n: (endereco?.numero || endereco?.sn)
    ,endereco_bairro: endereco?.bairro
    ,endereco_uf: endereco?.uf
    ,endereco_cidade: endereco?.cidade
    ,identificacao_email: identificacao?.email
  };

  const validacaoErros = {
    dadosGerais_nome: t("nome-segurado")
    ,dadosGerais_adesao: t("admissao")
    ,dadosGerais_admissao: t("adesao")
    //,dadosGerais_empresa: t("empresa")
    //,dadosGerais_plano: t("plano")
    ,t: t("titular")
    ,identificacao_mae: "Nome da mãe" // t("mae")
    ,identificacao_sexo: t("sexo")
    ,identificacao_estadoCivil: t("estado-civil")
    ,identificacao_parentesco: t("parentesco")
    ,identificacao_nascimento: "Data de Nascimento"
    ,identificacao_cpf: t("cpf")
    ,identificacao_identidade: t("identidade")
    ,identificacao_paisEmissor: t("pais-emissor")
    ,identificacao_cns: "CNS - Cartão Nacional de Saúde"
    ,endereco_cep: t("cep")
    ,n: "Número da residência"
    ,endereco_bairro: t("bairro")
    ,endereco_uf: t("uf")
    ,endereco_cidade: t("cidade")
    ,identificacao_email: "E-mail"
  };

  const validacoesErrosArray = Object.entries(validacao).filter(([chave, valor]) => valor === undefined || valor === "").map(([chave]) => (validacaoErros as any)[chave]);
  console.log('pis', identificacao.pis_pasep?.replace('.', '').replace('/',''));
  const botaoSolicitacaoWeb = useMemo(() => (
    <BotaoSolicitacaoWeb
      aria-describedby={tooltipId}
      disabled={!!validacoesErrosArray.length}
      text={t("salvar")}
      iconProps={{ iconName: "Save" }}
      onClick={() => {
        if (tipoTela === TipoTela.CADASTRO) {
          return dispatch(EmpresaStore.actions.solicitarInclusaoBeneficiario({
            TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao[dadosGerais?.tipo === "T" ? "CADASTRO_TITULAR" : "CADASTRO_DEPENDENTE"],
            TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
            CD_USUARIO_CRIACAO: usuario?.SCAEMPBA_NOME as string,
            ID_SCACONTR: planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.SCACONTR_ID_SCACONTR as number,
            ID_SGEUNID: usuario?.ID_SGEUNID as number,
            NM_SEGURADO: dadosGerais.nome?.toUpperCase() as string,
            SN_TITULAR: dadosGerais.tipo === "T" ? "S" : "N",
            TP_USUARIO: dadosGerais.tipo as string,
            DT_ADESAO: dadosGerais.adesao as Date,
            DT_ADMISSAO: dadosGerais.admissao as Date,
            CD_SOLICITACAO_TITULAR: dadosGerais.tipo === "T" ? null : dadosGerais.titular as number, // Titular
            CD_BANCO: dadosBancarios.banco as number,
            CD_PAIS_EMISSOR_CARTEIRA: identificacao.paisEmissor as string,
            CD_PIS_PASEP: identificacao.pis_pasep?.replace('.', '').replace('/','').toString() || "",
            CD_ZONA: null,
            CD_ZONA_COM: null,
            DS_BAIRRO: endereco?.bairro?.toUpperCase() as string,
            DS_BAIRRO_COM: enderecoComercial?.bairro?.toUpperCase() as string,
            DS_COMPLEMENTO: endereco?.complemento?.toUpperCase() as string,
            DS_COMPLEMENTO_COM: enderecoComercial?.complemento?.toUpperCase() as string,
            DS_EMAIL: identificacao.email?.toUpperCase() as string,
            DS_ENDERECO: endereco?.logradouro?.toUpperCase() as string,
            DS_ENDERECO_COM: enderecoComercial?.logradouro?.toUpperCase() as string,
            DS_OBSERVACAO: observacao as string,
            DS_ORGAO_EXPEDITOR: identificacao.orgaoEmissor as string,
            DT_NASCIMENTO: identificacao.nascimento as Date,
            ID_SCAEMPBA: usuario?.ID_SCAEMPBA as number,
            ID_SGEPLANO: planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.ID_SGEPLANO as number,
            NM_AGENCIA: dadosBancarios.agencia as string,
            NM_CIDADE: municipios?.find(e => e.ID_SGEMUNIC === endereco?.cidade)?.SGEMUNIC_NOME as string,
            NM_CIDADE_COM: municipios?.find(e => e.ID_SGEMUNIC === enderecoComercial?.cidade)?.SGEMUNIC_NOME as string,
            NM_MAE: identificacao.mae?.toUpperCase() as string,
            NM_PAI: identificacao.pai?.toUpperCase() as string,
            NM_UF: ufs.find(e => e.ID_SGEUNIFE === endereco?.uf)?.SGEUNIFE_COD_UNIDFED as string || "",
            NM_UF_COM: ufs.find(e => e.ID_SGEUNIFE === enderecoComercial?.uf)?.SGEUNIFE_NOME as string || "",
            NR_AGENCIA: dadosBancarios.agencia_n as string,
            NR_CELULAR: endereco?.celular as string,
            NR_CEP: endereco?.cep as string,
            NR_CEP_COM: enderecoComercial?.cep as string,
            NR_CNS: identificacao.cns as string,
            NR_CPF: identificacao.cpf as string,
            NR_DN: identificacao.nascidoVivo as string,
            NR_ENDERECO: endereco?.numero as number,
            NR_ENDERECO_COM: enderecoComercial?.numero as number,
            NR_RG: identificacao.identidade as string,
            NR_TELEFONE: endereco?.telefone as string,
            NR_TELEFONE_COM: enderecoComercial?.telefone as string,
            TP_ESTADO_CIVIL: identificacao.estadoCivil as any,
            TP_SEXO: identificacao.sexo as any,
            ID_SCADEPEN: identificacao?.parentesco as string,
            CD_MATRICULA_TEM: titularSelecionado?.SCACARTE_CODIGO_CART as string 
          }))
        } else {
          return dispatch(EmpresaStore.actions.solicitarAlteracaoBeneficiario({
            TP_SOLICITACAO: Objeto_ServicoObjeto_SolicitacaoWeb.TipoSolicitacao["ALTERACAO_EMPRESA"],
            TP_STATUS: Objeto_ServicoObjeto_SolicitacaoWeb.TipoStatus.ABERTA,
            CD_USUARIO_CRIACAO: usuario?.SCAEMPBA_NOME as string,
            ID_SCACONTR: beneficiarioSelecionado?.ID_SCACONTR as number,
            ID_SGEUNID: usuario?.ID_SGEUNID as number,
            NM_SEGURADO: dadosGerais.nome?.toUpperCase() as string,
            SN_TITULAR: dadosGerais.tipo === "T" ? "S" : "N",
            TP_USUARIO: dadosGerais.tipo as string,
            CD_MATRICULA: beneficiarioSelecionado?.SCACARTE_CODIGO_CART as string,
            CD_USUARIO_ALTERACAO: usuario?.SGEPESS_JCONTATO as string,
            CD_SOLICITACAO_TITULAR: dadosGerais.tipo === "T" ? null : dadosGerais.titular as number,
            CD_BANCO: dadosBancarios.banco as number,
            CD_PAIS_EMISSOR_CARTEIRA: identificacao.paisEmissor as string,
            CD_PIS_PASEP: identificacao.pis_pasep?.toString() || "",
            CD_ZONA: null,
            CD_ZONA_COM: null,
            DS_BAIRRO: endereco?.bairro?.toUpperCase() as string,
            DS_BAIRRO_COM: enderecoComercial?.bairro?.toUpperCase() as string,
            DS_COMPLEMENTO: endereco?.complemento?.toUpperCase() as string,
            DS_COMPLEMENTO_COM: enderecoComercial?.complemento?.toUpperCase() as string,
            DS_EMAIL: identificacao.email?.toUpperCase() as string,
            DS_ENDERECO: endereco?.logradouro?.toUpperCase() as string,
            DS_ENDERECO_COM: enderecoComercial?.logradouro?.toUpperCase() as string,
            DS_OBSERVACAO: observacao as string,
            DS_ORGAO_EXPEDITOR: identificacao.orgaoEmissor as string,
            DT_NASCIMENTO: identificacao.nascimento as Date,
            ID_SCAEMPBA: usuario?.ID_SCAEMPBA as number,
            ID_SGEPLANO: planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.ID_SGEPLANO as number,
            NM_AGENCIA: dadosBancarios.agencia as string,
            NM_CIDADE: municipios?.find(e => e.ID_SGEMUNIC === endereco?.cidade)?.SGEMUNIC_NOME as string,
            NM_CIDADE_COM: municipios?.find(e => e.ID_SGEMUNIC === enderecoComercial?.cidade)?.SGEMUNIC_NOME as string,
            NM_MAE: identificacao.mae?.toUpperCase() as string,
            NM_PAI: identificacao.pai?.toUpperCase() as string,
            NM_UF: ufs.find(e => e.ID_SGEUNIFE === endereco?.uf)?.SGEUNIFE_COD_UNIDFED as string || "",
            NM_UF_COM: ufs.find(e => e.ID_SGEUNIFE === enderecoComercial?.uf)?.SGEUNIFE_NOME as string || "",
            NR_AGENCIA: dadosBancarios.agencia_n as string,
            NR_CELULAR: endereco?.celular as string,
            NR_CEP: endereco?.cep as string,
            NR_CEP_COM: enderecoComercial?.cep as string,
            NR_CNS: identificacao.cns as string,
            NR_CPF: identificacao.cpf as string,
            NR_DN: identificacao.nascidoVivo as string,
            NR_ENDERECO: endereco?.numero as number,
            NR_ENDERECO_COM: enderecoComercial?.numero as number,
            NR_RG: identificacao.identidade as string,
            NR_TELEFONE: endereco?.telefone as string,
            NR_TELEFONE_COM: enderecoComercial?.telefone as string,
            TP_ESTADO_CIVIL: identificacao.estadoCivil as any,
            TP_SEXO: identificacao.sexo as any,
            ID_SCADEPEN: identificacao?.parentesco as string,
            CD_MATRICULA_TEM: titularSelecionado?.SCACARTE_CODIGO_CART as string
          }))
        }
      }}
    />
  ), [validacoesErrosArray]);

  console.log('doc', documentos);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t(tipoTela === TipoTela.CADASTRO ? "inclusao-beneficiario" : "alterar-dados-cadastrais")}</Label>

      {tipoTela === TipoTela.ALTERACAO ? (
        <Stack style={{ marginTop: "1em" }}>
          <TextField
            required
            readOnly
            autoFocus
            styles={{ root: { flex: 1, minWidth: "16em" } }}
            label={t("selecione-um-beneficiario")} iconProps={{ iconName: 'Search' }}
            value={beneficiarioSelecionado?.SGEPESS_NOME}
            onClick={() => definirBeneficiarioModalAberto(true)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                definirBeneficiarioModalAberto(true);
              }
            }} />
        </Stack>
      ) : null}

      <input ref={arquivoInput} type="file" accept=".jpeg,.jpg,.jpg2,.png,.png2,.gif,.pdf,.doc,.docx,.docx2" style={{ display: "none" }} onChange={(e) => {
        if (e.target.files?.length && e.target.accept.indexOf(e.target.files[0].name.split(".").pop() as string) !== -1) {
          definirDocumentos([...documentos, e.target.files[0]]);
        }
      }} />

      {tipoTela === TipoTela.ALTERACAO && beneficiarioSelecionado && !dadosCadastrais ? (
        <div style={{ margin: "2em" }}>
          <Spinner label={t("carregando-dados-cadastro")} ariaLive="assertive" labelPosition="left" />
        </div>
      ) : null}

      {tipoTela === TipoTela.CADASTRO || dadosCadastrais ? (
        <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <TextField
              required
              label={t("nome-segurado")}
              value={dadosGerais?.nome}
              onChange={(evt, nome) => definirDadosGerais({ ...dadosGerais, nome })}
              styles={{ ...styles.textInput, root: { flex: 2.5 } }}
            />
            {/* <TextField
              readOnly
              //disabled
              styles={styles.textInput}
              label={t("categoria-beneficio")}
              iconProps={{ iconName: 'Search' }}
            /> */}
            {/* <Dropdown
              required
              style={{ flex: 1 }}
              selectedKey={dadosGerais?.categoria}
              onChange={(_, item) => definirDadosGerais({ ...dadosGerais, categoria: item?.key as number })}
              label={t("categoria-beneficio")}
              options={categoriaDependente?.map(e => ({ key: e.ID_SCADEPEN, text: `${e.ID_SCADEPEN} - ${e.DESCRICAO}` })) as Array<IDropdownOption>}
            /> */}
            <Dropdown
              disabled={tipoTela === TipoTela.ALTERACAO}
              required={tipoTela === TipoTela.CADASTRO}
              style={{ flex: 1 }}
              selectedKey={dadosGerais?.tipo}
              onChange={(_, item) => definirDadosGerais({ ...dadosGerais, tipo: item?.key as string })}
              label={t("tipo-beneficiario")}
              options={[
                { key: "T", text: t("titular") },
                { key: "D", text: t("dependente") },
                { key: "A", text: t("agregado") },
              ]}
            />
            {dadosGerais?.tipo !== "T" && (
              <TextField
                componentRef={matriculaTFRef}
                disabled={tipoTela === TipoTela.ALTERACAO}
                required={tipoTela === TipoTela.CADASTRO}
                readOnly
                autoFocus
                styles={{ root: { flex: 1, minWidth: "16em" } }}
                label={t("titular")} iconProps={{ iconName: 'Search' }}
                value={titularSelecionado?.SGEPESS_NOME}
                onClick={() => definirTitularModalAberto(true)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    definirTitularModalAberto(true);
                  }
                }} />
            )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: "1em" }}>
            <DataSeletor
              disabled={tipoTela === TipoTela.ALTERACAO}
              isRequired={tipoTela === TipoTela.CADASTRO}
              label={t("adesao")}
              value={dadosGerais?.adesao}
              onSelectDate={(data) => definirDadosGerais({ ...dadosGerais, adesao: data as Date })}
            />
            <DataSeletor
              disabled={tipoTela === TipoTela.ALTERACAO}
              isRequired={tipoTela === TipoTela.CADASTRO}
              label={t("admissao")}
              value={dadosGerais?.admissao}
              onSelectDate={(data) => definirDadosGerais({ ...dadosGerais, admissao: data as Date })}
            />
            <TextField
              required
              disabled
              readOnly
              value={planos.find(e => e.SCACONTR_CODIGO === contratoAtivo)?.chain((e: any) => `${e.SCAEMPBA_CODIGO} - ${e.SCAEMPBA_NOME}`)}
              styles={styles.textInput}
              label={t("empresa")}
            />
            <Dropdown
              disabled={tipoTela === TipoTela.ALTERACAO}
              required={tipoTela === TipoTela.CADASTRO}
              styles={{ root: { flex: 1 } }}
              label={t("plano")}
              options={planosDistinto}
              selectedKey={planoAtivo}
              onChange={(_, item?: IDropdownOption) => {
                definirPlanoAtivo(item?.key as string)
                definirDadosGerais({ ...dadosGerais, plano: item?.key as string })
              } }
            />
          </Stack>

          <Stack style={{ paddingTop: 16, backgroundColor: "#fff", flex: 1 }}>
            <Stack horizontal style={{ justifyContent: "space-between", alignItems: "center" }}>
              <Pivot
                selectedKey={pivotKey}
                onLinkClick={(item: any): void => setPivotKey(item.props.itemKey)}>
                <PivotItem itemKey={PivotItens.IDENTIFICACAO} headerText={t("identificacao")} style={styles.pivotItem} />
                <PivotItem itemKey={PivotItens.DADOS_BANCARIOS} headerText={t("dados-bancarios")} style={styles.pivotItem} />
                <PivotItem itemKey={PivotItens.ENDERECO} headerText={t("endereco")} style={styles.pivotItem} />
                <PivotItem itemKey={PivotItens.ENDERECO_COMERCIAL} headerText={t("endereco-comercial")} style={styles.pivotItem} />
                {/* <PivotItem itemKey={PivotItens.DOCUMENTOS} headerText={t("documentos")} style={styles.pivotItem} /> */}
                <PivotItem itemKey={PivotItens.OBSERVACAO} headerText={t("observacao")} style={styles.pivotItem} />
              </Pivot>

              {pivotKey === PivotItens.DOCUMENTOS && (
                <ActionButton iconProps={{ iconName: "Add" }} className={classNames.botaoAdicionarDocumento} onClick={() => arquivoInput.current?.click()} >
                  {t("adicionar")}
                </ActionButton>
              )}
            </Stack>

            <Stack style={{ padding: '0 1em 1em 1em', flex: 1 }}>
              {pivotKey === PivotItens.IDENTIFICACAO && (
                <Stack style={{overflow: "auto",height: 210}} tokens={{ childrenGap: ".5em" }}>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      styles={styles.textInput}
                      label={t("pai")}
                      value={identificacao?.pai}
                      onChange={(evt, pai) => definirIdentificacao({ ...identificacao, pai })}
                    />
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("mae")}
                      value={identificacao?.mae}
                      onChange={(evt, mae) => definirIdentificacao({ ...identificacao, mae })}
                    />
                    <Dropdown
                      required
                      style={{ minWidth: 184 }}
                      label={t("sexo")}
                      options={[
                        { key: "M", text: t("masculino") },
                        { key: "F", text: t("feminino") },
                        { key: "I", text: t("indefinido") },
                      ]}
                      selectedKey={identificacao?.sexo}
                      onChange={(_, item) => definirIdentificacao({ ...identificacao, sexo: item?.key as string })}
                    />
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <Dropdown
                      required
                      style={{ minWidth: "12.5em" }}
                      label={t("estado-civil")}
                      options={[
                        { key: "S", text: t("solteiro") },
                        { key: "C", text: t("casado") },
                        { key: "V", text: t("viuvo") },
                        { key: "D", text: t("divorciado") },
                        { key: "I", text: t("desquitado") },
                      ]}
                      selectedKey={identificacao?.estadoCivil}
                      onChange={(_, item) => definirIdentificacao({ ...identificacao, estadoCivil: item?.key as string })}
                    />
                    <Dropdown
                      required
                      style={{ minWidth: "12.5em" }}
                      label={t("parentesco")}
                      options={parentescos?.map(e => ({ key: e.SCADEPEN_TIPO as string, text: e.DS_PARENTESCO as string })) || []}
                      selectedKey={identificacao?.parentesco}
                      onChange={(_, item) => definirIdentificacao({ ...identificacao, parentesco: item?.key as string })}
                    />
                    <TextField
                      styles={styles.textInput}
                      ariaLabel={t("numero-declaracao-nascido-vivo")}
                      description={t("numero-declaracao-nascido-vivo")}
                      label={t("nascido-vivo")}
                      value={identificacao?.nascidoVivo}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, nascidoVivo: texto?.replace(/\D/, "") })}
                    />
                    <DataSeletor
                      isRequired
                      label={t("nascimento")}
                      maxDate={new Date()}
                      value={identificacao?.nascimento}
                      onSelectDate={(data) => definirIdentificacao({ ...identificacao, nascimento: data as Date })}
                    />
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      styles={styles.textInput}
                      label={t("conjugue")}
                      value={identificacao?.conjugue}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, conjugue: texto })}
                    />
                    <MaskedTextField
                      label={t("pis-pasep")}
                      mask="999.99999.99/9"
                      value={(identificacao?.pis_pasep || "").toString()}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, pis_pasep: (texto || "").replace(/\D/, "") })}
                    />
                    <MaskedTextField
                      required
                      label={t("cpf")}
                      mask="999.999.999-99"
                      value={identificacao?.cpf}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, cpf: texto })}
                    />
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("e-mail")}
                      value={identificacao?.email}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, email: texto })}
                    />
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("identidade")}
                      value={identificacao?.identidade}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, identidade: texto })}
                    />
                    <Dropdown
                      required
                      styles={{ root: { flex: 1 } }}
                      label={t("pais-emissor")}
                      options={paises?.map(e => ({ key: e.ID_SGEPAIS?.toString() || "", text: e.SGEPAIS_DESCRICAO as string })) || []}
                      selectedKey={identificacao?.paisEmissor}
                      onChange={(_, item) => definirIdentificacao({ ...identificacao, paisEmissor: item?.key as string })}
                    />
                    <TextField
                      styles={styles.textInput}
                      label={t("orgao-emissor")}
                      value={identificacao?.orgaoEmissor}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, orgaoEmissor: texto })}
                    />
                    <TextField
                      required
                      styles={styles.textInput}
                      label={t("cns")}
                      value={identificacao?.cns}
                      onChange={(evt, texto) => definirIdentificacao({ ...identificacao, cns: texto?.replace(/\D/, "") })}
                    />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.DADOS_BANCARIOS && (
                <Stack tokens={{ childrenGap: ".5em" }}>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      readOnly
                      styles={styles.textInput}
                      label={t("banco")}
                      iconProps={{ iconName: 'Search' }}
                      value={bancoSelecionado?.SGEBANCO_NOME}
                      onClick={() => definirBancoModalAberto(true)}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          definirBancoModalAberto(true);
                        }
                      }} />
                    <TextField
                      styles={styles.textInput}
                      label={t("agencia-n")}
                      value={dadosBancarios?.agencia_n}
                      onChange={(evt, texto) => definirDadosBancarios({ ...dadosBancarios, agencia_n: texto?.replace(/\D/, "") })}
                    />
                    <TextField
                      styles={styles.textInput}
                      label={t("agencia")}
                      value={dadosBancarios?.agencia}
                      onChange={(evt, texto) => definirDadosBancarios({ ...dadosBancarios, agencia: texto })}
                    />
                    <TextField
                      styles={styles.textInput}
                      label={t("conta")}
                      value={dadosBancarios?.conta}
                      onChange={(evt, texto) => definirDadosBancarios({ ...dadosBancarios, conta: texto })}
                    />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.ENDERECO && (
                <Stack tokens={{ childrenGap: "1em" }}>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <MaskedTextField
                      required
                      value={endereco?.cep}
                      onChange={(_, cep) => {
                        const _cep = cep?.replace(/\D/g, "");

                        (_cep?.length === 8 ? dispatch(ServicoStore.actions.consultarCEP(_cep)).then((e: Objeto_Servico_Endereco.Resposta) => {
                          let _endereco = endereco || {};

                          _endereco.cidade = municipios?.find(m => m.SGEMUNIC_NOME === e.Cidade?.toUpperCase())?.ID_SGEMUNIC;
                          _endereco.bairro = e.Bairro;
                          _endereco.complemento = e.Complemento;
                          _endereco.logradouro = e.Logradouro;
                          _endereco.uf = ufs.find(uf => uf.SGEUNIFE_COD_UNIDFED === e.UF?.toUpperCase())?.ID_SGEUNIFE;

                          return _endereco;
                        }) : Promise.resolve(endereco)).then((e: IEndereco) => definirEndereco({ ...e, cep}));
                      }}
                      styles={styles.textInput}
                      label={t("cep")}
                      mask="99999-999" />
                    <TextField
                      required
                      value={endereco?.logradouro}
                      onChange={(_, logradouro) => definirEndereco({ ...endereco, logradouro })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("endereco")} />
                    <TextField
                      required={!endereco?.sn}
                      disabled={endereco?.sn}
                      value={endereco?.numero?.toString()}
                      onChange={(_, numero) => definirEndereco({ ...endereco, numero: parseInt(numero || "")})}
                      styles={styles.textInput}
                      label={t("numero")} />
                    <span>
                    <Label htmlFor={semNumeroFieldId}>{t("sem-numero")}</Label>
                    <Checkbox
                      id={semNumeroFieldId}
                      checked={endereco?.sn} onChange={(_, checked) => definirEndereco({ ...endereco, sn: checked })}
                      styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 6 } }}/>
                    </span>
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      value={endereco?.complemento}
                      onChange={(_, complemento) => definirEndereco({ ...endereco, complemento })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("complemento")} />
                    <TextField
                      value={endereco?.bairro}
                      onChange={(_, bairro) => definirEndereco({ ...endereco, bairro })}
                      required
                      styles={{ ...styles.textInput, root: { flex: 2 } }}
                      label={t("bairro")} />
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    {/* <TextField
                      readOnly
                      value={endereco?.zona}
                      onChange={(_, zona) => definirEndereco({ ...endereco, zona })}
                      styles={styles.textInput}
                      label={t("zona")}
                      iconProps={{ iconName: 'Search' }} /> */}
                    <ComboBox
                      autoComplete="on"
                      required
                      styles={{ root: { width: 64 } }}
                      selectedKey={endereco?.uf}
                      onChange={(_, item) => definirEndereco({ ...endereco, uf: item?.key as number})}
                      label={t("uf")}
                      options={ufs.map((e) => ({ key: e.ID_SGEUNIFE as number, text: e.SGEUNIFE_COD_UNIDFED as string }))}
                    />
                    <ComboBox
                      autoComplete="on"
                      required
                      disabled={!endereco?.uf}
                      selectedKey={endereco?.uf ? endereco?.cidade : undefined}
                      onChange={(_, item) => definirEndereco({ ...endereco, cidade: item?.key as number })}
                      label={t("cidade")}
                      options={(municipios || []).filter(e => e.ID_SGEUNIFE === endereco?.uf).map((e) => ({ key: e.ID_SGEMUNIC as number, text: e.SGEMUNIC_NOME as string }))}
                    />
                    <MaskedTextField
                      value={endereco?.telefone}
                      onChange={(_, telefone) => definirEndereco({ ...endereco, telefone })}
                      label={t("telefone")}
                      mask="(99) 9999-9999" />
                    <MaskedTextField
                      value={endereco?.celular}
                      onChange={(_, celular) => definirEndereco({ ...endereco, celular })}
                      label={t("celular")}
                      mask="(99) 9.9999-9999" />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.ENDERECO_COMERCIAL && (
                <Stack tokens={{ childrenGap: "1em" }}>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <MaskedTextField
                      value={enderecoComercial?.cep}
                      onChange={(_, cep) => {
                        const _cep = cep?.replace(/\D/g, "");

                        (_cep?.length === 8 ? dispatch(ServicoStore.actions.consultarCEP(_cep)).then((e: Objeto_Servico_Endereco.Resposta) => {
                          let _endereco = enderecoComercial || {};

                          _endereco.cidade = municipios?.find(m => m.SGEMUNIC_NOME === e.Cidade?.toUpperCase())?.ID_SGEMUNIC;
                          _endereco.bairro = e.Bairro;
                          _endereco.complemento = e.Complemento;
                          _endereco.logradouro = e.Logradouro;
                          _endereco.uf = ufs.find(uf => uf.SGEUNIFE_COD_UNIDFED === e.UF?.toUpperCase())?.ID_SGEUNIFE;

                          return _endereco;
                        }) : Promise.resolve(enderecoComercial)).then((e: IEndereco) => definirEnderecoComercial({ ...e, cep}));
                      }}
                      styles={styles.textInput}
                      label={t("cep")}
                      mask="99999-999" />
                    <TextField
                      value={enderecoComercial?.logradouro}
                      onChange={(_, logradouro) => definirEnderecoComercial({ ...enderecoComercial, logradouro })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("endereco")} />
                    <TextField
                      disabled={enderecoComercial?.sn}
                      value={enderecoComercial?.numero?.toString()}
                      onChange={(_, numero) => definirEnderecoComercial({ ...enderecoComercial, numero: parseInt(numero || "") })}
                      styles={styles.textInput}
                      label={t("numero")} />
                    <span>
                    <Label htmlFor={semNumeroFieldId}>{t("sem-numero")}</Label>
                    <Checkbox
                      id={semNumeroFieldId}
                      checked={enderecoComercial?.sn} onChange={(_, checked) => definirEnderecoComercial({ ...enderecoComercial, sn: checked })}
                      styles={{ root: { flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 6 } }}/>
                    </span>
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    <TextField
                      value={enderecoComercial?.complemento}
                      onChange={(_, complemento) => definirEnderecoComercial({ ...enderecoComercial, complemento })}
                      styles={{ ...styles.textInput, root: { flex: 3 } }}
                      label={t("complemento")} />
                    <TextField
                      value={enderecoComercial?.bairro}
                      onChange={(_, bairro) => definirEnderecoComercial({ ...enderecoComercial, bairro })}
                      styles={{ ...styles.textInput, root: { flex: 2 } }}
                      label={t("bairro")} />
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: "1em" }}>
                    {/* <TextField
                      readOnly
                      value={enderecoComercial?.zona}
                      onChange={(_, zona) => definirEnderecoComercial({ ...enderecoComercial, zona })}
                      styles={styles.textInput}
                      label={t("zona")}
                      iconProps={{ iconName: 'Search' }} /> */}
                    <ComboBox
                      autoComplete="on"
                      styles={{ root: { width: 64 } }}
                      selectedKey={enderecoComercial?.uf}
                      onChange={(_, item) => definirEnderecoComercial({ ...enderecoComercial, uf: item?.key as number})}
                      label={t("uf")}
                      options={ufs.map((e) => ({ key: e.ID_SGEUNIFE as number, text: e.SGEUNIFE_COD_UNIDFED as string }))}
                    />
                    <ComboBox
                      autoComplete="on"
                      disabled={!enderecoComercial?.uf}
                      selectedKey={enderecoComercial?.uf ? enderecoComercial?.cidade : undefined}
                      onChange={(_, item) => definirEnderecoComercial({ ...enderecoComercial, cidade: item?.key as number })}
                      label={t("cidade")}
                      options={(municipios || []).filter(e => e.ID_SGEUNIFE === endereco?.uf).map((e) => ({ key: e.ID_SGEMUNIC as number, text: e.SGEMUNIC_NOME as string }))}
                    />
                    <MaskedTextField
                      value={enderecoComercial?.telefone}
                      onChange={(_, telefone) => definirEnderecoComercial({ ...enderecoComercial, telefone })}
                      label={t("telefone")}
                      mask="(99) 9999-9999" />
                  </Stack>
                </Stack>
              )}

              {pivotKey === PivotItens.DOCUMENTOS && (
                <Documentos arquivos={documentos} removerArquivo={arquivo => {
                  definirDocumentos(documentos.filter(e => e.name !== arquivo.name && e.lastModified !== arquivo.lastModified && e.size !== arquivo.size));
                }} />
              )}

              {pivotKey === PivotItens.OBSERVACAO && (
                <TextField
                  label="Descrever"
                  multiline
                  rows={3}
                  value={observacao}
                  onChange={(_, texto) => definirObservacao(texto)}
                />
              )}
            </Stack>
          </Stack>

          <Stack.Item align="end">
            {!!validacoesErrosArray.length ? (
              <TooltipHost
                id={tooltipId}
                calloutProps={{
                  gapSpace: 0,
                }}
                tooltipProps={{
                  onRenderContent: () => (
                    <ul style={{ margin: 10, padding: 0 }}>
                      <li style={{ display: "flex", color: "#e81123" }}>
                        <FontIcon iconName="Error" style={{
                          marginRight: 8,
                        }} />
                        Os seguintes campos são inválidos:
                      </li>
                      {Object.entries(validacao).filter(([chave, valor]) => valor === undefined || valor === "").map(([chave]) => (validacaoErros as any)[chave]).map(e => {
                        return <li key={e}>{e}</li>
                      })}
                    </ul>
                  )
                }}
              >
                {botaoSolicitacaoWeb}
              </TooltipHost>
            ) : (
              botaoSolicitacaoWeb
            )}
          </Stack.Item>
        </Stack>
      ) : null}

      <BeneficiarioPesquisaModal
        titular
        isOpen={titularModalAberto}
        onDismiss={(beneficiario) => {
          definirTitularSelecionado(beneficiario);
          definirTitularModalAberto(false);
        }}
      />

      <BeneficiarioPesquisaModal
        isOpen={beneficiarioModalAberto}
        onDismiss={(beneficiario) => {
          definirBeneficiarioSelecionado(beneficiario)
          definirDadosCadastrais(undefined);
          definirBeneficiarioModalAberto(false);
        }}
      />

      {useMemo(() => (
        <PesquisaModal<Objeto_Servico_Banco.Resposta>
          titulo={t("listagem-bancos")}
          itens={bancos || []}
          placeholderCampoPesquisa={t("pesquisar")}
          tituloBotaoSelecionar={t("ok")}
          isBlocking={false}
          isOpen={bancoModalAberto}
          onDismiss={(banco) => {
            definirBancoSelecionado(banco)
            definirBancoModalAberto(false);
          }}
          selectionMode={SelectionMode.single}
          columns={[
            {
              key: "codigo",
              name: t("codigo"),
              ariaLabel: t("codigo"),
              fieldName: "ID_SGEBANCO",
              minWidth: 110,
              maxWidth: 110,
            },
            {
              key: "banco",
              name: t("banco"),
              ariaLabel: t("banco"),
              fieldName: "SGEBANCO_NOME",
            },
          ] as Array<IColumn>}
        />
      ), [bancos, bancoModalAberto, bancoSelecionado])}
    </div>
  );
}

export default InclusaoBeneficiario;
