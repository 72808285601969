import React, { useEffect, useState } from "react";
import { Image } from "office-ui-fabric-react";
import cartao from "./cartao.png";
import verso from "./cartao-verso.png";
import { Label } from "@fluentui/react";
import { CSSProperties } from "react";
import * as BeneficiarioStore from "../../store/Beneficiario";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";

export interface IStyle {
  card: CSSProperties;
  flipCard: CSSProperties;
  flipCardInner: CSSProperties;
  flipCardHover: CSSProperties;
  flipCardFront: CSSProperties;
  flipCardBack: CSSProperties;
  info: CSSProperties;
  descricao: CSSProperties;
}

const styles: IStyle = {
  card: {
    overflow: "hidden",
    borderRadius: 22,
    border: "solid 1px #8a8886",
    boxSizing: "border-box",
    backfaceVisibility: "hidden",
  },

  flipCard: {
    backgroundColor: "transparent",
    width: 463,
    height: 293,
    perspective: "1000px",
  },

  flipCardInner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
    /* boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", */
  },

  flipCardHover: {
    transform: "rotateY(180deg)",
  },

  flipCardFront: {
    position: "absolute",
    width: "100%",
    height: "100%",
    /* backgroundColor: "#bbb", */
    color: "white",
    transform: "rotateX(0deg)",
  },

  flipCardBack: {
    position: "absolute",
    width: "100%",
    height: "100%",
    /* backgroundColor: "#2980b9", */
    color: "white", // cor do texto
    transform: "rotateY(180deg)",
  },

  info:{
    fontSize: 12, 
    fontWeight:600,
    alignSelf: 'start', 
    /* lineHeight: 32 */
  },

  descricao:{
    fontSize: 10,
    alignSelf: 'start',
    /* lineHeight: 10, */
  },

};

/* export interface Carteirinha{
	produto?: string; //não traz
	tipo?: string; //não traz
	codigo?: string;
	fdata_nascimento?: string;
	acomodacao?: string;
  vigencia_plano?: string;
  data_val?: string;
	nome?: string;
	rede?: string;
	local_aten?: string;
	plano_reg?: string;
  abrangencia?: string;
  nr_via?:string;
  cobertura_ptemp?:string; 
  contratante?:string; //Não veio pela SP. A consulta traz uma razao social que talvez seja o contratante. n tenho certeza
  segme_plano?:string;
} */

/* const dados: Carteirinha = {
  produto: "SAFIRA - NACIONAL - ESPECIAL - CE - C/OBST",
  tipo: "COLETIVO EMPRESARIAL",
  codigo: "0 123 123456789012 1",
  fdata_nascimento: "02/03/1967", acomodacao: "INDIVIDUAL", vigencia_plano: "31/12/2007", data_val: "31/12/2007",
  nome: "MARIA DA SILVA A CARVALHO", rede: "NA99 MASTER",
  local_aten: "9999", plano_reg: "REGULAMENTADO", abrangencia: "NACIONAL", nr_via: "01",
  cobertura_ptemp: "01/01/2007", contratante: "LOREM IPSUM DOLOR AMET CONSECT",
  segmentacao: "AMBULATORIAL + HOSPITALAR SEM OBSTETRÍCIA + ODONTOLÓGICO",
};
 */

interface ICarteirinha {
  styles?: { root?: CSSProperties }
}

const Carteirinha: React.FC<ICarteirinha>  = (props) => {
  const [hover, setHover] = useState(false);
  const { beneficiario: { carteirinha } } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BeneficiarioStore.actions.obterCarteirinha());
  }, [dispatch]);

  return(
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{...props.styles?.root, ...styles.flipCard}}>
      <div style={{ ...styles.flipCardInner, ...(hover ? styles.flipCardHover : null) }}>
        <div style={{...styles.flipCardFront, ...styles.card}}>
          <div style={{position: "absolute", width: "100%", top: 30, zIndex: 999, paddingLeft: 15, paddingRight: 36}}>
            <div>
              {/* <Label style={{ fontWeight:600, alignSelf: 'start', fontSize:11, position:"absolute"}}>SAFIRA - NACIONAL - ESPECIAL - CE - C/OBST</Label> */}
              <Label style={{ fontWeight:600, alignSelf: 'start',fontSize: 12.7, position:"absolute", top:15}}>{carteirinha?.tipo_contr_plano}</Label>
            </div>
          </div>
          <div style={{position: "absolute", width: "100%", top: 105, zIndex: 999, paddingLeft: 15, paddingRight: 36}}>
            <div>
              <Label style={{fontSize:'1.5em', fontWeight:'bold', position:"absolute"}}>{carteirinha?.codigo}</Label>
            </div>
            <div style={{display: "flex",flexDirection: "row", justifyContent: "space-between"}}>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top:20}}>{carteirinha?.fdata_nasciment}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position:"absolute", top:31}}>Data de Nascimento</Label>
              </div>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top:20, left: 122}}>{carteirinha?.acomodacao}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position:"absolute", top:31, left: 122}}>Acomodação</Label>
              </div>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top:20, /* left: 200, */ left: 260}}>{carteirinha?.vigencia_plano}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position:"absolute",  top:31, /* left: 200, */ left: 260}}>Vigência do plano</Label>
              </div>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top:20, left: 363}}>{carteirinha?.data_val}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position:"absolute",  top:31, left: 363}}>Validade</Label>
              </div>
            </div>
            <div style={{display: "flex" ,flexDirection: "row", justifyContent: "space-between"}}>
              <div>
                <Label style={{ fontSize:'1.0em', fontWeight:600, /* lineHeight: 20, */ position:"absolute", top: 45}}>{carteirinha?.nome}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position:"absolute", top:58}}>Nome do Beneficiário</Label>
              </div>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', top:48, position:"absolute", right: 86}}>{carteirinha?.rede}</Label>
                <Label style={{fontSize: 10, right: 60,/* lineHeight: 5, */ top: 58, position: "absolute"}}>Rede de Atendimento</Label>
              </div>
            </div>
            <div style={{display: "flex" ,flexDirection: "row", justifyContent: "space-between"}}>
              <div>
                <Label style={{fontSize: 12, fontWeight:600, alignSelf: 'start',position:"absolute", top: 75}}>{carteirinha?.local_aten}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 88}}>Atend.</Label>
              </div>
              <div>
                <Label style={{fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top: 75, left: 55}}>{carteirinha?.plano_reg}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 88, left: 55}}>Plano</Label>
              </div>
              <div>
                <Label style={{fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top: 75, left: 200}}>{carteirinha?.abrangencia}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 88, left: 200}}>Abrangência</Label>
              </div>
              <div>
                <Label style={{fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top: 75, right: 143}}>{carteirinha?.nr_via}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 88, right: 143}}>Via</Label>
              </div>
            </div>

            <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top: 102}}>{carteirinha?.coberura_ptemp}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 115}}>Cobertura Parcial Temporária</Label>
              </div>
              <div>
                <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start', position:"absolute", top:102, right:105}}>{carteirinha?.contratante}</Label>
                <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 115, right:258}}>Contratante</Label>
              </div>
            </div>
   
            <div style={{display:"flex",flexDirection: "row"}}>
              <Label style={{ fontSize: 12, fontWeight:600, alignSelf: 'start',  position: "absolute", top: 130 }}>{carteirinha?.segme_plano}</Label>
              <Label style={{fontSize: 10, alignSelf: 'start', position: "absolute", top: 142}}>Segmentação Assistencial do Plano</Label>
            </div>
          </div>
          <Image
            src={cartao}
            width={463}
            height={293}/>
        </div>
        <div style={{...styles.flipCardBack, ...styles.card}}>
          <Image
            style={{ objectFit: "none" }}
            src={verso}
            width= {463}
            height= {293}/>
        </div>
      </div>
    </div>
  );

};

export default Carteirinha;