import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Stack,
  Label,
  TextField,
  DefaultButton,
  Dropdown,
  PrimaryButton,
} from "@fluentui/react";
import { UseTranslationResponse, useTranslation } from "../../i18n";

import { getStyles } from "./styles";
import { Objeto_ConexaoBanco } from "../../api";
import { useDispatch } from "react-redux";
import * as ServicoStore from "../../store/Servico";
import { BotaoSolicitacaoWeb } from "../../components/BotaoSolicitacaoWeb";

function URLValida(str?: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str || "");
}

const Configuracao: React.FC = () => {
  const styles = getStyles();

  const { t }: UseTranslationResponse = useTranslation();

  const dispatch = useDispatch<any>();

  const [configuracao, definirConfiguracao] = useState<Objeto_ConexaoBanco.Requisicao>({});
  const [configuracaoValida, definirConfiguracaoValida] = useState<boolean>(false);

  const obterConfiguracao = useCallback(() => {
    dispatch(ServicoStore.actions.obterConfiguracoes(configuracao)).then((Slave: any) => definirConfiguracao({ ...configuracao, Slave })).catch(() => {
      definirConfiguracao({ ...configuracao, Slave: undefined })
    });
  }, [configuracao]);

  return (
    <div style={styles.root}>
      <Label style={styles.title}>{t("configuracoes")}</Label>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Label style={styles.subTitle}>{t("autenticacao-servidor")}</Label>

        <Stack horizontal tokens={{ childrenGap: "1em" }}>
          <TextField
            required
            autoFocus
            styles={styles.textInput}
            label={t("usuario-windows")}
            value={configuracao?.Usuario}
            onChange={(evt, texto) => {
              definirConfiguracao({ ...configuracao, Usuario: texto});
            }}
          />
          <TextField
            required
            type="password"
            styles={styles.textInput}
            label={t("senha-windows")}
            value={configuracao?.Senha}
            onChange={(evt, texto) => {
              definirConfiguracao({ ...configuracao, Senha: texto});
            }}
          />
          <DefaultButton disabled={!configuracao?.Usuario || !configuracao?.Senha} style={{ alignSelf: "flex-end"}} text={t("obter-configuracoes")} onClick={obterConfiguracao} />
        </Stack>

        {configuracao.Slave && (
          <>
            <Label style={styles.subTitle}>{"Arquivo de Configuração"}</Label>

            <Stack style={{ flex: 1 }} tokens={{ childrenGap: "1em" }}>
              <Stack horizontal tokens={{ childrenGap: "1em" }}>
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Servidor"}
                  value={configuracao.Slave?.Servidor}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Servidor: texto }});
                  }}
                />
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Porta"}
                  value={configuracao.Slave?.Porta}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Porta: texto }});
                  }}
                />
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Base"}
                  value={configuracao.Slave?.Banco}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Banco: texto }});
                  }}
                />
              </Stack>
              <Stack horizontal tokens={{ childrenGap: "1em" }}>
                <Dropdown
                  label="Tipo do Banco"
                  style={{ minWidth: 130 }}
                  selectedKey={configuracao.Slave?.BaseDados}
                  options={[
                    { key: Objeto_ConexaoBanco.BD.Sql, text: 'SLQ' },
                    { key: Objeto_ConexaoBanco.BD.Oracle, text: 'Oracle' },
                    { key: Objeto_ConexaoBanco.BD.NotSet, text: 'Não Definido' },
                  ]}
                  onChange={(evt, item) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, BaseDados: item?.key as number }});
                  }}
                />
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Usuário"}
                  value={configuracao.Slave?.Usuario}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Usuario: texto }});
                  }}
                />
                <TextField
                  required
                  type="password"
                  styles={styles.textInput}
                  label={"Senha"}
                  value={configuracao.Slave?.Senha}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Senha: texto }});
                  }}
                />
              </Stack>
              <Stack tokens={{ childrenGap: "1em" }}>
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Online"}
                  value={configuracao.Slave?.Online}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Online: texto }});
                  }}
                />
                <TextField
                  required
                  styles={styles.textInput}
                  label={"Serviços"}
                  value={configuracao.Slave?.Servico}
                  onChange={(evt, texto) => {
                    definirConfiguracao({ ...configuracao, Slave: { ...configuracao.Slave, Servico: texto }});
                  }}
                />
              </Stack>
              <Stack style={{ flex: 1, alignSelf: "flex-end", alignItems: "flex-end" }} horizontal tokens={{ childrenGap: "1em" }}>
                <DefaultButton
                  disabled={
                    !configuracao?.Usuario
                    || !configuracao?.Senha
                    || !URLValida(configuracao?.Slave?.Servidor)
                    || !configuracao?.Slave?.Porta
                    || !configuracao?.Slave?.Banco
                    || !configuracao?.Slave?.BaseDados
                    || !configuracao?.Slave?.Usuario
                    || !configuracao?.Slave?.Senha
                    || !URLValida(configuracao?.Slave?.Online)
                    || !URLValida(configuracao?.Slave?.Servico)
                  }
                  text={"Testar Conexão"} onClick={obterConfiguracao}
                  iconProps={{ iconName: "PlugDisconnected" }}
                />
                <PrimaryButton
                  disabled={!configuracaoValida}
                  text={t("salvar")}
                  iconProps={{ iconName: "Save" }}
                  onClick={obterConfiguracao}
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </div>
  );
}

export default Configuracao;
