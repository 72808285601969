import { Action, Reducer } from "redux";

import {
  API_Controllers,
  Objeto_EmpresaObjeto_Fatura,
  Objeto_EmpresaObjeto_Boleto,
  Objeto_EmpresaObjeto_Plano,
  Objeto_EmpresaObjeto_Beneficiario,
  Objeto_EmpresaObjeto_MotivoDesligamento,
  Objeto_EmpresaObjeto_Empresa,
  Objeto_EmpresaObjeto_MotivoTransferencia,
  Objeto_Solicitacao,
  Objeto_ServicoObjeto_SolicitacaoWeb,
  Objeto_EmpresaObjeto_DesligamentoAgendado,
  Objeto_EmpresaObjeto_CategoriaDependente,
  Objeto_EmpresaObjeto_NomeAbreviado,
  Objeto_EmpresaObjeto_Anexo,
  Objeto_EmpresaObjeto_TipoArquivo
} from "../api";
import { AppThunkAction } from ".";
import { IBaseSolicitacaoWeb } from "./Servico";

export const EmpresaAPI = new API_Controllers.Empresa("");

type SN = 'S' | 'N';

interface I2ViaCarteirinha extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  CD_MATRICULA: string,
  ID_SCAEMPBA: number,
  NM_SEGURADO: string,
  SN_TITULAR: SN,
  TP_USUARIO: string,
  NR_CPF: string,
}

interface ITransferenciaContrato extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  CD_MATRICULA: string,
  ID_SCAEMPBA: number,
  NM_SEGURADO: string,
  SN_TITULAR: SN,
  ID_SGEPLANO: number,
  TP_USUARIO: string,
  ID_SCAEMPBA_TRANSFERENCIA: number,
  NR_CPF: string,
  CD_MOTIVO_ALTERACAO_ANS: number,
}

interface IDesligamentoBeneficiario extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  CD_MATRICULA: string,
  ID_SGEMOTIV: number,
  DT_DESLIGAMENTO: Date,
  ID_SCAEMPBA: number,
  NM_SEGURADO: string,
  SN_TITULAR: SN,
  TP_USUARIO: string,
  SN_EXCLUI_DEPENDENTE: SN,
  NR_CPF: string,
  DT_FALECIMENTO: Date | null,
  CD_SOLICITACAO_TITULAR: number | null,
  // TODO: DOCS
}

export interface IAlteracaoBeneficiario extends IBaseSolicitacaoWeb {
  DS_OBSERVACAO: string,
  ID_SCAEMPBA: number,
  NM_SEGURADO: string,
  SN_TITULAR: SN,
  TP_USUARIO: string,
  CD_MATRICULA: string,
  CD_SOLICITACAO_TITULAR: number | null,
  // CD_PARENTESCO
  ID_SGEPLANO: number,
  NM_PAI: string,
  NM_MAE: string,
  TP_SEXO: 'M' | 'F' | 'I',
  TP_ESTADO_CIVIL: 'S' | 'C' | 'V' | 'D' | 'I',
  NR_DN: string, // Nascido Vivo
  NR_CPF: string, // Numeros apenas // Validar CPF
  CD_PIS_PASEP: string,
  NR_CNS: string,
  NR_RG: string,
  CD_PAIS_EMISSOR_CARTEIRA: string,
  DS_ORGAO_EXPEDITOR: string,
  DT_NASCIMENTO: Date,
  DS_EMAIL: string,
  NR_CEP: string,
  DS_ENDERECO: string,
  NR_ENDERECO: number,
  DS_COMPLEMENTO: string,
  DS_BAIRRO: string,
  CD_ZONA: number | null,
  NM_CIDADE: string,
  NM_UF: string,
  NR_TELEFONE: string,
  NR_CELULAR: string,
  NR_CEP_COM: string,
  DS_ENDERECO_COM: string,
  NR_ENDERECO_COM: number,
  DS_COMPLEMENTO_COM: string,
  DS_BAIRRO_COM: string,
  CD_ZONA_COM: number | null,
  NM_CIDADE_COM: string,
  NM_UF_COM: string,
  NR_TELEFONE_COM: string,
  // cdsolicitacao
  CD_USUARIO_ALTERACAO: string,
  // dtultimaalteracao
  // dtcriacao
  CD_BANCO: number,
  NR_AGENCIA: string,
  NM_AGENCIA: string,
  // TODO: DOCS
  ID_SCADEPEN: string,
  CD_MATRICULA_TEM: string
}

export interface IInclusaoBeneficiario extends IBaseSolicitacaoWeb, Omit<IAlteracaoBeneficiario, "CD_MATRICULA" | "CD_USUARIO_ALTERACAO"> {
  DT_ADESAO: Date,
  DT_ADMISSAO: Date,
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
  faturas: Array<Objeto_EmpresaObjeto_Fatura.Resposta>;
  boletos: Array<Objeto_EmpresaObjeto_Boleto.Resposta>;
  planos: Array<Objeto_EmpresaObjeto_Plano.Resposta>;
  beneficiarios: Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>;
  desligamentosAgendados: Array<Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta>;
  motivosDesligamento: Array<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta>;
  motivosTransferencia: Array<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta>;
  contratoAtivo?: string;
  empresas: Array<Objeto_EmpresaObjeto_Empresa.Resposta>,
  solicitacoes?: Array<Objeto_Solicitacao.Resposta>,
  categoriaDependente: Array<Objeto_EmpresaObjeto_CategoriaDependente.Resposta>;
  nomeAbreviado: Array<Objeto_EmpresaObjeto_NomeAbreviado.Resposta>;
  anexo:Array<Objeto_EmpresaObjeto_Anexo.Resposta>;
  contratosLotacao: Array<Objeto_EmpresaObjeto_Plano.Resposta>;
  tipoArquivo: Array<Objeto_EmpresaObjeto_TipoArquivo.Resposta>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface ObterFaturaAction {
  type: "EMPRESA::FATURAS",
  faturas: Array<Objeto_EmpresaObjeto_Fatura.Resposta>,
}

export interface ObterBoletosAction {
  type: "EMPRESA::BOLETOS",
  boletos: Array<Objeto_EmpresaObjeto_Boleto.Resposta>,
}

export interface ObterPlanosAction {
  type: "EMPRESA::PLANOS",
  planos: Array<Objeto_EmpresaObjeto_Plano.Resposta>,
}

export interface ObterContratosLotacaoAction {
  type: "EMPRESA::CONTRATOS_LOTACAO",
  contratosLotacao: Array<Objeto_EmpresaObjeto_Plano.Resposta>,
}

export interface ObterBeneficiariosAction {
  type: "EMPRESA::BENEFICIARIOS",
  beneficiarios: Array<Objeto_EmpresaObjeto_Beneficiario.Resposta>,
}

export interface ObterDesligamentosAgendadosAction {
  type: "EMPRESA::DESLIGAMENTOS_AGENDADOS",
  desligamentosAgendados: Array<Objeto_EmpresaObjeto_DesligamentoAgendado.Resposta>;
}

export interface ObterMotivosDesligamentoAction {
  type: "EMPRESA::MOTIVOS_DESLIGAMENTO",
  motivosDesligamento: Array<Objeto_EmpresaObjeto_MotivoDesligamento.Resposta>,
}

export interface ObterMotivosTransferenciaAction {
  type: "EMPRESA::MOTIVOS_TRANSFERENCIA",
  motivosTransferencia: Array<Objeto_EmpresaObjeto_MotivoTransferencia.Resposta>,
}

export interface DefinirContratoAtivoAction {
  type: "EMPRESA::CONTRATO_ATIVO",
  contratoAtivo: string,
}

export interface DefinirEmpresasAction {
  type: "EMPRESA::EMPRESAS",
  empresas: Array<Objeto_EmpresaObjeto_Empresa.Resposta>,
}

export interface DefinirSolicitacoesAction {
  type: "EMPRESA::SOLICITACOES",
  solicitacoes: Array<Objeto_Solicitacao.Resposta>,
}

export interface ObterCategoriaDependenteAction {
  type: "EMPRESA::CATEGORIA_DEPENDENTE",
  categoriaDependente: Array<Objeto_EmpresaObjeto_CategoriaDependente.Resposta>,
}

export interface ObterNomeAbreviadoAction {
  type: "EMPRESA::NOME_ABREVIADO",
  nomeAbreviado: Array<Objeto_EmpresaObjeto_NomeAbreviado.Resposta>,
}

export interface ObterAnexoAction {
  type: "EMPRESA::ANEXO",
  anexo: Array<Objeto_EmpresaObjeto_Anexo.Resposta>,
}

export interface ObterTipoArquivoAction {
  type: "EMPRESA::TIPO_ARQUIVO",
  tipoArquivo: Array<Objeto_EmpresaObjeto_TipoArquivo.Resposta>,
}

// Declare a "discriminated union" type. This guarantees that all references to "type" properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  ObterFaturaAction
  | ObterBoletosAction
  | ObterPlanosAction
  | ObterBeneficiariosAction
  | ObterDesligamentosAgendadosAction
  | ObterMotivosDesligamentoAction
  | ObterMotivosTransferenciaAction
  | DefinirContratoAtivoAction
  | DefinirEmpresasAction
  | DefinirSolicitacoesAction
  | ObterCategoriaDependenteAction
  | ObterNomeAbreviadoAction
  | ObterAnexoAction
  | ObterContratosLotacaoAction
  | ObterTipoArquivoAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don"t directly mutate state, but they can have external side-effects (such as loading data).

export const actions = {
  obterFaturas: (SCACONTR_CODIGO: string, SCALOTEM_CODIGO?: string): AppThunkAction<KnownAction> => (dispatch) => (
    
    SCALOTEM_CODIGO? EmpresaAPI.FaturaLotacao({ SCACONTR_CODIGO, SCALOTEM_CODIGO }).then((faturas) => {
      faturas = faturas.map(p => {
        p.SCOTITUL_DATA_EMISSAO = p.SCOTITUL_DATA_EMISSAO ? new Date(p.SCOTITUL_DATA_EMISSAO) : undefined;
        p.SCOTITUL_DATA_VCTO = p.SCOTITUL_DATA_VCTO ? new Date(p.SCOTITUL_DATA_VCTO) : undefined;
        p.SCOTITUL_DATA_BAIXA = p.SCOTITUL_DATA_BAIXA ? new Date(p.SCOTITUL_DATA_BAIXA) : undefined;
        p.DT_CANCELAMENTO = p.DT_CANCELAMENTO ? new Date(p.DT_CANCELAMENTO) : undefined;

        return p;
      })

      dispatch({ type: "EMPRESA::FATURAS", faturas });
    }) : EmpresaAPI.Fatura({ SCACONTR_CODIGO }).then((faturas) => {
      faturas = faturas.map(p => {
        p.SCOTITUL_DATA_EMISSAO = p.SCOTITUL_DATA_EMISSAO ? new Date(p.SCOTITUL_DATA_EMISSAO) : undefined;
        p.SCOTITUL_DATA_VCTO = p.SCOTITUL_DATA_VCTO ? new Date(p.SCOTITUL_DATA_VCTO) : undefined;
        p.SCOTITUL_DATA_BAIXA = p.SCOTITUL_DATA_BAIXA ? new Date(p.SCOTITUL_DATA_BAIXA) : undefined;
        p.DT_CANCELAMENTO = p.DT_CANCELAMENTO ? new Date(p.DT_CANCELAMENTO) : undefined;

        return p;
      })

      dispatch({ type: "EMPRESA::FATURAS", faturas });
    })
  ),
  obterBoletos: (SCOTITUL_CONTRATO: string, DATA_INICIAL?: Date, DATA_LIMITE?: Date): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Boleto({ SCOTITUL_CONTRATO, DATA_INICIAL, DATA_LIMITE }).then((boletos) => {
      boletos = boletos.map(p => {
        p.SCOTITUL_DATA_EMISSAO = p.SCOTITUL_DATA_EMISSAO ? new Date(p.SCOTITUL_DATA_EMISSAO) : undefined;
        p.SCOTITUL_DATA_VCTO = p.SCOTITUL_DATA_VCTO ? new Date(p.SCOTITUL_DATA_VCTO) : undefined;
        p.SCOTITUL_DATA_BAIXA = p.SCOTITUL_DATA_BAIXA ? new Date(p.SCOTITUL_DATA_BAIXA) : undefined;
        p.SCABENEF_DATA_INCLUSAO = p.SCABENEF_DATA_INCLUSAO ? new Date(p.SCABENEF_DATA_INCLUSAO) : undefined;
        p.SGEPESS_FDATA_NASCIMENT = p.SGEPESS_FDATA_NASCIMENT ? new Date(p.SGEPESS_FDATA_NASCIMENT) : undefined;

        return p;
      })

      dispatch({ type: "EMPRESA::BOLETOS", boletos });
    })
  ),
  obterPlanos: (SCAEMPBA_CODIGO?: string): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Plano({ SCAEMPBA_CODIGO }).then((planos) => {
      planos = planos.map(p => {
        p.SCACONTR_DATA_INICIO = p.SCACONTR_DATA_INICIO ? new Date(p.SCACONTR_DATA_INICIO) : undefined;
        p.SCACONTR_VALIDADE = p.SCACONTR_VALIDADE ? new Date(p.SCACONTR_VALIDADE) : undefined;

        return p;
      })

      if (!SCAEMPBA_CODIGO) {
        dispatch({ type: "EMPRESA::PLANOS", planos });

        if (planos.length) {
          dispatch({ type: "EMPRESA::CONTRATO_ATIVO", contratoAtivo: planos[0].SCACONTR_CODIGO as string });
        }
      }

      return planos;
    })
  ),
  obterPlanosLotacao: (SCAEMPBA_CODIGO?: string): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.PlanoLotacao({ SCAEMPBA_CODIGO }).then((planos) => {
      planos = planos.map(p => {
        p.SCACONTR_DATA_INICIO = p.SCACONTR_DATA_INICIO ? new Date(p.SCACONTR_DATA_INICIO) : undefined;
        p.SCACONTR_VALIDADE = p.SCACONTR_VALIDADE ? new Date(p.SCACONTR_VALIDADE) : undefined;

        return p;
      })

      if (!SCAEMPBA_CODIGO) {
        dispatch({ type: "EMPRESA::PLANOS", planos });

        /* if (planos.length) { //SUP-313819
          dispatch({ type: "EMPRESA::CONTRATO_ATIVO", contratoAtivo: planos[0].SCACONTR_CODIGO as string });
        } */
      }

      return planos;
    })
  ),
  obterContratosLotacao: (SCAEMPBA_CODIGO?: string): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.ContratosLotacao({ SCAEMPBA_CODIGO }).then((contratosLotacao) => {
      contratosLotacao = contratosLotacao.map(p => {
        p.SCACONTR_DATA_INICIO = p.SCACONTR_DATA_INICIO ? new Date(p.SCACONTR_DATA_INICIO) : undefined;
        p.SCACONTR_VALIDADE = p.SCACONTR_VALIDADE ? new Date(p.SCACONTR_VALIDADE) : undefined;

        return p;
      })

      if (!SCAEMPBA_CODIGO) {
        dispatch({ type: "EMPRESA::CONTRATOS_LOTACAO", contratosLotacao });

        if (contratosLotacao.length) {
          dispatch({ type: "EMPRESA::CONTRATO_ATIVO", contratoAtivo: contratosLotacao[0].SCACONTR_CODIGO as string });
        }
      }

      return contratosLotacao;
    })
  ),
  obterCarteirinhas: (SCACARTE_CODIGO_CART: string): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Carteirinha({ SCACARTE_CODIGO_CART }).then((carteirinhas) => {
      return carteirinhas.map(c => {
        c.SCACARTE_DT_GERACAO = c.SCACARTE_DT_GERACAO ? new Date(c.SCACARTE_DT_GERACAO) : undefined;
        c.SCACARTE_VALIDADE_FINAL = c.SCACARTE_VALIDADE_FINAL ? new Date(c.SCACARTE_VALIDADE_FINAL) : undefined;

        return c;
      })
    })
  ),
  obterBeneficiarios: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Beneficiario().then((beneficiarios) => {
      beneficiarios = beneficiarios.map(b => {
        b.SCACONBE_INICIO_VIGENCIA = b.SCACONBE_INICIO_VIGENCIA ? new Date(b.SCACONBE_INICIO_VIGENCIA) : undefined;

        return b;
      })

      dispatch({ type: "EMPRESA::BENEFICIARIOS", beneficiarios });
    })
  ),
  obterBeneficiariosLotacao: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.BeneficiarioLotacao().then((beneficiarios) => {
      beneficiarios = beneficiarios.map(b => {
        b.SCACONBE_INICIO_VIGENCIA = b.SCACONBE_INICIO_VIGENCIA ? new Date(b.SCACONBE_INICIO_VIGENCIA) : undefined;

        return b;
      })

      dispatch({ type: "EMPRESA::BENEFICIARIOS", beneficiarios });
    })
  ),
  obterDesligamentosAgendados: (DATA_INICIAL?: Date, DATA_LIMITE?: Date): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.DesligamentoAgendado({ DATA_INICIAL, DATA_LIMITE }).then((desligamentosAgendados) => {
      desligamentosAgendados = desligamentosAgendados.map(da => {
        da.SCACONBE_DATA_ULT_ALT = da.SCACONBE_DATA_ULT_ALT ? new Date(da.SCACONBE_DATA_ULT_ALT) : undefined;
        da.SCACONBE_FINAL_VIGENCIA = da.SCACONBE_FINAL_VIGENCIA ? new Date(da.SCACONBE_FINAL_VIGENCIA) : undefined;

        return da;
      })

      dispatch({ type: "EMPRESA::DESLIGAMENTOS_AGENDADOS", desligamentosAgendados });
    })
  ),
  obterMotivosDesligamento: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.MotivoDesligamento().then((motivosDesligamento) => {
      dispatch({ type: "EMPRESA::MOTIVOS_DESLIGAMENTO", motivosDesligamento });
    })
  ),
  obterMotivosTransferencia: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.MotivoTransferencia().then((motivosTransferencia) => {
      dispatch({ type: "EMPRESA::MOTIVOS_TRANSFERENCIA", motivosTransferencia });
    })
  ),
  definirContratoAtivo: (SCACONTR_CODIGO: string): AppThunkAction<KnownAction> => (dispatch) => (
    dispatch({ type: "EMPRESA::CONTRATO_ATIVO", contratoAtivo: SCACONTR_CODIGO })
  ),
  obterEmpresas: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Empresa().then((empresas) => {
      dispatch({ type: "EMPRESA::EMPRESAS", empresas });
    })
  ),
  obterSolicitacoes: (DATA_INICIAL?: Date, DATA_LIMITE?: Date): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Solicitacao({ DATA_INICIAL, DATA_LIMITE }).then((solicitacoes) => {
      dispatch({ type: "EMPRESA::SOLICITACOES", solicitacoes: solicitacoes.filter(e => (e.CD_TP_SOLICITACAO as number) <= 9) });
    })
  ),
  obterDadosCadastrais: (ID_SCABENEF: number): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.BeneficiarioCadastro({ ID_SCABENEF }).then((res) => {
      res.SCABENEF_DATA_INCLUSAO = res.SCABENEF_DATA_INCLUSAO ? new Date(res.SCABENEF_DATA_INCLUSAO) : undefined;
      res.SCACONBE_INICIO_VIGENCIA = res.SCACONBE_INICIO_VIGENCIA ? new Date(res.SCACONBE_INICIO_VIGENCIA) : undefined;
      res.SCABENEF_DATA_NASC = res.SCABENEF_DATA_NASC ? new Date(res.SCABENEF_DATA_NASC) : undefined;

      return res;
    })
  ),
  solicitarInclusaoBeneficiario: (dados: IInclusaoBeneficiario): AppThunkAction<KnownAction> => () => (
    EmpresaAPI.SolicitacaoWeb(dados as any)
  ),
  solicitarAlteracaoBeneficiario: (dados: IAlteracaoBeneficiario): AppThunkAction<KnownAction> => () => (
    EmpresaAPI.SolicitacaoWeb(dados as any)
  ),
  solicitarDesligamentoBeneficiario: (dados: IDesligamentoBeneficiario): AppThunkAction<KnownAction> => () => (
    EmpresaAPI.SolicitacaoWeb(dados as any)
  ),
  solicitarTransferenciaContrato: (dados: ITransferenciaContrato): AppThunkAction<KnownAction> => () => (
    EmpresaAPI.SolicitacaoWeb(dados as any)
  ),
  solicitar2ViaCarteirinha: (dados: I2ViaCarteirinha): AppThunkAction<KnownAction> => () => (
    EmpresaAPI.SolicitacaoWeb(dados as any)
  ),
  obterCategoriaDependente: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.CategoriaDependente().then((categoriaDependente) => {
      dispatch({ type: "EMPRESA::CATEGORIA_DEPENDENTE", categoriaDependente });
    })
  ),
  obterNomeAbreviado: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.NomeAbreviado().then((nomeAbreviado) => {
      dispatch({ type: "EMPRESA::NOME_ABREVIADO", nomeAbreviado });
    })
  ),
  obterAnexo: (SWEDOCUM_ID_ORIGEM:string): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.Anexo({SWEDOCUM_ID_ORIGEM}).then((anexo) => {
      dispatch({ type: "EMPRESA::ANEXO", anexo });
    })
  ),
  obterTipoArquivo: (): AppThunkAction<KnownAction> => (dispatch) => (
    EmpresaAPI.TipoArquivo().then((tipoArquivo) => {
      dispatch({ type: "EMPRESA::TIPO_ARQUIVO", tipoArquivo });
    })
  )
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
  if (state === undefined) {
    return {
      faturas: [],
      boletos: [],
      planos: [],
      beneficiarios: [],
      desligamentosAgendados: [],
      motivosDesligamento: [],
      motivosTransferencia: [],
      empresas: [],
      categoriaDependente:[],
      nomeAbreviado:[],
      anexo:[],
      contratosLotacao:[],
      tipoArquivo:[],
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "EMPRESA::FATURAS":
      return { ...state, faturas: action.faturas };
    case "EMPRESA::BOLETOS":
      return { ...state, boletos: action.boletos };
    case "EMPRESA::PLANOS":
      return { ...state, planos: action.planos };
    case "EMPRESA::CONTRATOS_LOTACAO":
      return { ...state, contratosLotacao: action.contratosLotacao };
    case "EMPRESA::BENEFICIARIOS":
      return { ...state, beneficiarios: action.beneficiarios };
    case "EMPRESA::DESLIGAMENTOS_AGENDADOS":
      return { ...state, desligamentosAgendados: action.desligamentosAgendados };
    case "EMPRESA::MOTIVOS_DESLIGAMENTO":
      return { ...state, motivosDesligamento: action.motivosDesligamento };
    case "EMPRESA::MOTIVOS_TRANSFERENCIA":
      return { ...state, motivosTransferencia: action.motivosTransferencia };
    case "EMPRESA::CONTRATO_ATIVO":
      return { ...state, contratoAtivo: action.contratoAtivo };
    case "EMPRESA::EMPRESAS":
      return { ...state, empresas: action.empresas };
    case "EMPRESA::SOLICITACOES":
      return { ...state, solicitacoes: action.solicitacoes };
    case "EMPRESA::CATEGORIA_DEPENDENTE":
      return { ...state, categoriaDependente: action.categoriaDependente };
    case "EMPRESA::NOME_ABREVIADO":
      return { ...state, nomeAbreviado: action.nomeAbreviado };
    case "EMPRESA::ANEXO":
      return { ...state, anexo: action.anexo };
    case "EMPRESA::TIPO_ARQUIVO":
      return { ...state, tipoArquivo: action.tipoArquivo };   
    default:
      return state;
  }
};
