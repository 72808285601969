import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  IModalProps,
  SelectionMode,
  Sticky,
  StickyPositionType,
  TooltipHost,
  IColumn,
  TextField,
  PrimaryButton,
  Stack,
  DefaultButton,
  getTheme
} from "@fluentui/react";

import { classNames } from "./styles";
import { useTranslation, UseTranslationResponse } from "../../i18n";
import { Tabela, Filtro } from "../Tabela";

const tema = getTheme();

interface IPesquisaModal<T = any> extends Omit<IModalProps, "onDismiss"> {
  titulo: string;
  tituloCampoPesquisa?: string;
  tituloBotaoSelecionar?: string;
  placeholderCampoPesquisa?: string;
  columns: Array<IColumn>;
  itens: Array<T>;
  selectionMode?: SelectionMode;
  onDismiss?: (item: T | undefined) => void;
}

const PesquisaModal = <T,>({ isOpen, onDismiss, tituloBotaoSelecionar, itens, ...props }: React.PropsWithChildren<IPesquisaModal<T>>) => {
  const { t }: UseTranslationResponse = useTranslation();
  const [linhaSelecionada, definirLinhaSelecionada] = useState<T>();
  const [filtro, definirFiltro] = useState<string | undefined>();

  useEffect(() => {
    !isOpen && definirFiltro(undefined);
  }, [isOpen])

  const tabela = useMemo(() => (
    <Tabela<T>
      cabecalhoFixo
      styles={{ container: { flex: 1, border: `solid 1px ${tema.semanticColors.bodyFrameDivider}` } }}
      items={itens || []}
      enableShimmer={itens.length === 0}
      shimmerLines={5}
      columns={props.columns}
      selectionMode={props.selectionMode}
      {...(filtro && filtro !== "" ? { filtro : e => Object.values(e).find(e => e?.toString().toUpperCase().indexOf((filtro || "").toUpperCase()) === 0) } : null)}
      selecao={selecao => definirLinhaSelecionada(selecao[0])}
      onItemInvoked={() => {
        onDismiss?.call(null, linhaSelecionada);
      }}
      onRenderDetailsHeader={(props, defaultRender) => props ? (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
          {defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip: tooltipHostProps => <TooltipHost {...tooltipHostProps} />,
          })}
        </Sticky>
      ) : null}
    />
  ), [filtro,/*  linhaSelecionada, */ itens]);

  const okButton = useMemo(() => (
    <PrimaryButton
      text={tituloBotaoSelecionar}
      disabled={!linhaSelecionada}
      onClick={() => {
        onDismiss?.call(null, linhaSelecionada);
      }}
      style={{ minWidth: 104 }}
    />
  ), [linhaSelecionada, tituloBotaoSelecionar, isOpen]);

  return useMemo(() => (
    <Modal
      containerClassName={classNames.container}
      {...props}
      isOpen={isOpen}
      onDismiss={() => {
        onDismiss?.call(null, undefined);
      }}
    >
      <div className={classNames.header}>
        <span>{props.titulo}</span>
      </div>
      <TextField
        className={classNames.input}
        autoFocus
        label={props.tituloCampoPesquisa || ""}
        placeholder={props.placeholderCampoPesquisa || ""}
        iconProps={{ iconName: 'Search' }}
        onChange={(evt, texto) => {
          definirFiltro(texto);
        }}/>
      <div style={{ display: "flex", padding: 16, flex: 1 }}>
        {isOpen && tabela}
      </div>
      <Stack horizontal tokens={{ childrenGap: "1em" }} style={{ padding: 16, justifyContent: "flex-end" }}>
        <DefaultButton text={t("cancelar")} onClick={() => onDismiss?.call(null, undefined)} />
        {okButton}
      </Stack>
    </Modal>
  ), [isOpen, tabela, okButton]);
}

export default PesquisaModal;
