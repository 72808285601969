import React,  { useState } from "react";
import {Modal, Stack, Label, DefaultButton, IModalProps} from "@fluentui/react";
import { styles } from "./styles";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { useDispatch, useSelector } from "react-redux";
import * as BeneficiarioStore from "../../../store/Beneficiario";
import { ApplicationState } from "../../../store";
import {IProcedimento} from "../Reembolso";
import { stringify } from "querystring";


interface IProcedimentoModal extends IModalProps {
  titulo: string;
  itens?: Array<any>;
  selecao?: (itens: IProcedimento) => void;
  isOpen: boolean | undefined;
  onDismiss: () => void;
}

const ProcedimentoModal: React.FC<IProcedimentoModal> = ({isOpen, onDismiss, selecao, titulo}) => {
  const dispatch = useDispatch();
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [valor, setValor] = useState("");
  const [nome, setNome] = useState("");
  const { beneficiario: { procedimento } } = useSelector((store: ApplicationState) => store);

  const fazerLogin = () => {
    dispatch(BeneficiarioStore.actions.obterProcedimento(codigo));
  };
  
  const operacao = parseFloat(valor) * parseFloat(quantidade);

  const Submit = ()  =>{
    console.log("aqui")
    selecao?.call(null,{codigo: codigo, nome:nome, descricao:descricao, quantidade:quantidade, valorUnidade:valor, valorTotal: operacao.toString()});
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      >
      <div style={styles.container}>
        <div>
          <Label style={styles.titulo}>{titulo}</Label>
          <Stack tokens={{ childrenGap: 8 }}>
            <div style={{display:"flex", flexDirection:"row"}}>

              <TextField required label="Código do procedimento" value={codigo} 
              onChange={(_evt, texto) => {
                setCodigo(texto as string);
              }}
              onBlur={fazerLogin}/>
              
            </div>
            
            <TextField disabled label="Procedimento" value={procedimento?.SGESERVI_DESCRICAO}
            onChange={(_evt, texto) => {
              setNome(texto as string);
            }}
            />

            <TextField required label="Quantidade" value={quantidade} 
            onChange={(_evt, texto) => {
                setQuantidade(texto as string);
              }}/>

            <TextField required label="Valor Cobrado (R$)" value={valor}
            onChange={(_evt, texto) => {
              setValor(texto as string);
            }}/>
          
            <TextField label="Descrição Adicional" value={descricao} 
            onChange={(_evt, texto) => {
                setDescricao(texto as string);
              }}
              multiline rows={3}
              />

          </Stack>
          <div style={{display:"flex", flexDirection:"row", justifyContent: "right", marginTop: 15}}>

            <DefaultButton
              //disabled
              text="Adicionar" 
              onClick={Submit}
              />
          </div>
          
        </div>
      </div>;
    </Modal >
  )
}

export default ProcedimentoModal;
