import React, { useCallback, useEffect, useState } from "react";
import { DetailsListLayoutMode, IColumn, Label, PrimaryButton, SelectionMode, Stack, } from "@fluentui/react";
import { TFunction, useTranslation, UseTranslationResponse } from "../../i18n";

import { styles } from "./styles";
import { DataSeletor } from "../../components/DataSeletor";
import { Tabela } from "../../components/Tabela";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as BeneficiarioStore from "../../store/Beneficiario";
import * as EmpresaStore from "../../store/Empresa";
import { obterTipoUsuario, TipoUsuario } from "../../store/Autenticacao";
import { Objeto_Solicitacao } from "../../api";

const obterColunas = (t: TFunction): Array<IColumn> => {
  return ([
    {
      key: 'tipo-solicitacao',
      name: t("tipo-solicitacao"),
      minWidth: 240,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div>{item.TIPO_SOLICITACAO}</div>;
      },
    },
    {
      key: 'em-aberto',
      name: t("em-aberto"),
      minWidth: 100,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.ABERTA && item.ABERTA > 0 ? styles.bolder : null) }}>{item.ABERTA}</div>;
      },
    },
    {
      key: 'atendida',
      name: t("atendida"),
      minWidth: 80,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.ATENDIDA && item.ATENDIDA > 0 ? styles.bolder : null) }}>{item.ATENDIDA}</div>;
      },
    },
    {
      key: 'cancelada',
      name: t("cancelada"),
      minWidth: 80,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.CANCELADA && item.CANCELADA > 0 ? styles.bolder : null) }}>{item.CANCELADA}</div>;
      },
    },
    {
      key: 'divergencia',
      name: t("divergencia"),
      minWidth: 80,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.DIVERGENCIA && item.DIVERGENCIA > 0 ? styles.bolder : null) }}>{item.DIVERGENCIA}</div>;
      },
    },
    {
      key: 'em-analise',
      name: t("em-analise"),
      minWidth: 80,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.EM_ANALISE && item.EM_ANALISE > 0 ? styles.bolder : null) }}>{item.EM_ANALISE}</div>;
      },
    },
    {
      key: 'em-edicao',
      name: t("em-edicao"),
      minWidth: 80,
      maxWidth: 140,
      onRender: (item: Objeto_Solicitacao.Resposta) => {
        return <div style={{ ...styles.celulaCento, ...(item.EM_EDICAO && item.EM_EDICAO > 0 ? styles.bolder : null) }}>{item.EM_EDICAO}</div>;
      },
    },
  ] as Array<IColumn>).map((c) => {
    if (!c.styles) c.styles = { cellTitle: { justifyContent: "center" } };

    return c;
  });
}

const ManutencaoSolicitacoes: React.FC = () => {
  
  const { autenticacao: { usuario }, beneficiario, empresa } = useSelector((store: ApplicationState) => store);
  const dispatch = useDispatch<any>()

  const { t }: UseTranslationResponse = useTranslation();;

  const tipoUsuario = obterTipoUsuario(usuario);
  const solicitacoes = tipoUsuario === TipoUsuario.BENEFICIARIO ? beneficiario.solicitacoes : empresa.solicitacoes;

  const [carregando, setCarregando] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState<Date | undefined>(new Date().chain(data => data.setMonth(data.getMonth() - 3).chain(() => data)));
  const [dataLimite, setDataLimite] = useState<Date | undefined>(new Date());

  const obterDados = useCallback(() => {
    if (usuario) {
      setCarregando(true);

      if (tipoUsuario === TipoUsuario.BENEFICIARIO) {
        dispatch(BeneficiarioStore.actions.obterSolicitacoes(dataInicial, dataLimite)).finally(() => setCarregando(false));
      } else {
        dispatch(EmpresaStore.actions.obterSolicitacoes(dataInicial, dataLimite)).finally(() => setCarregando(false));
      }
    }
  }, [dispatch, usuario, dataInicial, dataLimite]);

  useEffect(() => {
    obterDados();
  }, []);

  return (
    <div style={styles.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Label style={styles.title}>{t("manutencao-solicitacoes")}</Label>
        <Stack horizontal style={{ alignItems: "center" }}>
          <Label>{t("periodo-solicitacao")}</Label>
          <DataSeletor
            value={dataInicial}
            onSelectDate={data => setDataInicial(data || undefined)}
            placeholder={t("data-inicial")}
            formatDate={(data?: Date) => data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1") || ""}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <Label>{t("ate")}</Label>
          <DataSeletor
            value={dataLimite}
            onSelectDate={data => setDataLimite(data || undefined)}
            minDate={dataInicial}
            placeholder={t("data-final")}
            formatDate={(data?: Date) => data?.toISOString().replace(/(\d{4})-(\d{2})-(\d{2})(.+)/, "$3/$2/$1") || ""}
            style={{ maxWidth: 112, marginRight: 8, marginLeft: 8 }}
          />
          <PrimaryButton text={t("buscar")} disabled={false} onClick={obterDados} />
        </Stack>
      </div>

      <Stack style={styles.body} tokens={{ childrenGap: "1em" }}>
        <Tabela
          cabecalhoFixo
          styles={{ root: { height: "100%", minHeight: 320 } }}
          items={solicitacoes || []}
          compact={false}
          columns={obterColunas(t)}
          enableShimmer={!solicitacoes}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enterModalSelectionOnTouch={true}
        />
      </Stack>
    </div>
  );
}

export default ManutencaoSolicitacoes;
